export function formatFileInfo(newFiles) {
  return newFiles.reduce((accumulator, curr) => {
    accumulator.push({
      filename: curr.name,
      file_size: curr.size,
      content_type: curr.type,
    });
    return accumulator;
  }, []);
}
