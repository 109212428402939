import { makeStyles } from "@mui/styles";
export const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    overflowWrap: "anywhere",
  },
  folder: {
    fontSize: "8em",
    fill: "#f0dc82",
  },
  document: {
    fontSize: "8em",
    fill: "cornflowerblue",
  },
  form: {
    fontSize: "8em",
    fill: "darkcyan",
  },
  sheet: {
    fontSize: "8em",
    fill: "mediumslateblue",
  },
  report: {
    fontSize: "8em",
    fill: "#9c27b0",
  },
  description: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  descriptionContainer: {
    padding: "0.5em",
    textAlign: "start",
    overflow: "hidden",
  },
  filename: {
    fontSize: "1.15rem",
    wordBreak: "break-word",
    color: "black",
  },
  icon: { display: "flex", height: "100%", alignItems: "center", justifyContent: "center", width: "fit-content" },
};
export const useStyles = makeStyles((theme) => ({
  toolbar1: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 0.5em 0 0.5em",
  },
  folderContainer: { display: "flex", flexWrap: "wrap", justifyContent: "baseline" },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  folderPaper: { height: "100%", overflowY: "auto", borderRadius: "0" },
  loadingEmpty: {
    height: "75%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 0.5em 0 0.5em",
  },
}));
