export function forceDownload(url, filename) {
  if (!url || !filename) return;
  fetch(url)
    .then((res) => {
      return res.blob().then((blob) => {
        let anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(blob);
        anchor.setAttribute("download", filename);
        anchor.click();
      });
    })
    .catch((e) => console.log("fail", { e }));
}
