import React, { useState } from "react";
import { Tabs, Tab, Tooltip, Fab } from "@mui/material";
import { Dashboard } from "../../Dashboard/Dashboard";
import { Breadcrumb } from "../../Dashboard/Components/Breadcrumbs";
import { a11yProps, TabPanel } from "./helpers";
import { UserTable } from "./UserTable";
import { InvitationTable } from "./InvitationTable";
import { InvitationDialog } from "./InvitationDialog";
import { Send } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useInvitations } from "./useInvitations";
import { SnackbarAlertRedux } from "../../Documentation/Components/SnackbarAlert";
import { styled } from "@mui/material/styles";

const CustomTabPanel = styled(TabPanel)`
  & .MuiBox-root {
    height: 100%;
  }
`;
export function Users() {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = React.useState(0);
  const handleChangeTab = (_, newValue) => {
    setCurrentTab(newValue);
  };
  const [email, setEmail] = useState("");
  const roles = useSelector((state) => state.roles.roles);
  const [checkboxes, setCheckboxes] = useState(null);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [open, setOpen] = useState(false);
  const [pendingOrgInvites, setPendingOrgInvites] = useState([]);
  const alert1 = useSelector((state) => state.documentation.alert);
  const severity1 = useSelector((state) => state.documentation.severity);
  useInvitations(dispatch, setCheckboxes, setPendingOrgInvites);
  return (
    <Dashboard
      pageName="Users"
      breadcrumbs={
        <Breadcrumb
          currentValue={"Users"}
          linkValues={[
            { name: "Home", to: "/" },
            { name: "Admin", to: "/admin" },
          ]}
        />
      }
    >
      <div>
        <Tabs value={currentTab} onChange={handleChangeTab} aria-label="Users/Invitations">
          <Tab label="Active" {...a11yProps(0)} />
          <Tab label="Invited" {...a11yProps(1)} />
        </Tabs>
      </div>
      <CustomTabPanel value={currentTab} index={0} style={{ overflowY: "auto" }}>
        <UserTable />
      </CustomTabPanel>
      <CustomTabPanel value={currentTab} index={1} style={{ overflowY: "auto" }}>
        <InvitationTable
          setPendingOrgInvites={setPendingOrgInvites}
          loadingInvite={loadingInvite}
          setLoadingInvite={setLoadingInvite}
          pendingOrgInvites={pendingOrgInvites}
        />
      </CustomTabPanel>
      <InvitationDialog
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
        email={email}
        setEmail={setEmail}
        setLoadingInvite={setLoadingInvite}
        dispatch={dispatch}
        roles={roles}
        setPendingOrgInvites={setPendingOrgInvites}
        open={open}
        setOpen={setOpen}
      />

      <Tooltip title="Invite" placement="left">
        <Fab
          style={{
            position: "absolute",
            bottom: "2em",
            right: "2em",
          }}
          color="primary"
          key={"Add"}
          aria-label="add"
          onClick={() => setOpen(true)}
        >
          <Send />
        </Fab>
      </Tooltip>
      <SnackbarAlertRedux alert={alert1} severity={severity1} />
    </Dashboard>
  );
}
