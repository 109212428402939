import { makeStyles } from "@mui/styles";
import {
  Badge,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  TextField,
  Typography,
  Collapse,
} from "@mui/material";
import {
  FolderOpenTwoTone,
  InsertDriveFileTwoTone,
  AssignmentTurnedInTwoTone,
  ListAltTwoTone,
  Today,
  Event,
  Search,
  FilterList,
  FolderSpecial,
  Cancel,
  SummarizeTwoTone,
} from "@mui/icons-material/";
import { GridOverlay, DataGridPro } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "../../Utils/history";
import { Breadcrumb } from "../Dashboard/Components/Breadcrumbs";
import { Dashboard } from "../Dashboard/Dashboard";
import { setSearch } from "./Reducers/SearchReducer";
import { getSearch } from "./Reducers/Thunks/getSearch";
import { last30days, now } from "../Viewer/Components/SpreadsheetViewer/helpers";
import "./dataGridStyles.css";
import {
  deselectShapes,
  setContainedSize,
  setQuestions,
  setScaleFactor,
  setShapes,
  setTree,
} from "../Editor/Reducers/EditorReducer";
export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  table: {
    minWidth: 700,
    tableLayout: "fixed",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  pagination: {
    display: "flex",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    position: "sticky",
    width: "100%",
    bottom: 0,
  },
  filename: {
    fontSize: "1em",
    wordBreak: "break-word",
    color: "black",
  },
  description: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
}));
const styles = {
  paper: {
    display: "flex",
    alignItems: "center",
  },
  paperDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "0 1em",
    margin: "8px",
  },
};
export const SearchResults = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const results = useSelector((state) => state.search.results);
  const ancestors = useSelector((state) => state.documentation.currentFolder.ancestors);
  const folderName = useSelector((state) => state.documentation.currentFolder.folder.name);
  const folderID = useSelector((state) => state.documentation.currentFolder.folder.id);
  const ancestorBreadcrumbs = ancestors.map((x) => ({
    name: x.name,
    to: "/documentation/browse/" + x.id,
  }));

  const [datetime, setDatetime] = useState({
    modified_after: last30days(),
    modified_before: now(),
    created_after: last30days(),
    created_before: now(),
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [types, setTypes] = useState({ presentation: true, form: true, sheet: true, folder: true });
  const [selected, setSelected] = useState({
    inFolder: false,
    types: false,
    created: false,
    modified: false,
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const columns = [
    {
      field: "Name",
      flex: 2,
      renderCell: (params) => {
        const { id, DoctypeIcon, name, description, doctype } = params.value;
        return (
          <ListItem
            style={{ display: "flex", alignItems: "center", padding: 0, cursor: "pointer" }}
            onClick={() =>
              doctype ? history.push(`/documentation/view/${id}`) : history.push(`/documentation/browse/${id}`)
            }
          >
            {DoctypeIcon}
            <div style={{ paddingLeft: "1em" }}>
              <Typography className={classes.filename}>{name}</Typography>
              {description && (
                <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
                  {`${description}`}
                </Typography>
              )}
            </div>
          </ListItem>
        );
      },
      sortComparator: (a, b) => {
        return String(a.name) > String(b.name) ? 1 : -1;
      },
    },
    { field: "Created", flex: 1 },
    { field: "Modified", flex: 1 },
    { field: "Folder", flex: 1 },
    { field: "Type", flex: 1 },
  ];
  const rows = results
    ? results.map((result, index) => {
        const { id, name, doctype, description, created_at, modified_at, path } = result;
        const pathname = path[path.length - 1].name;
        let [monthCreated, dateCreated, yearCreated] = new Date(created_at).toLocaleDateString("en-US").split("/");
        let [hourCreated, minuteCreated, , pmCreated] = new Date(created_at).toLocaleTimeString("en-US").split(/:| /);
        let [monthModified, dateModified, yearModified] = new Date(modified_at).toLocaleDateString("en-US").split("/");
        let [hourModified, minuteModified, , pmModified] = new Date(modified_at)
          .toLocaleTimeString("en-US")
          .split(/:| /);

        const DoctypeIcon =
          doctype === "sheet" ? (
            <ListAltTwoTone style={{ fill: "mediumslateblue" }} />
          ) : doctype === "form" ? (
            <AssignmentTurnedInTwoTone style={{ fill: "darkcyan" }} />
          ) : doctype === "presentation" ? (
            <InsertDriveFileTwoTone style={{ fill: "cornflowerblue" }} />
          ) : doctype === "report" ? (
            <SummarizeTwoTone style={{ fill: "#9c27b0" }} />
          ) : (
            <FolderOpenTwoTone style={{ fill: "#f0dc82" }} />
          );
        return {
          id: index,
          doctype,
          Name: { id, DoctypeIcon, name, description, doctype },

          Created: `${dateCreated}/${monthCreated}/${yearCreated} ${hourCreated}:${minuteCreated} ${pmCreated}`,
          Modified: `${dateModified}/${monthModified}/${yearModified} ${hourModified}:${minuteModified} ${pmModified}`,
          Folder: pathname,
          Type:
            doctype === "sheet"
              ? "Spreadsheet"
              : doctype === "form"
              ? "Checklist"
              : doctype === "presentation"
              ? "Presentation"
              : doctype === "report"
              ? "Report"
              : "Folder",
        };
      })
    : [];

  const handleInFolder = () => setSelected((state) => ({ ...state, inFolder: !state.inFolder }));

  useEffect(() => {
    dispatch(setQuestions({ reset: true }));
    dispatch(setTree({ reset: true }));
    dispatch(setShapes({ shapes: [] }));
    dispatch(setScaleFactor({ scaleX: null, scaleY: null }));
    dispatch(setContainedSize({ width: 0, height: 0 }));
    dispatch(deselectShapes());
  });
  return (
    <Dashboard
      pageName={`Search`}
      breadcrumbs={
        <Breadcrumb
          currentValue={"Search"}
          linkValues={[
            { name: "Home", to: "/" },
            ancestorBreadcrumbs.length > 0 && { name: "Documentation", to: "/documentation/browse" },
            ...(ancestorBreadcrumbs.length > 1 ? ancestorBreadcrumbs.slice(1) : ""),
            folderName
              ? {
                  name: folderName === "root" ? "Documentation" : folderName,
                  to: `/documentation/browse/${folderID}`,
                }
              : {
                  name: "Documentation",
                  to: `/documentation/browse/`,
                },
          ]}
        />
      }
    >
      <Paper className={classes.root}>
        <div style={{ display: "flex" }}>
          {AdvancedSearchInput({ datetime, types, selected })}
          <IconButton variant="contained" aria-describedby={id} onClick={handleClick} color="primary">
            <Badge badgeContent={0} color="secondary">
              <FilterList color="primary" />
            </Badge>
          </IconButton>
        </div>
        <Collapse in={selected.inFolder}>
          <Paper variant="outlined" style={styles.paper}>
            <div style={styles.paperDiv}>
              <Typography>Only In: {folderName}</Typography>
            </div>
            <IconButton onClick={handleInFolder}>
              <Cancel style={{ color: "#ad2b2b", cursor: "pointer" }} />
            </IconButton>
          </Paper>
        </Collapse>

        <Collapse in={selected.types}>
          <Paper variant="outlined" style={styles.paper}>
            <div style={styles.paperDiv}>
              <Typography>Types:</Typography>
              <FormControl size="small" component="fieldset" className={classes.formControl}>
                <FormGroup style={{ flexDirection: "row" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={types.folder}
                        onChange={() => setTypes((state) => ({ ...state, folder: !state.folder }))}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label="Folders"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={types.presentation}
                        onChange={() => setTypes((state) => ({ ...state, presentation: !state.presentation }))}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label="Presentations"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={types.sheet}
                        onChange={() => setTypes((state) => ({ ...state, sheet: !state.sheet }))}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label="Spreadsheets"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={types.form}
                        onChange={() => setTypes((state) => ({ ...state, form: !state.form }))}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label="Checklists"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <IconButton onClick={() => setSelected((state) => ({ ...state, types: !state.types }))}>
              <Cancel style={{ color: "#ad2b2b", cursor: "pointer" }} />
            </IconButton>
          </Paper>
        </Collapse>

        <Collapse in={selected.created}>
          <Paper variant="outlined" style={styles.paper}>
            <div style={styles.paperDiv}>
              <Typography
                component="span"
                style={{
                  paddingRight: "1em",
                }}
              >
                Date Created:
              </Typography>
              {CreatedAfter(setDatetime, datetime)}
              {CreatedBefore(setDatetime, datetime)}
            </div>
            <IconButton onClick={() => setSelected((state) => ({ ...state, created: !state.created }))}>
              <Cancel style={{ color: "#ad2b2b", cursor: "pointer" }} />
            </IconButton>
          </Paper>
        </Collapse>
        <Collapse in={selected.modified}>
          <Paper variant="outlined" style={{ display: "flex", alignItems: "center" }}>
            <div style={styles.paperDiv}>
              <Typography
                component="span"
                style={{
                  paddingRight: "1em",
                }}
              >
                Date Modified:
              </Typography>
              {ModifiedAfter(setDatetime, datetime)}
              {ModifiedBefore(setDatetime, datetime)}
            </div>
            <IconButton onClick={() => setSelected((state) => ({ ...state, modified: !state.modified }))}>
              <Cancel style={{ color: "#ad2b2b", cursor: "pointer" }} />
            </IconButton>
          </Paper>
        </Collapse>
        <div style={{ height: "calc(100% - 56px)", width: "100%" }}>
          <DataGridPro
            initialState={{
              sorting: {
                sortModel: [{ field: "Type", sort: "asc" }],
              },
            }}
            components={{
              NoRowsOverlay: () => <GridOverlay style={{ flexDirection: "column" }}>No Results Found</GridOverlay>,
              Footer: () => (
                <div style={{ margin: "0px 16px", display: "flex", alignItems: "center" }}>
                  Total Results: {results ? results.length : 0}
                </div>
              ),
            }}
            columns={columns}
            rows={rows}
          />
        </div>
      </Paper>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {folderName && folderName !== "root" ? (
          <ListItem
            button
            aria-label={`In ${folderName}`}
            style={{ textDecoration: selected.inFolder ? "line-through" : "none" }}
            onClick={() => setSelected((state) => ({ ...state, inFolder: !state.inFolder }))}
          >
            <ListItemIcon>
              <FolderSpecial style={{ fill: "#f0dc82" }} />
            </ListItemIcon>
            <ListItemText>Only In {folderName}</ListItemText>
          </ListItem>
        ) : (
          ""
        )}
        <ListItem
          button
          aria-label="Type"
          style={{ textDecoration: selected.types ? "line-through" : "none" }}
          onClick={() => setSelected((state) => ({ ...state, types: !state.types }))}
        >
          <ListItemIcon>
            <InsertDriveFileTwoTone style={{ fill: "cornflowerblue" }} />
          </ListItemIcon>
          <ListItemText>By Type</ListItemText>
        </ListItem>
        <ListItem
          button
          aria-label="Created From"
          style={{ textDecoration: selected.created ? "line-through" : "none" }}
          onClick={() => setSelected((state) => ({ ...state, created: !state.created }))}
        >
          <ListItemIcon>
            <Today style={{ fill: "#3f51b5" }} />
          </ListItemIcon>
          <ListItemText>Date Created</ListItemText>
        </ListItem>
        <ListItem
          button
          aria-label="Modified From"
          style={{ textDecoration: selected.modified ? "line-through" : "none" }}
          onClick={() => setSelected((state) => ({ ...state, modified: !state.modified }))}
        >
          <ListItemIcon>
            <Event style={{ fill: "#9b36f4" }} />
          </ListItemIcon>
          <ListItemText>Date Modified</ListItemText>
        </ListItem>
      </Popover>
    </Dashboard>
  );
};

function AdvancedSearchInput({ datetime, types, selected }) {
  const dispatch = useDispatch();
  const query = useSelector((state) => state.search.q);
  const folderID = useSelector((state) => state.documentation.currentFolder.folder.id);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      getSearch({
        q: query,
        types: [
          ...(types.presentation ? ["presentation"] : []),
          ...(types.form ? ["form"] : []),
          ...(types.sheet ? ["sheet"] : []),
        ],
        ...(selected.created ? { created_before: datetime.created_before, created_after: datetime.created_after } : {}),
        ...(selected.modified
          ? {
              modified_before: datetime.modified_before,
              modified_after: datetime.modified_after,
            }
          : {}),
        in_folder: selected.inFolder ? folderID : false,
        include_folders: types.folder,
      })
    ).then((res) => {
      dispatch(setSearch({ results: res.data.results }));
    });
  };
  return (
    <TextField
      fullWidth
      placeholder="Search..."
      id="advanced-search"
      onClick={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
      onChange={(e) => {
        dispatch(setSearch({ q: e.target.value }));
      }}
      onKeyPress={(e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
          handleSubmit(e);
        }
      }}
      value={query || ""}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <IconButton onClick={handleSubmit} color="primary" aria-label="search">
            <Search />
          </IconButton>
        ),
      }}
    />
  );
}

function CreatedBefore(setDatetime, datetime) {
  return (
    <TextField
      size="small"
      style={{ paddingRight: "1em" }}
      id="Created Before"
      label="End"
      onChange={(e) => setDatetime((state) => ({ ...state, created_before: e.target.value }))}
      type="date"
      value={datetime.created_before}
    />
  );
}

function CreatedAfter(setDatetime, datetime) {
  return (
    <TextField
      size="small"
      style={{ paddingRight: "1em" }}
      id="Created After"
      label="Start"
      onChange={(e) => setDatetime((state) => ({ ...state, created_after: e.target.value }))}
      type="date"
      value={datetime.created_after}
    />
  );
}

function ModifiedBefore(setDatetime, datetime) {
  return (
    <TextField
      size="small"
      style={{ paddingRight: "1em" }}
      label="End"
      id="Modified Before"
      onChange={(e) => setDatetime((state) => ({ ...state, modified_before: e.target.value }))}
      type="date"
      value={datetime.modified_before}
    />
  );
}

function ModifiedAfter(setDatetime, datetime) {
  return (
    <TextField
      size="small"
      id="Modified After"
      style={{ paddingRight: "1em" }}
      label="Start"
      onChange={(e) => setDatetime((state) => ({ ...state, modified_after: e.target.value }))}
      type="date"
      value={datetime.modified_after}
    />
  );
}
