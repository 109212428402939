import { useEffect } from "react";
import isEqual from "lodash.isequal";
import { useDispatch, useSelector } from "react-redux";
import { setIsBlockingViewer } from "../../Documentation/Reducers/DocumentationReducer";

export function useLocalStorageQuestions() {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.editor?.questions);
  const contentsQuestions = useSelector((state) => state.documentation.currentDocument?.contents?.questions);
  const isLoaded = questions && JSON.stringify(questions) !== "{}";
  const username = useSelector((state) => state.login.username);
  const orgID = useSelector((state) => state.login.org?.id);
  const docID = useSelector((state) => state.documentation?.currentDocument?.id);
  const docName = useSelector((state) => state.documentation?.currentDocument?.name);
  const uid = `${username}_${orgID}`;
  const checklistUID = `${docName}---${docID}`;
  useEffect(() => {
    if (isLoaded && !isEqual(questions, contentsQuestions)) {
      const areQuestionsPartiallyComplete = Object.keys(questions).every((q) => {
        let question = questions[q];
        let noComments = question.comments === "";
        let noAnswers =
          question.values === "not checked" ||
          question.values === "" ||
          (Array.isArray(question.values) && question.values.length === 0);
        let noVerification = question.verification === "";
        return noComments && noAnswers && noVerification;
      });
      if (!areQuestionsPartiallyComplete) {
        if (localStorage.getItem(uid)) {
          let checklists = JSON.parse(localStorage.getItem(uid));
          let modifiedChecklists = Object.keys(checklists).reduce((acc, cur) => {
            if (String(cur) === String(checklistUID)) {
              acc[checklistUID] = questions;
            } else {
              acc[cur] = checklists[cur];
            }
            return acc;
          }, {});
          if (!modifiedChecklists[checklistUID]) {
            modifiedChecklists[checklistUID] = questions;
          }
          dispatch(setIsBlockingViewer({ isBlockingViewer: true }));
          localStorage.setItem(uid, JSON.stringify(modifiedChecklists));
        } else {
          dispatch(setIsBlockingViewer({ isBlockingViewer: true }));
          localStorage.setItem(uid, JSON.stringify({ [checklistUID]: questions }));
        }
      }
    }
  }, [dispatch, questions, contentsQuestions, checklistUID, uid, isLoaded]);
}
