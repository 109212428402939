export const styles = {
  folder: {
    fontSize: "8em",
    fill: "#f0dc82",
  },
  document: {
    fontSize: "8em",
    fill: "cornflowerblue",
  },
  form: {
    fontSize: "8em",
    fill: "darkcyan",
  },
  sheet: {
    fontSize: "8em",
    fill: "mediumslateblue",
  },
};
export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
