import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFolder } from "../../Reducers/thunks";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Alert,
} from "@mui/material";
import { documentationSelector } from "../../Reducers/DocumentationReducer";
import { FolderOpenTwoTone } from "@mui/icons-material";
export const NewFolderDialog = (props) => {
  const dispatch = useDispatch();
  const { currentFolder } = useSelector(documentationSelector);
  const { onCreated, onClose, ...passthroughProps } = props;
  const [name, setName] = useState(() => "");
  const [description, setDescription] = useState(() => "");
  const [error, setError] = useState();
  const onChangeName = (e) => setName(e.target.value);
  const onChangeDesc = (e) => setDescription(e.target.value);
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createFolder({
        parent_id: currentFolder.folder.id,
        name,
        description,
      })
    )
      .then((value) => {
        onClose();
        onCreated(value);
      })
      .catch(function (err) {
        if (err.response) {
          const detail = err.response.data.detail;
          if (typeof detail === "string") {
            setError(detail);
          } else if (Array.isArray(detail)) {
            detail.forEach((e) => {
              console.log(e);
            });
          }
        }
        return Promise.reject(err);
      });
  };
  const resetForm = () => {
    setName("");
    setDescription("");
    setError();
  };
  return (
    <Dialog onClose={onClose} TransitionProps={{ onEntering: resetForm }} {...passthroughProps}>
      <div style={{ display: "flex" }}>
        <FolderOpenTwoTone
          style={{
            fontSize: "4em",
            fill: "#f0dc82",
          }}
        />
        <DialogTitle id="form-dialog-title">New Folder</DialogTitle>
      </div>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <DialogContentText>To create a new folder, enter a name and optional description below</DialogContentText>
          {error && <Alert severity="error">{error}</Alert>}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Folder name"
            type="text"
            value={name}
            onChange={onChangeName}
            fullWidth
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            value={description}
            onChange={onChangeDesc}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type={"submit"} style={{ color: "#097c09" }}>
            Create Folder
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
