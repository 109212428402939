import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { usePrevious } from "../../Documentation/Helpers/helpers";
import { resetFileObjQueue, setSelectedThumbnail } from "../../Documentation/Reducers/DocumentationReducer";
import { getDocument } from "../../Documentation/Reducers/thunks";
import { getDocumentVersion } from "../../Documentation/Reducers/Thunks/getDocumentVersion";

export function useInitializeDocument({ isEditor }) {
  let { id, version_number } = useParams();
  const dispatch = useDispatch();
  const prevID = usePrevious(id);
  const currentFolderId = useSelector((state) => state.documentation?.currentFolder?.folder?.id);
  const prevLocation = useSelector((state) => state.documentation?.prevLocation);
  const isPrevLocation = (prevLocation ? prevLocation.split("/")[prevLocation.split("/").length - 1] : null) !== id;
  useLayoutEffect(() => {
    if (prevID !== id && isPrevLocation) {
      if (!isEditor) {
        dispatch(resetFileObjQueue());
      }
      if (version_number) {
        dispatch(getDocumentVersion({ id, version: version_number }));
        dispatch(setSelectedThumbnail({ value: 0 }));
      } else if (!version_number) {
        dispatch(getDocument({ id, isEditor, currentFolderId }));
        dispatch(setSelectedThumbnail({ value: 0 }));
      }
    }
  }, [dispatch, prevID, id, isEditor, version_number, currentFolderId, isPrevLocation]);
}
