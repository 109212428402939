import React from "react";
import {
  FormControl,
  FormGroup,
  Checkbox,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Dialog,
  Slide,
} from "@mui/material";
import { isValidEmail } from "./helpers";
import { listPendingOrgInvites } from "../../Dashboard/Thunks/listPendingOrgInvites";
import { inviteUser } from "../../Dashboard/Thunks/inviteUser";
import { setAlert } from "../../Documentation/Reducers/DocumentationReducer";

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function InvitationDialog({
  checkboxes,
  setCheckboxes,
  email,
  setEmail,
  setLoadingInvite,
  dispatch,
  roles,
  setPendingOrgInvites,
  open,
  setOpen,
}) {
  return (
    <Dialog TransitionComponent={Transition} fullWidth open={open} onClose={() => setOpen(false)}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1em",
        }}
      >
        <Typography variant="h5">Send Invitation</Typography>
        <TextField label="Email" value={email || ""} onChange={(e) => setEmail(e.target.value)} />
        {checkboxes && <Typography variant="h6">Initial Roles:</Typography>}
        {checkboxes ? (
          <FormControl style={{ maxHeight: "10em", overflow: "auto" }} component="div">
            <FormGroup>
              {Object.keys(checkboxes)
                .sort()
                .map((role, i) => {
                  const isDescription = roles.filter((r) => r.name === role)[0]?.description;
                  return (
                    <FormControlLabel
                      key={role + i}
                      control={
                        <Checkbox
                          checked={checkboxes[role]}
                          onChange={(e) => setCheckboxes((state) => ({ ...state, [e.target.name]: e.target.checked }))}
                          name={role}
                        />
                      }
                      label={`${role}${isDescription ? `: ${isDescription}` : ""}`}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        ) : (
          ""
        )}
        <Button
          variant="outlined"
          color="primary"
          disabled={!email || !isValidEmail.test(email)}
          onClick={(e) => {
            if (!email) return;
            setLoadingInvite(true);
            setOpen(false);
            setEmail("");
            dispatch(
              inviteUser({
                email,
                roles: checkboxes
                  ? Object.keys(checkboxes).reduce((acc, cur) => {
                      if (checkboxes[cur]) {
                        acc.push(roles.find((r) => r.name === cur).id);
                      }
                      return acc;
                    }, [])
                  : [],
              })
            )
              .then(() => {
                dispatch(listPendingOrgInvites()).then((res) => {
                  setPendingOrgInvites(res?.data?.results);
                  dispatch(setAlert({ alert: `Invitation Sent To ${email}.`, severity: "success" }));
                  setCheckboxes(
                    checkboxes
                      ? Object.keys(checkboxes).reduce((acc, cur) => {
                          acc[cur] = false;
                          return acc;
                        }, [])
                      : []
                  );
                });
                setLoadingInvite(false);
              })
              .catch((err) => {
                setLoadingInvite(false);
                dispatch(setAlert({ alert: `${err.response.data.detail}`, severity: "error" }));
              });
          }}
        >
          Send Invite
        </Button>
      </div>
    </Dialog>
  );
}
