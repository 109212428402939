export function FormatMuiDate(year, month, day) {
  return `${year}-${month.length === 1 ? "0" + month : month}-${day.length === 1 ? "0" + day : day}`;
}

export const now = () => {
  const [month, day, year] = new Date().toLocaleDateString("en-US").split("/");
  return FormatMuiDate(year, month, day);
};

export const last30days = () => {
  let [month, day, year] = new Date(new Date().setDate(new Date().getDate() - 30))
    .toLocaleDateString("en-US")
    .split("/");
  return FormatMuiDate(year, month, day);
};

export const toUnixTimestamp = (date) => new Date(date).getTime() / 1000;

export const byDate = (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at);
