import { Paper } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Breadcrumb } from "../../Dashboard/Components/Breadcrumbs";
import { Dashboard } from "../../Dashboard/Dashboard";
import { getActivityLog } from "../Thunks/getActivityLog";
import { formatDatetime } from "./Helpers/formatDatetime";
import { activityResponse } from "./Helpers/activityResponse";

export function ActivityLog() {
  const [results, setResults] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getActivityLog()).then((res) => {
      setResults(res.data.results);
    });
  }, [dispatch]);
  const columns = [
    {
      field: "Datetime",
      flex: 1,
    },
    {
      field: "Activity",
      flex: 4,
      renderCell: (params) => params.value,
    },
  ];

  const rows = results
    ? results.map((res, index) => {
        const date = new Date(res.activity_time);
        const Activity = activityResponse(res);
        return {
          id: index,
          Datetime: formatDatetime(date),
          Activity,
        };
      })
    : [];

  return (
    <Dashboard
      pageName="Activity Log"
      breadcrumbs={
        <Breadcrumb
          currentValue={"Activity Log"}
          linkValues={[
            { name: "Home", to: "/" },
            { name: "Admin", to: "/admin" },
          ]}
        />
      }
    >
      <Paper style={{ height: "100%" }}>
        <DataGridPro columns={columns} rows={rows} />
      </Paper>
    </Dashboard>
  );
}
