import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setQuestions } from "../../Editor/Reducers/EditorReducer";
import { setQuestionIndex } from "../../Documentation/Reducers/DocumentationReducer";

export function useSetQuestions() {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.editor?.questions);
  const question_index = useSelector((state) => state.documentation?.currentDocument?.contents?.question_index);
  const questionsContents = useSelector((state) => state.documentation.currentDocument?.contents?.questions);
  const loading = useSelector((state) => state.documentation.loading);
  const username = useSelector((state) => state.login?.username);
  const docID = useSelector((state) => state.documentation?.currentDocument?.id);
  const orgID = useSelector((state) => state.login.org?.id);
  const docName = useSelector((state) => state.documentation?.currentDocument?.name);
  const checklistUID = `${docName}---${docID}`;
  const uid = `${username}_${orgID}`;

  useEffect(() => {
    const areThereValues = (a, c) => {
      if (!questions[c].hasOwnProperty("values")) a = true;
      return a;
    };
    const noQuestions = !questions;
    const valuesAreEmpty = questions && Object.keys(questions).reduce(areThereValues, false);
    const questionsExist = JSON.stringify(questionsContents) !== "{}";
    if (
      username &&
      orgID &&
      (noQuestions || (valuesAreEmpty && !loading && questionsExist && questionsContents && localStorage))
    ) {
      let storedChecklists = JSON.parse(localStorage.getItem(uid));
      let isChecklistStored = storedChecklists && storedChecklists[checklistUID];
      const combinedQuestions = questionsContents
        ? Object.keys(questionsContents).reduce((acc, cur) => {
            if (isChecklistStored && isChecklistStored.hasOwnProperty(cur)) {
              acc[cur] = {
                ...questionsContents[cur],
                choices: isChecklistStored[cur].choices,
                ...(isChecklistStored[cur]?.values
                  ? { values: isChecklistStored[cur]?.values }
                  : { values: "not checked" }),
              };
            } else {
              acc[cur] = { ...questionsContents[cur], values: "not checked" };
            }
            return acc;
          }, {})
        : {};
      dispatch(
        setQuestions({
          questions: combinedQuestions,
          isEditor: false,
          ...(isChecklistStored ? { isLocalStorage: true } : { isLocalStorage: false }),
        })
      );
      dispatch(setQuestionIndex({ initialize: question_index }));
    }
  }, [dispatch, questionsContents, question_index, questions, checklistUID, loading, uid, username, orgID]);
}
