import React from "react";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import { NoteAdd, Close, CreateNewFolder, Add } from "@mui/icons-material/";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";
import { Perm } from "./Dialogs/helpers";
export default function DocumentationSpeedDial({ onAddFolder, onAddDocument }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);
  const user_permissions = useSelector((state) => state.documentation?.currentFolder?.folder?.user_permissions);
  const permList = Perm.getLabelList(user_permissions);

  return permList.includes("Write") ? (
    <SpeedDial
      ariaLabel="SpeedDial"
      className={classes.speedDial}
      icon={<Add />}
      openIcon={<Close />}
      onOpen={(e) => {
        if (e.type === "click") {
          setClicked(true);
          setOpen(true);
        }
        if (e.type === "mouseenter") {
          setOpen(true);
        }
      }}
      onClose={(e) => {
        if (e.type === "click") {
          if (clicked) {
            setOpen(false);
            setClicked(false);
          } else {
            setClicked(true);
          }
        }
        if (e.type === "mouseleave" && !clicked) {
          setOpen(false);
        }
      }}
      open={open}
    >
      <SpeedDialAction
        key={"Add Document"}
        icon={<NoteAdd />}
        tooltipTitle={"Document"}
        tooltipOpen
        tooltipPlacement={"left"}
        onClick={(e) => {
          e.preventDefault();
          onAddDocument();
          setOpen(false);
        }}
        TooltipClasses={classes}
      />
      <SpeedDialAction
        key={"Add Folder"}
        icon={<CreateNewFolder />}
        tooltipTitle={"Folder"}
        tooltipOpen
        tooltipPlacement={"left"}
        onClick={(e) => {
          e.preventDefault();
          onAddFolder();
          setOpen(false);
        }}
        TooltipClasses={classes}
      />
    </SpeedDial>
  ) : (
    ""
  );
}
