import React from "react";
import { Toolbar, AppBar, Typography } from "@mui/material";
import { useStyles } from "../styles";

export const LoginAppBar = () => {
  const classes = useStyles();
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography edge="start" variant="h6" className={classes.title}>
          Login
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
