import { Button, Fade, Modal, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import { useStyles } from "../styles";
export function QRLinkModal({ openQR, setOpenQR, value, onClose = () => setOpenQR(false), name }) {
  const classes = useStyles();
  const printQR = () => {
    let divContents = document.getElementById("QRCode").innerHTML;
    let a = window.open("", "", "height=500, width=500");
    a.document.write(`<html><body><h1>${name}</h1><br />${divContents}</body></html>`);
    a.print();
    a.document.close();
    a.close();
    return true;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openQR}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={openQR}>
        <div className={classes.modalPaper}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ margin: "0 auto" }}>
              <Typography>QR Code Link</Typography>
            </div>
            <div id="QRCode" style={{ margin: "0 auto" }}>
              <QRCode value={value} />
            </div>
            <div style={{ margin: "0 auto", display: "flex" }}>
              <Button color="primary" variant="contained" onClick={printQR} style={{ marginRight: "1em" }}>
                Print
              </Button>
              <Button color="primary" variant="contained" onClick={onClose}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
