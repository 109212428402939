import { combineReducers } from "redux";
import { editor } from "./Components/Editor/Reducers/EditorReducer";
import { login } from "./Components/Login/Reducers/LoginReducer";
import { registration } from "./Components/Registration/Reducers/RegistrationReducer";
import { admin } from "./Components/Admin/Reducers/AdminReducer";
import { roles } from "./Components/Roles/Reducers/RolesReducer";
import { documentation } from "./Components/Documentation/Reducers/DocumentationReducer";
import { search } from "./Components/Search/Reducers/SearchReducer";
const rootReducer = combineReducers({
  editor,
  login,
  registration,
  admin,
  roles,
  documentation,
  search,
});

export default rootReducer;
