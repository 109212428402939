import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogTitle, DialogActions, Button, Alert } from "@mui/material";
import { setDocumentChangesMedia, setFileObjQueueMedia } from "../../Documentation/Reducers/DocumentationReducer";
import { deselectShapes } from "../../Editor/Reducers/EditorReducer";

export const ReplacePageDialog = (props) => {
  const { onReplace, onClose, acceptedFiles, ...passthroughProps } = props;
  const [error, setError] = useState();
  const [areYouSure, setAreYouSure] = useState(false);
  const dispatch = useDispatch();

  const currentPage = useSelector((state) => state.documentation.currentPage);
  const fileObjQueue = useSelector((state) => state.documentation.fileObjQueue);
  const isFileObjQueue = fileObjQueue.find((x) => x.pageID === currentPage?.pageID);

  const onSubmit = (e) => {
    dispatch(deselectShapes());
    if (acceptedFiles) {
      const file = acceptedFiles[0];
      if (isFileObjQueue) {
        dispatch(
          setFileObjQueueMedia({
            content_type: file.type,
            filename: file.name,
            size: file.size,
            lastModified: file.lastModified,
            media: URL.createObjectURL(file),
            pageID: currentPage.pageID,
          })
        );
      } else {
        dispatch(
          setDocumentChangesMedia({
            content_type: file.type,
            filename: file.name,
            size: file.size,
            lastModified: file.lastModified,
            media: URL.createObjectURL(file),
            pageID: currentPage.pageID,
          })
        );
      }
    }
    onClose();
    onReplace();
  };
  const resetForm = () => {
    setError();
    setAreYouSure(false);
  };

  return (
    <Dialog onClose={onClose} TransitionProps={{ onEntering: resetForm }} {...passthroughProps}>
      <DialogTitle id="form-dialog-title">
        Are you {areYouSure ? "REALLY " : ""}sure you want to replace this page?
      </DialogTitle>{" "}
      {error && <Alert severity="error">{error}</Alert>}
      {areYouSure ? (
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onSubmit} style={{ color: "#097c09" }}>
            Yes, Replace Page
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => setAreYouSure(true)} style={{ color: "#097c09" }}>
            Replace Page
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
