export function TrashBreadcrumbs(currentFolder) {
  return [
    { name: "Home", to: "/" },
    { name: "Documentation", to: "/documentation/browse" },
    ...(currentFolder.ancestors.length > 1
      ? currentFolder.ancestors
          .map((x) => {
            return {
              name: x.name,
              to: "/documentation/browse/" + x.id,
            };
          })
          .filter((x) => x.name !== "root")
      : ""),
    currentFolder.folder.name &&
      currentFolder.folder.name !== "root" && {
        name: currentFolder.folder.name,
        to: "/documentation/browse/" + currentFolder.folder.id,
      },
  ];
}
