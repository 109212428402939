import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "1em",
    margin: "0 auto",
    width: "85%",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    backgroundColor: "#f5f5f5",
  },
  list: {
    // width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));
