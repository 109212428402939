import { AppBar, Button, ButtonGroup, CssBaseline, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import history from "../../../Utils/history";
import { refreshUserData } from "../../Login/Reducers/refreshUserData";
import { LoadingSpinner } from "../../Viewer/Components/LoadingSpinner";
import { useInvitationID } from "../Hooks/useInvitationID";
import { acceptInvitation } from "../Thunks/acceptInvitation";
import { PopoverAvatar } from "./PopoverAvatar";

export function Invitation() {
  const dispatch = useDispatch();
  const authenticated = useSelector((state) => state.login.authenticated);
  const orgName = useSelector((state) => state.login.org?.name);
  const [invitation, setInvitation] = useState(null);

  const params = useParams();
  const [error, setError] = useState(null);
  function handleInvite({ accept }) {
    return () =>
      dispatch(acceptInvitation({ id: params.id, accept: accept === "accept" }))
        .then((res) => {
          sessionStorage.removeItem("inviteID");
          if (res.status === 200) {
            dispatch(refreshUserData())
              .then((_) => history.push("/"))
              .catch((err) => console.error(err));
          } else {
            history.push("/");
          }
        })
        .catch((err) => {
          console.log(err);
          setError(err?.response?.data?.detail);
        });
  }
  useInvitationID(params, setInvitation, setError);

  return (
    <div
      style={{
        flexGrow: 1,
        height: "100%",
      }}
    >
      <CssBaseline />
      <AppBar style={{ paddingLeft: 16 }} position="fixed">
        <Toolbar>
          <Typography edge="start" variant="h6" style={{ flexGrow: 1 }}>
            Invitation
          </Typography>
          {authenticated ? <PopoverAvatar /> : ""}
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: 64, marginLeft: 16 }}>
        {error && !invitation ? (
          <Typography>Something went wrong: {error}</Typography>
        ) : !invitation ? (
          <LoadingSpinner />
        ) : authenticated ? (
          orgName === invitation.org_name ? (
            <div>
              <Typography variant="h5">You are already a member of {invitation.org_name} on DropDoc.</Typography>
              <Typography>
                <Button
                  component={Link}
                  variant="contained"
                  onClick={() => {
                    sessionStorage.removeItem("inviteID");
                  }}
                  to="/"
                >
                  Go to your Dashboard
                </Button>
              </Typography>
            </div>
          ) : (
            <div>
              <Typography variant="h5">You've been invited to join {invitation.org_name} on DropDoc!</Typography>
              <Typography>Would you like to accept?</Typography>
              <ButtonGroup variant="contained" aria-label="outlined primary button group" color="primary">
                <Button onClick={handleInvite({ accept: "accept" })}>Accept</Button>
                <Button onClick={handleInvite({ accept: "decline" })}>Decline</Button>
              </ButtonGroup>
            </div>
          )
        ) : (
          <div>
            {sessionStorage?.registered ? (
              <>
                <Typography>Registration Successful! </Typography>{" "}
                <Typography>
                  <Link to="/login">Log in</Link> to accept invitation to {invitation.org_name} on DropDoc
                </Typography>
              </>
            ) : (
              <>
                <div style={{ padding: "1em" }}>
                  <Typography variant="h5">You have been invited to join {invitation.org_name} on DropDoc!</Typography>
                </div>
                <div style={{ padding: "1em" }}>
                  <Typography variant="h6">Already have an account?</Typography>
                  <Button
                    component={Link}
                    onClick={() => {
                      sessionStorage.setItem("inviteID", params.id);
                    }}
                    variant="contained"
                    to="/login"
                  >
                    Log In Here
                  </Button>
                </div>

                <div style={{ padding: "1em" }}>
                  <Typography variant="h6">Not signed up yet?</Typography>
                  <Button
                    component={Link}
                    onClick={() => {
                      sessionStorage.setItem("inviteID", params.id);
                    }}
                    variant="contained"
                    to="/register"
                  >
                    Register Here
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
