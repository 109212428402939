import React from "react";
import { Dashboard } from "../../Dashboard/Dashboard";
import { Breadcrumb } from "../../Dashboard/Components/Breadcrumbs";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

import preval from "preval.macro";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));
export function Settings() {
  const classes = useStyles();
  const userLimit = useSelector((state) => state.login.org?.plan_options?.limit_users);
  const activeUsers = useSelector((state) => state.login.org?.plan_options?.limit_active_users);
  return (
    <Dashboard
      pageName="Settings"
      breadcrumbs={
        <Breadcrumb
          currentValue={"Settings"}
          linkValues={[
            { name: "Home", to: "/" },
            { name: "Admin", to: "/admin" },
          ]}
        />
      }
    >
      <Paper className={classes.paper} elevation={3}>
        <div> Active User Limit: {userLimit}</div>
        <div> Currently Active Users: {activeUsers}</div>
        <div> Build Date: {preval`module.exports = new Date().toLocaleString();`}.</div>
      </Paper>
    </Dashboard>
  );
}
