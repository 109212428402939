import { useEffect } from "react";
import { setScaleFactor } from "../Reducers/EditorReducer";
import { useDispatch, useSelector } from "react-redux";

export function useScaleFactor({ naturalHeight, naturalWidth, annotationWidth, boxHeight, svgMiddleY, svgMiddleX }) {
  const dispatch = useDispatch();
  const containedSize = useSelector((state) => state.editor.containedSize);
  const zoom = useSelector((state) => state.editor.zoom);
  const selected = useSelector((state) => state.editor.selected);

  useEffect(() => {
    if (containedSize && naturalWidth && naturalHeight) {
      dispatch(
        setScaleFactor({
          scaleX: containedSize.width / naturalWidth,
          scaleY: containedSize.height / naturalHeight,
        })
      );
    } else {
      dispatch(
        setScaleFactor({
          scaleX: 1,
          scaleY: 1,
        })
      );
    }
  }, [
    dispatch,
    containedSize,
    naturalHeight,
    naturalWidth,
    annotationWidth,
    boxHeight,
    zoom,
    selected,
    svgMiddleY,
    svgMiddleX,
  ]);
}
