import React, { useState } from "react";
import { useStyles } from "./styles";
import clsx from "clsx";
import { DashboardAppBar } from "./Components/DashboardAppBar";
import { usePageName } from "./Hooks/usePageName";

export const Dashboard = ({ pageName, breadcrumbs, children }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleDrawer = React.useCallback(() => {
    setOpen(!open);
  }, [open]);
  const handleClickAway = React.useCallback(() => {
    setOpen(false);
  }, []);
  usePageName(pageName);
  return (
    <div className={classes.root}>
      <DashboardAppBar pageName={pageName} open={open} handleDrawer={handleDrawer} handleClickAway={handleClickAway} />
      <main className={clsx(classes.content, open && classes.contentShift)}>
        <div className={classes.drawerHeader} />
        {breadcrumbs}
        {children}
      </main>
    </div>
  );
};
