import { Fingerprint, PhonelinkLock } from "@mui/icons-material";
import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../../Utils/history";
import { SnackbarAlertRedux } from "../../../Documentation/Components/SnackbarAlert";
import { setAlert } from "../../../Documentation/Reducers/DocumentationReducer";
import { postLoginDetails } from "../../../Login/Reducers/postLoginDetails";

export const PasswordModal = ({ showPasswordModal, setShowPasswordModal, handleSubmit }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.login.loading);
  const error = useSelector((state) => state.login.error);
  const username = useSelector((state) => state.login.username);
  const [password, setPassword] = useState(() => window.sessionStorage.getItem("password") || "");
  const [twoFactor, setTwoFactor] = useState("");
  const [isTwoFactor, setIsTwoFactor] = useState(false);
  const [isCapslock, setIsCapslock] = useState(false);
  const alert = useSelector((state) => state.documentation.alert);
  const severity = useSelector((state) => state.documentation.severity);
  const handlePasswordForm = (e) => {
    e.preventDefault();
    dispatch(postLoginDetails({ username, password, setIsTwoFactor, twoFactor }))
      .then((login) => {
        console.log(login);
        setShowPasswordModal(false);
        dispatch(setAlert({ alert: "Successfully Logged In", severity: "success" }));
        handleSubmit();
      })
      .catch((e) => {
        console.error(e);
        history.push("/login");
      });
  };
  const handleCapsLock = (e) => {
    if (e.getModifierState("CapsLock")) {
      setIsCapslock(true);
    } else {
      setIsCapslock(false);
    }
  };
  return (
    <Modal open={showPasswordModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography color="primary">You Are Logged Out. Please Enter Your Password</Typography>

        <TextField
          id="password"
          label="Password"
          inputProps={{ "data-testid": "password" }}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            startAdornment: <Fingerprint />,
          }}
          error={isCapslock}
          onKeyUp={handleCapsLock}
          fullWidth
          margin="normal"
        />
        {isCapslock ? (
          <Typography style={{ color: "#d32f2f" }} variant="caption">
            Caps Lock is on
          </Typography>
        ) : (
          ""
        )}

        {isTwoFactor && (
          <TextField
            id="twofactor"
            label="Two Factor Authentication"
            inputProps={{ "data-testid": "twofactor" }}
            type="twofactor"
            value={twoFactor}
            onChange={(e) => setTwoFactor(e.target.value)}
            InputProps={{
              startAdornment: <PhonelinkLock />,
            }}
            fullWidth
            margin="normal"
            autoFocus
          />
        )}
        {error && !loading ? (
          isTwoFactor ? (
            <Typography color="secondary">Two Factor Authentication Required</Typography>
          ) : (
            <Typography color="secondary">Incorrect username or password</Typography>
          )
        ) : (
          ""
        )}

        <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
          <Button
            type="submit"
            data-testid="button"
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={handlePasswordForm}
          >
            {loading ? "Logging In..." : "Login"}
          </Button>
        </Grid>
        <SnackbarAlertRedux alert={alert} severity={severity} />
      </Box>
    </Modal>
  );
};
