import Compressor from "compressorjs";

export async function compress(file, quality, maxHeight, maxWidth) {
  //https://stackoverflow.com/questions/49800374/callback-to-async-await
  return await new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      maxHeight,
      maxWidth,
      success: resolve,
      error: reject,
    });
  });
}
// let thumbnail = await compress(file);
// console.log(thumbnail);
