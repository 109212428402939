import { Description, SupervisorAccount } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb } from "../../Dashboard/Components/Breadcrumbs";
import { Dashboard } from "../../Dashboard/Dashboard";
import { RemoveModal } from "../../Modal/RemoveModal";
import { LoadingSpinner } from "../../Viewer/Components/LoadingSpinner";
import { useGetRole } from "../Hooks/useGetRole";
import { deleteRole } from "../Reducers/deleteRole";
import { rolesSelector, setValue } from "../Reducers/RolesReducer";
import { updateRoleInfo } from "../Reducers/updateRoleInfo";
import { UsersWithRolesTable } from "./UsersWithRolesTable";
export const EditRoleForm = () => {
  const dispatch = useDispatch();
  const { currentRole, loading } = useSelector(rolesSelector);
  const { id, role, description, list_filterable } = currentRole;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useGetRole(dispatch);
  return (
    <Dashboard
      pageName="Edit Role"
      breadcrumbs={
        <Breadcrumb
          currentValue={`${currentRole.role}`}
          linkValues={[
            { name: "Home", to: "/" },
            { name: "Admin", to: "/admin" },
            { name: "Roles", to: "/admin/roles/" },
          ]}
        />
      }
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div style={{ height: "100%", overflow: "auto" }}>
          <div>
            <Typography variant="h4" style={{ margin: "0.5em 0 0.5em 0.5em" }}>
              Edit Role
            </Typography>
            <div style={{ width: "fit-content", marginLeft: "1em" }}>
              <TextField
                id="role"
                label="Role"
                type="role"
                value={role || ""}
                onChange={dispatchFunctions(dispatch, role, description).setRole}
                InputProps={{
                  startAdornment: <SupervisorAccount />,
                }}
                fullWidth
                margin="normal"
              />
              <TextField
                id="description"
                label="Description"
                type="description"
                value={description || ""}
                onChange={dispatchFunctions(dispatch, role, description).setDescription}
                InputProps={{
                  startAdornment: <Description />,
                }}
                fullWidth
                margin="normal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={list_filterable || false}
                    onChange={(e) => {
                      dispatch(setValue({ field: "list_filterable", value: !list_filterable }));
                    }}
                  />
                }
                label="Allow Quick Filtering for this Role"
              />
              <Grid container style={{ marginTop: "10px" }}>
                <Button
                  onClick={handleOpen}
                  variant="outlined"
                  style={{ textTransform: "none", marginRight: "1em", color: "red" }}
                >
                  Delete Role
                </Button>
                <Button
                  onClick={dispatchFunctions(dispatch, role, description, list_filterable).setRoleInfo}
                  variant="outlined"
                  style={{ textTransform: "none", color: "green" }}
                >
                  Save Changes
                </Button>
                <RemoveModal
                  open={open}
                  handleClose={handleClose}
                  id={id}
                  value={role}
                  thunk={deleteRole}
                  text={"Role"}
                />
              </Grid>
            </div>
          </div>
          <UsersWithRolesTable />
        </div>
      )}
    </Dashboard>
  );
};

function dispatchFunctions(dispatch, role, description, list_filterable) {
  return {
    setRole: (e) => dispatch(setValue({ field: "role", value: e.target.value })),
    setDescription: (e) => dispatch(setValue({ field: "description", value: e.target.value })),
    setRoleInfo: () => dispatch(updateRoleInfo({ role, description, list_filterable })),
  };
}
