import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFileObjQueue } from "../../Documentation/Reducers/DocumentationReducer";
import { formatNewFiles } from "../Components/formatNewFiles";

export function usePasteFile() {
  const dispatch = useDispatch();
  useEffect(() => {
    const handlePaste = (event) => {
      if (event.clipboardData.files.length) {
        const fileObject = event.clipboardData.files;
        const files = formatNewFiles(fileObject);
        dispatch(setFileObjQueue({ files }));
      }
    };
    window.addEventListener("paste", handlePaste);

    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, [dispatch]);
}
