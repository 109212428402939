import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
const style = {
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80%",
  overflow: "auto",
};
export const TermsAndConditionsModal = ({ open, setOpen }) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="TermsAndConditions"
      aria-describedby="TermsAndConditions"
    >
      <Box sx={style}>
        <TermsAndConditions />
        <Button variant="outlined" color="primary" onClick={() => setOpen(false)}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

const TermsAndConditions = () => (
  <div>
    <h1>TERMS AND CONDITIONS</h1>
    <p>
      These terms and conditions (the "Terms and Conditions") govern the use of DropDoc.com (the "Site"). This Site is
      owned and operated by Invaware Corporation.
    </p>
    <p>
      By using this Site, you indicate that you have read and understand these Terms and Conditions and agree to abide
      by them at all times.
    </p>
    <h2>Intellectual Property</h2>
    <p>
      All content published and made available on our Site is the property of Invaware Corporation and the Site's
      creators. This includes, but is not limited to images, text, logos, documents, downloadable files and anything
      that contributes to the composition of our Site.
    </p>
    <h2>Acceptable Use</h2>
    <p>
      As a user of our Site, you agree to use our Site legally, not to use our Site for illegal purposes, and not to:
    </p>
    <ul>
      <li>Violate the intellectual property rights of the Site owners or any third party to the Site; or</li>
      <li>Act in any way that could be considered fraudulent;</li>
    </ul>
    <p>
      If we believe you are using our Site illegally or in a manner that violates these Terms and Conditions, we reserve
      the right to limit, suspend or terminate your access to our Site. We also reserve the right to take any legal
      steps necessary to prevent you from accessing our Site.
    </p>
    <h2>Accounts</h2>
    <p>When you create an account on our Site, you agree to the following:</p>

    <ol>
      <li>
        You are solely responsible for your account and the security and privacy of your account, including passwords or
        sensitive information attached to that account; and
      </li>
      <li>
        All personal information you provide to us through your account is up to date, accurate, and truthful and that
        you will update your personal information if it changes.
      </li>
    </ol>

    <p>
      We reserve the right to suspend or terminate your account if you are using our Site illegally or if you violate
      these Terms and Conditions.
    </p>

    <h2>Sale of Services</h2>

    <p>
      These Terms and Conditions apply to all the services that are displayed on our Site at the time you access it. All
      information that we provide about our services are as accurate as possible. However, we are not legally bound by
      such information. You agree to purchase services from our Site at your own risk.
    </p>

    <h2>Subscriptions</h2>
    <p>
      Your subscription automatically renews and you will be automatically billed until we receive notification that you
      want to cancel the subscription.
    </p>

    <h2>Free Trial</h2>
    <p>
      We offer the following free trial of our services: A 7 day free trial that begins when a new organization is
      created.
    </p>

    <p>
      At the end of your free trial, the following will occur: You will automatically be billed our monthly subscription
      rate. If you do not want to be billed, you will need to cancel your subscription before your free trial ends.
    </p>

    <h2>Payments</h2>
    <p>We accept the following payment methods on our Site:</p>

    <ol>
      <li>Credit Card.</li>
      <li>PayPal.</li>
    </ol>

    <p>
      When you provide us with your payment information, you authorize our use of and access to the payment instrument
      you have chosen to use. By providing us with your payment information, you authorize us to charge the amount due
      to this payment instrument.
    </p>

    <p>
      If we believe your payment has violated any law or these Terms and Conditions, we reserve the right to cancel or
      reverse your transaction.
    </p>

    <h2>Consumer Protection Law</h2>
    <p>
      Where the Consumer Protection Act, or any other consumer protection legislation in your jurisdiction applies and
      cannot be excluded, these Terms and Conditions will not limit your legal rights and remedies under that
      legislation. These Terms and Conditions will be read subject to the mandatory provisions of that legislation. If
      there is a conflict between these Terms and Conditions and that legislation, the mandatory provisions of the
      legislation will apply.
    </p>

    <h2>Links to Other Websites</h2>
    <p>
      Our Site may contain links to third party websites or services that we do not own or control. We are not
      responsible for the content, policies, or practices of any third party website or service linked to on our Site.
      It is your responsibility to read the terms and conditions and privacy policies of these third party websites
      before using these sites.
    </p>

    <h2>Limitation of Liability</h2>
    <p>
      Invaware Corporation and our directors, officers, agents, employees, subsidiaries, and affiliates will not be
      liable for any actions, claims, losses, damages, liabilities and expenses including legal fees from your use of
      the Site.
    </p>

    <h2>Indemnity</h2>
    <p>
      Except where prohibited by law, by using this Site you indemnify and hold harmless Invaware Corporation and our
      directors, officers, agents, employees, subsidiaries, and affiliates from any actions, claims, losses, damages,
      liabilities and expenses including legal fees arising out of your use of our Site or your violation of these Terms
      and Conditions.
    </p>

    <h2>Applicable Law</h2>
    <p>These Terms and Conditions are governed by the laws of the Province of Ontario.</p>

    <h2>Severability</h2>
    <p>
      If at any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent or
      invalid under applicable laws, those provisions will be deemed void and will be removed from these Terms and
      Conditions. All other provisions will not be affected by the removal and the rest of these Terms and Conditions
      will still be considered valid.
    </p>

    <h2>Changes</h2>
    <p>
      These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and to
      reflect any changes to the way we operate our Site and the way we expect users to behave on our Site. We will
      notify users by email of changes to these Terms and Conditions or post a notice on our Site.
    </p>

    <p>Effective Date: 24th day of January, 2022</p>
  </div>
);
