import React from "react";
import { AccordionSummary, Avatar, IconButton, Typography } from "@mui/material";
import { ExpandMore, Delete } from "@mui/icons-material/";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./useStyles";
import { setDidQuestionsChange, setQuestionIndex } from "../../../Documentation/Reducers/DocumentationReducer";
import { removeQuestion } from "../../../Editor/Reducers/EditorReducer";

export function SummaryContainer({ index, id }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const didQuestionsChange = useSelector((state) => state.documentation.didQuestionsChange);
  const title = useSelector((state) => state.editor?.questions[id].title);

  return (
    <AccordionSummary
      expandIcon={<ExpandMore />}
      classes={{ content: classes.content }}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Avatar
        style={{
          color: "white",
          backgroundColor: "#202c45",
          fontSize: "1em",
          width: "1.5em",
          height: "1.5em",
          marginRight: "1em",
        }}
      >
        {index}
      </Avatar>
      <Typography className={classes.heading}>{title}</Typography>
      <IconButton
        color="primary"
        style={{ marginLeft: "auto" }}
        onClick={(e) => {
          e.preventDefault();
          if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));
          dispatch(removeQuestion({ id }));
          dispatch(setQuestionIndex({ remove: id }));
        }}
      >
        <Delete />
      </IconButton>
    </AccordionSummary>
  );
}
