import React from "react";
import { PopoverAvatar } from "./PopoverAvatar";
import {
  Toolbar,
  AppBar,
  IconButton,
  Drawer,
  useTheme,
  Divider,
  CssBaseline,
  Typography,
  ClickAwayListener,
} from "@mui/material";
import { Menu, ChevronLeft, ChevronRight, Search } from "@mui/icons-material/";
import { useStyles } from "../styles";
import clsx from "clsx";
import { SideDrawer } from "./SideDrawer";
import { GlobalSearchField } from "../../Search/GlobalSearchField";
import { NotificationPopover } from "./NotificationPopover";
import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import history from "../../../Utils/history";
import { useNotificationsSubsFavs } from "../Hooks/useNotificationsSubsFavs";

export const IconButtonStyle = { marginLeft: "auto" };
const DrawerTopStyle = { textAlign: "center", paddingTop: "7px" };
export const DashboardAppBar = ({ open, handleDrawer, handleClickAway, pageName }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const org = useSelector((state) => state.login.org);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const divRef = React.useRef();
  const isEdit = window.location.pathname.includes("documentation/edit");
  const handleFocus = () => {
    setAnchorEl(divRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const SearchField = window.location.pathname !== "/documentation/search" && (
    <GlobalSearchField handleFocus={handleFocus} anchorEl={anchorEl} divRef={divRef} handleClose={handleClose} />
  );
  const AvatarButton = (
    <IconButton>
      <PopoverAvatar color="inherit" />
    </IconButton>
  );
  const NotificationButton = (
    <IconButton>
      <NotificationPopover />
    </IconButton>
  );
  const PageName = isEdit ? (
    <Typography variant="h6">
      <span style={{ fontWeight: "bold" }}>{`${pageName}`}</span>
      {`: ${org?.name || ""}`}
    </Typography>
  ) : (
    <Typography variant="h6">
      {`${pageName}${org?.name ? ":" : ""} `}
      <span style={{ fontWeight: "bold" }}>{`${org?.name || ""}`}</span>
    </Typography>
  );
  const DashboardDrawer = (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={handleDrawer}
      edge="start"
      className={clsx(classes.menuButton, open && classes.hide)}
    >
      <Menu />
    </IconButton>
  );
  useNotificationsSubsFavs();
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            {DashboardDrawer}
            {PageName}
            <div
              style={{
                marginLeft: "auto",
                display: window.location.pathname === "/documentation/search" ? "flex" : "contents",
              }}
            >
              {isMobileScreen
                ? window.location.pathname !== "/documentation/search" && (
                    <IconButton
                      style={{ marginLeft: "auto", color: "#fafafa" }}
                      onClick={() => history.push("/documentation/search")}
                      color="primary"
                    >
                      <Search />
                    </IconButton>
                  )
                : SearchField}
              {NotificationButton}
              {AvatarButton}
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <div style={DrawerTopStyle}>{/* <InvawareColor style={{ width: "77%" }} /> */}</div>
            <IconButton onClick={handleDrawer}>
              {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </div>
          <Divider />
          <SideDrawer onClick={handleDrawer} />
        </Drawer>
      </div>
    </ClickAwayListener>
  );
};
