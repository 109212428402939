export const foldPath = (result) => (acc, path, idx) => {
  const isLastIdx = idx === result.path.length - 1;
  if (isLastIdx && result.kind === "folder" && path.name !== result.name) {
    //empty folder nesting
    return {
      [path.name]: {
        folder_path: result.path.map((p) => p.name).join(" / "),
        folder_id: path.id,
        [result.name]: {
          folder_path: result.path.map((p) => p.name).join(" / ") + ` / ${result.name}`,
          folder_id: result.id,
        },
      },
    };
  } else if (isLastIdx) {
    //place result in array
    return {
      [path.name]: {
        folder_path: result.path.map((p) => p.name).join(" / "),
        folder_id: path.id,
        arr: [result],
      },
    };
  } else {
    //path nesting
    return {
      [path.name]: {
        folder_id: path.id,
        ...acc,
      },
    };
  }
};
