import axios from "axios";
import { baseURL } from "../thunks";

export const editFolder =
  ({ id, name, description, version, kind, doctype }) =>
  async () => {
    return kind === "folder"
      ? await axios.put(
          baseURL + "documentation/folders/" + id,
          { name, description, version },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
      : await axios.put(
          baseURL + "documentation/documents/" + id,
          { name, description, version, doctype },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
  };
