import isEqual from "lodash.isequal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../Utils/history";
import { Perm } from "../../Documentation/Components/Dialogs/helpers";
import { setAlert, setIsBlocking } from "../../Documentation/Reducers/DocumentationReducer";

export function useBlockingIfDraft() {
  const dispatch = useDispatch();
  const fileObjQueue = useSelector((state) => state.documentation.fileObjQueue);
  const page_index = useSelector((state) => state.documentation.page_index);
  const contents_index = useSelector((state) => state.documentation.currentDocument?.contents?.page_index);
  const currentDocumentChanges = useSelector((state) => state.documentation.currentDocumentChanges);
  const didQuestionsChange = useSelector((state) => state.documentation.didQuestionsChange);
  const doctype = useSelector((state) => state.documentation.currentDocument?.doctype);

  const user_permissions = useSelector((state) => state.documentation?.currentDocument?.user_permissions);
  const id = useSelector((state) => state.documentation?.currentDocument?.id);

  const permList = user_permissions && Perm.getLabelList(user_permissions);

  useEffect(() => {
    if (user_permissions && !permList.includes("Write")) {
      dispatch(setIsBlocking({ isBlocking: false }));
      dispatch(setAlert({ alert: "You need permission to Edit this document.", severity: "warning" }));
      history.push(`/documentation/view/${id}`);
    } else {
      if (
        doctype === "presentation" &&
        (fileObjQueue.length !== 0 || !isEqual(page_index, contents_index) || currentDocumentChanges)
      ) {
        dispatch(setIsBlocking({ isBlocking: true }));
      }
      if ((doctype === "form" || doctype === "sheet" || doctype === "report") && didQuestionsChange) {
        dispatch(setIsBlocking({ isBlocking: true }));
      }
    }
  }, [
    doctype,
    fileObjQueue,
    page_index,
    contents_index,
    currentDocumentChanges,
    didQuestionsChange,
    dispatch,
    permList,
    id,
    user_permissions,
  ]);
}
