import React from "react";
import { MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { styles } from "./useStyles";
import history from "../../../Utils/history";
import {
  FolderOpenTwoTone,
  InsertDriveFileTwoTone,
  AssignmentTurnedInTwoTone,
  ListAltTwoTone,
} from "@mui/icons-material";

export function Favorites() {
  const favorites = useSelector((state) => state.documentation.favorites);

  return favorites.map((favorite, index) => {
    return (
      <MenuItem
        onClick={() =>
          favorite.kind === "folder"
            ? history.push(`/documentation/browse/${favorite.item_id}`)
            : history.push(`/documentation/view/${favorite.item_id}`)
        }
        key={index}
      >
        <span>
          {favorite.kind === "folder" ? (
            <FolderOpenTwoTone style={styles.folder} />
          ) : favorite.doctype === "presentation" ? (
            <InsertDriveFileTwoTone style={styles.document} />
          ) : favorite.doctype === "form" ? (
            <AssignmentTurnedInTwoTone style={styles.form} />
          ) : (
            <ListAltTwoTone style={styles.sheet} />
          )}
          {favorite.name}
          {favorite.description ? `: ${favorite.description}` : ""}
        </span>
      </MenuItem>
    );
  });
}
