import React from "react";
import { Shape } from "./Components/Annotator/Shapes";
export function getContainedSize(img) {
  //https://stackoverflow.com/questions/52186493/how-can-we-get-new-image-size-dimension-after-giving-object-fitcontain-property
  let ratio = img.naturalWidth / img.naturalHeight;
  let width = img.height * ratio;
  let height = img.height;
  if (width > img.width) {
    width = img.width;
    height = img.width / ratio;
  }
  return { width, height };
}
export const isFingerOrThumb = (e) =>
  e.touches[0].clientY < e.touches[1].clientY ? e.touches[0].clientY : e.touches[1].clientY;

export const mapShapeToCanvas = (shape) => <Shape key={shape.id} {...shape} />;

export const getCoordinates = (e) => {
  const rect = e.target.getBoundingClientRect();
  const x = e.clientX - rect.x;
  const y = e.clientY - rect.y;
  return { x, y };
};

export const uniqueID = (_) => {
  let now = new Date();
  return now.getTime().toString();
};

export const getHandleRect = ({ selected, handle }) => {
  let handleRect = {
    x: selected.x,
    y: selected.y,
    width: 30,
    height: 30,
    left: null,
    right: null,
    bottom: null,
    top: null,
  };
  // set dims
  if (handle === "top" || handle === "bottom") {
    handleRect.width = selected.width;
  } else if (handle === "left" || handle === "right") {
    handleRect.height = selected.height;
  } else if (handle === "center") {
    handleRect.height = selected.height;
    handleRect.width = selected.width;
  }
  // set y
  if (handle.startsWith("top")) {
    handleRect.y = selected.y - 30;
  } else if (handle.startsWith("bottom")) {
    handleRect.y = selected.y + selected.height;
  } else if (handle === "rotationHandle") {
    handleRect.y = selected.y + selected.height / 2;
  }
  // set x
  if (handle.endsWith("eft")) {
    handleRect.x = selected.x - 30;
  } else if (handle.endsWith("ight")) {
    handleRect.x = selected.x + selected.width;
  } else if (handle === "rotationHandle") {
    handleRect.x = selected.x + selected.width + 50;
  }
  handleRect.top = handleRect.y;
  handleRect.left = handleRect.x;
  handleRect.right = handleRect.x + handleRect.width;
  handleRect.bottom = handleRect.y + handleRect.height;

  return handleRect;
};

export function setHandles({ handleRect, handle }) {
  let handleX = handleRect.left;
  let handleY = handleRect.top;
  if (handle === "center") {
    handleX = handleRect.left + handleRect.width / 2;
    handleY = handleRect.top + handleRect.height / 2;
  }
  if (handle.startsWith("top")) {
    handleY = handleRect.top + handleRect.height;
  }
  if (handle.endsWith("eft")) {
    handleX = handleRect.left + handleRect.width;
  }
  return { handleX, handleY };
}

export function reduxLog(x) {
  return console.log(JSON.stringify(x, undefined, 2));
}
