import axios from "axios";
import history from "../../../Utils/history";
import { requestLoading, requestReceived, setCurrentUser } from "../Reducers/AdminReducer";
import { baseURL } from "../Reducers/thunks";

export const updateUserInfo =
  ({ id, email, full_name, is_org_admin, roles }) =>
  async (dispatch) => {
    dispatch(requestLoading());
    try {
      let res = await axios({
        method: "put",
        url: baseURL + `users/${id}`,
        data: {
          email,
          full_name,
          is_org_admin,
          roles,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(requestReceived({ detail: "requestReceived" }));
      dispatch(
        setCurrentUser({
          username: "",
          password: "",
          email: "",
          is_active: null,
          is_org_admin: null,
          roles: [],
          full_name: "",
          id: null,
        })
      );
      history.push("/admin/users");
      return res;
    } catch (error) {
      dispatch(requestReceived({ detail: error }));
      console.error(error);
    }
  };
