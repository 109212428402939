import axios from "axios";
import { setUploadProgress } from "../../Documentation/Reducers/DocumentationReducer";
import { handleError } from "../../Documentation/Reducers/Thunks/Helpers/handleError";
import { baseURL } from "../../Documentation/Reducers/thunks";
import { compress } from "../Components/Helpers/compress";
import { convertBlobToFile } from "./Helpers/convertBlobToFile";
import { convertBlobURLToFile } from "./Helpers/convertBlobURLToFile";
import { formatFileInfo } from "./Helpers/formatFileInfo";
import { getImageDimensions } from "./Helpers/getImageDimensions";
const uploadQuality = 0.6;
const maxWidthFiles = 2560;
const maxHeightFiles = 1440;
const maxWidthThumbs = 256;
const maxHeightThumbs = 256;
export const publishDocument =
  ({ currentDocument, fileObjQueue, currentDocumentChanges, page_index, changelog, onCancel, setShowPasswordModal }) =>
  async (dispatch) => {
    try {
      const modifiedPages = [
        ...(fileObjQueue ? fileObjQueue : []),
        ...(currentDocumentChanges
          ? Object.keys(currentDocumentChanges).map((pageID) => ({ ...currentDocumentChanges[pageID] }))
          : []),
      ];
      dispatch(setUploadProgress({ uploadProgress: 5 }));
      const compressedImages = await modifiedPages.reduce(async (acc, curr) => {
        const isBlob = curr && curr.media && !curr.media.includes("amazonaws.com") && curr?.media.includes("blob:http");
        let file, thumbnail, dimensionReduction;
        if (isBlob) {
          let img = document.createElement("img");
          img.src = curr.media;
          let [width, height] = [img.naturalWidth, img.naturalHeight];

          let converted = await convertBlobURLToFile(curr);

          file = (await curr.content_type.includes("image"))
            ? height > width
              ? await compress(converted, uploadQuality, maxWidthFiles, maxHeightFiles)
              : await compress(converted, uploadQuality, maxHeightFiles, maxWidthFiles)
            : converted;

          let img2 = (await curr.content_type.includes("image")) ? await getImageDimensions(file) : null;

          dimensionReduction = img2
            ? {
                width: img2.naturalWidth < width ? img2.naturalWidth / width : 1,
                height: img2.naturalHeight < height ? img2.naturalHeight / height : 1,
              }
            : null;

          thumbnail = (await curr.content_type.includes("image"))
            ? await compress(converted, uploadQuality, maxHeightThumbs, maxWidthThumbs)
            : null;

          if (file instanceof Blob) {
            file = await convertBlobToFile(file);
          }

          if (thumbnail instanceof Blob) {
            thumbnail = await convertBlobToFile(thumbnail);
          }
        } else {
          file = null;
          thumbnail = null;
        }
        return {
          ...(await acc),
          [curr.pageID]: {
            file,
            thumbnail,
            dimensionReduction,
          },
        };
      }, {});
      const signFiles = async () => {
        dispatch(setUploadProgress({ uploadProgress: 10 }));
        const reducedFiles = Object.keys(compressedImages).reduce((acc, cur) => {
          if (compressedImages[cur].file) {
            acc[cur] = {
              file: compressedImages[cur].file,
            };
          }
          return acc;
        }, {});
        const signedFiles = await axios.post(
          `${baseURL}documentation/documents/${currentDocument.id}/sign-content-upload`,
          formatFileInfo(
            Object.keys(reducedFiles).map((pageID) => {
              return reducedFiles[pageID].file;
            })
          ),
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        dispatch(setUploadProgress({ uploadProgress: 20 }));

        return Object.keys(reducedFiles).reduce((acc, pageID, idx) => {
          return { ...acc, [pageID]: signedFiles.data[idx] };
        }, {});
      };
      const signThumbnails = async () => {
        dispatch(setUploadProgress({ uploadProgress: 30 }));
        const reducedThumbs = Object.keys(compressedImages).reduce((acc, cur) => {
          if (compressedImages[cur].thumbnail) {
            acc[cur] = {
              thumbnail: compressedImages[cur].thumbnail,
            };
          }
          return acc;
        }, {});

        dispatch(setUploadProgress({ uploadProgress: 40 }));
        const signedThumbs = await axios.post(
          `${baseURL}documentation/documents/${currentDocument.id}/sign-content-upload`,
          formatFileInfo(
            Object.keys(reducedThumbs).map((pageID) => {
              return reducedThumbs[pageID].thumbnail;
            })
          ),
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        return Object.keys(reducedThumbs).reduce(
          (acc, pageID, index) => ({ ...acc, [pageID]: signedThumbs.data[index] }),
          {}
        );
      };
      const fileRes = await signFiles();
      const thumbRes = await signThumbnails();
      dispatch(setUploadProgress({ uploadProgress: 50 }));
      const thumbUploadIDs = await Promise.all(
        Object.keys(thumbRes).map((pageID) => {
          let { upload_id, url, fields } = thumbRes[pageID];
          const formData = new FormData();
          formData.append("Content-Type", fields["Content-Type"]);
          formData.append("acl", fields.acl);
          formData.append("key", fields.key);
          formData.append("policy", fields.policy);
          formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
          formData.append("x-amz-credential", fields["x-amz-credential"]);
          formData.append("x-amz-date", fields["x-amz-date"]);
          formData.append("x-amz-signature", fields["x-amz-signature"]);
          formData.append("file", compressedImages[pageID].thumbnail);
          let res = axios({
            method: "post",
            url,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(() => ({ [pageID]: upload_id }))
            .catch((e) => {
              handleError(e, onCancel, dispatch, setShowPasswordModal);
            });
          return res;
        })
      );
      dispatch(setUploadProgress({ uploadProgress: 60 }));

      const fileUploadIds = await Promise.all(
        Object.keys(fileRes).map((pageID) => {
          let { upload_id, url, fields } = fileRes[pageID];
          const formData = new FormData();
          formData.append("Content-Type", fields["Content-Type"]);
          formData.append("acl", fields.acl);
          formData.append("key", fields.key);
          formData.append("policy", fields.policy);
          formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
          formData.append("x-amz-credential", fields["x-amz-credential"]);
          formData.append("x-amz-date", fields["x-amz-date"]);
          formData.append("x-amz-signature", fields["x-amz-signature"]);
          formData.append("file", compressedImages[pageID].file);
          let res = axios({
            method: "post",
            url,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(() => ({ [pageID]: upload_id }))
            .catch((e) => {
              handleError(e, onCancel, dispatch, setShowPasswordModal);
            });
          return res;
        })
      );
      dispatch(setUploadProgress({ uploadProgress: 70 }));

      const combineFilesThumbs = fileUploadIds.reduce((acc, cur) => {
        let pageID = Object.keys(cur)[0];
        let isThumb = thumbUploadIDs.find((thumb) => thumb[pageID]);

        if (isThumb) {
          acc.push({
            pageID,
            file: Object.values(cur)[0],
            thumbnail: Object.values(isThumb)[0],
          });
        } else {
          acc.push({
            pageID,
            file: Object.values(cur)[0],
          });
        }

        return acc;
      }, []);
      dispatch(setUploadProgress({ uploadProgress: 80 }));

      const pages = [...page_index].reduce((accumulator, currentPageID) => {
        const isNewPage = fileObjQueue && fileObjQueue.find((obj) => obj.pageID === currentPageID);
        const isPageWithChanges = currentDocumentChanges && currentDocumentChanges[currentPageID];
        let dimensionReduction = compressedImages[currentPageID]?.dimensionReduction;
        if (isNewPage) {
          // new page
          const newFile = combineFilesThumbs.find((page) => page.pageID === isNewPage.pageID);
          const shapes = isNewPage.shapes.length
            ? isNewPage.shapes.map((shape) => {
                const x = shape.x * dimensionReduction.width;
                const width = shape.width * dimensionReduction.width;
                const y = shape.y * dimensionReduction.height;
                const height = shape.height * dimensionReduction.height;

                return {
                  ...shape,
                  x,
                  width,
                  y,
                  height,
                };
              })
            : isNewPage.shapes;
          const newPage = {
            ...isNewPage,
            shapes,
            media: newFile.file,
            ...(newFile.thumbnail ? { media_thumb: newFile.thumbnail } : {}),
          };
          accumulator[currentPageID] = newPage;
        } else if (isPageWithChanges) {
          //page with modifications
          const hasMedia = combineFilesThumbs?.find((page) => page.pageID === currentPageID);
          const pageWithChanges = {
            ...currentDocument.contents.pages[currentPageID],
            ...currentDocumentChanges[currentPageID],
            ...(hasMedia
              ? { media: hasMedia.file, ...(hasMedia.thumbnail ? { media_thumb: hasMedia.thumbnail } : {}) }
              : {}), //does page have new media?
          };
          accumulator[currentPageID] = pageWithChanges;
        } else {
          //page has no modifications
          accumulator[currentPageID] = { ...currentDocument.contents.pages[currentPageID] };
        }
        return accumulator;
      }, {});

      dispatch(setUploadProgress({ uploadProgress: 90 }));

      return await axios({
        method: "put",
        url: baseURL + "documentation/documents/" + currentDocument?.id,
        data: {
          ...currentDocument,
          contents: {
            pages,
            page_index,
          },
          changelog,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          dispatch(setUploadProgress({ uploadProgress: 100 }));
          return res;
        })
        .catch((e) => {
          handleError(e, onCancel, dispatch, setShowPasswordModal);
        });
    } catch (e) {
      handleError(e, onCancel, dispatch, setShowPasswordModal);
    }
  };
