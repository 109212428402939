import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { determineThumbnail } from "./determineThumbnail";
import { onDragEnd } from "./onDragEnd";
import { Thumbnail } from "./Thumbnail";
export function Thumbnails({ isEditor }) {
  const dispatch = useDispatch();
  const currentDocument = useSelector((state) => state.documentation.currentDocument);
  const page_index = useSelector((state) => state.documentation.page_index);
  const fileObjQueue = useSelector((state) => state.documentation.fileObjQueue);
  const currentDocumentChanges = useSelector((state) => state.documentation.currentDocumentChanges);

  return isEditor ? (
    <DragDropContext onDragEnd={(result) => onDragEnd(result, page_index, dispatch)}>
      <Droppable droppableId="list">
        {(provided) => {
          return (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {page_index.map((pageID, index) => {
                const isFileObjQueue =
                  fileObjQueue &&
                  fileObjQueue.find((x) => {
                    return x.pageID === pageID;
                  });
                const isInDocumentChanges = currentDocumentChanges && currentDocumentChanges[pageID];
                return (
                  <Draggable key={`draggable${index}`} draggableId={`draggable${index}`} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Thumbnail
                          index={index}
                          key={index}
                          thumbnail={
                            isFileObjQueue
                              ? determineThumbnail({ thumbnailObj: isFileObjQueue, index })
                              : isInDocumentChanges
                              ? determineThumbnail({ thumbnailObj: currentDocumentChanges[pageID], index })
                              : determineThumbnail({ thumbnailObj: currentDocument.contents.pages[pageID], index })
                          }
                          isEditor
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  ) : (
    page_index.map((thumbID, index) => (
      <Thumbnail
        index={index}
        key={index}
        thumbnail={determineThumbnail({ thumbnailObj: currentDocument.contents.pages[thumbID], index })}
      />
    ))
  );
}
