import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  Container,
  Paper,
  CircularProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getUser } from "../Thunks/getUser";
import { updateUserInfo } from "../Thunks/updateUserInfo";
import { adminSelector, setValue, setFileInput } from "../Reducers/AdminReducer";
import { rolesSelector } from "../../Roles/Reducers/RolesReducer";
import { RoleTransferList } from "../../Roles/Components/RoleTransferList/RoleTransferList";
import { getRoles } from "../../Roles/Reducers/getRoles";
import { FieldContainer } from "../../Form/Form";
import { RemoveModal } from "../../Modal/RemoveModal";
import { deleteUser } from "../Thunks/deleteUser";
import { Email, Face } from "@mui/icons-material/";
import { Dashboard } from "../../Dashboard/Dashboard";
import { Breadcrumb } from "../../Dashboard/Components/Breadcrumbs";
import { BigAvatar, FlexContainer } from "../../Form/styles";
import { makeInitials } from "../../Form/helpers";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(1),
    marginBottom: "1em",
  },
  paper: {
    background: "#f5f5f5",
  },
  extraLargeAvatar: {
    width: "10em",
    height: "10em",
  },
  input: {
    display: "none",
  },
}));

export const EditUserForm = () => {
  const dispatch = useDispatch();
  const { currentUser, loading } = useSelector(adminSelector);
  const {
    selectedRoles,
    loading: { rolesLoading },
  } = useSelector(rolesSelector);
  const { id, username, email, full_name, is_org_admin, avatar } = currentUser;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useGetUserAndRoles(dispatch);

  const handleUpdateUser = () =>
    dispatch(
      updateUserInfo({
        id,
        email,
        full_name,
        is_org_admin,
        roles: selectedRoles,
      })
    );
  const initials = makeInitials(full_name);
  return (
    <Dashboard
      pageName="Edit User"
      breadcrumbs={
        <Breadcrumb
          currentValue={`${currentUser.username}`}
          linkValues={[
            { name: "Home", to: "/" },
            { name: "Admin", to: "/admin" },
            { name: "Users", to: "/admin/users" },
          ]}
        />
      }
    >
      {loading || rolesLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <CircularProgress />
        </div>
      ) : (
        <Container style={{ paddingTop: "1em", overflow: "scroll", display: "flex", justifyContent: "center" }}>
          <FlexContainer>
            <Grid container>
              <Grid item xs={12}>
                <Paper className={classes.padding}>
                  <form>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                      <BigAvatar name={initials} src={avatar ? avatar : ""}>
                        {initials}
                      </BigAvatar>
                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "1em 0" }}>
                      <Face style={{ marginRight: 5 }} />
                      <Typography>{full_name}</Typography>
                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "1em 0" }}>
                      <Email style={{ marginRight: 5 }} />
                      <Typography>{email}</Typography>
                    </div>
                    <FieldContainer>
                      <RoleTransferList />
                    </FieldContainer>
                    <FieldContainer>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={is_org_admin || false}
                            onChange={(e) => {
                              dispatch(setValue({ field: "is_org_admin", value: e.target.checked }));
                            }}
                            color="primary"
                          />
                        }
                        label="Organization Administrator?"
                      />
                    </FieldContainer>
                    <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
                      <Button
                        onClick={handleOpen}
                        variant="outlined"
                        style={{ textTransform: "none", marginRight: "1em", color: "red" }}
                      >
                        Delete User
                      </Button>
                      <Button
                        onClick={handleUpdateUser}
                        variant="outlined"
                        style={{ textTransform: "none", color: "green" }}
                      >
                        Save Changes
                      </Button>
                      <RemoveModal
                        open={open}
                        handleClose={handleClose}
                        id={id}
                        value={username}
                        thunk={deleteUser}
                        text={"User"}
                      />
                    </Grid>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          </FlexContainer>
        </Container>
      )}
    </Dashboard>
  );
};

function useGetUserAndRoles(dispatch) {
  useEffect(() => {
    dispatch(getUser());
    dispatch(getRoles({ skip: 0, limit: 100, order: "", orderBy: "name", search: "" }));
    dispatch(setFileInput({ upload_id: "", name: "", type: "" }));
  }, [dispatch]);
}
