import Axios from "axios";
import { loading, received, setCurrentDocument } from "../DocumentationReducer";
import { baseURL } from "../thunks";

export const getDocumentVersion =
  ({ id, version }) =>
  async (dispatch) => {
    try {
      dispatch(loading());
      const res = await Axios.get(baseURL + "documentation/documents/" + id + "/version/" + version, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log("getDocumentVersion called");
      dispatch(setCurrentDocument({ doc: res.data, isEditor: false }));

      dispatch(received({ error: false }));
    } catch (error) {
      dispatch(received({ error: true }));
      console.log(error);
    }
  };
