import React from "react";
import { FieldContainer } from "./Form";
import { TextField } from "@mui/material";

export const TextFieldContainer = ({
  id,
  label,
  type,
  value,
  onChange,
  icon,
  required = true,
  fullWidth = true,
  inputProps = {},
}) => {
  return (
    <FieldContainer>
      <TextField
        id={id}
        label={label}
        type={type}
        value={value}
        onChange={onChange}
        fullWidth={fullWidth}
        required={required}
        inputProps={inputProps}
        InputProps={{
          startAdornment: icon,
        }}
      />
    </FieldContainer>
  );
};
