import axios from "axios";
import history from "../../../Utils/history";
import { requestLoading, requestReceived } from "./RolesReducer";
import { baseURL } from "./thunks";

export const deleteRole =
  ({ id }) =>
  async (dispatch) => {
    dispatch(requestLoading());
    try {
      await axios.delete(baseURL + `roles/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(requestReceived({ detail: "requestReceived" }));
      history.push("/admin/roles");
    } catch (error) {
      dispatch(requestReceived({ detail: error }));
      console.error(error);
    }
  };
