import { useState } from "react";
import { LoadingSpinner } from "../LoadingSpinner";
import { TabPanel } from "./ChecklistSearchDialog";
import { ChecklistTreeBrowser } from "./ChecklistTreeBrowser";
import { useChecklistFolderStructure } from "../../Hooks/useChecklistFolderStructure";
import { Typography } from "@mui/material";

export const BrowsePanel = ({ value, setSelectedChecklist, selectedChecklist }) => {
  const [results, setResults] = useState(null);
  useChecklistFolderStructure(results, setResults);
  return (
    <TabPanel value={value} index={0}>
      <Typography>Browse for a checklist to add questions to this result sheet.</Typography>

      <div style={{ height: "100%", overflow: "scroll" }}>
        {!results ? (
          <LoadingSpinner />
        ) : (
          <ChecklistTreeBrowser
            results={results}
            setSelectedChecklist={setSelectedChecklist}
            selectedChecklist={selectedChecklist}
          />
        )}
      </div>
    </TabPanel>
  );
};
