import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectShape, setScale, setPosition, setRotation } from "../../Reducers/EditorReducer";
import { getHandleRect, setHandles } from "../../helpers";
import { selectionStyle } from "./styles";
import { rotateAroundPoint } from "../../Reducers/helpers";
import { Handles } from "./Handles";

export const Selection = ({ view }) => {
  const dispatch = useDispatch();
  const dragEl = useRef(null);
  const dragOffset = useRef(null);
  const selected = useSelector((state) => state.editor.selected);
  const scaleX = useSelector((state) => state.editor.scaleX);
  const scaleY = useSelector((state) => state.editor.scaleY);
  const handleMouseMove = (e) => {
    if (e.cancelable) {
      e.preventDefault();
      e.stopPropagation();
    }
    const handle = dragEl.current;
    const rect = e.touches
      ? e.touches[0].target.closest("#editorCanvas").getBoundingClientRect()
      : e.target.closest("#editorCanvas").getBoundingClientRect();
    const origin = { x: 0, y: 0 };
    const point = { x: dragOffset.current.x, y: dragOffset.current.y };
    const angleDeg = selected.rotation;
    const { x: rotOffsetX, y: rotOffsetY } = rotateAroundPoint({ origin, point, angleDeg });
    const mouseX = ((e.touches ? e.touches[0].clientX : e.clientX) - rect.x) / scaleX;
    const mouseY = ((e.touches ? e.touches[0].clientY : e.clientY) - rect.y) / scaleY;
    const offsetX = mouseX - rotOffsetX;
    const offsetY = mouseY - rotOffsetY;
    if (handle === "rotationHandle") {
      dispatch(setRotation({ x: mouseX, y: mouseY }));
    } else {
      if (handle === "center") dispatch(setPosition({ x: offsetX, y: offsetY }));
      if (handle !== "center" && handle) dispatch(setScale({ x: offsetX, y: offsetY, handle }));
    }
  };

  const handleMouseDown = (e) => {
    if (e.cancelable) {
      e.preventDefault();
      e.stopPropagation();
    }
    const canvas = e.touches ? e.touches[0].target.closest("#editorCanvas") : e.target.closest("#editorCanvas");
    const rect = canvas.getBoundingClientRect();
    const handle = e.target.dataset.handle;
    dragEl.current = handle;
    const handleRect = getHandleRect({ selected, handle });
    const { handleX, handleY } = setHandles({ handleRect, handle });
    const mouseX = ((e.touches ? e.touches[0].clientX : e.clientX) - rect.x) / scaleX;
    const mouseY = ((e.touches ? e.touches[0].clientY : e.clientY) - rect.y) / scaleY;
    const midX = selected.x + selected.width / 2;
    const midY = selected.y + selected.height / 2;
    const origin = { x: midX, y: midY };
    const point = { x: mouseX, y: mouseY };
    const angleDeg = -selected.rotation;
    const { x, y } = rotateAroundPoint({ origin, point, angleDeg });
    dragOffset.current = { x: x - handleX, y: y - handleY };
    if (e.touches) {
      canvas.addEventListener("touchmove", handleMouseMove, { passive: false });
      window.addEventListener(
        "touchend",
        function () {
          dragEl.current = null;
          canvas.removeEventListener("touchmove", handleMouseMove, { passive: false });
          dispatch(selectShape({ id: selected.id }));
        },
        { once: true, passive: false }
      );
    } else {
      canvas.addEventListener("mousemove", handleMouseMove);
      window.addEventListener(
        "mouseup",
        function () {
          dragEl.current = null;
          canvas.removeEventListener("mousemove", handleMouseMove);
          dispatch(selectShape({ id: selected.id }));
        },
        { once: true }
      );
    }
  };

  return (
    <div style={selected && scaleX && scaleY && selectionStyle({ selected, scaleX, scaleY })}>
      {!view && <Handles handleMouseDown={handleMouseDown} />}
    </div>
  );
};
