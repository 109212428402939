import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import { LoadingSpinner } from "../../Viewer/Components/LoadingSpinner";
import { CancelScheduleSend, Edit, Link, Send, QrCode } from "@mui/icons-material";
import { listPendingOrgInvites } from "../../Dashboard/Thunks/listPendingOrgInvites";
import { cancelInvitation } from "../Thunks/cancelInvitation";
import { UpdateInvitationDialog } from "./UpdateInvitationDialog";
import { formatDate } from "./helpers";
import { setAlert } from "../../Documentation/Reducers/DocumentationReducer";
import { updateInvitation } from "../Thunks/updateInvitation";
import { QRLinkModal } from "../../Viewer/Components/QRLinkModal";
export function InvitationTable({ setPendingOrgInvites, loadingInvite, setLoadingInvite, pendingOrgInvites }) {
  const loading = useSelector((state) => state.roles.loading);
  const dispatch = useDispatch();
  const [openQR, setOpenQR] = useState(false);
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [selectedQR, setSelectedQR] = useState("");
  return (
    <div style={{ padding: 0, height: "100%" }}>
      {loading || loadingInvite ? (
        <LoadingSpinner />
      ) : (
        <DataGridPro
          style={{ height: "100%", overflowY: "auto" }}
          components={{
            NoRowsOverlay: () => (
              <GridOverlay style={{ flexDirection: "column" }}>
                {loading ? <LoadingSpinner /> : "No Invitations"}
              </GridOverlay>
            ),
            Footer: () =>
              pendingOrgInvites && pendingOrgInvites.length ? (
                <div style={{ margin: "0px 16px", display: "flex", alignItems: "center" }}>
                  Pending Invitations: {pendingOrgInvites.length}
                </div>
              ) : (
                ""
              ),
          }}
          rows={pendingOrgInvites.map((invite, i) => {
            return {
              id: i,

              Email: invite.email,
              Roles: invite.roles.map((y) => y.name).join(", ") || "N/A",
              Creation: formatDate(invite.created_at),
              Expiry: formatDate(invite.expiry),
              "Copy Link": (
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.origin + "/invitation/" + invite.id).then(() => {
                      dispatch(setAlert({ alert: "Invite link copied to clipboard.", severity: "success" }));
                    });
                  }}
                >
                  <Link />
                </IconButton>
              ),
              "QR Code": (
                <IconButton
                  onClick={() => {
                    setSelectedQR(window.location.origin + "/invitation/" + invite.id);
                    setOpenQR(true);
                  }}
                >
                  <QrCode />
                </IconButton>
              ),
              Resend: (
                <IconButton
                  onClick={() =>
                    dispatch(
                      updateInvitation({
                        id: invite.id,
                        email: invite.email,
                        expiry: invite.expiry,
                        roles: invite.roles.map((role) => role.id),
                        resend_email: true,
                      })
                    ).then((res) => {
                      dispatch(setAlert({ alert: "Invite Email Re-sent.", severity: "success" }));
                    })
                  }
                >
                  <Send />
                </IconButton>
              ),
              Edit: (
                <IconButton
                  onClick={() => {
                    setSelectedInvitation(invite);
                  }}
                >
                  <Edit />
                </IconButton>
              ),
              Cancel: (
                <IconButton
                  onClick={() => {
                    dispatch(cancelInvitation({ id: invite.id })).then(() =>
                      dispatch(listPendingOrgInvites()).then((res) => {
                        setPendingOrgInvites(res.data.results);
                      })
                    );
                  }}
                >
                  <CancelScheduleSend />
                </IconButton>
              ),
            };
          })}
          columns={[
            { field: "Email", flex: 1 },
            { field: "Roles", flex: 2 },
            { field: "Creation", flex: 1 },
            { field: "Expiry", flex: 1 },
            { field: "QR Code", flex: 0.5, renderCell: (params) => params.value },
            { field: "Copy Link", flex: 0.5, renderCell: (params) => params.value },
            { field: "Resend", flex: 0.5, renderCell: (params) => params.value },
            { field: "Edit", flex: 0.5, renderCell: (params) => params.value },
            { field: "Cancel", flex: 0.5, renderCell: (params) => params.value },
          ]}
        />
      )}
      {selectedInvitation && (
        <UpdateInvitationDialog
          selectedInvitation={selectedInvitation}
          setSelectedInvitation={setSelectedInvitation}
          setLoadingInvite={setLoadingInvite}
          setPendingOrgInvites={setPendingOrgInvites}
          open={selectedInvitation}
        />
      )}
      <QRLinkModal
        openQR={openQR}
        setOpenQR={setOpenQR}
        name={"DropDoc Invitation"}
        value={selectedQR}
        onClose={() => {
          setOpenQR(false);
          setSelectedQR("");
        }}
      />
    </div>
  );
}
