import axios from "axios";
import { requestLoading, requestReceived, setCurrentRole } from "./RolesReducer";
import { baseURL } from "./thunks";

export const getRole = () => async (dispatch) => {
  const roleId = document.location.pathname.split("/");

  dispatch(requestLoading());
  try {
    const currentRole = await axios.get(baseURL + `roles/${roleId[roleId.length - 1]}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    dispatch(
      setCurrentRole({
        role: currentRole.data.name,
        description: currentRole.data.description,
        id: currentRole.data.id,
        list_filterable: currentRole.data.list_filterable,
      })
    );
    dispatch(requestReceived({ detail: "requestReceived" }));
    return currentRole.data;
  } catch (error) {
    dispatch(requestReceived({ detail: error }));
    console.error(error);
  }
};
