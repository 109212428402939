import { Add } from "@mui/icons-material/";
import { Fab } from "@mui/material";
import React, { createRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { setFileObjQueue } from "../../Documentation/Reducers/DocumentationReducer";
import { useCurrentPage } from "../Hooks/useCurrentPage";
import { usePasteFile } from "../Hooks/usePasteFile";
import { styles } from "../styles";
import { EmptyDocument } from "./EmptyDocument";
import { formatNewFiles } from "./formatNewFiles";
import { PresentationEditor } from "./PresentationEditor";

export function PresentationWrapper() {
  const dispatch = useDispatch();
  const page_index = useSelector((state) => state.documentation.page_index);

  const inputRef = createRef();
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles) {
        const files = formatNewFiles(acceptedFiles);
        dispatch(setFileObjQueue({ files }));
      }
    },
    [dispatch]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ noClick: true, onDrop });
  const handleAddPage = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const files = formatNewFiles(e.target.files);
      dispatch(setFileObjQueue({ files }));
    }
  };
  const isPresentationEmpty = page_index.length === 0;
  useCurrentPage({ edit: true });
  usePasteFile();

  return (
    <>
      {isPresentationEmpty ? (
        <div
          {...getRootProps()}
          style={{ ...styles.thumbnailContainer, backgroundColor: isDragActive ? "beige" : "initial" }}
        >
          <input {...getInputProps()} />

          <EmptyDocument />
        </div>
      ) : (
        <PresentationEditor />
      )}
      <Fab
        style={styles.AddPage}
        key={"Add"}
        color="primary"
        onClick={() => {
          inputRef.current.click();
        }}
      >
        <Add />
      </Fab>
      <input type="file" ref={inputRef} onChange={handleAddPage} style={styles.input} multiple />
    </>
  );
}
