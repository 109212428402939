import { AssignmentTurnedInTwoTone, Search } from "@mui/icons-material";
import {
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearch } from "../../../Search/Reducers/Thunks/getSearch";
import { LoadingSpinner } from "../LoadingSpinner";
import { TabPanel } from "./ChecklistSearchDialog";
import { findNode } from "./findNode";

export function SearchPanel({ value, setSelectedChecklist, selectedChecklist }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();
  const [results, setResults] = React.useState();
  const [query, setQuery] = React.useState("");
  const tree = useSelector((state) => state.editor.tree);

  const handleSubmit = (e) => {
    setLoading(true);
    setError("");
    e.preventDefault();
    dispatch(
      getSearch({
        q: query,
        types: ["form"],
        in_folder: false,
        include_folders: false,
      })
    )
      .then((res) => {
        setResults(res.data.results);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError("Something went wrong fetching your results... please try again");
        setLoading(false);
      });
  };

  return (
    <TabPanel value={value} index={1}>
      <Typography>Search for a checklist to add questions to this result sheet.</Typography>
      <TextField
        fullWidth
        placeholder="Search..."
        id="advanced-search"
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter") {
            handleSubmit(e);
          }
        }}
        value={query || ""}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleSubmit} color="primary" aria-label="search">
              <Search />
            </IconButton>
          ),
        }}
        style={{ padding: 5 }}
      />
      <DialogContent dividers>
        {error ? (
          <Typography>{error}</Typography>
        ) : loading ? (
          <LoadingSpinner />
        ) : results && results.length ? (
          <List style={{ height: "100%", overflow: "auto" }}>
            {results.map((result, index) => {
              let isNodePresent = tree ? findNode(tree, result.id) : null;
              return (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    disabled={isNodePresent && (isNodePresent[0] || isNodePresent[1]) ? true : false}
                    onClick={() => {
                      setSelectedChecklist(result.id);
                    }}
                    selected={selectedChecklist === result.id}
                  >
                    <ListItemIcon>
                      <AssignmentTurnedInTwoTone
                        style={{
                          fill: "darkcyan",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={result.name}
                      secondary={
                        isNodePresent && (isNodePresent[0] || isNodePresent[1]) && <span>already selected</span>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        ) : results && !results.length ? (
          <List style={{ height: "100%", overflow: "auto" }}>
            <ListItem>
              <ListItemText primary={"No Results Found."} />
            </ListItem>
          </List>
        ) : (
          ""
        )}
      </DialogContent>
    </TabPanel>
  );
}
