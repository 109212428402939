import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { setQuestion } from "../../../Editor/Reducers/EditorReducer";

export function CommentContainer({ allowComments, index }) {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.editor.questions);

  const handleComments = (comments, index) => {
    dispatch(setQuestion({ id: index, comments }));
  };

  return (
    allowComments && (
      <TextField
        id="comments"
        label="Comments"
        multiline
        value={questions[index].comments || ""}
        onChange={(e) => handleComments(e.target.value, index)}
        margin="normal"
      />
    )
  );
}
