import {
  AssignmentInd,
  Delete,
  Description,
  Edit,
  FileCopy,
  History,
  ViewList,
  Visibility,
} from "@mui/icons-material/";
import TabIcon from "@mui/icons-material/Tab";
import { ListItem, ListItemIcon, ListItemText, Menu } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import history from "../../../../Utils/history";
import { setCopy } from "../../Reducers/DocumentationReducer";
import { getDocument } from "../../Reducers/thunks";
import { getDocumentHistory } from "../../Reducers/Thunks/getDocumentHistory";
import { Perm } from "../Dialogs/helpers";
export const RightClickMenu = ({ popoverProps, contextMenu, handleClose }) => {
  const dispatch = useDispatch();
  const {
    name,
    description,
    id,
    kind,
    doctype,
    version,
    modifiedAt,
    createdAt,
    created_by_username,
    modified_by_username,
    setShowDeleteDoc,
    setDocToEdit,
    setShowEdit,
    onCopy,
    subscription_options,
    user_permissions,
    setOpenAssignments,
  } = popoverProps;
  const folderId = useSelector((state) => state.documentation.currentFolder.folder.id, shallowEqual);
  const folderName = useSelector((state) => state.documentation.currentFolder.folder.name, shallowEqual);
  const handleCopy = () => {
    dispatch(
      setCopy({
        copy: {
          name,
          description,
          id,
          kind,
          originalFolderID: folderId,
          originalFolderName: folderName,
        },
      })
    );
    onCopy(name);
  };
  const handleClickDelete = () => {
    setShowDeleteDoc(true);
    setDocToEdit({ id, name, description, kind, version, doctype });
  };
  const handleClickEdit = () => {
    setShowEdit(true);
    setDocToEdit({
      id,
      name,
      doctype,
      description,
      kind,
      version,
      modifiedAt,
      createdAt,
      created_by_username,
      modified_by_username,
      subscription_options,
      user_permissions,
    });
  };
  const handleClickHistory = () => {
    dispatch(getDocument({ id, isEditor: false })).then((x) => {
      dispatch(getDocumentHistory({ id })).then((x) => history.push("/documentation/" + id + "/history"));
    });
  };
  const handleClickViewResults = () => {
    history.push(`/documentation/results/${id}`);
  };
  const permList = Perm.getLabelList(user_permissions);
  const handleAssignment = () => {
    setOpenAssignments(true);
    setDocToEdit({
      id,
      name,
      doctype,
      description,
      kind,
      version,
      modifiedAt,
      createdAt,
      created_by_username,
      modified_by_username,
      subscription_options,
    });
  };
  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
    >
      {kind === "document" && permList.includes("Read") ? (
        <ListItem button component={Link} to={"/documentation/view/" + id} aria-label="view">
          <ListItemIcon>
            <Visibility />
          </ListItemIcon>
          <ListItemText>View</ListItemText>
        </ListItem>
      ) : (
        ""
      )}
      {doctype === "form" && permList.includes("Write") && (
        <ListItem button aria-label="history" onClick={handleClickViewResults}>
          <ListItemIcon>
            <ViewList />
          </ListItemIcon>
          <ListItemText>View Results </ListItemText>
        </ListItem>
      )}
      {kind === "document" && permList.includes("Write") ? (
        <ListItem button component={Link} to={"/documentation/edit/" + id} aria-label="edit">
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </ListItem>
      ) : (
        ""
      )}
      {permList.includes("Write") ? (
        <ListItem button aria-label="copy" onClick={handleCopy}>
          <ListItemIcon>
            <FileCopy />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
        </ListItem>
      ) : (
        ""
      )}
      <ListItem button aria-label="properties" onClick={handleClickEdit}>
        <ListItemIcon>
          <Description />
        </ListItemIcon>
        <ListItemText>Properties</ListItemText>
      </ListItem>

      <ListItem button aria-label="assign" onClick={handleAssignment}>
        <ListItemIcon>
          <AssignmentInd />
        </ListItemIcon>
        <ListItemText>Assign</ListItemText>
      </ListItem>
      {kind === "document" && (
        <ListItem button aria-label="history" onClick={handleClickHistory}>
          <ListItemIcon>
            <History />
          </ListItemIcon>
          <ListItemText>History </ListItemText>
        </ListItem>
      )}

      {permList.includes("Delete") ? (
        <ListItem button aria-label="delete" onClick={handleClickDelete}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </ListItem>
      ) : (
        ""
      )}

      <ListItem
        button
        aria-label="New Tab"
        onClick={() => {
          if (kind === "document") {
            window.open("/documentation/view/" + id, "_blank");
            handleClose();
          } else {
            window.open("/documentation/browse/" + id, "_blank");
            handleClose();
          }
        }}
      >
        <ListItemIcon>
          <TabIcon />
        </ListItemIcon>
        <ListItemText>Open New Tab</ListItemText>
      </ListItem>
    </Menu>
  );
};
