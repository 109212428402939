import history from "../../../Utils/history";

export const baseURL = `${process.env.REACT_APP_BASE_URL}`;

export const toUrlEncoded = (obj) =>
  Object.keys(obj)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]))
    .join("&");
export const logoutRedirect = () => {
  localStorage.removeItem("login");
  localStorage.removeItem("token");

  history.push("/login");
};
