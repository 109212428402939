export const reducers = {
  requestLoading: (state) => {
    if (state.loading === false) {
      state.loading = true;
    }
  },
  requestReceived: (state, action) => {
    const { detail } = action.payload;
    if (state.loading === true) {
      state.loading = false;
    } else {
      state.error = detail ? detail : "";
    }
  },
  updateRoleRows: (state, action) => {
    const { roles, total } = action.payload;
    state.roles = roles;
    state.total = total;
  },
  resetRoleRows: (state) => {
    state.roles = [];
    state.total = 0;
  },
  setValue: (state, action) => {
    const { field, value } = action.payload;
    if (field === "role") {
      state.currentRole.role = value || "";
    }
    if (field === "description") {
      state.currentRole.description = value || "";
    }
    if (field === "list_filterable") {
      console.log(String(value) === "false");
      state.currentRole["list_filterable"] = Boolean(value);
    }
  },
  setCurrentRole: (state, action) => {
    const { role, description, id, list_filterable } = action.payload;
    state.currentRole.role = role;
    state.currentRole.description = description;
    state.currentRole.id = id;
    state.currentRole.list_filterable = list_filterable;
  },
  setSelectedRoles: (state, action) => {
    const { roles } = action.payload;
    state.selectedRoles = roles;
  },
};
