import { logoutUser } from "../../Login/Reducers/LoginReducer";
import { logoutRedirect } from "../../Login/Reducers/thunks";
import { resetDocumentation } from "../../Documentation/Reducers/DocumentationReducer";
import { resetUserRows } from "../../Admin/Reducers/AdminReducer";
import { resetRoleRows } from "../../Roles/Reducers/RolesReducer";

export function handleLogout(dispatch) {
  return () => {
    dispatch(logoutUser());
    dispatch(resetDocumentation());
    dispatch(resetUserRows());
    dispatch(resetRoleRows());
    sessionStorage.clear();
    localStorage.clear();
    logoutRedirect();
  };
}
