import axios from "axios";
import { handle403 } from "../../../Dashboard/Hooks/Helpers/handle403";
import { received } from "../DocumentationReducer";
import { baseURL } from "../thunks";

export const getNotifications = () => async (dispatch) => {
  try {
    const getReq = await axios({
      method: "get",
      url: baseURL + "documentation/notifications",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return getReq;
  } catch (error) {
    handle403(error, dispatch);
    dispatch(received({ error: true }));
  }
};
