import store from "../../../../store";
import history from "../../../../Utils/history";

import { handleLogout } from "../../../Dashboard/Thunks/handleLogout";
import { loading, received, setAlert, setCurrentFolder, setTrash } from "../DocumentationReducer";
import { fetchCurrentFolder } from "./fetchCurrentFolder";
import { fetchFolderContents } from "./fetchFolderContents";
import { getTrash } from "./getTrash";

export const getFolderContents =
  ({ id, name, description, user_permissions, ancestors, targetId, previousResults, order }) =>
  async (dispatch) => {
    let skip = 0;
    let limit = 100;
    const params = new URLSearchParams();
    params.append("ordering", order?.kind + "kind");
    params.append("ordering", order?.name + "name");
    params.append("ordering", order?.created_at + "created_at");
    params.append("ordering", order?.modified_at + "modified_at");
    params.append("skip", skip);
    params.append("limit", limit);
    try {
      dispatch(loading());
      dispatch(getTrash({ folder_id: "root" })).then((x) => {
        dispatch(setTrash({ trash: x }));
      });
      dispatch(
        setCurrentFolder({
          id,
          name,
          description,
          user_permissions,
          ancestors,
          targetId,
          initial: false,
          previousResults,
        })
      );
      const currentState = store.getState().documentation?.currentFolder?.folder;
      if (parseInt(targetId) !== currentState?.id) {
        dispatch(fetchCurrentFolder({ targetId }));
      }

      await fetchFolderContents({
        targetId,
        params,
        dispatch,
        skip,
        limit,
      });
    } catch (error) {
      dispatch(received({ error: true }));
      if (error?.response?.data?.detail === "Could not validate credentials") {
        handleLogout(dispatch)();
      }

      history.push("/documentation/browse/" + id);
      dispatch(
        setAlert({
          alert: error?.response?.data?.detail || `Something went wrong trying to access this folder...`,
          severity: "error",
        })
      );

      console.log(error);
    }
  };
