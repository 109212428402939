export const baseURL = `${process.env.REACT_APP_BASE_URL}`;

export const headers = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
};
