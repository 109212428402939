import { Add } from "@mui/icons-material";
import { Fab } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "../LoadingSpinner";
import { ChecklistSearchDialog } from "./ChecklistSearchDialog";
import { QuestionLinkEditor } from "./QuestionLinkEditor";
import QuestionSelectionList from "./QuestionSelectionList";
import { useSetQuestionTree } from "./useSetQuestionTree";

export function SpreadsheetEditor() {
  const [open, setOpen] = useState(false);
  const [openSelection, setOpenSelection] = useState(false);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [treeID, setTreeID] = useState(null);
  const loading = useSelector((state) => state.documentation.loading);
  useSetQuestionTree(treeID);
  // useSetQuestionsEditor();
  // useConstructTree(treeID);
  return (
    <div style={{ overflowY: "auto", height: "100%", paddingBottom: "5em" }}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <QuestionLinkEditor
            treeID={treeID}
            openSelection={openSelection}
            setOpenSelection={setOpenSelection}
            setOpen={setOpen}
            setTreeID={setTreeID}
          />
          <QuestionSelectionList />

          <div style={{ position: "absolute", bottom: "1em", right: "1em" }}>
            <Fab
              color="primary"
              onClick={() => {
                setTreeID("root");
                setOpen(true);
              }}
              aria-label="addQuestion"
            >
              <Add />
            </Fab>
          </div>

          {open && (
            <ChecklistSearchDialog
              open={open}
              setOpen={setOpen}
              setSelectedChecklist={setSelectedChecklist}
              selectedChecklist={selectedChecklist}
              treeID={treeID}
            />
          )}
        </>
      )}
    </div>
  );
}
