import React, { useState } from "react";
import {
  FormControl,
  FormGroup,
  Checkbox,
  TextField,
  Button,
  FormControlLabel,
  Dialog,
  Slide,
  Typography,
} from "@mui/material";
import { listPendingOrgInvites } from "../../Dashboard/Thunks/listPendingOrgInvites";
import { setAlert } from "../../Documentation/Reducers/DocumentationReducer";
import { updateInvitation } from "../Thunks/updateInvitation";
import { useDispatch, useSelector } from "react-redux";
import { isValidEmail } from "./helpers";
export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function UpdateInvitationDialog({
  selectedInvitation,
  setSelectedInvitation,
  setLoadingInvite,
  setPendingOrgInvites,
}) {
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.roles.roles);
  const [checkboxes, setCheckboxes] = useState(
    roles
      ? roles.reduce((a, c) => {
          if (selectedInvitation.roles.find((invitation) => invitation.name === c.name)) {
            a[c.name] = true;
          } else {
            a[c.name] = false;
          }
          return a;
        }, [])
      : null
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth
      open={selectedInvitation ? true : false}
      onClose={() => {
        setSelectedInvitation(null);
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1em",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Update Invitation
        </Typography>
        <TextField
          label="Email"
          value={selectedInvitation.email || ""}
          onChange={(e) => setSelectedInvitation((state) => ({ ...state, email: e.target.value }))}
        />
        {checkboxes && <Typography variant="h6">Initial Roles:</Typography>}
        {checkboxes ? (
          <FormControl style={{ maxHeight: "10em", overflow: "auto" }} component="div">
            <FormGroup>
              {Object.keys(checkboxes).map((role, i) => {
                return (
                  <FormControlLabel
                    key={role + i}
                    control={
                      <Checkbox
                        checked={checkboxes[role]}
                        onChange={(e) => setCheckboxes((state) => ({ ...state, [e.target.name]: e.target.checked }))}
                        name={role}
                      />
                    }
                    label={`${role}: ${roles.filter((r) => r.name === role)[0].description}`}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        ) : (
          ""
        )}
        <Button
          variant="outlined"
          color="primary"
          disabled={!selectedInvitation.email || !isValidEmail.test(selectedInvitation.email)}
          onClick={(e) => {
            setLoadingInvite(true);
            dispatch(
              updateInvitation({
                id: selectedInvitation.id,
                email: selectedInvitation.email,
                expiry: selectedInvitation.expiry,
                roles: checkboxes
                  ? Object.keys(checkboxes).reduce((acc, cur) => {
                      if (checkboxes[cur]) {
                        acc.push(roles.find((r) => r.name === cur).id);
                      }
                      return acc;
                    }, [])
                  : [],
                resend_email: false,
              })
            )
              .then(() => {
                dispatch(listPendingOrgInvites())
                  .then((res) => {
                    setPendingOrgInvites(res?.data?.results);
                    dispatch(
                      setAlert({
                        alert: `Invitation to ${selectedInvitation.email} has been Updated.`,
                        severity: "success",
                      })
                    );
                    setSelectedInvitation(null);
                  })
                  .catch((err) => {
                    dispatch(setAlert({ alert: `${err.response.data.detail}`, severity: "error" }));
                  });
                setLoadingInvite(false);
              })
              .catch((err) => {
                dispatch(setAlert({ alert: `${err.response.data.detail}`, severity: "error" }));
              });
          }}
        >
          Update Invite
        </Button>
      </div>
    </Dialog>
  );
}
