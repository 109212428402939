import axios from "axios";
import { registrationLoading, registrationComplete } from "./RegistrationReducer";
import { setLoggedInUser } from "../../Login/Reducers/LoginReducer";
import history from "../../../Utils/history";
import { baseURL, headers } from "./thunks";

export const activateUser = () => async (dispatch) => {
  const token = new URL(document.location).searchParams.get("token");

  try {
    dispatch(registrationLoading());
    const userActivation = await axios.post(baseURL + "auth/activate", `"${token}"`, headers);
    localStorage.setItem("login", true);
    localStorage.setItem("token", userActivation.data.access_token);
    const userRequest = await axios.get(baseURL + "auth/me", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + userActivation.data.access_token,
      },
    });
    sessionStorage.setItem("username", userRequest.data.username);
    sessionStorage.setItem("email", userRequest.data.email);
    sessionStorage.setItem("full_name", userRequest.data.full_name);
    const userPendingInvites = await axios({
      method: "get",
      url: baseURL + "auth/invites",

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    dispatch(
      setLoggedInUser({
        username: userRequest.data.username,
        email: userRequest.data.email,
        full_name: userRequest.data.full_name,
        is_org_admin: userRequest.data.is_org_admin,
        available_orgs: userRequest.data.available_orgs,
        org: userRequest.data.org,
        roles: userRequest.data.roles,
        authenticated: true,
        avatar: userRequest.data.avatar,
        otp_enabled: userRequest.data.otp_enabled,
        invitations: userPendingInvites.data,
      })
    );
    dispatch(registrationComplete());

    history.push("/");
  } catch (error) {
    console.error(error);
  }
};
