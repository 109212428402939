import { useLocation } from "react-router-dom";

export const finalPathname = (location) =>
  location.pathname
    .split("/")
    .filter((x) => x !== "")
    .pop();

export function LastLocation() {
  const location = useLocation();
  return finalPathname(location);
}
