import React from "react";
import { FlexContainer } from "../styles";
import { Button, Divider } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { loginSelector, setLoggedInUser } from "../../Login/Reducers/LoginReducer";
import history from "../../../Utils/history";
import { selectOrg } from "../Thunks/selectOrg";
import { Star } from "@mui/icons-material";
import { resetDocumentation } from "../../Documentation/Reducers/DocumentationReducer";
import { resetUserRows } from "../../Admin/Reducers/AdminReducer";
import { resetRoleRows } from "../../Roles/Reducers/RolesReducer";

export const AvailableOrgButtons = () => {
  const { available_orgs, org } = useSelector(loginSelector);
  const dispatch = useDispatch();

  return available_orgs.map((available_org) => {
    return (
      <div key={available_org.id}>
        <FlexContainer>
          {org && available_org.id === org.id ? (
            <Button disableRipple startIcon={<Star />}>
              {available_org.name}
            </Button>
          ) : (
            <Button
              onClick={() => {
                dispatch(selectOrg({ id: available_org.id })).then((res) => {
                  history.push("/");
                  dispatch(resetDocumentation());
                  dispatch(resetUserRows());
                  dispatch(resetRoleRows());
                  dispatch(
                    setLoggedInUser({
                      username: res.data.username,
                      email: res.data.email,
                      full_name: res.data.full_name,
                      is_org_admin: res.data.is_org_admin,
                      available_orgs: res.data.available_orgs,
                      org: res.data.org,
                      roles: res.data.roles,
                      authenticated: true,
                      avatar: res.data.avatar,
                      otp_enabled: res.data.otp_enabled,
                    })
                  );
                });
              }}
            >
              {available_org.name}
            </Button>
          )}
        </FlexContainer>
        <Divider />
      </div>
    );
  });
};
