import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  AppBar,
  Toolbar,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useStyles } from "../styles";
import { Lock, VpnKey, CheckCircle, Cancel } from "@mui/icons-material/";
import { setResetPassword } from "../Reducers/setResetPassword";
import history from "../../../Utils/history";
export const ResetPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.login.loading);
  const [password, setPassword] = useState(() => "");
  const [confirmPassword, setConfirmPassword] = useState(() => "");
  const [sent, setSent] = useState(false);
  const [error, setError] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setResetPassword({ password }))
      .then((res) => setSent(true))
      .catch((e) => {
        setError(e.message);
      });
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography edge="start" variant="h6" className={classes.title}>
            Reset Password
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md">
        <Typography variant="h4">Reset Password</Typography>
        <Typography variant="body1">Password must be at least 8 characters long.</Typography>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.padding}>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    id="password"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoFocus
                    required
                    startAdornment={<Lock />}
                    endAdornment={password.length < 8 ? <Cancel /> : <CheckCircle />}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                  <OutlinedInput
                    id="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    autoComplete="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    startAdornment={<VpnKey />}
                    endAdornment={
                      !password || (confirmPassword.length < 8 && password !== confirmPassword) ? (
                        <Cancel />
                      ) : (
                        <CheckCircle />
                      )
                    }
                  />
                </FormControl>

                <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
                  {loading ? (
                    <Button>Saving Password...</Button>
                  ) : error ? (
                    <Typography className={classes.warning}>Something went wrong! Please try again</Typography>
                  ) : sent ? (
                    <Button className={classes.success} disabled>
                      Password Has Been Reset
                    </Button>
                  ) : (
                    <Button
                      disabled={password !== confirmPassword}
                      type="submit"
                      variant="outlined"
                      color="primary"
                      style={{ textTransform: "none" }}
                    >
                      {!password
                        ? "Please Enter a Password"
                        : password !== confirmPassword
                        ? "Passwords Do Not Match!"
                        : "Save Password"}
                    </Button>
                  )}
                </Grid>
              </form>
              {sent && (
                <Button
                  disableFocusRipple
                  disableRipple
                  style={{ textTransform: "none" }}
                  variant="text"
                  color="primary"
                  onClick={() => history.push("/login")}
                >
                  Go to Login
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
