import { useEffect } from "react";
import { getUsers } from "../../Admin/Thunks/getUsers";
import { getRole } from "../Reducers/getRole";

export function useGetRole(dispatch) {
  useEffect(() => {
    dispatch(getRole()).then((res) => {
      dispatch(getUsers({ skip: 0, limit: 100000, order: "", orderBy: "", search: "", role_filters: [res.id] }));
    });
  }, [dispatch]);
}
