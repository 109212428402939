import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";

export function PermissionsToolbar(showInherited, setShowInherited) {
  return () => (
    <GridToolbarContainer>
      <FormControlLabel
        control={
          <Checkbox
            checked={showInherited}
            onChange={(e) => setShowInherited(e.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Show Inherited Permissions"
      />
    </GridToolbarContainer>
  );
}
