import styled from "styled-components";
import { Button } from "@mui/material";

export const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ToolbarButton = styled(Button)`
  background: ${(props) => props.selected};
`;
