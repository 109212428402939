import axios from "axios";
import { received } from "../DocumentationReducer";
import { baseURL } from "../thunks";

export const putFolderACL =
  ({ id, ACLList }) =>
  async (dispatch) => {
    try {
      const putReq = await axios({
        method: "put",
        url: baseURL + "documentation/folders/" + id + "/acl",
        data: ACLList,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      console.log({ putReq });
      return putReq;
    } catch (error) {
      console.log(error);
      dispatch(received({ error: true }));
    }
  };
