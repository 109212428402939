import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export function Arrow(y, scaleY, x, scaleX, width, height, rotation, color, id, handleClick) {
  return (
    <div
      style={{
        position: "absolute",
        top: y * scaleY,
        left: x * scaleX,
        width: width * scaleX,
        height: height * scaleY,
        transform: `rotate(${rotation}deg)`,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: color,
      }}
    >
      <ArrowRightAltIcon
        id={id}
        onClick={handleClick}
        preserveAspectRatio="none"
        style={{
          pointerEvents: "auto",
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
}
