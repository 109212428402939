import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  RadioGroup,
  Radio,
  Select,
  TextField,
  Typography,
  Modal,
  IconButton,
  Button,
} from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { setQuestion } from "../../../Editor/Reducers/EditorReducer";
import { validateEmail, validURL } from "./helpers";
import { QrReader } from "react-qr-reader";
import { Box } from "@mui/system";

export function ValueContainer({ values, valueType, description, index, choices, widget, title }) {
  const dispatch = useDispatch();
  const [showQR, setShowQR] = useState(false);

  const handleTrueFalse = (value, index) =>
    value === values || !value
      ? dispatch(setQuestion({ id: index, value: "not checked" }))
      : dispatch(setQuestion({ id: index, value }));
  const handleSingleChoice = (value, index) =>
    value === values || !value
      ? dispatch(setQuestion({ id: index, value: "not checked" }))
      : dispatch(setQuestion({ id: index, value }));
  const handleMultipleChoice = (value, index) =>
    value && index && dispatch(setQuestion({ id: index, value, multiple: true }));
  const handleNumber = (value) => {
    dispatch(setQuestion({ id: index, value: value === "" ? "" : Number(value) }));
  };

  const handleDateTime = (value, index) => dispatch(setQuestion({ id: index, value }));
  const handleTextField = (value, index) => dispatch(setQuestion({ id: index, textField: value }));

  const QRField = (
    <FormControl component="fieldset">
      <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>

      <TextField
        type={valueType === "number" || valueType === "decimal" ? "number" : "text"}
        id="qr-field"
        label={title}
        value={values === "not checked" ? "" : values || ""}
        onChange={(e) => {
          valueType === "number" || valueType === "decimal"
            ? handleNumber(e.target.value)
            : handleTextField(e.target.value, index);
        }}
        InputProps={{
          endAdornment: (
            <IconButton aria-label="qr-code" onClick={() => setShowQR(true)}>
              <QrCodeScannerIcon />
            </IconButton>
          ),
        }}
      />
    </FormControl>
  );

  const TrueFalseRadio = (
    <FormControl component="fieldset">
      <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
      <RadioGroup
        aria-label="checklist"
        name="checklist"
        value={!values && values !== 0 ? "" : values}
        style={{ flexDirection: "column" }}
        onClick={(e) => handleTrueFalse(e.target.value, index)}
      >
        <FormControlLabel value={true} control={<Radio />} label="True" />
        <FormControlLabel value={false} control={<Radio />} label="False" />
      </RadioGroup>
    </FormControl>
  );
  const PassFailRadio = (
    <FormControl component="fieldset">
      <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
      <RadioGroup
        aria-label="checklist"
        name="checklist"
        value={values}
        style={{ flexDirection: "column" }}
        onClick={(e) => handleTrueFalse(e.target.value, index)}
      >
        <FormControlLabel value={true} control={<Radio />} label="Pass" />
        <FormControlLabel value={false} control={<Radio />} label="Fail" />
      </RadioGroup>
    </FormControl>
  );
  const NumberInput =
    widget === "qr code" ? (
      QRField
    ) : (
      <FormControl component="fieldset">
        <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
        <TextField
          type="number"
          value={values}
          onWheel={(event) => {
            //https://github.com/mui/material-ui/issues/7960
            event.target.blur();
          }}
          onChange={(e) => handleNumber(e.target.value)}
          label={title}
        />
      </FormControl>
    );
  const SingleChoiceRadio =
    widget === "dropdown" ? (
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
        <FormControl style={{ minWidth: 120, maxWidth: 1000 }}>
          <InputLabel id="demo-simple-select-label">Answer</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            displayEmpty
            value={values === "not checked" ? "" : values}
            label="Answer"
            onChange={(e) => handleSingleChoice(e.target.value, index)}
          >
            {choices.map((choice, j) => (
              <MenuItem key={j} value={choice}>
                {choice}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    ) : (
      <FormControl component="fieldset">
        <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
        <RadioGroup
          aria-label="checklist"
          name="checklist"
          value={values}
          style={{ flexDirection: "row" }}
          onClick={(e) => handleSingleChoice(e.target.value, index)}
        >
          <FormControlLabel style={{ display: "none" }} value="not checked" control={<Radio />} label="Not Checked" />
          {choices.map((choice, j) => {
            return <FormControlLabel key={j} value={choice} control={<Radio />} label={choice} />;
          })}
        </RadioGroup>
      </FormControl>
    );
  const MultipleChoiceRadio =
    widget === "checkboxes" ? (
      <FormControl component="fieldset">
        <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
        {choices.map((choice, j) => {
          return (
            <FormControlLabel
              key={j}
              control={
                <Checkbox
                  checked={values ? values.includes(choice) : false}
                  onClick={(e) => handleMultipleChoice(e.target.name, index)}
                  name={choice}
                  color="primary"
                />
              }
              label={choice}
            />
          );
        })}
      </FormControl>
    ) : (
      <FormControl component="fieldset">
        <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
        {choices.map((choice, j) => {
          return (
            <FormControlLabel
              key={j}
              onClick={(e) => handleMultipleChoice(e.target.value, index)}
              checked={values ? values.includes(choice) : false}
              value={choice}
              control={<Radio />}
              label={choice}
            />
          );
        })}
      </FormControl>
    );
  const DateTimePicker = (
    <FormControl component="fieldset" noValidate>
      <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
      <TextField
        id="datetime-local"
        label={title}
        type="datetime-local"
        onChange={(e) => handleDateTime(e.target.value, index)}
        value={values}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </FormControl>
  );
  const EmailTextField =
    widget === "qr code" ? (
      QRField
    ) : (
      <FormControl>
        <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
        <TextField
          error={!validateEmail(values)}
          id="email-field"
          label={title}
          value={values || ""}
          onChange={(e) => handleTextField(e.target.value, index)}
          helperText={!validateEmail(values) && "Invalid Email"}
        />
      </FormControl>
    );
  const URLTextField =
    widget === "qr code" ? (
      QRField
    ) : (
      <FormControl component="fieldset">
        <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
        <TextField
          error={!validURL(values)}
          id="url-field"
          label={title}
          value={values || ""}
          onChange={(e) => handleTextField(e.target.value, index)}
          helperText={!validURL(values) && "Invalid URL"}
        />
      </FormControl>
    );
  const TextValueField =
    widget === "qr code" ? (
      QRField
    ) : (
      <FormControl component="fieldset">
        <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
        <TextField
          id="text-field"
          label={title}
          value={values === "not checked" ? "" : values || ""}
          onChange={(e) => handleTextField(e.target.value, index)}
        />
      </FormControl>
    );

  const DurationField =
    widget === "qr code" ? (
      QRField
    ) : (
      <FormControl component="fieldset">
        <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
        <TextField
          id="duration-field"
          label={title}
          value={values || ""}
          onChange={(e) => handleTextField(e.target.value, index)}
        />
      </FormControl>
    );

  return (
    <div>
      {valueType === "true/false"
        ? TrueFalseRadio
        : valueType === "pass/fail"
        ? PassFailRadio
        : valueType === "number" || valueType === "decimal"
        ? NumberInput
        : valueType === "single choice"
        ? SingleChoiceRadio
        : valueType === "multiple choice"
        ? MultipleChoiceRadio
        : valueType === "datetime"
        ? DateTimePicker
        : valueType === "email"
        ? EmailTextField
        : valueType === "url"
        ? URLTextField
        : valueType === "text"
        ? TextValueField
        : valueType === "duration"
        ? DurationField
        : ""}
      <Modal open={showQR} onClose={() => setShowQR(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Scan QR Code
          </Typography>
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={(result, error) => {
              if (result) {
                valueType === "number" || valueType === "decimal"
                  ? handleNumber(result?.text)
                  : handleTextField(result?.text, index);
                setShowQR(false);
              }

              if (error) {
                console.info(error);
                // setShowQR(false);
              }
            }}
            style={{ width: "100%" }}
          />

          <Button onClick={() => setShowQR(false)}>Close</Button>
        </Box>
      </Modal>
    </div>
  );
}
