import React from "react";
import { Toolbar } from "@mui/material";
import { DocumentationDropdowns } from "./DocumentationDropdowns";
import { DocumentationButtons } from "./DocumentationButtons";
export const buttonStyle = {
  width: "2em",
  height: "2em",
};
const styles = {
  button: { display: "flex", position: "absolute" },
  toolbar1: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 0.5em 0 0.5em",
  },
};
export const DocumentationToolbar = (props) => {
  const { setShowPaste } = props;
  return (
    <Toolbar style={styles.toolbar1}>
      <div style={styles.button}>
        <DocumentationButtons setShowPaste={setShowPaste} />
        <DocumentationDropdowns />
      </div>
    </Toolbar>
  );
};
