import axios from "axios";
import { loading } from "./LoginReducer";
import { baseURL } from "./thunks";

export const setResetPassword =
  ({ password }) =>
  async (dispatch) => {
    const activationToken = new URL(document.location).searchParams.get("token");

    try {
      dispatch(loading({ isLoading: true }));
      const resetPassword = await axios.post(
        baseURL + "auth/reset-password",
        {
          password,
          token: activationToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + activationToken,
          },
        }
      );

      dispatch(loading({ isLoading: false }));

      return resetPassword;
    } catch (error) {
      dispatch(loading({ isLoading: false }));
      console.error(error);
      return error;
    }
  };
