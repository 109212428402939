import { useEffect } from "react";
import { usePrevious } from "../../Documentation/Helpers/helpers";

export function useClearAvatar(avatar, setClearAvatar) {
  let previousAvatar = usePrevious(avatar);

  useEffect(() => {
    if (avatar !== previousAvatar) setClearAvatar(false);
  }, [avatar, previousAvatar, setClearAvatar]);
}
