import axios from "axios";
import { loading } from "../../Documentation/Reducers/DocumentationReducer";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const getProductList = () => async (dispatch) => {
  try {
    dispatch(loading());
    return await axios({
      method: "get",
      url: baseURL + "billing/products",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (e) {
    console.error(e);
    return e;
  }
};
