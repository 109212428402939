import React from "react";
import { Button, TextField, Typography } from "@mui/material";
import { createOrg } from "../Thunks/createOrg";
import { selectOrg } from "../Thunks/selectOrg";
import { setLoggedInUser } from "../../Login/Reducers/LoginReducer";
import { getAssignments } from "../../Documentation/Reducers/Thunks/getAssignments";

export function CreateOrganization({ setOrgName, orgName, dispatch }) {
  return (
    <div style={{ margin: "1em" }}>
      <Typography variant="h5">Create an Organization</Typography>
      <TextField
        fullWidth
        placeholder="Enter Organization Name"
        id="org_name"
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        onChange={(e) => {
          setOrgName(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            if (orgName.length) {
              dispatch(createOrg({ name: orgName })).then((res1) => {
                dispatch(selectOrg({ id: res1?.data?.id })).then((res2) => {
                  dispatch(getAssignments()).then((res3) => {
                    dispatch(
                      setLoggedInUser({
                        username: res2.data.username,
                        email: res2.data.email,
                        full_name: res2.data.full_name,
                        is_org_admin: res2.data.is_org_admin,
                        available_orgs: res2.data.available_orgs,
                        org: res2.data.org,
                        roles: res2.data.roles,
                        assignments: res3.data,
                        authenticated: true,
                        avatar: res2.data.avatar,
                        otp_enabled: res2.data.otp_enabled,
                      })
                    );
                  });
                });
              });
            }
          }
        }}
        value={orgName || ""}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <Button
              onClick={() => {
                if (orgName.length) {
                  dispatch(createOrg({ name: orgName })).then((res1) => {
                    dispatch(selectOrg({ id: res1?.data?.id })).then((res2) => {
                      dispatch(getAssignments()).then((res3) => {
                        dispatch(
                          setLoggedInUser({
                            username: res2.data.username,
                            email: res2.data.email,
                            full_name: res2.data.full_name,
                            is_org_admin: res2.data.is_org_admin,
                            available_orgs: res2.data.available_orgs,
                            org: res2.data.org,
                            roles: res2.data.roles,
                            authenticated: true,
                            assignments: res3.data,
                            avatar: res2.data.avatar,
                            otp_enabled: res2.data.otp_enabled,
                          })
                        );
                      });
                    });
                  });
                }
              }}
              color="primary"
              variant="outlined"
              aria-label="org_name"
            >
              Submit
            </Button>
          ),
        }}
      />
    </div>
  );
}
