import { Edit, Save, Visibility } from "@mui/icons-material/";
import Download from "@mui/icons-material/Download";
import { Breadcrumbs, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import history from "../../../Utils/history";
import { Perm } from "../../Documentation/Components/Dialogs/helpers";
import { setPrevLocation } from "../../Documentation/Reducers/DocumentationReducer";
import { forceDownload } from "../../Editor/Components/Annotator/Helpers/forceDownload";
import { parseFilename } from "../../Editor/Components/Annotator/Helpers/parseFilename";
import { deselectShapes } from "../../Editor/Reducers/EditorReducer";
import { MapLinkValues } from "./MapLinkValues";
import { OptionsButton } from "./OptionsButton";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    background: theme.palette.primary.light,
    padding: "12px",
    color: "white",
  },
}));

export const Breadcrumb = (props) => {
  const { currentValue, linkValues, isViewer, isEditor, setOpenQR, setOpenSubs, setOpenAssignments, hideOptions } =
    props;
  let { version_number } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const id = useSelector((state) => state.documentation?.currentDocument?.id);
  const doctype = useSelector((state) => state.documentation?.currentDocument?.doctype);
  const currentDocumentChanges = useSelector((state) => state.documentation?.currentDocumentChanges);
  const user_permissions = useSelector((state) => state.documentation?.currentDocument?.user_permissions);
  const didQuestionsChange = useSelector((state) => state.documentation?.didQuestionsChange);
  const currentPage = useSelector((state) => state.documentation?.currentPage);
  const queueLength = useSelector((state) => state.documentation?.queueLength);
  const isBlocking = useSelector((state) => state.documentation?.isBlocking);
  const isModified = currentDocumentChanges || queueLength || didQuestionsChange || isBlocking;
  const permList = user_permissions && Perm.getLabelList(user_permissions);
  const [anchorEl, setAnchorEl] = React.useState(null);
  return (
    <div style={{ display: "grid", gridTemplateColumns: "11fr 0fr", background: "#4c566a" }}>
      <DndProvider
        backend={TouchBackend}
        options={{
          enableTouchEvents: true,
          enableMouseEvents: true,
          delayTouchStart: 500,
        }}
        context={document}
      >
        <Breadcrumbs aria-label="Breadcrumb" className={classes.breadcrumbs}>
          {MapLinkValues(linkValues)}

          {currentValue && (
            <Typography
              style={{
                color: "white",
              }}
            >
              {currentValue}
            </Typography>
          )}
        </Breadcrumbs>
      </DndProvider>
      <div style={{ display: "flex" }}>
        {location.pathname.includes("/admin") || hideOptions
          ? ""
          : OptionsButton({
              anchorEl,
              setAnchorEl,
              setOpenQR,
              id,
              setOpenSubs,
              setOpenAssignments,
              doctype,
              isViewer,
            })}
        {!version_number && isViewer && !location.pathname.includes("documentation/results") && (
          <>
            {currentPage && doctype === "presentation" && !currentPage.content_type.includes("image") ? (
              <IconButton
                style={{ color: "white" }}
                onClick={() => forceDownload(currentPage?.media, parseFilename(currentPage.media))}
              >
                <Download />
              </IconButton>
            ) : (
              ""
            )}
            {user_permissions && permList.includes("Write") ? (
              <IconButton
                onClick={() => {
                  if (doctype === "presentation") dispatch(deselectShapes());
                  dispatch(setPrevLocation({ prevLocation: location.pathname }));
                  history.push(`/documentation/edit/${id}`);
                }}
                style={{ color: "white" }}
                aria-label="edit"
              >
                <Edit />
              </IconButton>
            ) : (
              ""
            )}
          </>
        )}

        {!version_number && isEditor && (
          <IconButton
            onClick={() => {
              if (doctype === "presentation") dispatch(deselectShapes());
              dispatch(setPrevLocation({ prevLocation: location.pathname }));
              history.push(`/documentation/view/${id}`);
            }}
            style={{ color: "white" }}
            aria-label="view"
          >
            {isModified ? <Save /> : <Visibility />}
          </IconButton>
        )}
      </div>
    </div>
  );
};
