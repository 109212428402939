import { useEffect } from "react";
import { setIsBlockingViewer } from "../../../Documentation/Reducers/DocumentationReducer";

export function useIsBlockingViewer(uid, checklistUID, dispatch, isBlockingViewer, submitting) {
  useEffect(() => {
    if (submitting) {
      dispatch(setIsBlockingViewer({ isBlockingViewer: false }));
    } else if (localStorage.getItem(uid) && !submitting) {
      let checklists = JSON.parse(localStorage.getItem(uid));
      let keys = Object.keys(checklists);

      if (keys.includes(checklistUID)) {
        dispatch(setIsBlockingViewer({ isBlockingViewer: true }));
      }
    }
  }, [uid, dispatch, isBlockingViewer, checklistUID, submitting]);
}
