import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { reducers } from "./reducers";
const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers,
});
export const searchSelector = (state) => state.search;

export const { setSearch } = searchSlice.actions;

export const search = searchSlice.reducer;
