import { Fade, IconButton, Modal } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "../styles";
import { listActiveLoginSessions } from "../Thunks/listActiveLoginSessions";
import { Delete, StarOutlined } from "@mui/icons-material";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import { forceLogoutSession } from "../Thunks/forceLogoutSession";
import { useActiveLoginSessions } from "../Hooks/useActiveLoginSessions";
export function DeviceListModal({ open, setOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deviceList, setDeviceList] = useState();
  useActiveLoginSessions(setDeviceList);
  const columns = [
    {
      field: "Ip Address",
      flex: 1,
    },
    { field: "Device", flex: 3 },
    { field: "Expiry", flex: 1 },
    { field: "Created", flex: 1 },
    { field: "Last Accessed", flex: 1 },
    {
      field: "Force Logout",
      flex: 0,
      renderCell: (params) => params.value,
    },
  ];
  const rows =
    deviceList &&
    deviceList.map((row) => {
      return {
        id: row.id,
        "Ip Address": row.ip_address,
        Device: row.user_agent,
        Expiry: new Date(row.expiry).toLocaleString("en-CA"),
        Created: new Date(row.created_at).toLocaleString("en-CA"),
        "Last Accessed": new Date(row.last_accessed_at).toLocaleString("en-CA"),
        "Force Logout": row.is_active ? (
          <StarOutlined style={{ color: "#e3e333" }} />
        ) : (
          <IconButton
            onClick={() =>
              dispatch(forceLogoutSession({ session_id: row.id })).then(() => {
                dispatch(listActiveLoginSessions()).then((res) => {
                  setDeviceList(res.data);
                });
              })
            }
          >
            <Delete />
          </IconButton>
        ),
      };
    });

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
    >
      <Fade in={open}>
        <div className={classes.modalPaper} style={{ padding: 0, height: "80%", width: "80%" }}>
          <DataGridPro
            style={{ height: "100%", overflowY: "auto" }}
            components={{
              NoRowsOverlay: () => <GridOverlay style={{ flexDirection: "column" }}>{"No Devices Found"}</GridOverlay>,
              Footer: () => (
                <div style={{ margin: "0px 16px", display: "flex", alignItems: "center" }}>
                  Active Devices: {deviceList ? deviceList.length : 0}
                </div>
              ),
            }}
            columns={columns}
            rows={rows}
          />
        </div>
      </Fade>
    </Modal>
  );
}
