import React, { useCallback } from "react";
import { DragHandle as DragHandleIcon } from "@mui/icons-material/";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { selectShape, setZoom, setZoomOffset } from "../../Editor/Reducers/EditorReducer";
const Box = styled.div`
  background: padding-box rgb(200, 204, 208);
  width: 12px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: -6px;
  cursor: col-resize;
  position: relative;
  &:hover {
    transition: all 0.3s ease;
    opacity: 0.4;
    border-left: 5px solid rgb(200, 204, 208);
    border-right: 5px solid rgb(200, 204, 208);
  }
`;

const maxWidth = document.body.offsetWidth;
const defaultWidth = document.body.offsetWidth * 0.2;
const minWidth = 5;
const DragHandle = styled(DragHandleIcon)`
  position: relative;
  left: -1em;
  border: 1px solid rgb(200, 204, 208);
  background: white;
  width: 1em;
  height: 3em;
  cursor: col-resize;
  top: calc(50% - 1.5em);
  &:hover {
    background: padding-box rgb(200, 204, 208);
    transition: all 0.3s ease;
    opacity: 0.8;
  }
`;
export const AnnotationListResize = ({ isEditor, shapeRect, annotationWidth, setAnnotationWidth, children }) => {
  const dispatch = useDispatch();
  const clickOutside = (e) => {
    if (e.target.id === "AnnotationList") {
      dispatch(selectShape({ id: null }));
      dispatch(setZoom({ zoom: 1 }));
      dispatch(setZoomOffset({ top: 0, left: 0 }));
      if (!isEditor && shapeRect?.current) shapeRect.current = null;
    }
  };

  const handleMouseDown = (e) => {
    if (e.cancelable) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.touches) {
      document.addEventListener("touchend", handleMouseUp, true);
      document.addEventListener("touchmove", handleMouseMove, true);
    } else {
      document.addEventListener("mouseup", handleMouseUp, true);
      document.addEventListener("mousemove", handleMouseMove, true);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("touchend", handleMouseUp, true);
    document.removeEventListener("touchmove", handleMouseMove, true);
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (e.cancelable) {
        e.preventDefault();
        e.stopPropagation();
      }
      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      const newWidth = document.body.offsetWidth - clientX;
      if (newWidth > minWidth && newWidth < maxWidth) {
        setAnnotationWidth(newWidth);
      }
    },
    [setAnnotationWidth]
  );

  return (
    <div
      style={{
        display: "flex",
        background: "white",
      }}
      onClick={clickOutside}
    >
      <Box
        onDoubleClick={() => setAnnotationWidth(annotationWidth > minWidth ? minWidth : defaultWidth)}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        role="presentation"
      />
      <DragHandle
        id="dragHandle"
        onDoubleClick={() => setAnnotationWidth(annotationWidth > minWidth ? minWidth : defaultWidth)}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
      />
      <span
        id="AnnotationList"
        style={{ marginLeft: "-2.5em", textAlign: "center", overflowY: "auto", background: "white", width: "100%" }}
      >
        {children}
      </span>
    </div>
  );
};
