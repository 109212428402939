import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import { setShapeDescription, setPageDescription } from "../../Reducers/EditorReducer";
import ReactMde from "react-mde";
import { usePrevious } from "../../../Documentation/Helpers/helpers";
import "./styles.css";
const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  openLinksInNewWindow: true,
});
export default function TextEditor() {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.editor.selected);
  const description = useSelector((state) => state.editor.description);
  const [selectedTab, setSelectedTab] = useState("write");
  const value = selected ? selected.description : description;
  const prevValue = usePrevious(value);
  useEffect(() => {
    if (selectedTab === "preview" && value !== prevValue) setSelectedTab("write");
  }, [value, prevValue, selectedTab]);
  useEffect(() => {
    document.getElementsByClassName("mde-textarea-wrapper")[0].parentElement.style.height = "inherit";
  });
  return (
    <ReactMde
      value={value}
      onChange={handleChange(dispatch, selected)}
      selectedTab={selectedTab}
      onTabChange={setSelectedTab}
      generateMarkdownPreview={handleMarkdown(value, prevValue)}
      childProps={{
        writeButton: {
          tabIndex: -1,
        },
        textArea: {
          style: {
            resize: "none",
          },
        },
      }}
    />
  );
}

function handleMarkdown(value, prevValue) {
  return async (markdown) => {
    if (value === prevValue) {
      return await Promise.resolve(converter.makeHtml(markdown));
    }
  };
}

function handleChange(dispatch, selected) {
  return (e) => {
    if (selected) {
      return dispatch(setShapeDescription({ id: selected.id, description: e }));
    } else {
      dispatch(setPageDescription({ description: e }));
    }
  };
}
