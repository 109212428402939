import React from "react";
import { useDrop } from "react-dnd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const MapLinkValues = (linkValues) => {
  return linkValues
    .filter((x) => x)
    .map((link, index) =>
      index === 0 ? (
        <Link key={index} style={{ textDecoration: "underline", color: "white" }} to={link.to}>
          {link.name}
        </Link>
      ) : (
        <MappedLinks key={index} link={link} index={index} />
      )
    );
};
function MappedLinks({ link }) {
  const rootID = useSelector((state) => state.documentation.rootID);
  const splitLink = link.to.split("/");
  const isLastBrowse = splitLink[splitLink.length - 1] === "browse" ? rootID : splitLink[splitLink.length - 1];
  const [{ isOver }, drop] = useDrop({
    accept: ["document", "folder"],
    drop: () => {
      return {
        name: link.name,
        kind: "folder",
        id: Number(isLastBrowse),
        allowedDropEffect: "any",
      };
    },

    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  return (
    <Link style={{ textDecoration: "underline", color: isOver ? "red" : "white" }} ref={(el) => drop(el)} to={link.to}>
      {link.name}
    </Link>
  );
}
