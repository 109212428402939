import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { reducers } from "./reducers";
const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers,
});
export const adminSelector = (state) => state.admin;

export const { requestLoading, requestReceived, updateRows, resetUserRows, setCurrentUser, setValue, setFileInput } =
  adminSlice.actions;

export const admin = adminSlice.reducer;
