import axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const selectOrg =
  ({ id }) =>
  async () => {
    try {
      return await axios({
        method: "post",
        url: baseURL + "auth/select-org",
        data: id,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (e) {
      console.error(e);
      return e;
    }
  };
