import { IconButton } from "@mui/material";
import React from "react";
import { setSpreadsheet } from "../../../Documentation/Reducers/DocumentationReducer";
import { Visibility, Comment } from "@mui/icons-material/";

export function foldQuestionFields(questions, dispatch) {
  return (accumulator, currentIndex) => {
    accumulator.push({
      field: currentIndex,
      headerName: questions[currentIndex].title,
      flex: 1,
      type:
        questions[currentIndex].valueType === "true/false" ||
        questions[currentIndex].valueType === "pass/fail" ||
        questions[currentIndex].valueType === "single choice" ||
        questions[currentIndex].valueType === "text" ||
        questions[currentIndex].valueType === "url" ||
        questions[currentIndex].valueType === "email" ||
        questions[currentIndex].valueType === "duration"
          ? "string"
          : questions[currentIndex].valueType === "datetime"
          ? "date"
          : "number",
      sortComparator: (a, b) => {
        if (a && b) {
          const valueType = questions[currentIndex].valueType;
          return valueType === "true/false" ||
            valueType === "pass/fail" ||
            valueType === "single choice" ||
            valueType === "text" ||
            valueType === "url" ||
            valueType === "email" ||
            valueType === "duration" ||
            valueType === "datetime"
            ? String(a) > String(b)
              ? 1
              : -1
            : valueType === "multiple choice"
            ? a[0] > b[0]
              ? 1
              : -1
            : a - b;
        }
      },

      renderCell: (params) => {
        const row = params.row[currentIndex];
        const handleMediaDialog = () => {
          dispatch(setSpreadsheet({ media: row?.media }));
          dispatch(setSpreadsheet({ open: true }));
        };
        const handleCommentsDialog = () => {
          dispatch(setSpreadsheet({ comments: row?.comments }));
          dispatch(setSpreadsheet({ open: true }));
        };
        const isMediaButton =
          row?.media && row?.media.length ? (
            <IconButton
              className="tableButton"
              onClick={handleMediaDialog}
              aria-label="view"
              color="primary"
              style={{ padding: 0 }}
            >
              <Visibility />
            </IconButton>
          ) : (
            ""
          );
        const isCommentsButton = row?.comments ? (
          <IconButton
            className="tableButton"
            onClick={handleCommentsDialog}
            aria-label="view"
            color="primary"
            style={{ padding: 0 }}
          >
            <Comment />
          </IconButton>
        ) : (
          ""
        );
        const value = (
          <>
            <span
              style={{
                paddingRight: 10,
                backgroundColor: params.value === "" ? "yellow" : row?.valid ? "" : "red",
                color: row?.valid ? "black" : "white",
              }}
            >
              {params.value}
            </span>
            {isMediaButton}
            {isCommentsButton}
          </>
        );
        return value;
      },
      valueGetter: (params) => {
        // https://stackoverflow.com/questions/68961792/material-ui-data-grid-filter-not-working-when-using-rendercell-in-a-field
        // https://stackoverflow.com/questions/68550751/how-do-i-get-materialui-xgrid-filters-to-filter-by-rendercell-parameters-instead
        const isValueArray = !params?.value
          ? ""
          : questions[currentIndex].valueType === "pass/fail"
          ? params?.value?.value
            ? "pass"
            : "fail"
          : questions[currentIndex].valueType !== "pass/fail" &&
            questions[currentIndex].valueType !== "true/false" &&
            !params?.value?.value &&
            params?.value?.value !== 0
          ? "N/A"
          : questions[currentIndex].valueType === "datetime"
          ? params?.value?.value.split("T").join(" ")
          : Array.isArray(params?.value?.value)
          ? params?.value?.value.join(", ")
          : String(params?.value?.value);

        return isValueArray;
      },
    });

    return accumulator;
  };
}
