import isEqual from "lodash.isequal";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import NavigationPrompt from "react-router-navigation-prompt";
import { Breadcrumb } from "../Dashboard/Components/Breadcrumbs";
import { Dashboard } from "../Dashboard/Dashboard";
import { AssignmentModal } from "../Documentation/Components/Dialogs/AssignmentModal";
import { SnackbarAlertRedux } from "../Documentation/Components/SnackbarAlert";
import { setAlert } from "../Documentation/Reducers/DocumentationReducer";
import { ChecklistEditor } from "./Components/ChecklistEditor";
import { LoadingSpinner } from "./Components/LoadingSpinner";
import { PresentationWrapper } from "./Components/PresentationWrapper";
import { SpreadsheetEditor } from "./Components/SpreadsheetEditor/SpreadsheetEditor";
import { UpdateDialog } from "./Components/UpdateDialog";
import { useBlockingIfDraft } from "./Hooks/useBlockingIfDraft";
import { useInitializeDocument } from "./Hooks/useInitializeDocument";

export const Edit = () => {
  const alert = useSelector((state) => state.documentation.alert);
  const severity = useSelector((state) => state.documentation.severity);

  const path = useSelector(
    (state) => state.documentation.currentDocument?.path,
    (left, right) => isEqual(left, right)
  );
  const name = useSelector((state) => state.documentation.currentDocument?.name);
  const doctype = useSelector((state) => state.documentation.currentDocument?.doctype);
  const loading = useSelector((state) => state.documentation.loading);
  const isBlocking = useSelector((state) => state.documentation.isBlocking);
  const currentDocument = useSelector((state) => state.documentation.currentDocument);

  const [openAssignments, setOpenAssignments] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  useInitializeDocument({ isEditor: true });
  useBlockingIfDraft();
  const linkVals = useCallback(() => setLinkVals(path), [path]);

  return (
    <Dashboard
      pageName={`Edit ${name ?? ""}`}
      breadcrumbs={
        <Breadcrumb
          currentValue={name}
          linkValues={linkVals()}
          isEditor
          isBlocking={isBlocking}
          setOpenAssignments={setOpenAssignments}
        />
      }
    >
      <NavigationPrompt when={isBlocking}>
        {({ onConfirm, onCancel }) => (
          <UpdateDialog
            open={isBlocking}
            onCancel={onCancel}
            onConfirm={onConfirm}
            showPasswordModal={showPasswordModal}
            setShowPasswordModal={setShowPasswordModal}
          />
        )}
      </NavigationPrompt>
      {loading || !name ? (
        <LoadingSpinner />
      ) : (
        <>
          {doctype === "presentation" ? (
            <PresentationWrapper />
          ) : doctype === "sheet" || doctype === "report" ? (
            <SpreadsheetEditor />
          ) : (
            <ChecklistEditor />
          )}
          <SnackbarAlertRedux alert={alert} severity={severity} setAlert={setAlert} />
          {openAssignments && (
            <AssignmentModal
              docToEdit={currentDocument}
              open={openAssignments}
              onClose={() => setOpenAssignments(false)}
            />
          )}
        </>
      )}
    </Dashboard>
  );
};

export function setLinkVals(path) {
  const crumbs = [
    { name: "Home", to: "/" },
    { name: "Documentation", to: "/documentation/browse" },
  ];
  const linkVals =
    path && path.length > 1
      ? [...crumbs, ...path.slice(1).map((x) => ({ name: x.name, to: "/documentation/browse/" + x.id }))]
      : crumbs;
  return linkVals;
}
