import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "calc(100%)px",
    height: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
    tableLayout: "fixed",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  pagination: {
    display: "flex",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    position: "sticky",
    width: "100%",
    bottom: 0,
  },
}));
