import styled from "styled-components";
import { Paper } from "@mui/material";

export const Image = styled.img`
  position: absolute;
  /* z-index: 1; */
  padding: inherit;
`;
export const Video = styled.video`
  position: absolute;

  max-height: 100%;
  max-width: 100%;
  /* object-fit: contain; */
  z-index: 1;
  padding: inherit;
`;
export const Pdf = styled.iframe`
  position: absolute;

  height: 100%;
  width: 100%;
  pointer-events: ${(props) => props.dragging && "none"};
  /* object-fit: contain; */
  z-index: 1;
  padding: inherit;
`;

export const Annotations = styled.div`
  position: absolute;
  z-index: 2;
  overflow: hidden;
`;

export const CanvasPaper = styled(Paper)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  box-shadow: none;
  cursor: ${(props) => props.tool !== "select" && "copy"};
  overflow: hidden;
`;

export const styles = ({ selected, scaleX, scaleY }) => ({
  handle: {
    border: 0,
    width: 15,
    height: 15,
    position: "absolute",
    touchAction: "none",
  },
  top: {
    top: "0px",
    width: selected.width * scaleX,
    left: 15,
    cursor: "ns-resize",
    touchAction: "none",
  },
  topRight: {
    top: "0px",
    right: "0px",
    cursor: "nesw-resize",
    touchAction: "none",
  },
  topLeft: {
    top: "0px",
    left: "0px",
    cursor: "nwse-resize",
    touchAction: "none",
  },
  right: {
    top: 15,
    height: selected.height * scaleY,
    right: "0px",
    cursor: "ew-resize",
    touchAction: "none",
  },
  left: {
    top: 15,
    height: selected.height * scaleY,
    left: "0px",
    cursor: "ew-resize",
    touchAction: "none",
  },
  bottom: {
    bottom: "0px",
    width: selected.width * scaleX,
    left: 15,
    cursor: "ns-resize",
    touchAction: "none",
  },
  bottomRight: {
    bottom: "0px",
    right: "0px",
    cursor: "nwse-resize",
    touchAction: "none",
  },
  bottomLeft: {
    bottom: "0px",
    left: "0px",
    cursor: "nesw-resize",
    touchAction: "none",
  },
  rotationArm: {
    position: "absolute",
    background: "white",
    bottom: (selected.height / 2) * scaleY + 15,
    left: (selected.width / 2) * scaleX + 15,
    width: (selected.width / 2) * scaleX + 25,
    border: "1px solid black",
    height: 3,
    touchAction: "none",
    cursor: "pointer",
  },
  rotationHandle: {
    position: "absolute",
    background: "white",
    top: (selected.height / 2) * scaleY + 9,
    left: selected.width * scaleX + 30 + 5,
    width: 10,
    height: 10,
    border: "1px solid black",
    borderRadius: "100%",
    touchAction: "none",
    cursor: "pointer",
  },
  center: {
    margin: 15,
    width: selected.width * scaleX,
    height: selected.height * scaleY,
    touchAction: "none",
    cursor: "pointer",
  },
});

export function selectionStyle({ selected, scaleX, scaleY }) {
  return {
    position: "absolute",
    background: "rgba(128, 128, 255, 0.33)",
    top: selected.y * scaleY - 15,
    left: selected.x * scaleX - 15,
    width: selected.width * scaleX + 30,
    height: selected.height * scaleY + 30,
    transform: `rotate(${selected.rotation}deg)`,
  };
}

export const canvasStyle = {
  position: "relative",
  height: "100%",
  width: "100%",
  WebkitUserSelect: "none",
  userSelect: "none",
  touchAction: "none",
};
export function imageContainerStyle(zoomOffset) {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "inherit",
    top: zoomOffset.top,
    left: zoomOffset.left,
  };
}

export function imageStyle(svgRef, zoom) {
  return {
    width: `${svgRef?.current?.offsetWidth * zoom}px`,
    height: `${svgRef?.current?.offsetHeight * zoom}px`,
    objectFit: "contain",
  };
}

export function annotationStyle(containedHeight, containedWidth) {
  return {
    height: `${containedHeight}px`,
    width: `${containedWidth}px`,
  };
}
