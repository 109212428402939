import React from "react";
import { CloudUpload, CloudOff } from "@mui/icons-material/";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { removeFromFileObj, setPhotoVerification } from "../../../Documentation/Reducers/DocumentationReducer";
import { useTheme } from "@mui/material/styles";
import { VerificationPhotoCarousel } from "./VerificationPhotoCarousel";

export function VerificationContainer({ id, photoVerification }) {
  const dispatch = useDispatch();
  const fileObj = useSelector((state) => state.documentation?.fileObj);
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = fileObj && fileObj[id] ? fileObj[id].length : 0;

  const theme = useTheme();

  const handleRemove = () => {
    setActiveStep(0);
    dispatch(removeFromFileObj({ id, activeStep }));
  };
  return (
    <div style={{ margin: "1em 0", width: "100%" }}>
      {fileObj && fileObj[id]
        ? VerificationPhotoCarousel({ fileObj, id, activeStep, setActiveStep, theme, maxSteps })
        : ""}
      <div>
        <input
          onChange={(e) => {
            if (e.target.files) {
              const files = Array.from(e.target.files).map((file) => ({
                content_type: file.type,
                media: URL.createObjectURL(file),
                filename: file.name,
                size: file.size,
                lastModified: file.lastModified,
              }));
              dispatch(
                setPhotoVerification({
                  id,
                  files,
                })
              );
            }
          }}
          multiple
          style={{ display: "none" }}
          id={`contained-button-file-${id}`}
          type="file"
        />

        {fileObj && fileObj[id] ? (
          <Button
            variant="contained"
            component="span"
            color="primary"
            style={{ width: "fit-content", marginRight: "1em" }}
            startIcon={<CloudOff />}
            onClick={handleRemove}
          >
            Remove
          </Button>
        ) : (
          ""
        )}
        <label htmlFor={`contained-button-file-${id}`}>
          <Button
            variant="contained"
            component="span"
            color="primary"
            style={{ width: "fit-content" }}
            startIcon={<CloudUpload />}
          >
            Add Media Verification {photoVerification ? "(required)" : "(optional)"}
          </Button>
        </label>
      </div>
    </div>
  );
}
