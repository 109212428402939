import { Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dashboard } from "../Dashboard/Dashboard";
import {
  deselectShapes,
  setContainedSize,
  setQuestions,
  setScaleFactor,
  setShapes,
  setTree,
} from "../Editor/Reducers/EditorReducer";
import { QRLinkModal } from "../Viewer/Components/QRLinkModal";
import { SubscriptionModal } from "../Viewer/Components/SubscriptionModal";
import { AssignmentModal } from "./Components/Dialogs/AssignmentModal";
import DocumentationSpeedDial from "./Components/DocumentationSpeedDial";
import { FolderCards } from "./Components/FolderCards/FolderCards";
import { SnackbarAlert, SnackbarAlertRedux } from "./Components/SnackbarAlert";
import { DocumentationToolbar } from "./Components/Toolbar/DocumentationToolbar";
import { DocumentationBreadcrumbs } from "./Helpers/DocumentationBreadcrumbs";
import { LastLocation } from "./Helpers/LastLocation";
import { useDialogs } from "./Hooks/useDialogs";
export const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));
const initialDoc = {
  id: "",
  name: "",
  description: "",
  kind: "",
  version: "",
};
export const Documentation = () => {
  const last = LastLocation();
  const dispatch = useDispatch();
  const [showNewFolder, setShowNewFolder] = useState(false);
  const [showNewDoc, setShowNewDoc] = useState(false);
  const [showDeleteDoc, setShowDeleteDoc] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showPaste, setShowPaste] = useState(false);
  const [docToEdit, setDocToEdit] = useState(initialDoc);
  const [severity, setSeverity] = useState("success");
  const [alert, setAlert] = useState();
  const onAddFolder = () => setShowNewFolder(true);
  const onAddDocument = () => setShowNewDoc(true);
  const alert1 = useSelector((state) => state.documentation.alert);
  const severity1 = useSelector((state) => state.documentation.severity);
  const [openQR, setOpenQR] = useState(false);
  const [openSubs, setOpenSubs] = useState(false);
  const [openAssignments, setOpenAssignments] = useState(false);
  const currentDocument = useSelector((state) => state.documentation.currentDocument);

  const isRootFolder = last === "browse" ? "root" : last;
  const onCopy = (name) => {
    setSeverity("success");
    setAlert(`${name} copied to clipboard`);
  };

  const { Dialogs } = useDialogs(
    setShowNewFolder,
    setSeverity,
    setAlert,
    setShowNewDoc,
    setShowDeleteDoc,
    setShowEdit,
    setShowPaste,
    showNewFolder,
    showNewDoc,
    showDeleteDoc,
    docToEdit,
    showEdit,
    showPaste
  );
  useEffect(() => {
    dispatch(setQuestions({ reset: true }));
    dispatch(setTree({ reset: true }));
    dispatch(setShapes({ shapes: [] }));
    dispatch(setScaleFactor({ scaleX: null, scaleY: null }));
    dispatch(setContainedSize({ width: 0, height: 0 }));
    dispatch(deselectShapes());
  });

  return (
    <Dashboard
      pageName="Documentation"
      breadcrumbs={DocumentationBreadcrumbs({ setOpenQR, setOpenSubs, setOpenAssignments })}
    >
      <DocumentationToolbar setShowPaste={setShowPaste} />
      <Divider />
      <FolderCards
        setShowDeleteDoc={setShowDeleteDoc}
        setDocToEdit={setDocToEdit}
        setShowEdit={setShowEdit}
        setShowPaste={setShowPaste}
        onCopy={onCopy}
        setOpenAssignments={setOpenAssignments}
        isRootFolder={isRootFolder}
        allowedDropEffect="any"
      />

      <DocumentationSpeedDial onAddFolder={onAddFolder} onAddDocument={onAddDocument} />
      <SnackbarAlert alert={alert} setAlert={setAlert} severity={severity} />
      <SnackbarAlertRedux alert={alert1} severity={severity1} />
      {openQR && (
        <QRLinkModal openQR={openQR} setOpenQR={setOpenQR} value={window.location.href} name={currentDocument?.name} />
      )}
      {openSubs && <SubscriptionModal openSubs={openSubs} setOpenSubs={setOpenSubs} />}
      {openAssignments && (
        <AssignmentModal docToEdit={docToEdit} open={openAssignments} onClose={() => setOpenAssignments(false)} />
      )}
      {Dialogs}
    </Dashboard>
  );
};
