import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDocumentChanges,
  setFileObjDescription,
  setFileObjShapes,
} from "../../Documentation/Reducers/DocumentationReducer";
import { compareShapes } from "./helpers";

export const useTrackDocumentChanges = () => {
  const dispatch = useDispatch();
  const shapes = useSelector((state) => state.editor.shapes);
  const description = useSelector((state) => state.editor.description);
  const currentPage = useSelector((state) => state.documentation.currentPage);
  const fileObjQueue = useSelector((state) => state.documentation.fileObjQueue);
  const isFileObjQueue = fileObjQueue.find((x) => x.pageID === currentPage?.pageID);
  useEffect(() => {
    if (currentPage && shapes) {
      if (!isFileObjQueue) {
        if (!compareShapes(currentPage, shapes) || currentPage?.description !== description) {
          dispatch(
            setDocumentChanges({
              pageID: currentPage?.pageID,
              shapes,
              description,
              content_type: currentPage?.content_type,
              media: currentPage?.media,
            })
          );
        }
      } else {
        if (!compareShapes(currentPage, shapes)) {
          dispatch(setFileObjShapes({ shapes, pageID: currentPage?.pageID }));
        }
        if (currentPage?.description !== description) {
          dispatch(setFileObjDescription({ description, pageID: currentPage?.pageID }));
        }
      }
    }
  }, [dispatch, description, shapes, currentPage, isFileObjQueue]);
};
