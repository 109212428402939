import { Button, Dialog, DialogTitle } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setIsBlockingViewer } from "../../../Documentation/Reducers/DocumentationReducer";

export const NavigationDialog = (props) => {
  const dispatch = useDispatch();
  const { onCancel, onConfirm, open } = props;

  const handleNavigate = () => {
    dispatch(setIsBlockingViewer({ isBlockingViewer: false }));

    onConfirm();
  };

  return (
    <Dialog
      onClose={(_, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          onCancel();
        }
      }}
      open={open}
    >
      <DialogTitle id="form-dialog-title">
        Are you sure? This checklist is partially complete and navigating away will discard any media.
      </DialogTitle>

      <div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button style={{ color: "green" }} onClick={handleNavigate}>
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
