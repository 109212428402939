import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { received, setTrash } from "../Reducers/DocumentationReducer";
import { useParams } from "react-router-dom";
import { getTrash, getFolder } from "../Reducers/thunks";
import { handleError } from "../Helpers/handleError";

export function useTrash() {
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getFolder({ ID: id }));
    dispatch(getTrash({ folder_id: "root" }))
      .then((res) => {
        dispatch(setTrash({ trash: res }));
        dispatch(received({ error: false }));
      })
      .catch(handleError(dispatch));

    return () => dispatch(getTrash({ folder_id: "root" }));
  }, [dispatch, id]);
}
