import { Dialog, DialogContent, DialogTitle, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSpreadsheet } from "../../../../../Documentation/Reducers/DocumentationReducer";
import { VerificationPhotoCarousel } from "../../../ChecklistViewer/VerificationPhotoCarousel";
export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
    tableLayout: "fixed",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  pagination: {
    display: "flex",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    position: "sticky",
    width: "100%",
    bottom: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout={50} ref={ref} {...props} />;
});

export const SpreadsheetDialog = ({ theme }) => {
  const open = useSelector((state) => state.documentation.spreadsheet.open);
  const media = useSelector((state) => state.documentation.spreadsheet.media);
  const comments = useSelector((state) => state.documentation.spreadsheet.comments);
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = media ? media.length : 0;

  const isTitle = comments ? "Comments" : "Verification";

  const isContent = media ? (
    <VerificationPhotoCarousel
      media={media}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      maxSteps={maxSteps}
      theme={theme}
    />
  ) : comments ? (
    comments
  ) : (
    ""
  );

  const handleClose = () => {
    setTimeout(() => {
      dispatch(setSpreadsheet({ open: false, media: 0, comments: 0 }));
      setActiveStep(0);
    }, 500);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog"
      aria-describedby="alert-dialog"
    >
      <DialogTitle id="alert-dialog">{isTitle}</DialogTitle>
      <DialogContent>{isContent}</DialogContent>
    </Dialog>
  );
};
