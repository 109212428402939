export const styles = {
  item: { textDecoration: "none", minWidth: "115px" },
  icon: { display: "flex", height: "100%", alignItems: "center", justifyContent: "center", width: "fit-content" },
  description: {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    zIndex: 1000,
    overflow: "hidden",
  },
  container: { display: "flex", alignSelf: "center", height: "100%" },
  button: { backgroundColor: "transparent" },
};
