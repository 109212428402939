import {
  loading,
  received,
  setDidQuestionsChange,
  setQuestionIndex,
} from "../../../Documentation/Reducers/DocumentationReducer";
import { getChecklist } from "../../../Documentation/Reducers/Thunks/getChecklist";
import { removeQuestion, setFormIds, setTree } from "../../../Editor/Reducers/EditorReducer";
import { flattenTree } from "./flattenTree";
import { unflattenTree } from "./unflattenTree";

export function handleRemove(form_ids, node, dispatch, tree) {
  return (e) => {
    e.preventDefault();
    e.stopPropagation();
    async function fetchChecklists() {
      const fetchedChecklists = await Promise.all(
        form_ids ? form_ids.map((id) => dispatch(getChecklist({ id, currentFolderId: "root" }))) : []
      );

      return fetchedChecklists;
    }
    dispatch(loading());

    fetchChecklists()
      .then((res) => {
        let flattenedTree = tree && flattenTree(tree, 0, false);
        let associatedFormIds = flattenedTree.reduce(
          (acc, cur) => {
            if (cur.id === node.id) return acc;
            let val = flattenedTree.find((value) => acc.includes(value.parent) && !acc.includes(value.id));
            if (val) acc.push(val.id);
            return acc;
          },
          [node.id]
        );

        let withoutNodes = flattenedTree.filter((form) => !associatedFormIds.includes(form.id));
        dispatch(setTree({ tree: unflattenTree(withoutNodes) }));
        dispatch(setFormIds({ remove: associatedFormIds }));

        const questionIDXs = res.reduce((acc, checklist) => {
          if (associatedFormIds.includes(checklist.id)) {
            acc["associated"] = [
              ...(acc["associated"] ? acc["associated"] : []),
              ...checklist?.contents.question_index,
            ];
          } else {
            acc["unassociated"] = [
              ...(acc["unassociated"] ? acc["unassociated"] : []),
              ...checklist?.contents.question_index,
            ];
          }

          return acc;
        }, {});

        const toRemove = questionIDXs.associated.reduce((acc, cur) => {
          if (questionIDXs?.unassociated?.indexOf(cur) === -1) {
            acc.push(cur);
          } else {
            return acc;
          }

          return acc;
        }, []);

        if (toRemove?.length) {
          toRemove.forEach((id) => {
            dispatch(setQuestionIndex({ remove: id }));
            dispatch(removeQuestion({ id }));
          });
        } else {
          if (!questionIDXs.unassociated) {
            questionIDXs.associated.forEach((id) => {
              dispatch(setQuestionIndex({ remove: id }));
              dispatch(removeQuestion({ id }));
            });
          }
        }

        dispatch(setDidQuestionsChange({ isChanged: true }));
        dispatch(received({ error: false }));
      })
      .catch((e) => console.log(e));
  };
}
