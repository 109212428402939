import { useEffect } from "react";
import { useSelector } from "react-redux";
import Axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";
import { setRootResults } from "../../Documentation/Reducers/DocumentationReducer";
import { handle403 } from "./Helpers/handle403";

export function useFetchQuickLinks(dispatch, results) {
  const org = useSelector((state) => state.login.org);
  useEffect(() => {
    async function fetchData() {
      const {
        data: { results },
      } = await Axios.get(baseURL + "documentation/folders/root/contents", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(
        setRootResults({
          results: results.filter((res) => res.kind === "folder"),
        })
      );
    }
    if (org) {
      fetchData().catch((err) => {
        handle403(err, dispatch);
      });
    }
  }, [dispatch, results, org]);
}
