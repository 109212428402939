import React from "react";
import { styles } from "../../styles";
import { Grid, Typography } from "@mui/material";

export function EmptyChecklist() {
  return (
    <Grid style={styles.viewContainer} container>
      <Grid item xs={12} container style={styles.emptyImageContainer}>
        <Typography>--Please Add To Checklist--</Typography>
      </Grid>
    </Grid>
  );
}
