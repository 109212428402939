import axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const listPendingOrgInvites = () => async () => {
  try {
    return await axios({
      method: "get",
      url: baseURL + "auth/org-invites",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (e) {
    console.error(e);
    return e;
  }
};
