import { IconButton } from "@mui/material";
import styled, { keyframes } from "styled-components";

const bounce = keyframes`
  from {
    margin-top: 1;
  }
  to {
    margin-top: -5px;
  }
`;
export const PasteButton = styled(IconButton)`
  width: 2em;
  height: 2em;
  animation: ${bounce} 0.5s infinite alternate;
`;
