import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogTitle, DialogActions, Button, Alert } from "@mui/material";
import { removePage } from "../../Documentation/Reducers/thunks";
import { removeFromQueue } from "../../Documentation/Reducers/DocumentationReducer";

export const RemovePageDialog = (props) => {
  const dispatch = useDispatch();
  const { onRemoved, onClose, selectedThumbnail, ...passthroughProps } = props;
  const [error, setError] = useState();
  const [areYouSure, setAreYouSure] = useState(false);
  const currentDocument = useSelector((state) => state.documentation.currentDocument);
  const currentPage = useSelector((state) => state.documentation.currentPage);
  const draft_contents = useSelector((state) => state.documentation.currentDocument?.draft_contents);
  const fileObjQueue = useSelector((state) => state.documentation.fileObjQueue);
  const isFileObjQueue = fileObjQueue.find((item) => item?.pageID === currentPage?.pageID);

  const onSubmit = (e) => {
    e.preventDefault();
    if (isFileObjQueue) {
      const filename = currentPage.filename;
      dispatch(removeFromQueue({ pageID: currentPage.pageID }));
      onClose();
      onRemoved(filename);
    } else {
      dispatch(removePage({ currentPage, currentDocument, draft_contents }))
        .then((value) => {
          onClose();
          onRemoved(value);
        })
        .catch(function (err) {
          if (err.response) {
            const detail = err.response.data.detail;
            if (typeof detail === "string") {
              setError(detail);
            } else if (Array.isArray(detail)) {
              detail.forEach((e) => {
                console.log(e);
              });
            }
          }
          return Promise.reject(err);
        });
    }
  };
  const resetForm = () => {
    setError();
    setAreYouSure(false);
  };

  return (
    <Dialog onClose={onClose} TransitionProps={{ onEntering: resetForm }} {...passthroughProps}>
      <DialogTitle id="form-dialog-title">
        Are you {areYouSure ? "REALLY " : ""}sure you want to remove this page
      </DialogTitle>{" "}
      {error && <Alert severity="error">{error}</Alert>}
      {areYouSure ? (
        <DialogActions>
          <Button onClick={onClose} color="primary">
            No, Don't Remove This Page
          </Button>
          <Button onClick={onSubmit} style={{ color: "#097c09" }}>
            Yes, Remove This Page
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => setAreYouSure(true)} style={{ color: "#097c09" }}>
            Remove This Page
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
