import { Button, Dialog, DialogActions, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleError } from "../../Documentation/Helpers/handleError";
import {
  resetFileObjQueue,
  setCurrentDocument,
  setDidQuestionsChange,
  setQuestionIndex,
  setUploadProgress,
} from "../../Documentation/Reducers/DocumentationReducer";
import { discardDocumentChanges } from "../../Documentation/Reducers/Thunks/discardDocumentChanges";
import { setQuestions, setSaving } from "../../Editor/Reducers/EditorReducer";
import { handleForm } from "../Thunks/Helpers/handleForm";
import { handlePresentation } from "../Thunks/Helpers/handlePresentation";
import { handleSheet } from "../Thunks/Helpers/handleSheet";
import { PasswordModal } from "./ChecklistViewer/PasswordModal";

export const UpdateDialog = (props) => {
  const dispatch = useDispatch();
  const { onCancel, onConfirm, open, showPasswordModal, setShowPasswordModal } = props;
  const currentDocument = useSelector((state) => state.documentation.currentDocument);
  const page_index = useSelector((state) => state.documentation.page_index);
  const question_index = useSelector((state) => state.documentation.question_index);
  const doctype = useSelector((state) => state.documentation.currentDocument.doctype);
  const fileObjQueue = useSelector((state) => state.documentation.fileObjQueue);
  const currentDocumentChanges = useSelector((state) => state.documentation.currentDocumentChanges);
  const [changelog, setChangelog] = useState("");
  const saving = useSelector((state) => state.editor?.saving);
  const questions = useSelector((state) => state.editor?.questions);
  const form_ids = useSelector((state) => state.editor?.form_ids);
  const tree = useSelector((state) => state.editor?.tree);
  const uploadProgress = useSelector((state) => state.documentation.uploadProgress);
  const [discarding, setDiscarding] = useState(false);
  const handlePublish = () => {
    if (doctype === "presentation") {
      dispatch(setUploadProgress({ initialize: true }));

      handlePresentation(
        dispatch,
        currentDocument,
        fileObjQueue,
        currentDocumentChanges,
        page_index,
        changelog,
        onCancel,
        onConfirm,
        setShowPasswordModal
      );
    }
    if (doctype === "form") {
      handleForm(
        dispatch,
        questions,
        currentDocument,
        question_index,
        changelog,
        onConfirm,
        onCancel,
        setShowPasswordModal
      );
    }
    if (doctype === "sheet" || doctype === "report") {
      handleSheet(
        dispatch,
        questions,
        currentDocument,
        question_index,
        changelog,
        tree,
        form_ids,
        onConfirm,
        onCancel,
        setShowPasswordModal
      );
    }
  };
  const handleDiscard = () => {
    onConfirm();
    dispatch(resetFileObjQueue());
    dispatch(discardDocumentChanges({ currentDocument }))
      .then((res) => dispatch(setCurrentDocument({ doc: res.data, isEditor: true })))
      .catch(handleError(dispatch));
    dispatch(setQuestions({ reset: true }));
    dispatch(setQuestionIndex({ reset: true }));
    dispatch(setDidQuestionsChange({ isChanged: false }));
  };
  return showPasswordModal ? (
    <PasswordModal
      showPasswordModal={showPasswordModal}
      setShowPasswordModal={setShowPasswordModal}
      handleSubmit={handlePublish}
    />
  ) : uploadProgress ? (
    ""
  ) : (
    <Dialog
      onClose={(_, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          onCancel();
        }
      }}
      TransitionProps={{
        onEntering: () => {
          setChangelog("");
          dispatch(setSaving({ saving: false }));
          setDiscarding(false);
        },
      }}
      open={open}
    >
      <DialogTitle id="form-dialog-title" style={{ width: 500 }}>
        {discarding
          ? "Are You Sure You Want To Discard Your Changes?"
          : saving
          ? "Describe Your Changes:"
          : "Would You Like To Publish Your Changes?"}
      </DialogTitle>
      {discarding ? (
        <div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onCancel} color="primary">
              Cancel
            </Button>
            <Button style={{ color: "red" }} onClick={handleDiscard}>
              Discard Changes
            </Button>
          </div>
        </div>
      ) : saving ? (
        <div>
          <form style={{ padding: "8px" }} noValidate autoComplete="off">
            <TextField
              fullWidth
              label="Change Log (optional)"
              multiline
              rows={4}
              value={changelog}
              onChange={(e) => setChangelog(e.target.value)}
              variant="outlined"
            />
          </form>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onCancel} color="primary">
              Close
            </Button>
            <Button onClick={handlePublish} style={{ color: "#097c09" }}>
              Publish Changes
            </Button>
          </div>
        </div>
      ) : (
        <DialogActions>
          <Button onClick={() => setDiscarding(true)} style={{ color: "red" }}>
            Discard Changes
          </Button>
          <Button onClick={() => dispatch(setSaving({ saving: true }))} style={{ color: "#097c09" }}>
            Publish Changes
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
