import { Button, CircularProgress, Grid, Step, StepContent, Stepper } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavigationPrompt from "react-router-navigation-prompt";
import history from "../../../../Utils/history";
import {
  setAlert,
  setIsBlockingViewer,
  setPhotoVerification,
} from "../../../Documentation/Reducers/DocumentationReducer";
import { useLocalStorageQuestions } from "../../Hooks/useLocalStorageQuestions";
import { useSetQuestions } from "../../Hooks/useSetQuestions";
import { submitAnswers } from "../../Thunks/submitAnswers";
import { LoadingSpinner } from "../LoadingSpinner";
import { ActiveStepContainer } from "./ActiveStepContainer";
import { CommentContainer } from "./CommentContainer";
import { EmptyChecklist } from "./EmptyChecklist";
import { MediaContainer } from "./MediaContainer";
import { StepNavContainer } from "./StepNavContainer";
import { ValueContainer } from "./ValueContainer";
import { VerificationContainer } from "./VerificationContainer";
import { areQuestionsComplete } from "./helpers";
import { removeFromLocalStorage } from "./removeFromLocalStorage";
import { NavigationDialog } from "./NavigationDialog";
import { useScrollIntoView } from "./useScrollIntoView";
import { useIsBlockingViewer } from "./useIsBlockingViewer";
import { PasswordModal } from "./PasswordModal";

export function ChecklistViewer() {
  const [activeStep, setActiveStep] = React.useState(0);
  const username = useSelector((state) => state.login?.username);
  const question_index = useSelector((state) => state.documentation?.currentDocument?.contents?.question_index);
  const fileObj = useSelector((state) => state.documentation?.fileObj);
  const [submitting, setSubmitting] = useState(false);
  const loading = useSelector((state) => state.documentation.loading);
  const dispatch = useDispatch();
  const currentFolder = useSelector((state) => state.documentation?.currentFolder);
  const docID = useSelector((state) => state.documentation?.currentDocument?.id);
  const path = useSelector((state) => state.documentation?.currentDocument?.path);
  const docName = useSelector((state) => state.documentation?.currentDocument?.name);
  const folderId = path[path.length - 1]?.id || currentFolder?.folder?.id;
  const orgID = useSelector((state) => state.login.org?.id);
  const questions = useSelector((state) => state.editor?.questions);
  const isBlockingViewer = useSelector((state) => state.documentation?.isBlockingViewer);
  const uid = `${username}_${orgID}`;
  const checklistUID = `${docName}---${docID}`;
  const refs = useRef([]);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  useSetQuestions();
  useLocalStorageQuestions();
  useScrollIntoView(refs, activeStep);

  useIsBlockingViewer(uid, checklistUID, dispatch, isBlockingViewer, submitting);

  const handleSubmit = () => {
    if (questions) {
      setSubmitting(true);
      dispatch(setIsBlockingViewer({ isBlockingViewer: false }));

      if (areQuestionsComplete(questions, fileObj)) {
        const answers = Object.keys(questions).reduce((accumulator, question) => {
          accumulator[question] = {
            value:
              questions[question].values === "true"
                ? true
                : questions[question].values === "false"
                ? false
                : questions[question].values,

            ...(questions[question].comments ? { comments: questions[question].comments } : {}),
            ...(fileObj && fileObj.hasOwnProperty(question) ? { media: fileObj[question] } : {}),
          };
          return accumulator;
        }, {});
        dispatch(submitAnswers({ id: docID, answers }))
          .then(() => {
            dispatch(setPhotoVerification({ reset: true }));
            dispatch(setAlert({ alert: "Answers Successfully Submitted.", severity: "success" }));
            removeFromLocalStorage(uid, checklistUID);
            setSubmitting(false);
            history.push("/documentation/browse/" + folderId);
          })
          .catch((err) => {
            console.error(err);
            if (err?.response) {
              console.error(err?.response?.data);
            }
            let message = "Something went wrong with your submission.";
            setSubmitting(false);
            console.log("Request Payload: ", err?.config?.data);
            if (err?.response?.status === 400) {
              if (err?.response?.data?.detail) {
                message = `Error: ${err.response.data.detail}`;
              } else {
                message = err?.response?.data?.toString();
              }
            }
            if (err?.response?.status === 403) {
              if (err?.response?.data?.detail) {
                message = `Error: ${err.response.data.detail}`;
              } else {
                message = err?.response?.data?.toString();
              }
              setShowPasswordModal(true);
            }
            dispatch(setAlert({ alert: message, severity: "error" }));
          });
      }
    } else {
      return;
    }
  };
  return loading ? (
    <LoadingSpinner />
  ) : JSON.stringify(questions) === "{}" || !questions ? (
    <EmptyChecklist />
  ) : (
    <div
      style={{
        overflowY: "auto",
        height: "100%",
      }}
      id="scrollContainer"
    >
      <NavigationPrompt when={isBlockingViewer}>
        {({ onConfirm, onCancel }) => (
          <NavigationDialog open={isBlockingViewer} onCancel={onCancel} onConfirm={onConfirm} />
        )}
      </NavigationPrompt>
      <Stepper style={{ width: "99%" }} nonLinear activeStep={activeStep || 0} orientation="vertical">
        {JSON.stringify(questions) !== "{}" &&
          questions &&
          question_index &&
          question_index.map((questionID, i) => {
            const currentQuestion = questions[questionID];
            const {
              media,
              title,
              description,
              photoVerification,
              allowComments,
              valueType,
              choices,
              widget,
              required,
              values,
              content_type,
            } = currentQuestion;
            return values || values === "" || values === 0 ? (
              <Step key={questionID} id={`step-${i}`} ref={(ref) => (refs.current[i] = ref)}>
                <ActiveStepContainer
                  index={i}
                  setActiveStep={setActiveStep}
                  title={title}
                  required={required}
                  values={values}
                  photoVerification={photoVerification}
                  questionID={questionID}
                />
                <StepContent>
                  <Grid container direction="column">
                    <MediaContainer media={media} content_type={content_type} />
                    <ValueContainer
                      title={title}
                      values={values}
                      valueType={valueType}
                      description={description}
                      index={questionID}
                      choices={choices}
                      widget={widget}
                    />
                    <CommentContainer allowComments={allowComments} index={questionID} />
                    <VerificationContainer id={questionID} photoVerification={photoVerification} />
                  </Grid>
                  <StepNavContainer activeStep={activeStep} setActiveStep={setActiveStep} />
                </StepContent>
              </Step>
            ) : (
              <LoadingSpinner key={questionID} />
            );
          })}
      </Stepper>
      {submitting ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        ""
      )}
      {!questions ? (
        ""
      ) : (
        <Button
          disabled={!questions || !areQuestionsComplete(questions, fileObj)}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ marginLeft: "1em" }}
        >
          {submitting ? "Submitting..." : "Submit"}
        </Button>
      )}
      {showPasswordModal ? (
        <PasswordModal
          showPasswordModal={showPasswordModal}
          setShowPasswordModal={setShowPasswordModal}
          handleSubmit={handleSubmit}
        />
      ) : (
        ""
      )}
    </div>
  );
}
