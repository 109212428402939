import React, { useEffect, useState } from "react";
import { Divider, Paper, Typography, Toolbar, AppBar, MenuItem, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Dashboard } from "../Dashboard";
import { Favorites } from "./Favorites";
import { RecentActivity } from "./RecentActivity";
import { CreateOrganization } from "./CreateOrganization";
import { LoadingSpinner } from "../../Viewer/Components/LoadingSpinner";
import { PopoverAvatar } from "./PopoverAvatar";
import { useWindowSize } from "../Hooks/useWindowSize";
import history from "../../../Utils/history";
import { AvailableOrgs } from "../../Login/Components/AvailableOrgs";
import { LoginInfoBox } from "../../Login/Components/LoginInfoBox";
import { AssignmentTurnedInTwoTone } from "@mui/icons-material";
import { styles } from "./useStyles";
import {
  resetCurrentDocument,
  setQuestionIndex,
  setSpreadsheet,
} from "../../Documentation/Reducers/DocumentationReducer";
import { setQuestions } from "../../Editor/Reducers/EditorReducer";
import { SnackbarAlertRedux } from "../../Documentation/Components/SnackbarAlert";
import { Assignments } from "./Assignments";
export function Home() {
  const full_name = useSelector((state) => state.login.full_name);
  const org = useSelector((state) => state.login.org);
  const availableOrgs = useSelector((state) => state.login.available_orgs);
  const assignments = useSelector((state) => state.login.assignments);
  const [orgName, setOrgName] = useState("");
  const notifications = useSelector((state) => state.documentation.notifications);
  const favorites = useSelector((state) => state.documentation.favorites);
  const dispatch = useDispatch();
  const size = useWindowSize();
  const username = useSelector((state) => state.login?.username);
  const orgID = useSelector((state) => state.login.org?.id);
  const uid = `${username}_${orgID}`;
  const alert = useSelector((state) => state.documentation.alert);
  const severity = useSelector((state) => state.documentation.severity);

  const groupByDate = notifications.reduce((dates, notification) => {
    const date = notification.activity_time.split("T")[0];
    if (!dates[date]) {
      dates[date] = [];
    }
    dates[date].push(notification);
    return dates;
  }, {});
  useEffect(() => {
    if (sessionStorage.getItem("inviteID")) {
      history.push("/invitation/" + sessionStorage.getItem("inviteID"));
    }
  }, []);
  useEffect(() => {
    dispatch(resetCurrentDocument());
    dispatch(setQuestions({ reset: true }));
    dispatch(setQuestionIndex({ reset: true }));
    dispatch(setSpreadsheet({ reset: true }));
  }, [dispatch]);
  return !full_name ? (
    <LoadingSpinner />
  ) : !org && availableOrgs && availableOrgs.length !== 0 ? (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography edge="start" variant="h6" style={{ flexGrow: 1 }}>
            Select Organization
          </Typography>
          <PopoverAvatar />
        </Toolbar>
      </AppBar>{" "}
      <Grid item xs={12} sm={6}>
        <Paper style={{ padding: "1em" }}>
          <LoginInfoBox />
          <AvailableOrgs availableOrgs={availableOrgs} />
        </Paper>
      </Grid>
    </>
  ) : !sessionStorage?.getItem("inviteID") && !org && full_name ? (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography edge="start" variant="h6" style={{ flexGrow: 1 }}>
            Create Organization
          </Typography>
          <PopoverAvatar />
        </Toolbar>
      </AppBar>
      {CreateOrganization({ setOrgName, orgName, dispatch })}
    </>
  ) : (
    <Dashboard pageName="Home">
      <div style={{ padding: "24px", overflowY: "auto" }}>
        <Typography variant="h4">Hello {full_name},</Typography>
        <Typography>
          This is your dashboard. You'll find all your recent activity here, as well as any other important information.
        </Typography>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {localStorage.getItem(uid) ? (
            <Paper
              variant="outlined"
              square
              style={{ width: "100%", maxWidth: size.width <= 786 ? 380 : 800, marginTop: "1em" }}
            >
              <Typography style={{ paddingLeft: "0.5em" }} variant="h6">
                Checklists In Progress:
              </Typography>
              <Divider />
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                {Object.keys(JSON.parse(localStorage.getItem(uid))).map((checklist, index) => {
                  let [name, docID] = checklist.split("---");
                  return (
                    <MenuItem onClick={() => history.push(`/documentation/view/${docID}`)} key={index}>
                      <span>
                        <AssignmentTurnedInTwoTone style={styles.form} />
                        {name}
                      </span>
                    </MenuItem>
                  );
                })}
              </div>
            </Paper>
          ) : (
            ""
          )}
          {assignments && assignments?.length ? (
            <Paper
              variant="outlined"
              square
              style={{ width: "100%", maxWidth: size.width <= 786 ? 380 : 800, marginTop: "1em" }}
            >
              <Typography style={{ paddingLeft: "0.5em" }} variant="h6">
                Assignments:
              </Typography>
              <Divider />
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>{Assignments(assignments, dispatch)}</div>
            </Paper>
          ) : (
            ""
          )}
          {favorites.length ? (
            <Paper
              variant="outlined"
              square
              style={{ width: "100%", maxWidth: size.width <= 786 ? 380 : 800, marginTop: "1em" }}
            >
              <Typography style={{ paddingLeft: "0.5em" }} variant="h6">
                Favorites:
              </Typography>
              <Divider />
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                <Favorites />
              </div>
            </Paper>
          ) : (
            ""
          )}
          {notifications.length ? (
            <Paper
              variant="outlined"
              square
              style={{ width: "100%", maxWidth: size.width <= 786 ? 380 : 800, marginTop: "1em" }}
            >
              <Typography style={{ paddingLeft: "0.5em" }} variant="h6">
                Recent Activity:
              </Typography>
              <Divider />
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>{RecentActivity(groupByDate)}</div>
            </Paper>
          ) : (
            ""
          )}
        </div>
      </div>
      <SnackbarAlertRedux alert={alert} severity={severity} />
    </Dashboard>
  );
}
