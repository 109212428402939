import { useEffect } from "react";
import { getCoordinates, uniqueID } from "../helpers";
import { useDispatch, useSelector } from "react-redux";
import { editorSelector, addShape, setTool } from "../Reducers/EditorReducer";

export const useAddShape = (svgRef, coordsRef) => {
  const dispatch = useDispatch();
  const { shapes, tool, scaleX, scaleY } = useSelector(editorSelector);
  const content_type = useSelector((state) => state.documentation?.currentPage?.content_type);
  const isPDF = /pdf/.test(content_type);
  useEffect(() => {
    if (tool !== "select" && !isPDF) {
      const setFromEvent = (e) => {
        e.preventDefault();
        coordsRef.current = getCoordinates(e);

        if (tool === "rectangle" && e.target.id === "editorCanvas") {
          dispatch(
            addShape({
              id: uniqueID(),
              x: coordsRef.current.x / scaleX,
              y: coordsRef.current.y / scaleY,
              shape: "rectangle",
            })
          );
          setTimeout(() => dispatch(setTool({ tool: "select" })), 200);
        }

        if (tool === "ellipse" && e.target.id === "editorCanvas") {
          dispatch(
            addShape({
              id: uniqueID(),
              x: coordsRef.current.x / scaleX,
              y: coordsRef.current.y / scaleY,
              shape: "ellipse",
            })
          );
          setTimeout(() => dispatch(setTool({ tool: "select" })), 200);
        }
        if (tool === "arrow" && e.target.id === "editorCanvas") {
          dispatch(
            addShape({
              id: uniqueID(),
              x: coordsRef.current.x / scaleX,
              y: coordsRef.current.y / scaleY,
              shape: "arrow",
            })
          );
          setTimeout(() => dispatch(setTool({ tool: "select" })), 200);
        }
      };

      if (svgRef.current !== null) {
        let current = svgRef.current;
        current.addEventListener("click", setFromEvent);
        return () => current.removeEventListener("click", setFromEvent);
      }
    }
  }, [dispatch, shapes, tool, svgRef, coordsRef, scaleX, scaleY, isPDF]);
};
