import Axios from "axios";
import { baseURL } from "../../../Documentation/Reducers/thunks";
import { toUnixTimestamp } from "../../../Viewer/Components/SpreadsheetViewer/helpers";

export const getSearch =
  ({
    q,
    types = ["presentation", "form", "sheet"],
    include_folders = true,
    in_folder,
    created_before,
    created_after,
    modified_before,
    modified_after,
  }) =>
  async (dispatch) => {
    try {
      const params = new URLSearchParams();
      params.append("q", q);
      types.forEach((type) => {
        params.append("types", type);
      });
      params.append("include_folders", include_folders);
      in_folder && params.append("in_folder", in_folder);
      created_before && params.append("created_before", toUnixTimestamp(created_before + "T24:00:00"));
      created_after && params.append("created_after", toUnixTimestamp(created_after));
      modified_before && params.append("modified_before", toUnixTimestamp(modified_before + "T24:00:00"));
      modified_after && params.append("modified_after", toUnixTimestamp(modified_after));
      const res = await Axios({
        method: "get",
        url: baseURL + "documentation/search",
        params,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return res;
    } catch (e) {
      console.error(e);
    }
  };
