import { Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import history from "../../Utils/history";
import { Breadcrumb } from "../Dashboard/Components/Breadcrumbs";
import { Dashboard } from "../Dashboard/Dashboard";
import { createOrg } from "../Dashboard/Thunks/createOrg";
import { selectOrg } from "../Dashboard/Thunks/selectOrg";
import { setLoggedInUser } from "../Login/Reducers/LoginReducer";

export const NewOrganization = () => {
  const dispatch = useDispatch();

  const [orgName, setOrgName] = useState("");
  const createNewOrg = () => {
    if (orgName.length) {
      dispatch(createOrg({ name: orgName })).then((res1) => {
        dispatch(selectOrg({ id: res1?.data?.id })).then((res2) => {
          dispatch(
            setLoggedInUser({
              username: res2.data.username,
              email: res2.data.email,
              full_name: res2.data.full_name,
              is_org_admin: res2.data.is_org_admin,
              available_orgs: res2.data.available_orgs,
              org: res2.data.org,
              roles: res2.data.roles,
              authenticated: true,
              avatar: res2.data.avatar,
              otp_enabled: res2.data.otp_enabled,
            })
          );
          history.push("/");
        });
      });
    }
  };
  return (
    <Dashboard
      pageName="New Organization"
      breadcrumbs={
        <Breadcrumb
          currentValue={"New Organization"}
          linkValues={[
            { name: "Home", to: "/" },
            { name: "Admin", to: "/admin" },
          ]}
        />
      }
    >
      <div style={{ margin: "1em" }}>
        <Typography variant="h5">Create a New Organization</Typography>
        <TextField
          fullWidth
          placeholder="Enter Organization Name"
          id="org_name"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          onChange={(e) => {
            setOrgName(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") createNewOrg();
          }}
          value={orgName || ""}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <Button
                onClick={() => {
                  createNewOrg();
                }}
                color="primary"
                variant="outlined"
                aria-label="org_name"
              >
                Submit
              </Button>
            ),
          }}
        />
      </div>
    </Dashboard>
  );
};
