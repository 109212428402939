import { Button, Fade, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postSetOtp } from "../Thunks/postSetOtp";
import QRCode from "react-qr-code";
import { refreshUserData } from "../../Login/Reducers/refreshUserData";
import { useStyles } from "../styles";

export function TwoFactorModal({ open, setOpen, result }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const otp_enabled = useSelector((state) => state.login.otp_enabled);

  const [code, setCode] = useState("");

  const [errorTwoFactor, setErrorTwoFactor] = useState(false);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
    >
      <Fade in={open}>
        <div className={classes.modalPaper}>
          {otp_enabled ? (
            <div>
              <Typography>Enter your 6 digit code below to disable Two Factor Authentication</Typography>
              <TextField
                value={code || ""}
                onChange={(e) => {
                  e.preventDefault();

                  setCode(e.target.value);
                }}
                id="6-Digit Code"
                label="6-Digit Code"
                error={errorTwoFactor}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  dispatch(postSetOtp({ verify_otp: code, enabled: false }))
                    .then((res) => {
                      setErrorTwoFactor(false);
                      dispatch(refreshUserData());
                      setOpen(false);
                    })
                    .catch((e) => setErrorTwoFactor(true))
                }
              >
                Submit
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ margin: "0 auto" }}>
                <QRCode value={result ? result.uri : ""} />
              </div>
              <div>
                <Typography>
                  Scan this QR Code into your authentication app using your mobile device and enter the 6 digit code
                  below.
                </Typography>
              </div>
              <div style={{ margin: "0 auto" }}>
                <TextField
                  value={code || ""}
                  onChange={(e) => {
                    e.preventDefault();
                    setCode(e.target.value);
                  }}
                  id="6-Digit Code"
                  label="6-Digit Code"
                  error={errorTwoFactor}
                />
              </div>
              <div style={{ margin: "0 auto", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                {errorTwoFactor && <Typography style={{ color: "red" }}>Incorrect Code, Try Again</Typography>}
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    dispatch(postSetOtp({ verify_otp: code, enabled: true, uri: result.uri }))
                      .then((res) => {
                        setErrorTwoFactor(false);
                        dispatch(refreshUserData());
                        setOpen(false);
                      })
                      .catch((e) => {
                        console.error(e);
                        setErrorTwoFactor(true);
                      })
                  }
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
}
