import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "1600px",
    margin: "0 auto",
    overflow: "auto",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  content: {
    alignItems: "center",
  },
  formControl: {
    minWidth: 120,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    margin: "16px 0px",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));
