import { insertItemsAfterIndex } from "./helpers";
import { currentFolder, spreadsheet } from "./initialState";
import {
  initializeFolder,
  folderIsRoot,
  stepIntoFolder,
  stepOutOfFolder,
  filterId,
  filterFolderId,
  indexOfFirstValue,
  filterBelowIndex,
} from "./setCurrentFolderHelpers";

export const reducers = {
  loading: (state) => {
    if (state.loading === false) {
      state.loading = true;
    }
  },
  received: (state, action) => {
    const { error } = action.payload;
    if (state.loading) {
      state.loading = false;
    }
    if (error) {
      state.error = true;
    } else {
      state.error = false;
    }
  },
  resetDocumentation: (state) => {
    state.currentFolder = currentFolder;
    state.subscriptions = [];
    state.notifications = [];
    state.favorites = [];
  },
  resetCurrentDocument: (state) => {
    state.currentDocument = null;
  },
  setCurrentFolder: (state, action) => {
    const { id, ancestors, targetId, initial, previousResults } = action.payload;
    const indexOfResult =
      previousResults && indexOfFirstValue(previousResults, filterFolderId(previousResults, targetId));
    const indexOfAncestor = indexOfFirstValue(ancestors, filterId(ancestors, targetId));
    const isChanged = parseInt(targetId) !== parseInt(id);
    const isSteppingIn = isChanged && indexOfResult >= 0;
    const isSteppingOut = isChanged && indexOfAncestor >= 0;
    const isTargetRoot = targetId === state.rootID;
    const isSearchingOrSorting = parseInt(targetId) === parseInt(id);
    if (initial) {
      initializeFolder(state, action);
    } else if (isTargetRoot) {
      folderIsRoot(state);
    } else if (isSearchingOrSorting) {
      return state;
    } else if (isSteppingIn) {
      stepIntoFolder(state, filterFolderId(previousResults, targetId));
    } else if (isSteppingOut) {
      stepOutOfFolder(state, filterId(ancestors, targetId), filterBelowIndex(ancestors, indexOfAncestor));
    } else if (isChanged) {
      console.log("isChanged");
      return;
    }
  },
  setCurrentFolderContents: (state, action) => {
    const { results, total } = action.payload;
    state.currentFolder.results = results;
    state.currentFolder.total = total;
    state.currentDocument = null;
    state.currentPage = null;
    state.page_index = [];
    state.question_index = [];
    state.prevLocation = null;
    state.spreadsheet = spreadsheet;
  },
  updateCurrentFolderContents: (state, action) => {
    const { results } = action.payload;
    state.currentFolder.results = [...state.currentFolder.results, ...results];
  },
  setRootID: (state, action) => {
    const { id } = action.payload;
    state.rootID = id;
  },
  setCurrentDocument: (state, action) => {
    const { doc, isEditor } = action.payload;
    state.fileObj && URL.revokeObjectURL(state.fileObj.media);
    state.fileObj = null;
    state.currentDocument = doc;
    if (doc.doctype === "presentation") {
      if (isEditor) {
        if (Object.keys(doc.contents).length && doc.contents?.page_index) {
          state.page_index = [...doc.contents.page_index, ...state.fileObjQueue.map((x) => x.pageID)];
        } else {
          state.page_index = [...state.fileObjQueue.map((x) => x.pageID)];
        }
      } else {
        state.page_index = [...doc.contents.page_index];
      }
    }
    if (doc.doctype === "form") {
      if (Object.keys(doc.contents).length && doc.contents?.question_index) {
        state.question_index = doc.contents.question_index;
      } else {
        state.question_index = [];
      }
    }
  },
  setSelectedThumbnail: (state, action) => {
    const { value } = action.payload;
    state.selectedThumbnail = value;
  },
  setTrash: (state, action) => {
    const { trash } = action.payload;
    state.trash = trash;
  },
  setCopy: (state, action) => {
    const { copy } = action.payload;
    state.copy = copy;
  },
  setPhotoVerification: (state, action) => {
    const { id, files, reset } = action.payload;
    if (reset) {
      if (state.fileObj) {
        Object.keys(state.fileObj).map((id) => {
          if (state.fileObj[id]) {
            state.fileObj[id].forEach((file) => URL.revokeObjectURL(file.media));
          }

          return id;
        });
        state.fileObj = null;
      }
    } else {
      state.fileObj = {
        ...state.fileObj,
        [id]: [...(state.fileObj && state.fileObj[id] ? state.fileObj[id] : []), ...files],
      };
    }
  },
  setFileObjQueue: (state, action) => {
    const { files } = action.payload;
    state.fileObjQueue = [...state.fileObjQueue, ...files];
    state.queueLength = state.fileObjQueue.length;
    state.page_index = insertItemsAfterIndex(
      state.page_index,
      files.map((x) => x.pageID),
      state.selectedThumbnail
    );
  },
  setFileObjQueueMedia: (state, action) => {
    const { media, content_type, filename, size, lastModified, pageID } = action.payload;
    state.fileObjQueue = [
      ...state.fileObjQueue.map((page) =>
        page.pageID === pageID
          ? { ...page, shapes: [], description: filename, content_type, filename, size, lastModified, media }
          : page
      ),
    ];
  },
  removeFromFileObj: (state, action) => {
    const { id, activeStep } = action.payload;
    const { [id]: value, ...withoutID } = state.fileObj;
    if (state.fileObj[id].length === 1) {
      state.fileObj = withoutID;
    } else {
      state.fileObj = {
        ...state.fileObj,
        ...(state.fileObj && state.fileObj[id] ? { [id]: state.fileObj[id].filter((x, i) => i !== activeStep) } : {}),
      };
    }
  },
  removeFromQueue: (state, action) => {
    const { pageID } = action.payload;
    state.fileObjQueue = [
      ...state.fileObjQueue.reduce((a, c) => {
        if (c.pageID === pageID) {
          URL.revokeObjectURL(c.media);
        } else {
          a.push(c);
        }
        return a;
      }, []),
    ];
    state.page_index = state.page_index.filter((page) => page !== pageID);
  },
  setQuestion: (state, action) => {
    const {
      id,
      title,
      description,
      photoVerification,
      allowComments,
      valueType,
      widget,
      required,
      value,
      comments,
      multiple,
      textField,
      validation,
      actionType,
      roleID,
      userID,
    } = action.payload;
    if (title || title === "") {
      state.currentDocument.contents.questions[id].title = title;
    }
    if (description || description === "") {
      state.currentDocument.contents.questions[id].description = description;
    }
    if (photoVerification === false || photoVerification) {
      state.currentDocument.contents.questions[id].photoVerification = photoVerification;
    }
    if (allowComments === false || allowComments) {
      state.currentDocument.contents.questions[id].allowComments = allowComments;
    }
    if (valueType) {
      state.currentDocument.contents.questions[id].valueType = valueType;
    }
    if (widget) {
      state.currentDocument.contents.questions[id].widget = widget;
    }
    if (required === false || required) {
      state.currentDocument.contents.questions[id].required = required;
    }
    if (value || value === "" || value === 0) {
      if (multiple) {
        handleMultipleChoice(state, id, value);
      } else {
        state.currentDocument.contents.questions[id].values = value;
      }
    }
    if (textField || textField === "") {
      state.currentDocument.contents.questions[id].values = textField;
    }
    if (comments || comments === "") {
      state.currentDocument.contents.questions[id].comments = comments;
    }
    if (validation) {
      state.currentDocument.contents.questions[id].validation = validation;
    }
    if (actionType) {
      state.currentDocument.contents.questions[id].validation.action.type = actionType;
    }
    if (roleID) {
      handleNotifications(state, id, "roles", roleID);
    }
    if (userID) {
      handleNotifications(state, id, "users", userID);
    }
  },
  setMultipleChoice: (state, action) => {
    const { id, direction, remove, add, index } = action.payload;
    if (add) {
      state.currentDocument.contents.questions[id].choices = [
        ...state.currentDocument.contents.questions[id].choices,
        add,
      ];
    }
    if (remove) {
      state.currentDocument.contents.questions[id].choices = state.currentDocument.contents.questions[
        id
      ].choices.filter((x) => x !== remove);
    }
    if (direction) {
      state.currentDocument.contents.questions[id].choices = handleMove(
        state.currentDocument.contents.questions[id].choices,
        direction,
        index
      );
    }
  },
  addQuestion: (state, action) => {
    const { id } = action.payload;
    if (!state.currentDocument.contents.questions) {
      state.currentDocument.contents.questions = {
        [id]: {
          media: "",
          title: "",
          description: "",
          media_thumb: "",
          photoVerification: false,
          allowComments: false,
          required: true,
          valueType: "true/false",
          choices: [],
          widget: "radio buttons",
          validation: {},
        },
      };
    } else {
      state.currentDocument.contents.questions[id] = {
        media: "",
        title: "",
        description: "",
        media_thumb: "",
        photoVerification: false,
        allowComments: false,
        required: true,
        valueType: "true/false",
        choices: [],
        widget: "radio buttons",
        validation: {},
      };
    }
  },

  removeQuestion: (state, action) => {
    const { id } = action.payload;
    if (Object.keys(state.currentDocument.contents.questions).length === 1) {
      state.currentDocument.contents.questions = {};
    } else {
      state.currentDocument.contents.questions = Object.keys(state.currentDocument.contents.questions).reduce(
        (a, c) => {
          if (c !== id) {
            a[c] = state.currentDocument.contents.questions[c];
          }
          return a;
        },
        {}
      );
    }
  },
  setQuestionMedia: (state, action) => {
    const { media, filename, content_type, lastModified, verification, id } = action.payload;
    if (media || media === "") {
      state.currentDocument.contents.questions[id].media = media;
    }
    if (filename || filename === "") {
      state.currentDocument.contents.questions[id].filename = filename;
    }
    if (content_type || content_type === "") {
      state.currentDocument.contents.questions[id].content_type = content_type;
    }
    if (lastModified || lastModified === "") {
      state.currentDocument.contents.questions[id].lastModified = lastModified;
    }
    if (verification) {
      state.currentDocument.contents.questions[id].verification = verification;
    }
  },
  resetFileObjQueue: (state) => {
    state.queueLength = 0;
    state.fileObjQueue = [
      ...state.fileObjQueue.reduce((a, c) => {
        URL.revokeObjectURL(c.media);
        return a;
      }, []),
    ];
  },
  resetDocumentChanges: (state) => {
    if (state.currentDocumentChanges) {
      Object.keys(state.currentDocumentChanges).forEach((_, pageID) =>
        URL.revokeObjectURL(state.currentDocumentChanges[pageID]?.media)
      );
      state.currentDocumentChanges = null;
    }
  },

  setFileObjDescription: (state, action) => {
    const { description, pageID } = action.payload;
    if (state.fileObjQueue)
      state.fileObjQueue = [
        ...state.fileObjQueue.map((fileObj) => (fileObj.pageID === pageID ? { ...fileObj, description } : fileObj)),
      ];
  },
  setFileObjShapes: (state, action) => {
    const { shapes, pageID } = action.payload;
    state.fileObjQueue = [...state.fileObjQueue.map((x) => (x.pageID === pageID ? { ...x, shapes } : x))];
  },
  setCurrentPage: (state, action) => {
    const { currentPage } = action.payload;
    state.currentPage = currentPage;
  },
  setSearch: (state, action) => {
    const { search } = action.payload;
    state.search = search;
  },
  setSearching: (state, action) => {
    const { searching } = action.payload;
    state.searching = searching;
  },
  setPrevPage: (state, action) => {
    const { id, shapes, description } = action.payload;
    if (id) {
      state.prevPage = { id, shapes, description };
    } else {
      state.prevPage = null;
    }
  },
  setOrder: (state, action) => {
    const { order } = action.payload;
    state.order = order;
  },
  setPageIndex: (state, action) => {
    const { page_index } = action.payload;
    if (page_index) {
      state.page_index = page_index;
    } else {
      state.page_index = [];
    }
  },
  setQuestionIndex: (state, action) => {
    const { add, remove, reset, initialize, newOrder } = action.payload;
    if (newOrder) {
      state.question_index = newOrder;
    }
    if (initialize) {
      state.question_index = initialize;
    }
    if (add) {
      if (!state.question_index) {
        state.question_index = [add];
      } else {
        state.question_index = [...state.question_index, add];
      }
    }
    if (remove) {
      state.question_index = state.question_index.filter((x) => x !== remove);
    }
    if (reset) {
      state.question_index = [];
    }
  },
  removeQuestionIndexContents: (state, action) => {
    const { remove } = action.payload;
    state.currentDocument.contents.question_index = state.currentDocument.contents.question_index.filter(
      (idx) => idx !== remove
    );
  },
  setRootResults: (state, action) => {
    const { results } = action.payload;
    state.rootResults = results;
  },
  setDocumentHistory: (state, action) => {
    const { results } = action.payload;
    state.currentDocumentHistory = { results };
  },

  setPrevLocation: (state, action) => {
    const { prevLocation } = action.payload;
    state.prevLocation = prevLocation;
  },
  setAlert: (state, action) => {
    const { alert, severity } = action.payload;
    state.alert = alert;
    state.severity = severity;
  },
  setSpreadsheet: (state, action) => {
    const { additionalResults, results, open, media, comments, total, rowsPerPage, page, reset } = action.payload;
    if (reset) {
      state.spreadsheet = spreadsheet;
    }
    if (additionalResults) {
      state.spreadsheet.results = [...state.spreadsheet.results, ...results];
    } else if (results) {
      state.spreadsheet.results = results;
    }
    if (total) {
      state.spreadsheet.total = total;
    }
    if (rowsPerPage) {
      state.spreadsheet.rowsPerPage = rowsPerPage;
    }
    if (page || page === 0) {
      state.spreadsheet.page = page;
    }
    if (open || open === false) {
      state.spreadsheet.open = open;
    }
    if (media === 0) {
      state.spreadsheet.media = null;
    } else if (media) {
      state.spreadsheet.media = media;
    }
    if (comments === 0) {
      state.spreadsheet.comments = null;
    } else if (comments) {
      state.spreadsheet.comments = comments;
    }
  },
  setSingleRow: (state, action) => {
    const { singleRow, reset } = action.payload;
    if (reset) {
      state.singleRow = null;
    } else {
      state.singleRow = singleRow;
    }
  },
  setSubscriptions: (state, action) => {
    const { subscriptions } = action.payload;
    state.subscriptions = subscriptions;
  },
  setNotifications: (state, action) => {
    const { notifications } = action.payload;
    state.notifications = notifications;
  },
  setDocumentChanges: (state, action) => {
    const { pageID, shapes, description, content_type, media } = action.payload;

    if (!state.currentDocumentChanges) {
      state.currentDocumentChanges = {
        [pageID]: { pageID, shapes, description, content_type, media },
      };
    } else {
      state.currentDocumentChanges = {
        ...state.currentDocumentChanges,
        [pageID]: { ...state.currentDocumentChanges[pageID], pageID, shapes, description, content_type, media },
      };
    }
  },
  setDocumentChangesMedia: (state, action) => {
    const { content_type, filename, size, lastModified, media, pageID } = action.payload;

    if (!state.currentDocumentChanges) {
      state.currentDocumentChanges = {
        [pageID]: { pageID, media, content_type, filename, size, lastModified },
      };
    } else {
      state.currentDocumentChanges = {
        ...state.currentDocumentChanges,
        [pageID]: {
          ...state.currentDocumentChanges[pageID],
          shapes: [],
          description: filename,
          pageID,
          media,
          content_type,
          filename,
          size,
          lastModified,
        },
      };
    }
  },
  setDidQuestionsChange: (state, action) => {
    const { isChanged } = action.payload;
    if (isChanged) {
      state.didQuestionsChange = true;
    } else {
      state.didQuestionsChange = false;
    }
  },
  setFavorites: (state, action) => {
    const { favorites } = action.payload;
    state.favorites = favorites;
  },
  setUploadProgress: (state, action) => {
    const { initialize, uploadProgress, reset } = action.payload;
    if (reset) {
      state.uploadProgress = null;
    }
    if (initialize) {
      state.uploadProgress = 0;
    }
    if (uploadProgress) {
      state.uploadProgress = uploadProgress;
    }
  },
  setIsBlocking: (state, action) => {
    const { isBlocking } = action.payload;

    state.isBlocking = Boolean(isBlocking);
  },
  setIsBlockingViewer: (state, action) => {
    const { isBlockingViewer } = action.payload;

    state.isBlockingViewer = Boolean(isBlockingViewer);
  },
};

export function handleNotifications(state, questionID, key, value) {
  if (!state.currentDocument.contents.questions[questionID]?.validation[key]) {
    state.currentDocument.contents.questions[questionID].validation[key] = [value];
  } else if (state.currentDocument.contents.questions[questionID].validation[key].includes(value)) {
    state.currentDocument.contents.questions[questionID].validation[key] = [
      ...state.currentDocument.contents.questions[questionID].validation[key].filter((x) => x !== value),
    ];
  } else {
    state.currentDocument.contents.questions[questionID].validation[key] = [
      ...state.currentDocument.contents.questions[questionID].validation[key],
      value,
    ];
  }
}

export function handleMultipleChoice(state, id, value) {
  if (state.currentDocument.contents.questions[id].values.includes(value)) {
    state.currentDocument.contents.questions[id].values = [
      ...state.currentDocument.contents.questions[id].values.filter((x) => x !== value),
    ];
  } else {
    state.currentDocument.contents.questions[id].values = [
      ...state.currentDocument.contents.questions[id].values,
      value,
    ];
  }
}

export const handleMove = (state, direction, index) => {
  let arr = [...state];
  let removed = arr.splice(index, 1)[0];
  arr.splice(direction === "up" ? index - 1 : index + 1, 0, removed);
  return arr;
};
