import { Box, Button, Dialog, Tab, Tabs } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDidQuestionsChange, setQuestionIndex } from "../../../Documentation/Reducers/DocumentationReducer";
import { getChecklist } from "../../../Documentation/Reducers/Thunks/getChecklist";
import { setFormIds, setQuestions, setTree } from "../../../Editor/Reducers/EditorReducer";
import { BrowsePanel } from "./BrowsePanel";
import { constructTree } from "./constructTree";
import { SearchPanel } from "./SearchPanel";
import { QuestionSelectionDialogMultiple } from "./QuestionSelectionDialogMultiple";
import { flattenTree } from "./flattenTree";
import { unflattenTree } from "./unflattenTree";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  height: "80%",
  maxHeight: "80%",
  zIndex: 10,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export function ChecklistSearchDialog({ open, setOpen, setSelectedChecklist, selectedChecklist, treeID }) {
  const [value, setValue] = React.useState(0);
  const currentFolderId = useSelector((state) => state.documentation.id);
  const question_index = useSelector((state) => state.documentation.question_index);
  const form_links = useSelector((state) => state.documentation?.currentDocument?.contents?.form_links);
  const tree = useSelector((state) => state.editor.tree);
  const form_ids = useSelector((state) => state.editor.form_ids);
  const [source, setSource] = React.useState(null);
  const [destination, setDestination] = React.useState(null);
  const [openSelection, setOpenSelection] = React.useState(false);
  const [stashedIndexes, setStashedIndexes] = React.useState(null);
  const [stashedQuesitons, setStashedQuestions] = React.useState(null);

  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      fullWidth
      maxWidth={"md"}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
          <Tab label="Browse" {...a11yProps(0)} />
          <Tab label="Search" {...a11yProps(1)} />
        </Tabs>
      </Box>
      {BrowsePanel({ value, setSelectedChecklist, selectedChecklist })}
      {SearchPanel({ value, setSelectedChecklist, selectedChecklist })}
      <div>
        <Button
          disabled={selectedChecklist ? false : true}
          onClick={() => {
            dispatch(getChecklist({ id: selectedChecklist, currentFolderId })).then((res) => {
              if (!tree) {
                console.log("no tree, create a new tree");
                dispatch(setTree({ tree: constructTree(form_links, res?.contents?.questions || [], form_ids, [res]) }));
                dispatch(
                  setQuestions({
                    add: res?.contents?.questions,
                    isEditor: true,
                  })
                );
                if (!form_ids || !form_ids.includes(selectedChecklist)) {
                  dispatch(setFormIds({ form_id: selectedChecklist }));
                }
                if (res?.contents?.question_index) {
                  res?.contents?.question_index.forEach((question) => {
                    if (!question_index.includes(question)) {
                      dispatch(setQuestionIndex({ add: question }));
                    }
                  });
                }
                dispatch(setDidQuestionsChange({ isChanged: true }));
                setSelectedChecklist(null);
                setOpen(false);
              } else {
                setSource(selectedChecklist);
                setDestination(treeID);
                if (treeID === "root") {
                  console.log("root, adding form and questions directly");
                  dispatch(
                    setQuestions({
                      add: res?.contents?.questions,
                      isEditor: true,
                    })
                  );
                  dispatch(setFormIds({ form_id: selectedChecklist }));
                  if (res?.contents?.question_index) {
                    res?.contents?.question_index.forEach((question) => {
                      if (question_index && !question_index.includes(question)) {
                        dispatch(setQuestionIndex({ add: question }));
                      }
                    });
                  }
                  dispatch(setDidQuestionsChange({ isChanged: true }));
                  let flattenedTree = tree && flattenTree(tree, 0);
                  let withNewNode = [
                    ...flattenedTree,
                    { id: res.id, question_id: null, parent_question_id: null, children: [], name: res.name },
                  ];
                  dispatch(setTree({ tree: unflattenTree(withNewNode) }));
                  setOpen(false);
                } else {
                  console.log("not root, opening question selection");
                  setStashedIndexes(res?.contents?.question_index);
                  setStashedQuestions(res?.contents?.questions);
                  setOpenSelection(true);
                }
              }
            });
          }}
        >
          Add
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            setSelectedChecklist(null);
          }}
        >
          Cancel
        </Button>
      </div>
      {openSelection && (
        <QuestionSelectionDialogMultiple
          setOpen={setOpen}
          openSelection={openSelection}
          setOpenSelection={setOpenSelection}
          treeID={treeID}
          sourceID={source}
          destinationID={destination}
          setStashedIndexes={setStashedIndexes}
          setStashedQuestions={setStashedQuestions}
          stashedIndexes={stashedIndexes}
          stashedQuesitons={stashedQuesitons}
        />
      )}
    </Dialog>
  );
}
