export const baseURL = `${process.env.REACT_APP_BASE_URL}`;
export * from "./Thunks/getFolder";
export * from "./Thunks/getFolderContents";
export * from "./Thunks/getFolderQuery";
export * from "./Thunks/createFolder";
export * from "./Thunks/getTrash";
export * from "./Thunks/createDocument";
export * from "./Thunks/removeFolder";
export * from "./Thunks/restoreFromTrash";
export * from "./Thunks/removePage";
export * from "./Thunks/getDocument";
export * from "./Thunks/editFolder";
export * from "./Thunks/copyToFolder";

export const headers = () => ({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

export function isVideoOrPdf(content_type, name) {
  return /video/.test(content_type) || /pdf/.test(content_type)
    ? [
        {
          x: 0,
          y: 0,
          id: /video/.test(content_type) ? "video_description" + name : "pdf_description" + name,
          color: "none",
          shape: "rectangle",
          width: 0,
          height: 0,
          rotation: 0,
          selected: true,
          description: "",
          strokeWidth: 0,
        },
      ]
    : [];
}

export function saveCurrentPage(currentPage, currentDocument, shapes) {
  return {
    [currentPage]: {
      ...currentDocument.contents.pages[currentPage],
      shapes: shapes,
    },
  };
}

export function isEmpty(obj) {
  for (var i in obj) return false;
  return true;
}

export const removeKey = (obj, prop) => {
  return Object.keys(obj).reduce((acc, cur) => {
    if (cur !== prop) {
      acc[cur] = obj[cur];
    }
    return acc;
  }, {});
};
