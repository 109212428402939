import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, FormControlLabel, Checkbox, Typography, CircularProgress, TextField } from "@mui/material";
import { createNewUser } from "../Thunks/createNewUser";
import { adminSelector } from "../Reducers/AdminReducer";
import { RoleTransferList } from "../../Roles/Components/RoleTransferList/RoleTransferList";
import { getRoles } from "../../Roles/Reducers/getRoles";
import { rolesSelector } from "../../Roles/Reducers/RolesReducer";
import { Form, FieldContainer } from "../../Form/Form";
import { Face, CheckCircle, Cancel, AccountCircle, Email } from "@mui/icons-material/";
import { Dashboard } from "../../Dashboard/Dashboard";
import { Breadcrumb } from "../../Dashboard/Components/Breadcrumbs";
export const NewUserForm = () => {
  const dispatch = useDispatch();
  const { loading, fileInput } = useSelector(adminSelector);
  const { selectedRoles, loading: rolesLoading } = useSelector(rolesSelector);
  const [username, setUsername] = useState(() => "");
  const [password, setPassword] = useState(() => "");
  const [confirmPassword, setConfirmPassword] = useState(() => "");
  const [full_name, setFullName] = useState(() => "");
  const [email, setEmail] = useState(() => "");
  const [isAdmin, setisAdmin] = useState(() => false);
  const [clearAvatar, setClearAvatar] = useState(false);
  const handleCreateUser = () => {
    dispatch(
      createNewUser({
        username,
        email,
        is_org_admin: isAdmin,
        full_name,
        password,
        roles: selectedRoles,
        avatar: fileInput.upload_id ? fileInput.upload_id : "",
      })
    );
  };

  const handleFullName = (e) => setFullName(e.target.value);
  const handleUsername = (e) => setUsername(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);
  const handleConfirmPassword = (e) => setConfirmPassword(e.target.value);
  const handleisAdmin = (e) => setisAdmin(e.target.checked);
  useGetRoles(dispatch);
  return (
    <Dashboard
      pageName="New User"
      breadcrumbs={
        <Breadcrumb
          currentValue={"New"}
          linkValues={[
            { name: "Home", to: "/" },
            { name: "Admin", to: "/admin" },
            { name: "Users", to: "/admin/users" },
          ]}
        />
      }
    >
      {loading || rolesLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <CircularProgress />
        </div>
      ) : (
        <Form full_name={full_name} id={1} clearAvatar={clearAvatar} setClearAvatar={setClearAvatar}>
          <Typography variant="h4" style={{ margin: "0.5em 0 0.5em 0.5em" }}>
            Create New User
          </Typography>
          <TextField
            id="username"
            label="Username"
            type="text"
            value={username}
            onChange={handleUsername}
            InputProps={{
              startAdornment: <AccountCircle />,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            id="full_name"
            label="Full Name"
            type="text"
            value={full_name}
            onChange={handleFullName}
            InputProps={{
              startAdornment: <Face />,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            id="email"
            label="Email"
            type="email"
            value={email}
            onChange={handleEmail}
            InputProps={{
              startAdornment: <Email />,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={handlePassword}
            InputProps={{
              startAdornment: password.length < 8 ? <Cancel /> : <CheckCircle />,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            id="confirmPassword"
            label="Re-Type Password"
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPassword}
            InputProps={{
              startAdornment: confirmPassword.length < 8 || password !== confirmPassword ? <Cancel /> : <CheckCircle />,
            }}
            fullWidth
            margin="normal"
          />

          <FieldContainer>
            <RoleTransferList />
          </FieldContainer>
          <FieldContainer>
            <FormControlLabel
              control={<Checkbox checked={isAdmin} onChange={handleisAdmin} color="primary" />}
              label="Organization Administrator?"
            />
          </FieldContainer>
          <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
            <Button
              disabled={password !== confirmPassword || !username || !full_name || !email || loading}
              variant="outlined"
              style={{ textTransform: "none", color: "green" }}
              onClick={handleCreateUser}
            >
              {loading ? "Submitting..." : "Save Changes"}
            </Button>
          </Grid>
        </Form>
      )}
    </Dashboard>
  );
};

function useGetRoles(dispatch) {
  useEffect(() => {
    dispatch(getRoles({ skip: 0, limit: 100, order: "", orderBy: "name", search: "" }));
  }, [dispatch]);
}
