import React, { useState } from "react";
import {
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { not, intersection, union } from "./helpers";
import { useStyles } from "./styles";
import { rolesSelector, setSelectedRoles } from "../../Reducers/RolesReducer";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { adminSelector } from "../../../Admin/Reducers/AdminReducer";
import { ArrowForward, ArrowBack, ArrowUpward, ArrowDownward } from "@mui/icons-material/";

export const RoleTransferList = () => {
  const classes = useStyles();
  const { roles } = useSelector(rolesSelector);
  const { currentUser } = useSelector(adminSelector);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(() => []);
  const [left, setLeft] = useState(() => []);
  const [right, setRight] = useState(() => []);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const matches = useMediaQuery("(min-width:960px");
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  useEffect(() => {
    const userRoleIds = currentUser.roles.map((r) => r.id);
    const availableRoles = roles.filter((r) => userRoleIds.indexOf(r.id) === -1);
    const usedRoles = roles.filter((r) => userRoleIds.indexOf(r.id) !== -1);
    if (currentUser.roles) {
      setRight(usedRoles.map((role) => role.name));
      setLeft(availableRoles.map((role) => role.name));
      dispatch(setSelectedRoles({ roles: usedRoles.map((x) => x.id) }));
    } else {
      setLeft(roles.map((role) => role.name));
    }
  }, [dispatch, roles, currentUser]);

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    let selected = right.concat(leftChecked).flatMap((val) =>
      roles.reduce((acc, role) => {
        if (role.name === val) {
          acc.push(role.id);
        }
        return acc;
      }, [])
    );

    dispatch(setSelectedRoles({ roles: selected }));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    let selected = not(right, rightChecked).flatMap((val) =>
      roles.reduce((acc, role) => {
        if (role.name === val) {
          acc.push(role.id);
        }
        return acc;
      }, [])
    );

    dispatch(setSelectedRoles({ roles: selected }));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
      <Grid item xs={5} md={5}>
        {customList("Roles", left)}
      </Grid>
      <Grid item xs={2} md={2}>
        {matches ? (
          <Grid item container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              <ArrowForward />
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              <ArrowBack />
            </Button>
          </Grid>
        ) : (
          <Grid item container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              <ArrowUpward />
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              <ArrowDownward />
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={5} md={5}>
        {customList("Chosen Roles", right)}
      </Grid>
    </Grid>
  );
};
