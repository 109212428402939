import Axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const getSingleDocumentData =
  ({ id }) =>
  async (dispatch) => {
    try {
      return await Axios({
        method: "get",
        url: baseURL + "documentation/documents/documentdata/" + id,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (e) {
      console.error(e);
      return e;
    }
  };
