import { Home, Settings } from "@mui/icons-material/";
import EmailIcon from "@mui/icons-material/Email";
import HelpIcon from "@mui/icons-material/Help";
import { Divider, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AboutDialog from "../../About/AboutDialog";
import { loginSelector } from "../../Login/Reducers/LoginReducer";
import { useFetchQuickLinks } from "../Hooks/useFetchQuickLinks";
import { LinkContainer } from "./LinkContainer";
import { QuickLinks } from "./QuickLinks";
import { SideDrawerFavorites } from "./SideDrawerFavorites";
import { styles, useStyles } from "./useStyles";
export const SideDrawer = ({ onClick }) => {
  const dispatch = useDispatch();
  const { is_org_admin } = useSelector(loginSelector);
  const rootResults = useSelector((state) => state.documentation.rootResults);
  const results = useSelector((state) => state.documentation.currentFolder.results);
  const [aboutDialog, setAboutDialog] = useState(false);
  const classes = useStyles();
  const favorites = useSelector((state) => state.documentation.favorites);
  useFetchQuickLinks(dispatch, results);
  return (
    <div style={styles.sideDrawer}>
      <div>
        <LinkContainer text="Dashboard" to={`/`} icon={<Home />} onClick={onClick} />
      </div>

      <div>
        <div className="MuiListItem-gutters">
          <Typography variant="overline" style={{ paddingLeft: 10 }}>
            Documentation
          </Typography>
        </div>
        <div>{QuickLinks(classes, onClick, rootResults)}</div>
        <div>{SideDrawerFavorites(classes, onClick, favorites)}</div>
      </div>

      <div style={styles.staff}>
        {is_org_admin && (
          <>
            <div className="MuiListItem-gutters">
              <Typography variant="overline" style={{ paddingLeft: 10 }}>
                Staff
              </Typography>
            </div>
            <LinkContainer text="Admin Section" to={`/admin`} icon={<Settings />} onClick={onClick} />
          </>
        )}
        <Divider />
        <Link style={styles.linkContainer} to={{ pathname: "https://dropdoc.com/faq/" }} target="_blank">
          <ListItem button key={"faq"} onClick={onClick}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary={"FAQ"} />
          </ListItem>
        </Link>
        <Link style={styles.linkContainer} to={{ pathname: "https://dropdoc.com/support/" }} target="_blank">
          <ListItem button key={"contact_support"} onClick={onClick}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary={"Contact Support"} />
          </ListItem>
        </Link>

        <ListItem button key="about-link" onClick={() => setAboutDialog(true)}>
          <ListItemText secondary={process.env.REACT_APP_NAME} />
          <ListItemIcon>
            <Typography>v{process.env.REACT_APP_VERSION}</Typography>
          </ListItemIcon>
        </ListItem>
      </div>
      <AboutDialog open={aboutDialog} onClose={() => setAboutDialog(false)} />
    </div>
  );
};
