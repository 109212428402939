import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  folderContainer: { display: "flex", flexWrap: "wrap", justifyContent: "baseline", paddingBottom: "6em" },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  folderPaper: { height: "100%", overflowY: "auto", overflowX: "auto", borderRadius: "0" },
  loadingEmpty: {
    height: "75%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 0.5em 0 0.5em",
  },
  buttons: {
    display: "flex",
    position: "absolute",
  },
  search: { display: "flex", position: "absolute", right: "2em" },
}));
