import axios from "axios";
import { tokenLoading, tokenReceived, successfulReset } from "./LoginReducer";
import { baseURL, toUrlEncoded } from "./thunks";

export const postLoginDetails =
  ({ username, password, twoFactor, setIsTwoFactor }) =>
  async (dispatch) => {
    dispatch(tokenLoading());

    try {
      const tokenReq = await axios.post(
        baseURL + `auth/access-token`,
        toUrlEncoded({ username, password, ...(twoFactor.length ? { client_secret: twoFactor } : {}) })
      );
      localStorage.setItem("login", true);
      localStorage.setItem("token", tokenReq.data.access_token);
      const userReq = await axios.get(baseURL + "auth/me", {
        ...(twoFactor.length ? { data: { client_secret: twoFactor } } : {}),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + tokenReq.data.access_token,
        },
      });

      dispatch(successfulReset({ success: false }));
      dispatch(tokenReceived({ error: false }));
      return userReq;
    } catch (error) {
      console.log(error?.response?.data?.detail);
      if (error?.response?.data?.detail === "Incorrect OTP") {
        setIsTwoFactor(true);
      }
      dispatch(tokenReceived({ error: true }));
    }
  };
