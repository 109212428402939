import axios from "axios";
import { baseURL } from "../thunks";

export const copyToFolder =
  ({ id, destination_folder_id, remove, kind }) =>
  async () => {
    return axios.post(
      baseURL + "documentation/folders/" + destination_folder_id + (remove ? "/move" : "/copy"),
      {
        ...(kind === "document" ? { document_id: id } : { folder_id: id }),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  };
