import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import { styles } from "./useStyles";

export const LinkContainer = ({ text, to, icon, onClick }) => {
  return (
    <Link style={styles.linkContainer} to={to}>
      <ListItem button key={text} onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </Link>
  );
};
