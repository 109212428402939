import axios from "axios";
import { baseURL } from "../thunks";

export const getFolderACL =
  ({ id }) =>
  async () => {
    const { data } = await axios.get(baseURL + "documentation/folders/" + id + "/acl", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return data;
  };
