import axios from "axios";
import { requestLoading, requestReceived, setCurrentUser, updateRows } from "../Reducers/AdminReducer";
import { baseURL } from "../Reducers/thunks";

export const getUsers =
  ({ skip, limit, order, orderBy, search, role_filters }) =>
  async (dispatch) => {
    dispatch(requestLoading());
    const url = `${baseURL}users?${
      role_filters.length ? role_filters.map((id) => `role_filters=${id}`).join("&") : ""
    }&ordering=${order}${orderBy}&skip=${skip}&limit=${limit}${search.length ? `q=${search}` : ""}`;

    try {
      const fetchUsers = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(
        setCurrentUser({
          username: "",
          password: "",
          email: "",
          is_active: null,
          is_org_admin: null,
          roles: [],
          full_name: "",
          id: null,
        })
      );

      dispatch(
        updateRows({
          rows: fetchUsers.data.results,
          total: fetchUsers.data.total,
        })
      );
      dispatch(requestReceived({ detail: "requestReceived" }));
    } catch (error) {
      dispatch(requestReceived({ detail: error }));
      console.error(error);
    }
  };
