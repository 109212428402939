import React from "react";
import { ListItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedThumbnail } from "../../Documentation/Reducers/DocumentationReducer";
import { deselectShapes } from "../../Editor/Reducers/EditorReducer";

const stripMarkdown = (str) => (str ? str.split(/\n/g)[0].replace(/[*#~]/g, "") : "");

export const Thumbnail = ({ thumbnail, index, isEditor }) => {
  const dispatch = useDispatch();
  const currentDocument = useSelector((state) => state.documentation.currentDocument);
  const selectedThumbnail = useSelector((state) => state.documentation.selectedThumbnail);
  const page_index = useSelector((state) => state.documentation.page_index);
  const pageID = page_index[index];
  const fileObjQueue = useSelector((state) => state.documentation.fileObjQueue);
  const isFileObjQueue = fileObjQueue.find((x) => x.pageID === pageID);
  const currentDocumentChanges = useSelector((state) => state.documentation.currentDocumentChanges);

  const thumbnailDescription =
    isEditor && isFileObjQueue
      ? stripMarkdown(isFileObjQueue?.description)
      : stripMarkdown(
          currentDocumentChanges &&
            (currentDocumentChanges[pageID]?.description || currentDocumentChanges[pageID]?.description === "")
            ? currentDocumentChanges[pageID]?.description
            : currentDocument?.contents?.pages[page_index[index]]?.description
        );

  const handleClick = (e) => {
    const didThumbnailChange = e.currentTarget.value !== selectedThumbnail;

    if (didThumbnailChange) {
      dispatch(deselectShapes());
      dispatch(setSelectedThumbnail({ value: e.currentTarget.value }));
    }
  };

  return (
    <ListItem
      style={{
        height: "fit-content",
        padding: 0,
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        backgroundColor: selectedThumbnail === index ? "rgb(133 204 254)" : "inherit",
        border: selectedThumbnail === index ? "2px solid green" : "inherit",
      }}
      value={index}
      id={index + 1}
      onClick={handleClick}
      divider
    >
      <div>{thumbnail}</div>
      <div
        style={{ width: "inherit", whiteSpace: "nowrap", padding: "0 0.5em", textAlign: "center", overflow: "hidden" }}
      >
        {thumbnailDescription}
      </div>
    </ListItem>
  );
};
