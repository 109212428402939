import { useEffect } from "react";
import { useSelector } from "react-redux";
import history from "../../../Utils/history";

export function useIsAuthenticated() {
  const authenticated = useSelector((state) => state.login.authenticated);

  useEffect(() => {
    if (authenticated) {
      history.push("/");
    } else {
      return;
    }
  }, [authenticated]);
}
