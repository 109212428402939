export const initialState = {
  username: "",
  email: "",
  full_name: "",
  org: {
    name: "",
    slug: "",
  },
  error: "",
  loading: null,
};
