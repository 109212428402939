import { Grid, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    justifyContent: "center",
  },
}));
export const styles = {
  viewContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
  },
  imageContainer: { justifyContent: "stretch", alignItems: "center", height: "100%", overflowY: "auto" },
  emptyImageContainer: { justifyContent: "center", alignItems: "center", height: "100%", overflowY: "auto" },
  thumbnailContainer: {
    display: "block",
    height: "100%",
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    fontSize: "small",
  },
  emptyThumbnailContainer: {
    height: "100%",
    overflowY: "auto",
    borderRight: "1px solid #c8ccd0",
    justifyContent: "center",
    alignItems: "center",
  },
  progress: {
    height: "75%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 0.5em 0 0.5em",
  },
  list: {
    height: "fit-content",
    padding: 0,
    cursor: "pointer",
  },
  container: { padding: "0.5em" },
  thumbnail: {
    paddingLeft: "0.5em",
  },
  description: { maxWidth: "100%", textAlign: "center" },
  image: { maxWidth: "100%", maxHeight: "200px", height: "auto" },
  AddPage: {
    position: "absolute",
    bottom: "2em",
    right: "2em",
  },
  editor: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflowY: "hidden",
  },
  TextEdit: { maxHeight: "none", height: "100%" },
  input: { display: "none" },
  overflowContainer: { justifyContent: "stretch", alignItems: "center", height: "100%", overflowY: "auto" },
  canvasContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflowY: "hidden",
  },
  shapeListContainer: {
    display: "block",
    height: "100%",
    overflowY: "auto",
    borderLeft: "1px solid #c8ccd0",
    width: "600px",
  },
  media: {
    maxHeight: "500px",
    maxWidth: "500px",
    width: "100%",
    height: "100%",
    objectFit: "contain",
    paddingRight: "10px",
  },
};
export function GridStyles(shapes, annotationWidth) {
  return {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: `20% auto ${
      shapes && shapes.length ? (annotationWidth / document.body.offsetWidth) * 100 : 0
    }%`,
    overflow: "hidden",
  };
}
export const AnnotationGrid = styled(Grid)`
  background-color: ${(props) => props.background};
  min-height: 50px;
  max-height: 200px;
  align-content: center;
  padding-left: 5px;
  margin-top: 1px;
  overflow: auto;
  &:hover {
    cursor: pointer;
  }
`;
