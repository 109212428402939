import axios from "axios";
import { setUploadProgress } from "../DocumentationReducer";
import { baseURL } from "../thunks";
import { handleError } from "./Helpers/handleError";

export const publishQuestions =
  ({ currentDocument, newMedia, question_index, questions, changelog, onCancel, setShowPasswordModal }) =>
  async (dispatch) => {
    try {
      dispatch(setUploadProgress({ uploadProgress: 60 }));
      const modifiedQuestions = await [...question_index].reduce((accumulator, currentID) => {
        const isNewPage = newMedia && newMedia.find((file) => file.questionID === currentID);
        const currentQuestion = questions[currentID];
        accumulator[currentID] = {
          ...currentQuestion,
          ...(isNewPage ? { media: isNewPage.upload_id } : {}),
        };

        return accumulator;
      }, {});
      dispatch(setUploadProgress({ uploadProgress: 70 }));
      const putReq = await axios({
        method: "put",
        url: baseURL + "documentation/documents/" + currentDocument?.id,
        data: {
          ...currentDocument,
          contents: {
            sheet_id: currentDocument.contents.sheet_id ? currentDocument.contents.sheet_id : null,
            questions: modifiedQuestions,
            question_index,
          },
          changelog,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(setUploadProgress({ uploadProgress: 85 }));

      return putReq;
    } catch (e) {
      handleError(e, onCancel, dispatch, setShowPasswordModal);
    }
  };
