import { setSaving } from "../../../../Editor/Reducers/EditorReducer";
import { received, setAlert, setIsBlocking, setUploadProgress } from "../../DocumentationReducer";

export function handleError(e, onCancel, dispatch, setShowPasswordModal) {
  console.error(e);
  onCancel();
  dispatch(setSaving({ saving: false }));
  dispatch(received({ error: true }));
  dispatch(setIsBlocking({ isBlocking: false }));
  dispatch(setUploadProgress({ reset: true }));

  if (e?.response?.status === 403) {
    setShowPasswordModal(true);
    dispatch(setAlert({ alert: "Upload failed, you are logged out.", severity: "warning" }));
  } else {
    dispatch(setAlert({ alert: "Upload failed, something went wrong.", severity: "warning" }));
  }
}
