import React from "react";
import { Login } from "./Components/Login";
import { Dashboard } from "./Components/Dashboard";
import { Switch, Route, useLocation } from "react-router-dom";
import { Registration } from "./Components/Registration";
import { useSelector } from "react-redux";
import { ResetPassword } from "./Components/Login/Components/ResetPassword";
import { Redirect } from "react-router-dom";
import { Invitation } from "./Components/Dashboard/Components/Invitation";
import { ActivateAccount } from "./Components/Registration/Components/ActivateAccount";
import { NotFound404 } from "./Components/Dashboard/Components/NotFound404";

function App() {
  const authenticated = useSelector((state) => state.login.authenticated);
  let location = useLocation();

  return (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route path="/register">
        <Registration />
      </Route>
      <Route path="/auth/activate">
        <ActivateAccount />
      </Route>
      <Route path="/reset-password">
        <ResetPassword />
      </Route>
      <Route path={"/invitation/:id"}>
        <Invitation />
      </Route>
      <Route path="/">
        {authenticated && localStorage.login ? (
          <Dashboard />
        ) : (
          <Redirect to={{ pathname: "/login", state: { referrer: location.pathname } }} />
        )}
      </Route>
      <Route path="*">
        <NotFound404 />
      </Route>
    </Switch>
  );
}

export default App;
