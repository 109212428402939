import React, { useState, useEffect } from "react";
import { Button, Paper, Grid, Container, useMediaQuery } from "@mui/material";
import { useStyles, FlexContainer, BigAvatar, BigAvatarDiv } from "./styles";
import { ChangeAvatarDialog } from "../Admin/AvatarEditor/AvatarEditor";
import { usePrevious } from "../Documentation/Helpers/helpers";
import { makeInitials } from "./helpers";
// import { BigAvatar } from "../Admin/Users/helpers";
const UserAvatar = ({ full_name, small, avatar, setAvatarOpen, clearAvatar, setClearAvatar }) => {
  let previousAvatar = usePrevious(avatar);
  useEffect(() => {
    if (avatar !== previousAvatar) setClearAvatar(false);
  }, [avatar, previousAvatar, setClearAvatar]);

  const initials = makeInitials(full_name);

  return (
    <BigAvatarDiv small={small}>
      <BigAvatar name={initials} src={clearAvatar ? "" : avatar ? avatar : ""}>
        {initials}
      </BigAvatar>
      <Button
        onClick={() => setAvatarOpen(true)}
        style={{ marginTop: "1em" }}
        variant="contained"
        color="primary"
        component="span"
      >
        Choose Avatar
      </Button>
      {avatar && !clearAvatar && (
        <Button
          onClick={() => setClearAvatar(true)}
          style={{ marginTop: "1em" }}
          variant="contained"
          color="secondary"
          component="span"
        >
          Clear Avatar
        </Button>
      )}
    </BigAvatarDiv>
  );
};

export const Form = ({ children, full_name, id, avatar, clearAvatar, setClearAvatar }) => {
  const classes = useStyles();
  const large = useMediaQuery((theme) => theme.breakpoints.up("md", "xl"));
  const [avatarOpen, setAvatarOpen] = useState(false);
  const [blob, setBlob] = useState();
  const [fileName, setFileName] = useState();
  const [preview, setPreview] = useState(null);

  const UserAvatarDiv = (
    <UserAvatar
      full_name={full_name}
      small={false}
      avatar={blob ? blob : avatar}
      setAvatarOpen={setAvatarOpen}
      clearAvatar={clearAvatar}
      setClearAvatar={setClearAvatar}
    />
  );
  return (
    <Container maxWidth="md" style={{ paddingTop: "1em" }}>
      <FlexContainer>
        {large && id ? UserAvatarDiv : ""}
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.padding}>
              {!large && id && <div style={{ display: "flex", justifyContent: "center" }}>{UserAvatarDiv}</div>}
              <form>{children}</form>
            </Paper>
          </Grid>
        </Grid>
      </FlexContainer>
      <ChangeAvatarDialog
        avatarOpen={avatarOpen}
        setAvatarOpen={setAvatarOpen}
        blob={blob}
        setBlob={setBlob}
        setFileName={setFileName}
        setPreview={setPreview}
        fileName={fileName}
        preview={preview}
      />
    </Container>
  );
};
export const FieldContainer = ({ children }) => {
  return (
    <Grid container spacing={4} style={{ padding: "0 1em 0 1em" }} alignItems="flex-end">
      <Grid item md={true} sm={true} xs={true}>
        {children}
      </Grid>
    </Grid>
  );
};
