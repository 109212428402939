export function appendFormData(formData, fields, newFiles, index) {
  formData.append("Content-Type", fields["Content-Type"]);
  formData.append("acl", fields.acl);
  formData.append("key", fields.key);
  formData.append("policy", fields.policy);
  formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
  formData.append("x-amz-credential", fields["x-amz-credential"]);
  formData.append("x-amz-date", fields["x-amz-date"]);
  formData.append("x-amz-signature", fields["x-amz-signature"]);
  formData.append("file", newFiles[index]);
}
