import Axios from "axios";
import { baseURL } from "../thunks";

export const getChecklists = (formIds) => async (dispatch) => {
  console.log("Getting checklists", formIds);
  const results = [];
  for (let fId of formIds) {
    const { data } = await Axios.get(baseURL + "documentation/documents/" + fId, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    results.push(data);
  }
  return results;
};
