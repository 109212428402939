import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeFolder } from "../../Reducers/thunks";
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import { documentationSelector } from "../../Reducers/DocumentationReducer";

export const DeleteFolderDialog = (props) => {
  const dispatch = useDispatch();
  const { onDelete, onClose, open, docToEdit } = props;
  const { id, name, kind } = docToEdit;
  const [areYouSure, setAreYouSure] = useState(false);
  const { currentFolder } = useSelector(documentationSelector);
  const onSubmit = (e) => {
    e.preventDefault(e);
    const res = dispatch(removeFolder({ id, kind }));
    res
      .then(() => {
        onDelete({ id, name, kind, err: false, msg: "", currentFolder, dispatch });
        setAreYouSure(false);
        onClose();
      })
      .catch(function (err) {
        if (err.response) {
          onClose();
          setAreYouSure(false);
          onDelete({ id, name, kind, err: true, msg: err.response.data.detail, currentFolder, dispatch });
        }
        return Promise.reject(err);
      });
  };
  const resetForm = () => {
    setAreYouSure(false);
  };
  return (
    <Dialog onClose={onClose} TransitionProps={{ onEntering: resetForm }} open={open}>
      <DialogTitle id="form-dialog-title">
        Are you {areYouSure ? "REALLY " : ""}sure you want to remove {name}?
      </DialogTitle>{" "}
      {areYouSure ? (
        <DialogActions>
          <Button onClick={onClose} color="primary">
            No, Don't Delete {name}
          </Button>
          <Button onClick={onSubmit} style={{ color: "#097c09" }}>
            Yes, Delete {name}
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => setAreYouSure(true)} style={{ color: "#097c09" }}>
            Remove {kind === "folder" ? "Folder" : "Document"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
