import { setSelectedThumbnail, setPageIndex } from "../../Documentation/Reducers/DocumentationReducer";
import { reorder } from "../helpers";

export function onDragEnd(result, page_index, dispatch) {
  if (!result.destination) return;
  if (result.destination.index === result.source.index) return;

  const newOrder = reorder(page_index, result.source.index, result.destination.index);
  const removed = page_index[result.source.index];
  dispatch(setPageIndex({ page_index: newOrder }));
  dispatch(setSelectedThumbnail({ value: newOrder.indexOf(removed) }));
}
