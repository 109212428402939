import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptions } from "../../Documentation/Reducers/Thunks/getSubscriptions";
import { setFavorites, setNotifications, setSubscriptions } from "../../Documentation/Reducers/DocumentationReducer";
import { getNotifications } from "../../Documentation/Reducers/Thunks/getNotifications";
import { LastLocation } from "../../Documentation/Helpers/LastLocation";
import { getFavorites } from "../../Documentation/Reducers/Thunks/getFavorites";
import { handle403 } from "./Helpers/handle403";

export function useNotificationsSubsFavs() {
  const dispatch = useDispatch();

  const last = LastLocation();

  const isRootFolder = last === "browse" ? "root" : last;
  const org = useSelector((state) => state.login.org);
  const notifications = useSelector((state) => state.documentation.notifications);
  const notificationLength = notifications.filter((notification) => !notification.read_at).length;

  useEffect(() => {
    if (org) {
      dispatch(getSubscriptions())
        .then((res) => {
          dispatch(setSubscriptions({ subscriptions: res.data }));
          dispatch(getNotifications())
            .then((res) => {
              dispatch(setNotifications({ notifications: res.data.results }));
              dispatch(getFavorites())
                .then((res) => dispatch(setFavorites({ favorites: res.data.results })))
                .catch((err) => handle403(err, dispatch));
            })
            .catch((err) => handle403(err, dispatch));
        })
        .catch((err) => handle403(err, dispatch));
    }
  }, [isRootFolder, notificationLength, dispatch, org]);
}
