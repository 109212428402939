import React from "react";
import { Typography } from "@mui/material";
import { styles } from "./styles";
import { formatDateTime } from "../../Helpers/helpers";

export function descriptionContainer(x) {
  var date = new Date(x.modified_at);
  var created = new Date(x.created_at);

  const isDocument =
    x.kind === "document" ? (
      <Typography variant="body2" color="textSecondary" component="p" style={styles.modified}>
        Modified: {formatDateTime(date)}
      </Typography>
    ) : (
      <Typography variant="body2" color="textSecondary" component="p" style={styles.modified}>
        Created: {formatDateTime(created)}
      </Typography>
    );

  return (
    <div style={styles.descriptionContainer}>
      <Typography variant="h5" component="h5" style={styles.filename}>
        {x.name}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p" style={styles.description}>
        {x.description}
      </Typography>
      {isDocument}
    </div>
  );
}
