import React from "react";
import { capitalize, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { widgetList } from "./widgetList";
import { useStyles } from "./useStyles";
import { setDidQuestionsChange } from "../../../Documentation/Reducers/DocumentationReducer";
import { setQuestion } from "../../../Editor/Reducers/EditorReducer";

export function WidgetDropdown({ widget, setWidget, id }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const didQuestionsChange = useSelector((state) => state.documentation.didQuestionsChange);
  const valueType = useSelector((state) => state.editor?.questions[id].valueType);
  return Array.isArray(widgetList[valueType]) ? (
    <FormControl style={{ marginTop: "1em" }} fullWidth className={classes.formControl}>
      <InputLabel id="select-label">Widget</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        label="Widget"
        value={widget || ""}
        defaultValue={"radio buttons"}
        onChange={(e) => {
          if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

          setWidget(e.target.value);
          dispatch(setQuestion({ id, widget: e.target.value }));
        }}
      >
        {widgetList[valueType].map((w) => {
          return (
            <MenuItem key={w} value={w}>{`${w
              .split(" ")
              .map((x) => capitalize(x))
              .join(" ")}`}</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  ) : (
    ""
  );
}
