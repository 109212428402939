import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { listActiveLoginSessions } from "../Thunks/listActiveLoginSessions";

export function useActiveLoginSessions(setDeviceList) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listActiveLoginSessions()).then((res) => {
      setDeviceList(res.data);
    });
  }, [dispatch, setDeviceList]);
}
