import { Grid, ListItem } from "@mui/material";

import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { setCopy } from "../../Reducers/DocumentationReducer";
import { Perm } from "../Dialogs/helpers";
import { RightClickMenu } from "./RightClickMenu";
import { styles } from "./styles";

export const FolderCard = ({
  icon,
  description,
  doctype,
  options,
  id,
  setShowPaste,
  link,
  history,
  user_permissions,
  popoverProps,
}) => {
  const dispatch = useDispatch();
  const permList = Perm.getLabelList(user_permissions);
  const [contextMenu, setContextMenu] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };
  const [{ isOver }, drop] = useDrop({
    accept: ["document", "folder"],
    drop: () => {
      return {
        name: description.props.name,
        kind: description.props.kind,
        doctype: doctype,
        id,
        allowedDropEffect: "any",
      };
    },
    canDrop: (item) => {
      return description.props.kind === "folder" && !(item.type === "folder" && item.id === id);
    },
    collect: (monitor) => ({
      //todo: causes a state update error sometimes, probably a lib bug
      //todo: https://github.com/react-dnd/react-dnd/issues/1516
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const [{ opacity }, drag] = useDrag({
    type: description.props.kind,
    item: { id, name: description.props.name, type: description.props.kind, doctype: doctype },
    end(item, monitor) {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        const isDropAllowed =
          dropResult.allowedDropEffect === "any" || dropResult.allowedDropEffect === dropResult.dropEffect;
        if (isDropAllowed) {
          dispatch(
            setCopy({
              copy: {
                name: description.props.name,
                description: description.props.description,
                id,
                kind: description.props.kind,
                targetFolderID: dropResult.id,
                targetFolderName: dropResult.name,
              },
            })
          );
          setShowPaste(true);
        }
      }
    },
    collect: (monitor) => {
      monitor.isDragging() ? setIsDragging(true) : setTimeout(() => setIsDragging(false), 100);
      // setIsDragging(false);
      return {
        opacity: monitor.isDragging() ? 0.4 : 1,
      };
    },
  });
  function attachRef(el) {
    if (description.props.kind === "document") {
      drag(el);
    } else {
      drag(el);
      drop(el);
    }
  }
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragging) {
      if (e.metaKey) {
        window.open(link, "_blank");
      } else {
        history.push(link);
      }
    }
  };
  return (
    <Grid
      item
      container
      xs={12}
      sm={6}
      md={4}
      lg={3}
      style={{ opacity }}
      {...(permList.includes("Write") ? { ref: attachRef } : {})}
      onContextMenu={handleContextMenu}
    >
      <ListItem disableGutters selected={isOver} button style={styles.item}>
        <Grid style={styles.icon} onClick={handleClick} item xs={3}>
          {icon}
        </Grid>
        <Grid style={styles.description} onClick={handleClick} item xs={8}>
          {description}
        </Grid>
        {options}
        <RightClickMenu popoverProps={popoverProps} handleClose={handleClose} contextMenu={contextMenu} />
      </ListItem>
    </Grid>
  );
};
