import axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";
export function b64toBlob(dataURI) {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
}
export const updateCurrentUser =
  ({ username, email, full_name, avatar, preview, fileName }) =>
  async () => {
    try {
      if (preview) {
        const file = b64toBlob(preview);
        console.log(file);
        return await axios
          .post(
            baseURL + "users/sign-avatar-upload",
            { file_size: file.size, filename: fileName, content_type: file.type },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(async (res) => {
            const { upload_id, url, fields } = res.data;

            const formData = new FormData();
            formData.append("Content-Type", fields["Content-Type"]);
            formData.append("acl", fields.acl);
            formData.append("key", fields.key);
            formData.append("policy", fields.policy);
            formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
            formData.append("x-amz-credential", fields["x-amz-credential"]);
            formData.append("x-amz-date", fields["x-amz-date"]);
            formData.append("x-amz-signature", fields["x-amz-signature"]);
            formData.append("file", file);
            return await axios({
              method: "post",
              url,
              data: formData,
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then(
                async (result) =>
                  await axios({
                    method: "put",
                    url: baseURL + "auth/me",
                    data: {
                      username,
                      email,
                      full_name,
                      avatar: upload_id,
                    },
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  })
              )
              .catch((e) => console.log(e.message));
          });
      } else {
        return await axios({
          method: "put",
          url: baseURL + "auth/me",
          data: {
            username,
            email,
            full_name,
            avatar,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      }
    } catch (e) {
      console.error(e);
      return e;
    }
  };
