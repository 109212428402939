import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedThumbnail } from "../../Documentation/Reducers/DocumentationReducer";
import { Canvas } from "../../Editor/Components/Annotator/Canvas";
import { AnnotationList } from "../AnnotationList";
import { useCurrentPage } from "../Hooks/useCurrentPage";
import { usePageDescription } from "../Hooks/usePageDescription";
import { GridStyles, styles } from "../styles";
import { AnnotationListResize } from "./AnnotationListResize";
import { ResizeBox } from "./ResizeBox";
import { Thumbnails } from "./Thumbnails";

export function PresentationViewer() {
  const selected = useSelector((state) => state.editor.selected);
  const description = useSelector((state) => state.editor.description);
  const shapes = useSelector((state) => state.editor.shapes);
  const selectedThumbnail = useSelector((state) => state.documentation.selectedThumbnail);
  const page_index = useSelector((state) => state.documentation.page_index);
  const dispatch = useDispatch();
  const [annotationWidth, setAnnotationWidth] = useState(document.body.offsetWidth * 0.2);
  const [dragging, setDragging] = useState(false);
  const firstLine = "fit-content";
  const [boxHeight, setBoxHeight] = useState(() => firstLine);
  const shapeRect = useRef(null);
  const mainRef = useRef(null);
  const heightRef = useRef(null);
  const [height, setHeight] = useState(0);
  useCurrentPage({ edit: false });
  usePageDescription();
  useEffect(() => {
    document.getElementById(selectedThumbnail)?.scrollIntoView();
  }, [selectedThumbnail]);
  const handleIncrementThumbnail = () =>
    page_index.length - 1 > selectedThumbnail && dispatch(setSelectedThumbnail({ value: selectedThumbnail + 1 }));
  const handleDecrementThumbnail = () =>
    selectedThumbnail > 0 && dispatch(setSelectedThumbnail({ value: selectedThumbnail - 1 }));
  useEffect(() => {
    let current = mainRef?.current;
    const observer = new ResizeObserver((entries) => {
      if (heightRef) {
        setHeight(entries[0].contentRect.height);
      }
    });
    observer.observe(current);
    return () => {
      if (current) {
        observer.unobserve(current);
      } else {
        observer.unobserve();
      }
    };
  }, [mainRef]);

  return (
    <div ref={mainRef} id="mainGrid" style={GridStyles(shapes, annotationWidth)}>
      <div
        style={{
          height: height ? height : 0,
          display: "flex",
          flexDirection: "column",
          // overflowY: "auto",
          borderRight: "1px solid #c8ccd0",
          fontSize: "small",
        }}
      >
        <IconButton onClick={handleDecrementThumbnail} style={{ maxHeight: "2em", width: "100%" }}>
          <ArrowDropUp />
        </IconButton>
        <div
          id="thumbnails"
          style={{
            display: "block",
            height: "100%",
            overflowY: "auto",
            WebkitOverflowScrolling: "touch",
            fontSize: "small",
          }}
        >
          <Thumbnails />
        </div>
        <IconButton onClick={handleIncrementThumbnail} style={{ maxHeight: "2em", width: "100%" }}>
          <ArrowDropDown />
        </IconButton>
      </div>
      <div style={styles.overflowContainer}>
        <div style={styles.canvasContainer} id="canvasContainer">
          <Canvas view dragging={dragging} boxHeight={boxHeight} annotationWidth={annotationWidth} />
          {selected || description ? (
            <ResizeBox
              setDragging={setDragging}
              firstLine={firstLine}
              boxHeight={boxHeight}
              setBoxHeight={setBoxHeight}
            >
              <Markdown
                linkTarget="_blank"
                source={selected ? (selected.description ? selected.description : "-- empty --") : description}
              />
            </ResizeBox>
          ) : (
            ""
          )}
        </div>
      </div>
      {shapes && shapes.length ? (
        <AnnotationListResize
          shapeRect={shapeRect}
          annotationWidth={annotationWidth}
          setAnnotationWidth={setAnnotationWidth}
          id={"AnnotationListResize"}
        >
          <AnnotationList shapeRect={shapeRect} />
        </AnnotationListResize>
      ) : (
        ""
      )}
    </div>
  );
}
