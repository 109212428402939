import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { reducers } from "./reducers";
const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers,
});
export const loginSelector = (state) => state.login;

export const {
  loading,
  tokenLoading,
  tokenReceived,
  logoutUser,
  setLoggedInUser,
  setAssignments,
  resetPasswordSent,
  resetPasswordReceived,
  isTokenExpired,
  successfulReset,
} = loginSlice.actions;

export const login = loginSlice.reducer;
