import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { DndProvider } from "react-dnd";
import { usePreview } from "react-dnd-preview";
import { TouchBackend } from "react-dnd-touch-backend";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useChangeFolder } from "../../Hooks/useChangeFolder";
import { useSortFolder } from "../../Hooks/useSortFolder";
import { useStyles } from "../../styles";
import { CardDescription } from "./CardDescription";
import { FolderCard } from "./FolderCard";
import { generateKey } from "./Helpers/generateKey";
import { isKindAndId } from "./Helpers/isKindAndId";
import { OptionsPopover } from "./OptionsPopover";
import { selectBadges } from "./selectBadges";
import { SelectIcon } from "./SelectIcon";

export const FolderCards = ({
  setShowDeleteDoc,
  setDocToEdit,
  setShowEdit,
  onCopy,
  isRootFolder,
  setShowPaste,
  setOpenAssignments,
}) => {
  const MyPreview = () => {
    const { display, item, style } = usePreview();
    if (!display) {
      return null;
    }
    return (
      <div className="item-list__item" style={style}>
        {SelectIcon(item)}
      </div>
    );
  };
  const classes = useStyles();
  const results = useSelector((state) => state.documentation.currentFolder.results);
  const loading = useSelector((state) => state.documentation.loading);
  const subscriptions = useSelector((state) => state.documentation.subscriptions);
  const favorites = useSelector((state) => state.documentation.favorites);

  const history = useHistory();
  useChangeFolder();
  useSortFolder(isRootFolder);

  const Cards = results.map((folder, index) => {
    const {
      name,
      description,
      id,
      kind,
      version,
      modified_at,
      created_at,
      created_by_username,
      modified_by_username,
      doctype,
      subscription_options,
      user_permissions,
    } = folder;
    const popoverProps = {
      name,
      description,
      id,
      kind,
      doctype,
      version,
      modifiedAt: modified_at,
      createdAt: created_at,
      created_by_username,
      modified_by_username,
      setShowDeleteDoc,
      setDocToEdit,
      setShowEdit,
      onCopy,
      subscription_options,
      user_permissions,
      setOpenAssignments,
    };
    const isSubbed = subscriptions.reduce(isKindAndId(kind, id), false);
    const isFav = favorites.reduce(isKindAndId(kind, id), false);
    const key = generateKey(name + description + id + kind + version + modified_at + created_at + index);
    const icon = selectBadges(isSubbed, isFav, doctype);
    return (
      <FolderCard
        key={key}
        icon={icon}
        description={
          <CardDescription
            name={name}
            createdAt={created_at}
            modifiedAt={modified_at}
            description={description}
            kind={kind}
          />
        }
        doctype={doctype}
        popoverProps={popoverProps}
        options={<OptionsPopover {...popoverProps} />}
        link={kind === "folder" ? `/documentation/browse/${id}` : `/documentation/view/${id}`}
        history={history}
        id={id}
        setShowPaste={setShowPaste}
        user_permissions={user_permissions}
      />
    );
  });
  return (
    <DndProvider
      backend={TouchBackend}
      options={{
        enableTouchEvents: true,
        enableMouseEvents: true,
        delayTouchStart: 500,
      }}
      context={document}
    >
      <Paper className={classes.folderPaper}>
        {loading ? (
          <div className={classes.loadingEmpty}>
            <div>
              <CircularProgress />
            </div>
          </div>
        ) : results.length ? (
          <Grid className={classes.folderContainer} container>
            <MyPreview />
            {Cards}
          </Grid>
        ) : (
          <div className={classes.loadingEmpty}>
            <div>
              <Typography>- - Empty - -</Typography>
            </div>
          </div>
        )}
      </Paper>
    </DndProvider>
  );
};
