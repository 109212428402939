import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isOrderChanged, usePrevious } from "../Helpers/helpers";
import { LastLocation } from "../Helpers/LastLocation";
import { setSearch } from "../Reducers/DocumentationReducer";
import { getFolderContents } from "../Reducers/thunks";
export function useChangeFolder() {
  const description = useSelector((state) => state.documentation.currentFolder.folder.description);
  const name = useSelector((state) => state.documentation.currentFolder.folder.name);
  const id = useSelector((state) => state.documentation.currentFolder.folder.id);
  const user_permissions = useSelector((state) => state.documentation.currentFolder.folder.user_permissions);
  const ancestors = useSelector((state) => state.documentation.currentFolder.ancestors);
  const previousResults = useSelector((state) => state.documentation.currentFolder.results);
  const search = useSelector((state) => state.documentation.search);
  const rootID = useSelector((state) => state.documentation.rootID);
  const order = useSelector((state) => state.documentation.order, isOrderChanged);
  const dispatch = useDispatch();
  const last = LastLocation();
  const previousSearch = usePrevious(search);
  const isLastBrowse = last === "browse" ? rootID : last;
  const isSearchReset = previousSearch !== search && search === "";
  const previousLast = usePrevious(last);
  const isNewFolder = previousLast !== last || parseInt(isLastBrowse) !== id;
  useEffect(() => {
    if (id && isNewFolder) {
      dispatch(setSearch({ search: "" }));
      dispatch(
        getFolderContents({
          id,
          q: search,
          ancestors,
          targetId: isLastBrowse,
          previousResults,
          order,
          name,
          description,
          user_permissions,
        })
      );
    }
  }, [
    dispatch,
    search,
    isLastBrowse,
    order,
    description,
    name,
    ancestors,
    previousResults,
    user_permissions,
    isNewFolder,
    id,
    isSearchReset,
  ]);
}
