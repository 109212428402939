export function IsSquareOrCircle(y, scaleY, x, scaleX, width, height, color, strokeWidth, shape, rotation) {
  return {
    borderStyle: "solid",
    position: "absolute",
    top: y * scaleY,
    left: x * scaleX,
    width: width * scaleX,
    height: height * scaleY,
    borderColor: color,
    borderWidth: `${strokeWidth}px`,
    borderRadius: shape === "rectangle" ? "0%" : "100%",
    transform: `rotate(${rotation}deg)`,
    cursor: "pointer",
  };
}
