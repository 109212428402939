import React, { useCallback, useEffect } from "react";
import { DragHandle as DragHandleIcon } from "@mui/icons-material/";
import styled from "styled-components";
import { useSelector } from "react-redux";

const Box = styled.div`
  background: padding-box rgb(200, 204, 208);
  height: 12px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  cursor: row-resize;
  width: 100%;
  position: relative;
  text-align: center;
  &:hover {
    transition: all 0.3s ease;
    opacity: 0.4;
    border-top: 5px solid rgb(200, 204, 208);
    border-bottom: 5px solid rgb(200, 204, 208);
  }
`;
const DragHandle = styled(DragHandleIcon)`
  position: absolute;
  top: -0.5em;
  border: 1px solid rgb(200, 204, 208);
  background: white;
  width: 3em;
  height: 1em;
  left: calc(50% - 1.5em);
`;
const minBoxHeight = 15;
export const ResizeBox = ({ children, editor, setDragging, firstLine, boxHeight, setBoxHeight }) => {
  const currentPage = useSelector((state) => state.documentation.currentPage);

  const maxBoxHeight = document.body.offsetHeight;
  const handleMouseDown = (e) => {
    if (e.cancelable) {
      e.preventDefault();
      e.stopPropagation();
    }
    setDragging(true);
    if (e.touches) {
      document.addEventListener("touchend", handleMouseUp, true);
      document.addEventListener("touchmove", handleMouseMove, true);
    } else {
      document.addEventListener("mouseup", handleMouseUp, true);
      document.addEventListener("mousemove", handleMouseMove, true);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
    document.removeEventListener("touchend", handleMouseUp, true);
    document.removeEventListener("touchmove", handleMouseMove, true);
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (e.cancelable) {
        e.preventDefault();
        e.stopPropagation();
      }
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;
      const newHeight = document.body.offsetHeight - clientY;
      if (newHeight > minBoxHeight && newHeight < maxBoxHeight) {
        setBoxHeight(newHeight);
      }
    },
    [setBoxHeight, maxBoxHeight]
  );

  useEffect(() => {
    setBoxHeight(firstLine);
  }, [setBoxHeight, firstLine, currentPage]);

  return (
    <div style={BoxContainerStyle(boxHeight)}>
      <Box
        onDoubleClick={() => setBoxHeight(boxHeight > minBoxHeight ? minBoxHeight : firstLine)}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        role="presentation"
      >
        <DragHandle />
      </Box>

      <span style={spanStyle(editor)}>{children}</span>
    </div>
  );
};

function BoxContainerStyle(boxHeight) {
  return { height: boxHeight, display: "flex", flexDirection: "column" };
}

function spanStyle(editor) {
  return {
    ...(!editor && { overflowY: "auto" }),
    padding: "0.5em",
    textAlign: "left",
    background: "white",
    height: "inherit",
    overflowWrap: "anywhere",
    fontSize: "1.2em",
  };
}
