import { Button } from "@mui/material";
import React from "react";
import { styles } from "../../styles";
import DownloadIcon from "@mui/icons-material/Download";

export function MediaContainer({ media, content_type }) {
  const parsedFilename = media?.split("?")[0].substring(media.lastIndexOf("/") + 1) || "download";
  return (
    <div style={{ marginBottom: "1em", width: "100%" }}>
      {media ? (
        /video/.test(content_type) ? (
          <video style={styles.media} controls>
            <source src={media} type={content_type} />
          </video>
        ) : /image/.test(content_type) ? (
          <img src={media} style={styles.media} alt="imgAlt" />
        ) : (
          <Button
            startIcon={<DownloadIcon />}
            variant="contained"
            style={{ width: "fit-content" }}
            onClick={() => {
              const link = document.createElement("a");
              link.download = parsedFilename || "download";
              link.href = media;
              link.click();
            }}
          >
            {parsedFilename}
          </Button>
        )
      ) : (
        ""
      )}
    </div>
  );
}
