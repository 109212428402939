export const initialState = {
  error: "",
  loading: false,
  rows: [],
  total: 0,
  currentUser: {
    username: "",
    password: "",
    email: "",
    is_active: null,
    is_org_admin: null,
    roles: [],
    full_name: "",
    avatar: "",
    id: null,
  },
  fileInput: {
    upload_id: undefined,
    url: undefined,
    type: undefined,
    name: undefined,
  },
};
