import { last30days, now } from "../../Viewer/Components/SpreadsheetViewer/helpers";

export const spreadsheet = {
  results: null,
  open: false,
  media: null,
  comments: null,
  total: null,
  rowsPerPage: 10,
  page: 0,
  filtered: {
    username: { open: false, list: [], text: "", order: "desc" },
    datetime: { open: false, start: last30days(), end: now(), order: "desc" },
  },
};

export const currentFolder = {
  folder: {
    id: null,
    name: "",
    description: "",
    user_permissions: null,
  },
  ancestors: [],
  results: [],
  total: null,
};
export const initialState = {
  loading: false,
  error: false,
  errorMessage: "",
  rootID: null,
  rootResults: null,
  currentFolder,
  currentDocumentHistory: null,
  currentDocument: null,
  currentDocumentChanges: null,
  didQuestionsChange: false,
  currentPage: null,
  selectedThumbnail: 0,
  trash: [],
  copy: null,
  fileObj: null,
  fileObjQueue: [],
  uploadProgress: null,
  queueLength: 0,
  search: "",
  searching: false,
  prevPage: null,
  prevLocation: null,
  page_index: [],
  question_index: [],
  alert: null,
  severity: null,
  order: {
    name: "",
    kind: "-",
    created_at: "",
    modified_at: "",
  },
  spreadsheet,
  singleRow: null,
  subscriptions: [],
  notifications: [],
  favorites: [],
  isBlocking: false,
  isBlockingViewer: false,
};
