import React, { useState } from "react";
import { Button, Dialog, DialogContent, DialogActions, Typography, Grid, Divider } from "@mui/material";
import { ReactComponent as InvawareColor } from "./Invaware Icon.svg";
import axios from "axios";
import preval from "preval.macro";

export function isClosedOrNull(onClose, a) {
  return onClose ? a : null;
}

export default function AboutDialog({ open, onClose }) {
  const [serverInfo, setServerInfo] = useState({});

  const clientBuildTime = new Date(parseInt(preval`module.exports = new Date().getTime()`)).toLocaleString();

  return (
    <Dialog
      onClose={onClose}
      TransitionProps={{ onEntering: loadServerInfo(setServerInfo) }}
      aria-labelledby="about-dialog-title"
      open={open}
    >
      <DialogContent dividers>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={6}>
          <Grid item xs={3}>
            <InvawareColor width="100%" style={{ margin: "auto" }} />
          </Grid>
          <Grid item xs={9} style={{ fontSize: "1em" }}>
            <Typography gutterBottom>
              <strong>{process.env.REACT_APP_NAME}</strong>
              <br />
              Copyright 2021 Invaware Corporation. <br />
              All rights reserved.
            </Typography>
            <Divider />
            <div>
              <strong>Client Version: </strong>v{process.env.REACT_APP_VERSION}
              {isBuildCommit()}
              &nbsp;({clientBuildTime})
            </div>
            <div>
              <strong>Server Version: </strong>v{serverInfo.version}
              &nbsp;({serverInfo.time})
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export function isBuildCommit() {
  return process.env.REACT_APP_BUILD_COMMIT ? `-${process.env.REACT_APP_BUILD_COMMIT}` : "";
}

export function loadServerInfo(setServerInfo) {
  return () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}_build-info`).then((response) => {
      response.data.time = new Date(response.data.time).toLocaleString();
      setServerInfo(response.data);
    });
  };
}
