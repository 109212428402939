export function findNode(tree, id) {
  for (let node of tree) {
    if (node.id === id) {
      return [tree, node];
    } else if (node.children && node.children.length > 0) {
      let [parent, child] = findNode(node.children, id);
      if (child) {
        return [parent, child];
      }
    }
  }
  return [null, null];
}
