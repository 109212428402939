import { useEffect } from "react";
import { setCurrentPage } from "../../Documentation/Reducers/DocumentationReducer";
import { useSelector, useDispatch } from "react-redux";
import { setPageDescription, setShapes } from "../../Editor/Reducers/EditorReducer";

export function useCurrentPage({ edit }) {
  const currentDocument = useSelector((state) => state.documentation.currentDocument);
  const page_index = useSelector((state) => state.documentation.page_index);
  const selectedThumbnail = useSelector((state) => state.documentation.selectedThumbnail);
  const fileObjQueue = useSelector((state) => state.documentation.fileObjQueue);
  const currentDocumentChanges = useSelector((state) => state.documentation.currentDocumentChanges);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentDocument) {
      const pageID = page_index[selectedThumbnail];
      const isDocumentEmpty = page_index.length === 0;
      const isFileObjQueue = fileObjQueue.find((x) => x.pageID === pageID);
      const existingPage = currentDocument?.contents?.pages[pageID];
      const currentPage = isDocumentEmpty
        ? null
        : isFileObjQueue
        ? isFileObjQueue
        : {
            ...existingPage, // spread existing page
            ...(currentDocumentChanges && currentDocumentChanges[pageID] // are there changes on the current page?
              ? {
                  shapes: currentDocumentChanges[pageID]?.shapes || existingPage?.shapes,
                  description:
                    currentDocumentChanges[pageID]?.description || currentDocumentChanges[pageID]?.description === ""
                      ? currentDocumentChanges[pageID]?.description
                      : existingPage?.description,
                  media: currentDocumentChanges[pageID]?.media || existingPage?.media,
                  content_type: currentDocumentChanges[pageID]?.content_type || existingPage?.content_type,
                  filename: currentDocumentChanges[pageID]?.filename || existingPage?.filename,
                  size: currentDocumentChanges[pageID]?.size || existingPage?.size,
                  lastModified: currentDocumentChanges[pageID]?.lastModified || existingPage?.lastModified,
                }
              : {}),
            pageID,
          };
      dispatch(
        setCurrentPage({
          currentPage,
        })
      );
      if (currentPage) {
        dispatch(setPageDescription({ description: currentPage.description }));
      }

      if (/image/.test(currentPage?.content_type)) {
        dispatch(
          setShapes({
            shapes: currentPage.shapes,
            dragging: false,
          })
        );
      } else {
        dispatch(
          setShapes({
            shapes: [],
            dragging: false,
          })
        );
      }
    }
  }, [dispatch, page_index, selectedThumbnail, currentDocument, fileObjQueue, currentDocumentChanges, edit]);
}
