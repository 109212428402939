import {
  loading,
  received,
  setAlert,
  setCurrentDocument,
  setDidQuestionsChange,
  setIsBlocking,
  setQuestionIndex,
  setUploadProgress,
} from "../../../Documentation/Reducers/DocumentationReducer";
import { publishQuestions } from "../../../Documentation/Reducers/Thunks/publishQuestions";
import { setQuestions, setSaving } from "../../../Editor/Reducers/EditorReducer";
import { uploadNewMediaQuestions } from "../uploadNewMediaQuestions";

export function handleForm(
  dispatch,
  questions,
  currentDocument,
  question_index,
  changelog,
  onConfirm,
  onCancel,
  setShowPasswordModal
) {
  dispatch(loading());
  dispatch(setUploadProgress({ uploadProgress: 10 }));

  dispatch(uploadNewMediaQuestions({ questions, currentDocument, onCancel, setShowPasswordModal }))
    .then((newMedia) => {
      dispatch(
        publishQuestions({
          currentDocument,
          newMedia,
          question_index,
          questions,
          changelog,
          onCancel,
          setShowPasswordModal,
        })
      )
        .then((res) => {
          if (!res) {
            dispatch(setIsBlocking({ isBlocking: false }));
            dispatch(setSaving({ saving: false }));
            dispatch(received({ error: false }));
            dispatch(setUploadProgress({ reset: true }));
            onCancel();
            return;
          } else {
            onConfirm();
            dispatch(setUploadProgress({ uploadProgress: 100 }));
            dispatch(setIsBlocking({ isBlocking: false }));
            dispatch(setSaving({ saving: false }));
            dispatch(setDidQuestionsChange({ isChanged: false }));
            dispatch(setCurrentDocument({ doc: res.data, isEditor: true }));
            dispatch(setQuestions({ reset: true }));
            dispatch(setUploadProgress({ reset: true }));
            dispatch(setQuestionIndex({ reset: true }));
            dispatch(setAlert({ alert: "Publish Successful!", severity: "success" }));

            dispatch(received({ error: false }));
          }
        })
        .catch((e) => console.error(e));
    })
    .catch((e) => console.error(e));
}
