import { Link } from "react-router-dom";
import { FolderOpenTwoTone, ListAltTwoTone } from "@mui/icons-material/";
import { styles } from "../styles";
import { isDoctypeIcon } from "./isDoctypeIcon";

export const activityResponse = (res) => {
  const { activity_type, username, context_data } = res;
  if (activity_type === "DataSubmitted") {
    return (
      <span>
        {username} submitted <span style={{ color: "red" }}>{context_data.validation_errors ? "incorrect" : ""}</span>{" "}
        data to <ListAltTwoTone style={styles.sheet} />
        <Link to={`/documentation/results/${context_data.form_id}`}>{context_data.form_name}</Link>
      </span>
    );
  }
  if (activity_type === "FolderCreated") {
    return (
      <span>
        {username} created <FolderOpenTwoTone style={styles.folder} />
        <Link to={`/documentation/browse/${context_data.id}`}>{context_data.name}</Link> in {`.${context_data.path}`}
      </span>
    );
  }
  if (activity_type === "FolderUpdated") {
    if (context_data.name !== context_data.previous_name) {
      return (
        <span>
          {username} renamed <FolderOpenTwoTone style={styles.folder} />
          <Link to={`/documentation/browse/${context_data.id}`}>{context_data.previous_name}</Link> to{" "}
          <Link to={`/documentation/browse/${context_data.id}`}>{context_data.name}</Link>
        </span>
      );
    } else if (context_data.description !== context_data.previous_description) {
      return (
        <span>
          {username} changed description of <FolderOpenTwoTone style={styles.folder} />
          <Link to={`/documentaion/browse/${context_data.id}`}>{context_data.name}</Link>
          {context_data.previous_description} to {context_data.description}
        </span>
      );
    } else {
      return (
        <span>
          {username} updated name and description of <FolderOpenTwoTone style={styles.folder} />
          <Link to={`/documentation/browse/${context_data.id}`}>{context_data.name}</Link>
        </span>
      );
    }
  }
  if (activity_type === "FolderDeleted") {
    return (
      <span>
        {username} deleted <FolderOpenTwoTone style={styles.folder} />
        <Link to={"/documentation/trash/1"}>{context_data.name}</Link>
      </span>
    );
  }
  if (activity_type === "FolderMoved") {
    return (
      <span>
        {username} moved <FolderOpenTwoTone style={styles.folder} />
        <Link to={`/documentation/browse/${context_data.id}`}>{context_data.name}</Link> from{" "}
        {`.${context_data.previous_path}`} to {`.${context_data.path}`}
      </span>
    );
  }
  if (activity_type === "FolderCopied") {
    return (
      <span>
        {username} copied <FolderOpenTwoTone style={styles.folder} />
        <Link to={`/documentation/browse/${context_data.id}`}>{context_data.name}</Link> from{" "}
        {`.${context_data.source_folder_path}`} to {`.${context_data.path}`}
      </span>
    );
  }
  if (activity_type === "FolderACLUpdated") {
    return (
      <span>
        {username} updated permissions for <FolderOpenTwoTone style={styles.folder} />
        <Link to={`/documentation/browse/${context_data.id}`}>{context_data.name}</Link>
      </span>
    );
  }
  if (activity_type === "DocumentCreated") {
    return (
      <span>
        {username}
        {" created "} {isDoctypeIcon(context_data)}
        <Link to={`/documentation/view/${context_data.id}`}>{context_data.name}</Link> in {`.${context_data.path}`}
      </span>
    );
  }
  if (activity_type === "DocumentMoved") {
    return (
      <span>
        {username} moved {isDoctypeIcon(context_data)}
        <Link to={`/documentation/view/${context_data.id}`}>{context_data.name}</Link> from {context_data.source_path}{" "}
        to {`.${context_data.path}`}
      </span>
    );
  }
  if (activity_type === "DocumentCopied") {
    return (
      <span>
        {username} copied {isDoctypeIcon(context_data)}
        <Link to={`/documentation/view/${context_data.id}`}>{context_data.name}</Link> from{" "}
        {context_data.source_document_path} to {`.${context_data.path}`}
      </span>
    );
  }
  if (activity_type === "DocumentDeleted") {
    return (
      <span>
        {username} deleted {isDoctypeIcon(context_data)}
        <Link to={"/documentation/trash/1"}>{context_data.name}</Link> from {`.${context_data.path}`}
      </span>
    );
  }
  if (activity_type === "DocumentACLUpdated") {
    return (
      <span>
        {username} updated permissions for {isDoctypeIcon(context_data)}
        <Link to={`/documentation/view/${context_data.id}`}>{context_data.name}</Link> in {`.${context_data.path}`}
      </span>
    );
  }
  if (activity_type === "DocumentUpdated") {
    return (
      <span>
        {username} updated {isDoctypeIcon(context_data)}
        <Link to={`/documentation/${context_data.id}/history`}>{context_data.name}</Link> in {`.${context_data.path}`}
      </span>
    );
  }
  if (activity_type === "UserCreated") {
    return (
      <span>
        {username} created a new user: "<Link to={`/admin/users/${context_data.id}`}>{context_data.username}</Link>"
      </span>
    );
  }
  if (activity_type === "UserDeleted") {
    return (
      <span>
        {username} deleted user: "{context_data.username}"
      </span>
    );
  }
  if (activity_type === "UserUpdated") {
    return (
      <span>
        {username} updated user: "<Link to={`/admin/users/${context_data.id}`}>{context_data.username}</Link>"
      </span>
    );
  }
  if (activity_type === "RoleCreated") {
    return (
      <span>
        {username} created a new role: "<Link to={`/admin/roles/${context_data.id}`}>{context_data.name}</Link>"
      </span>
    );
  }
  if (activity_type === "RoleDeleted") {
    return (
      <span>
        {username} deleted role: "{context_data.name}"
      </span>
    );
  }
  if (activity_type === "RoleUpdated") {
    return (
      <span>
        {username} updated role: "<Link to={`/admin/roles/${context_data.id}`}>{context_data.name}</Link>"
      </span>
    );
  }
};
