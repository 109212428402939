import Axios from "axios";
import history from "../../../../Utils/history";
import { received, setAlert } from "../DocumentationReducer";
import { baseURL } from "../thunks";

export const getChecklist =
  ({ id, currentFolderId }) =>
  async (dispatch) => {
    console.log("Getting checklist", id);
    try {
      const { data } = await Axios.get(baseURL + "documentation/documents/" + id, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      dispatch(setAlert({ alert: error.response.data.detail, severity: "warning" }));
      history.push("/documentation/browse/" + currentFolderId);
      dispatch(received({ error: true }));
    }
  };
