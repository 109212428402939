import { Avatar, styled, Box } from "@mui/material";

export function invertHex(hex) {
  return (Number(`0x1${hex}`) ^ 0xffffff).toString(16).substr(1).toUpperCase();
}
export function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return hash;
}
export const formatDate = (str) => {
  return new Date(str).toLocaleString();
};
export function intToRGB(i) {
  let c = (i & 0x00ffffff).toString(16).toUpperCase();
  return "00000".substring(0, 6 - c.length) + c;
}
export const initials = (full_name) =>
  full_name
    .split(/(?:\s+)/g)
    .map((w) => w.charAt(0))
    .join("")
    .toUpperCase();
export const avatarColors = [
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#FF9800",
  "#FF5722",
];
const countLetters = (name) =>
  name.split("").reduce((a, _, i) => {
    a += name.charCodeAt(i);
    return a;
  }, 0);
export const selectColor = (name) => avatarColors[countLetters(name) % avatarColors.length];

export const BigAvatar = styled(Avatar)`
  width: 2em;
  height: 2em;
  background: ${(props) => selectColor(props.children)};
  color: "white";
`;

export const avatar = (row) =>
  row && (
    <BigAvatar
      alt={row.full_name}
      src={row.avatar && row.avatar}
      style={{
        background: selectColor(initials(row.full_name)),
        color: "white",
      }}
    >
      {!row.avatar ? initials(row.full_name) : ""}
    </BigAvatar>
  );

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`user-tabpanel-${index}`}
      aria-labelledby={`user-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `user-tab-${index}`,
    "aria-controls": `user-tabpanel-${index}`,
  };
}

export const isValidEmail = /^\S+@\S+\.\S+$/;
