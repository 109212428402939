import axios from "axios";
import history from "../../../Utils/history";
import { requestLoading, requestReceived } from "../Reducers/AdminReducer";
import { baseURL } from "../Reducers/thunks";

export const deleteUser =
  ({ id }) =>
  async (dispatch) => {
    dispatch(requestLoading());
    try {
      await axios.delete(baseURL + `users/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(requestReceived({ detail: "requestReceived" }));
      history.push("/admin/users");
    } catch (error) {
      dispatch(requestReceived({ detail: "unable to remove" }));
      console.error(error);
    }
  };
