import React from "react";
import { TextField, MenuItem, ListItemIcon, ListItemText, Popper, Fade, Paper, ClickAwayListener } from "@mui/material";
import { FindInPage, Search } from "@mui/icons-material/";
import history from "../../Utils/history";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "./Reducers/SearchReducer";
import { getSearch } from "./Reducers/Thunks/getSearch";

export function GlobalSearchField({ handleFocus, anchorEl, handleClose, divRef }) {
  const dispatch = useDispatch();
  const query = useSelector((state) => state.search.q);
  const open = Boolean(anchorEl);
  const id = open ? "global-search-popover" : undefined;
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div ref={divRef} style={{ marginLeft: "auto", background: "#fafafa" }}>
        <TextField
          placeholder="Search..."
          id="global-search"
          size={"small"}
          aria-describedby={id}
          onFocus={handleFocus}
          onChange={(e) => dispatch(setSearch({ q: e.target.value }))}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              dispatch(getSearch({ q: query })).then((res) => {
                dispatch(setSearch({ results: res.data.results }));
              });
              history.push("/documentation/search");
            }
          }}
          value={query || ""}
          autoComplete="off"
          InputProps={{
            startAdornment: <Search />,
          }}
        />
        <Popper
          anchorEl={anchorEl}
          open={open}
          placement={"bottom"}
          onClose={handleClose}
          transition
          style={{ zIndex: 10000 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper id={id}>
                <MenuItem>
                  <ListItemIcon>
                    <FindInPage color="primary" />
                  </ListItemIcon>
                  <ListItemText onClick={() => history.push("/documentation/search")} primary="Advanced Search" />
                </MenuItem>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
