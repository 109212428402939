import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setAlert, setFileObjQueue, setSelectedThumbnail } from "../../Documentation/Reducers/DocumentationReducer";
import { getDocument } from "../../Documentation/Reducers/thunks";
import { Canvas } from "../../Editor/Components/Annotator/Canvas";
import { EditorToolbar } from "../../Editor/Components/EditorToolbar/EditorToolbar";
import TextEditor from "../../Editor/Components/TextEditor/TextEditor";
import { AnnotationList } from "../AnnotationList";
import { useTrackDocumentChanges } from "../Hooks/useTrackDocumentChanges";
import { GridStyles, styles } from "../styles";
import { AnnotationListResize } from "./AnnotationListResize";
import { formatNewFiles } from "./formatNewFiles";
import { RemovePageDialog } from "./RemovePageDialog";
import { ReplacePageDialog } from "./ReplacePageDialog";
import { ResizeBox } from "./ResizeBox";
import { Thumbnails } from "./Thumbnails";

export function PresentationEditor() {
  const dispatch = useDispatch();
  const shapes = useSelector((state) => state.editor.shapes);
  const selectedThumbnail = useSelector((state) => state.documentation.selectedThumbnail);
  const [dragging, setDragging] = useState(false);
  const firstLine = 270;
  const [boxHeight, setBoxHeight] = useState(() => firstLine);
  const [annotationWidth, setAnnotationWidth] = useState(200);
  const [showRemovePage, setShowRemovePage] = useState(false);
  const [showReplacePage, setShowReplacePage] = useState(false);
  let { id } = useParams();
  useTrackDocumentChanges();
  useEffect(() => {
    document.getElementById(selectedThumbnail)?.scrollIntoView();
  }, [selectedThumbnail]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles) {
        const files = formatNewFiles(acceptedFiles);
        dispatch(setFileObjQueue({ files }));
      }
    },
    [dispatch]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ noClick: true, onDrop });

  const {
    getRootProps: getRootPropsReplace,
    getInputProps: getInputPropsReplace,
    isDragActive: isDragActiveReplace,
    acceptedFiles,
  } = useDropzone({
    noClick: true,
    onDrop: () => {
      setShowReplacePage(true);
    },
  });
  return (
    <>
      <div style={GridStyles(shapes, annotationWidth)}>
        <div
          {...getRootProps()}
          style={{ ...styles.thumbnailContainer, backgroundColor: isDragActive ? "beige" : "initial" }}
        >
          <input {...getInputProps()} />

          <Thumbnails isEditor />
        </div>
        <div style={styles.imageContainer}>
          <div style={styles.editor}>
            <EditorToolbar setShowRemovePage={setShowRemovePage} />
            <Canvas
              dragging={dragging}
              boxHeight={boxHeight}
              annotationWidth={annotationWidth}
              setShowReplacePage={setShowReplacePage}
              getRootPropsReplace={getRootPropsReplace}
              getInputPropsReplace={getInputPropsReplace}
              isDragActiveReplace={isDragActiveReplace}
            />
            <ResizeBox
              setDragging={setDragging}
              firstLine={firstLine}
              boxHeight={boxHeight}
              setBoxHeight={setBoxHeight}
            >
              <TextEditor styles={styles.TextEditor} />
            </ResizeBox>
          </div>
        </div>
        {shapes && shapes.length ? (
          <AnnotationListResize annotationWidth={annotationWidth} setAnnotationWidth={setAnnotationWidth} isEditor>
            <AnnotationList isEditor />
          </AnnotationListResize>
        ) : (
          ""
        )}
      </div>
      {showRemovePage && (
        <RemovePageDialog
          open={showRemovePage}
          onClose={() => setShowRemovePage(false)}
          onRemoved={() => {
            dispatch(setAlert({ severity: "success", alert: "Page Removed" }));
            dispatch(setSelectedThumbnail({ value: selectedThumbnail === 0 ? 0 : selectedThumbnail - 1 }));
            dispatch(getDocument({ id, isEditor: true }));
          }}
          id={id}
          selectedThumbnail={selectedThumbnail}
        />
      )}
      {showReplacePage && (
        <ReplacePageDialog
          open={showReplacePage}
          onClose={() => setShowReplacePage(false)}
          acceptedFiles={acceptedFiles}
          onReplace={() => {
            dispatch(setAlert({ severity: "success", alert: "Page Replaced" }));
            dispatch(getDocument({ id, isEditor: true }));
          }}
        />
      )}
    </>
  );
}
