import React from "react";
import { IconButton } from "@mui/material";
import { Add, Remove, ArrowDownward, ArrowUpward } from "@mui/icons-material/";

export function PermissionsFooter(handleAddPerm, handleRemovePerm, handlePermOrder) {
  return () => {
    return (
      <div
        style={{
          borderTop: "1px solid rgba(224, 224, 224, 1)",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <IconButton onClick={handleAddPerm} size="small" color="primary" aria-label="add" component="span">
            <Add />
          </IconButton>
          <IconButton onClick={handleRemovePerm} size="small" color="primary" aria-label="remove" component="span">
            <Remove />
          </IconButton>
        </div>
        <div>
          <IconButton
            onClick={() => handlePermOrder("left")}
            size="small"
            color="primary"
            aria-label="move up"
            component="span"
          >
            <ArrowUpward />
          </IconButton>
          <IconButton
            onClick={() => handlePermOrder("right")}
            size="small"
            color="primary"
            aria-label="move down"
            component="span"
          >
            <ArrowDownward />
          </IconButton>
        </div>
      </div>
    );
  };
}
