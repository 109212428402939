import Axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const getDocumentData =
  ({ id, start_time, end_time, skip = 0, limit = 999999999 }) =>
  async (dispatch) => {
    try {
      return await Axios({
        method: "get",
        url: baseURL + "documentation/documents/" + id + "/data",
        params: {
          form_id: id,
          start_time,
          end_time,
          skip,
          limit,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (e) {
      console.error(e);
      return e;
    }
  };
