import axios from "axios";
import { requestLoading, requestReceived, setCurrentUser } from "../Reducers/AdminReducer";
import { baseURL } from "../Reducers/thunks";

export const getUser = () => async (dispatch) => {
  const userId = document.location.pathname.split("/");

  dispatch(requestLoading());
  try {
    const currentUser = await axios.get(baseURL + `users/${userId[userId.length - 1]}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    dispatch(
      setCurrentUser({
        username: currentUser.data.user_account.username,
        email: currentUser.data.user_account.email,
        is_active: currentUser.data.user_account.is_active === true,
        is_org_admin: currentUser.data.is_org_admin === true,
        roles: currentUser.data.roles,
        full_name: currentUser.data.user_account.full_name,
        avatar: currentUser.data.user_account.avatar,
        id: currentUser.data.id,
      })
    );
    dispatch(requestReceived({ detail: "requestReceived" }));
  } catch (error) {
    dispatch(requestReceived({ detail: error }));
    console.error(error);
  }
};
