import axios from "axios";
import { removeKey, baseURL } from "../thunks";

export const removePage =
  ({ currentPage, currentDocument, draft_contents }) =>
  async (dispatch) => {
    const withoutPage = currentDocument.draft_user_id
      ? removeKey(draft_contents.pages, currentPage.pageID)
      : removeKey(currentDocument.contents.pages, currentPage.pageID);
    const withoutIndex = currentDocument.draft_user_id
      ? draft_contents.page_index.filter((x) => x !== currentPage.pageID)
      : currentDocument.contents.page_index.filter((x) => x !== currentPage.pageID);
    try {
      const putReq = await axios({
        method: "put",
        url: baseURL + "documentation/documents/" + currentDocument.id,
        data: {
          ...currentDocument,
          contents: {
            pages: withoutPage,
            page_index: withoutIndex,
          },
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return putReq;
    } catch (e) {
      console.log(e);
    }
  };
