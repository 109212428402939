import Axios from "axios";
import { received } from "../../Documentation/Reducers/DocumentationReducer";
import { baseURL } from "../../Documentation/Reducers/thunks";
import { compress } from "../Components/Helpers/compress";
import { convertBlobURLToFile } from "./addPhotoVerification";
import { postFormData } from "./Helpers/postFormData";
import {retryRequest} from "../../../Utils/RetryRequest";

export const submitAnswers =
  ({ id, answers }) =>
  async (dispatch) => {
    const mediaOnly = Object.keys(answers).reduce((acc, questionID) => {
      let media = answers[questionID].media;
      if (media) {
        media.forEach((x) => acc.push(x));
      }
      return acc;
    }, []);

    const compressedMedia = [];
    for (const uploadedFile of mediaOnly) {
      const converted = await convertBlobURLToFile(uploadedFile);
      const compressedFile = converted.type.includes("image") ? await compress(converted, 0.6) : converted;
      if (compressedFile instanceof Blob) {
        // compress converts an incoming file to a blob, need to reverse that operation
        //https://pqina.nl/blog/convert-a-blob-to-a-file-with-javascript/
        const file = new File([compressedFile], compressedFile.name, {
          type: compressedFile.type,
        });
        compressedMedia.push(file);
      } else {
        compressedMedia.push(await compressedFile);
      }
    }

    const formatFileInfo = compressedMedia.map((media) => ({
      filename: media.name,
      file_size: media.size,
      content_type: media.type,
    }));

    const res = await Axios.post(`${baseURL}documentation/documents/${id}/sign-content-upload`, [...formatFileInfo], {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })

    const uploadIds = res.data.map((su) => su.upload_id);
    Object.keys(answers).forEach((k) => {
      if (answers[k].media) {
        answers[k].media = answers[k].media.map((m) => uploadIds.shift());
      }
    });

    await Promise.all(
      res.data.map(async (data, idx) => {
        const { upload_id, url, fields } = data;
        const formData = new FormData();
        formData.append("Content-Type", fields["Content-Type"]);
        formData.append("acl", fields.acl);
        formData.append("key", fields.key);
        formData.append("policy", fields.policy);
        formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
        formData.append("x-amz-credential", fields["x-amz-credential"]);
        formData.append("x-amz-date", fields["x-amz-date"]);
        formData.append("x-amz-signature", fields["x-amz-signature"]);
        formData.append("file", compressedMedia[idx]);
        await retryRequest(postFormData(url, formData));
        dispatch(received({error: false}));
        return { upload_id, filename: fields.key };
      })
    );

    return Axios({
      method: "post",
      url: baseURL + "documentation/documents/" + id + "/data",
      data: {
        record: answers,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  };
