export const initialState = {
  username: "",
  password: "",
  email: "",
  full_name: "",
  is_org_admin: "",
  org: null,
  available_orgs: null,
  avatar: "",
  roles: [],
  assignments: null,
  error: false,
  loading: false,
  sent: "",
  expired: false,
  successfulReset: false,
  authenticated: localStorage.login || false,
  invitations: null,
};
