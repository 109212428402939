import React from "react";
import { MenuItem, Typography } from "@mui/material";
import { activityResponse } from "./NotificationPopover";

export function RecentActivity(groupByDate) {
  return Object.keys(groupByDate).map((group, index1) => {
    const today = new Date().toISOString().split("T")[0];
    const isToday = today === group;
    return isToday ? (
      <div key={index1}>
        <Typography style={{ paddingLeft: "0.5em" }}>Today</Typography>
        {groupByDate[group].map((notification, index2) => {
          const time = new Date(notification.activity_time).toLocaleTimeString(
            {},
            {
              hour12: true,
              hour: "numeric",
              minute: "numeric",
            }
          );

          return (
            <MenuItem key={index2}>
              <Typography color="textSecondary" variant="body2">
                {`${time} `}
              </Typography>
              {activityResponse(notification)}
            </MenuItem>
          );
        })}
      </div>
    ) : (
      <div key={index1}>
        <Typography style={{ paddingLeft: "0.5em" }}>{group}</Typography>
        {groupByDate[group].map((notification, index2) => {
          const time = new Date(notification.activity_time).toLocaleTimeString(
            {},
            {
              hour12: true,
              hour: "numeric",
              minute: "numeric",
            }
          );

          return (
            <MenuItem key={index2}>
              <Typography color="textSecondary" variant="body2">
                {`${time} `}
              </Typography>
              {activityResponse(notification)}
            </MenuItem>
          );
        })}
      </div>
    );
  });
}
