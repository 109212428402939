export function convertBlobsToFiles() {
  return (acc, curr) => {
    const isBlob = curr && curr.media && !curr.media.includes("amazonaws.com") && curr?.media.includes("blob:http");
    if (isBlob) {
      let uploadedFile = fetch(curr.media)
        .then((r) => r.blob())
        .then(
          (blobFile) =>
            new File([blobFile], curr.filename, {
              type: curr.content_type,
              lastModified: curr.lastModified,
            })
        );
      acc.push(uploadedFile);
    }

    return acc;
  };
}
