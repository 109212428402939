import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { copyToFolder } from "../../Reducers/thunks";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { documentationSelector } from "../../Reducers/DocumentationReducer";
import { FieldContainer } from "../../../Form/Form";
export const PasteDialog = (props) => {
  const dispatch = useDispatch();
  const { currentFolder, copy } = useSelector(documentationSelector);
  const { onPaste, onClose, ...passthroughProps } = props;
  const [remove, setRemove] = useState(false);
  const [error, setError] = useState();
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      copyToFolder({
        id: copy.id,
        destination_folder_id: copy.targetFolderID ? copy.targetFolderID : currentFolder.folder.id,
        remove,
        kind: copy.kind,
      })
    )
      .then((value) => {
        onClose();
        if (copy.targetFolderID) {
          onPaste({
            name: copy.name,
            kind: copy.kind,
            id: copy.id,
            originFolderName: currentFolder.folder.name,
            destFolderName: copy.targetFolderName,
            originFolderID: currentFolder.folder.id,
            destFolderID: copy.targetFolderID,
            remove,
          });
        } else {
          onPaste({
            name: copy.name,
            kind: copy.kind,
            id: copy.id,
            originFolderName: copy.originalFolderName,
            destFolderName: currentFolder.folder.name,
            originFolderID: copy.originalFolderID,
            destFolderID: currentFolder.folder.id,
            remove,
          });
        }
      })
      .catch(function (err) {
        if (err.response) {
          const detail = err.response.data.detail;
          if (typeof detail === "string") {
            setError(detail);
          } else if (Array.isArray(detail)) {
            detail.forEach((e) => {
              console.log(e);
            });
          }
        }
        return Promise.reject(err);
      });
  };
  const resetForm = () => {
    setError();
  };
  return (
    <Dialog onClose={onClose} TransitionProps={{ onEntering: resetForm }} {...passthroughProps}>
      <DialogTitle id="form-dialog-title">
        {copy.targetFolderID
          ? `${remove ? "Move" : "Paste"} ${copy.name} into ${copy.targetFolderName}`
          : `${remove ? "Move" : "Paste"} ${copy.name} into ${currentFolder.folder.name}`}
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <DialogContentText>
            {copy.targetFolderID
              ? `Are you sure you want to ${remove ? "move" : "paste"} ${copy.name} from ${
                  currentFolder.folder.name
                } to ${copy.targetFolderName}?`
              : `Are you sure you want to ${remove ? "move" : "paste"} ${copy.name} from ${
                  copy.originalFolderName
                } to ${currentFolder.folder.name}?`}
          </DialogContentText>
          {error && <Alert severity="error">{error}</Alert>}
          <FieldContainer>
            <FormControlLabel
              control={<Checkbox checked={remove} onChange={() => setRemove(!remove)} color="primary" />}
              label={`Remove Original Copy in ${
                copy.targetFolderID ? currentFolder.folder.name : copy.originalFolderName
              }?`}
            />
          </FieldContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type={"submit"} style={{ color: "#097c09" }}>
            {copy.targetFolderID
              ? `${remove ? "Move" : "Paste"} ${copy.name} into ${copy.targetFolderName}`
              : `${remove ? "Move" : "Paste"} ${copy.name} into ${currentFolder.folder.name}`}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
