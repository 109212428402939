import { useSelector } from "react-redux";
import { Breadcrumb } from "../../Dashboard/Components/Breadcrumbs";

export function DocumentationBreadcrumbs({ setOpenQR, setOpenSubs, setOpenAssignments }) {
  const ancestors = useSelector((state) => state.documentation.currentFolder.ancestors);
  const folderName = useSelector((state) => state.documentation.currentFolder.folder.name);
  const ancestorBreadcrumbs = ancestors.map((x) => ({
    name: x.name,
    to: "/documentation/browse/" + x.id,
  }));
  return (
    <Breadcrumb
      currentValue={`${!ancestorBreadcrumbs.length ? "Documentation" : folderName}`}
      linkValues={[
        { name: "Home", to: "/" },
        ancestorBreadcrumbs.length > 0 && { name: "Documentation", to: "/documentation/browse" },
        ...(ancestorBreadcrumbs.length > 1 ? ancestorBreadcrumbs.slice(1) : ""),
      ]}
      isViewer
      setOpenQR={setOpenQR}
      setOpenSubs={setOpenSubs}
      setOpenAssignments={setOpenAssignments}
    />
  );
}
