import { AppBar, CircularProgress, Typography, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { Checkout } from "./Checkout";
import history from "../../Utils/history";
import { PopoverAvatar } from "../Dashboard/Components/PopoverAvatar";
import { useSelector } from "react-redux";

export const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  toolbar: {
    flexWrap: "wrap",
  },
  link: {
    margin: theme.spacing(1, 1.5),
    color: "white",
  },
}));

export const Billing = () => {
  const org = useSelector((state) => state.login.org);
  useEffect(() => {
    if (window.location.pathname === "/billing/success") {
      setTimeout(() => {
        history.push("/");
      }, 3000);
    }
  }, []);
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography edge="start" variant="h6" style={{ flexGrow: 1 }}>
            Billing :: {org?.name}
          </Typography>
          <PopoverAvatar />
        </Toolbar>
      </AppBar>
      {window.location.pathname === "/billing/success" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            overflow: "auto",
          }}
        >
          <Typography variant="h5">Purchase Successful!</Typography>
          <Typography variant="body1">Thank you for signing up with DropDoc</Typography>
          <Typography variant="body1">Redirecting to Dashboard...</Typography>
          <CircularProgress />
        </div>
      ) : (
        <Checkout />
      )}
    </>
  );
};
