import axios from "axios";
import { resetPasswordSent, resetPasswordReceived } from "./LoginReducer";
import { baseURL, toUrlEncoded } from "./thunks";

export const sendPasswordReset =
  ({ email }) =>
  async (dispatch) => {
    dispatch(resetPasswordSent());
    try {
      const resetPassword = await axios.post(baseURL + "auth/send-password-reset", toUrlEncoded({ email }));
      console.log(resetPassword);
      dispatch(resetPasswordReceived({ error: false }));
    } catch (error) {
      dispatch(resetPasswordReceived({ error: true }));
    }
  };
