import {
  AssignmentInd,
  Delete,
  Description,
  Edit,
  FileCopy,
  History,
  MoreVert,
  ViewList,
  Visibility,
} from "@mui/icons-material/";
import { Grid, IconButton, ListItem, ListItemIcon, ListItemText, Popover } from "@mui/material";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import history from "../../../../Utils/history";
import { setAlert, setCopy } from "../../Reducers/DocumentationReducer";
import { getDocument } from "../../Reducers/thunks";
import { getDocumentHistory } from "../../Reducers/Thunks/getDocumentHistory";
import { Perm } from "../Dialogs/helpers";
import { styles } from "./styles";
import TabIcon from "@mui/icons-material/Tab";

export const OptionsPopover = ({ ...popoverProps }) => {
  const dispatch = useDispatch();
  const {
    name,
    description,
    id,
    kind,
    doctype,
    version,
    modifiedAt,
    createdAt,
    created_by_username,
    modified_by_username,
    setShowDeleteDoc,
    setDocToEdit,
    setShowEdit,
    onCopy,
    subscription_options,
    user_permissions,
    setOpenAssignments,
  } = popoverProps;
  const [anchorEl, setAnchorEl] = useState(null);
  const alert = useSelector((state) => state.documentation.alert);

  const folderId = useSelector((state) => state.documentation.currentFolder.folder.id, shallowEqual);
  const folderName = useSelector((state) => state.documentation.currentFolder.folder.name, shallowEqual);
  const handleCopy = () => {
    dispatch(
      setCopy({
        copy: {
          name,
          description,
          id,
          kind,
          originalFolderID: folderId,
          originalFolderName: folderName,
        },
      })
    );
    setAnchorEl(null);
    onCopy(name);
  };
  const handleClickDelete = () => {
    setShowDeleteDoc(true);
    setDocToEdit({ id, name, description, kind, version, doctype });
    setAnchorEl(null);
  };
  const handleClickEdit = () => {
    setShowEdit(true);
    setDocToEdit({
      id,
      name,
      doctype,
      description,
      kind,
      version,
      modifiedAt,
      createdAt,
      created_by_username,
      modified_by_username,
      subscription_options,
      user_permissions,
    });
    setAnchorEl(null);
  };
  const handleClickHistory = () => {
    dispatch(getDocument({ id, isEditor: false })).then((x) => {
      dispatch(getDocumentHistory({ id })).then((x) => history.push("/documentation/" + id + "/history"));
    });
  };
  const permList = Perm.getLabelList(user_permissions);
  const open = Boolean(anchorEl);
  const handleAnchor = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setAlert({ alert: null, severity: null }));
    return Boolean(anchorEl) ? setAnchorEl(null) : setAnchorEl(e.target);
  };
  const handleClickViewResults = () => {
    history.push(`/documentation/results/${id}`);
  };

  const handleAssignment = () => {
    setOpenAssignments(true);
    setDocToEdit({
      id,
      name,
      doctype,
      description,
      kind,
      version,
      modifiedAt,
      createdAt,
      created_by_username,
      modified_by_username,
      subscription_options,
    });
  };
  return (
    <Grid id={"options"} item xs={1} style={styles.container} onClick={handleAnchor} key={id + kind + createdAt}>
      <IconButton aria-label="options" disableRipple variant="contained" style={styles.button} size="small">
        <MoreVert />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!alert) {
            setAnchorEl(null);
            console.log("called", alert);
          }
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {kind === "document" && (
          <>
            {permList.includes("Read") ? (
              <ListItem button component={Link} to={"/documentation/view/" + id} aria-label="view">
                <ListItemIcon>
                  <Visibility />
                </ListItemIcon>
                <ListItemText>View</ListItemText>
              </ListItem>
            ) : (
              ""
            )}
            {permList.includes("Write") ? (
              <ListItem button component={Link} to={"/documentation/edit/" + id} aria-label="edit">
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </ListItem>
            ) : (
              ""
            )}
          </>
        )}
        {doctype === "form" && permList.includes("Write") && (
          <ListItem button aria-label="history" onClick={handleClickViewResults}>
            <ListItemIcon>
              <ViewList />
            </ListItemIcon>
            <ListItemText>View Results</ListItemText>
          </ListItem>
        )}
        {permList.includes("Write") ? (
          <ListItem button aria-label="copy" onClick={handleCopy}>
            <ListItemIcon>
              <FileCopy />
            </ListItemIcon>
            <ListItemText>Copy</ListItemText>
          </ListItem>
        ) : (
          ""
        )}
        <ListItem button aria-label="properties" onClick={handleClickEdit}>
          <ListItemIcon>
            <Description />
          </ListItemIcon>
          <ListItemText>Properties</ListItemText>
        </ListItem>

        <ListItem button aria-label="assign" onClick={handleAssignment}>
          <ListItemIcon>
            <AssignmentInd />
          </ListItemIcon>
          <ListItemText>Assign</ListItemText>
        </ListItem>
        {kind === "document" && (
          <ListItem button aria-label="history" onClick={handleClickHistory}>
            <ListItemIcon>
              <History />
            </ListItemIcon>
            <ListItemText>History </ListItemText>
          </ListItem>
        )}

        {permList.includes("Delete") ? (
          <ListItem button aria-label="delete" onClick={handleClickDelete}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </ListItem>
        ) : (
          ""
        )}
        <ListItem
          button
          aria-label="New Tab"
          onClick={(e) => {
            if (kind === "document") {
              window.open("/documentation/view/" + id, "_blank");
              handleAnchor(e);
            } else {
              window.open("/documentation/browse/" + id, "_blank");
              handleAnchor(e);
            }
          }}
        >
          <ListItemIcon>
            <TabIcon />
          </ListItemIcon>
          <ListItemText>Open New Tab</ListItemText>
        </ListItem>
      </Popover>
    </Grid>
  );
};
