import React from "react";
import { CheckCircle, Cancel } from "@mui/icons-material/";
import { StepButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export function ActiveStepContainer({ index, setActiveStep, title, required, values, photoVerification, questionID }) {
  const fileObj = useSelector((state) => state.documentation?.fileObj);
  const isVerified = photoVerification ? (fileObj && fileObj.hasOwnProperty(questionID) ? true : false) : true;
  const isCompleted = !required || (values !== "not checked" && values !== "" && values.length !== 0);
  return (
    <StepButton
      style={{ justifyContent: "flex-start" }}
      onClick={() => {
        setActiveStep(index);
      }}
      icon={isCompleted && isVerified ? <CheckCircle style={{ color: "green" }} /> : <Cancel color="error" />}
    >
      <Typography>
        {title} {required && "*"}
      </Typography>
    </StepButton>
  );
}
