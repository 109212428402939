import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../Utils/history";
import { getDocument } from "../../Documentation/Reducers/thunks";
import { setAlert, setSelectedThumbnail } from "../../Documentation/Reducers/DocumentationReducer";

export function useDraftWarning(draft) {
  const dispatch = useDispatch();
  const currentDocument = useSelector((state) => state.documentation.currentDocument);

  useEffect(() => {
    if (draft) {
      dispatch(
        setAlert({
          alert: (
            <div>
              This is not the most recent version of this document.{" "}
              <span
                style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  dispatch(getDocument({ id: currentDocument?.document_id, isEditor: false }));
                  dispatch(setSelectedThumbnail({ value: 0 }));
                  history.push("/documentation/view/" + currentDocument?.document_id);
                }}
              >
                Click here
              </span>{" "}
              to view the most recent.
            </div>
          ),
          severity: "warning",
        })
      );
    }
  }, [currentDocument, draft, dispatch]);
}
