import React from "react";
import { FieldContainer } from "../../../Form/Form";
import { formatDateTime } from "../../Helpers/helpers";
import { pathToDocument } from "./helpers";
import { Checkbox, FormControlLabel, Grid, DialogContent, DialogContentText, TextField, Alert } from "@mui/material";
import {
  FolderOpenTwoTone,
  InsertDriveFileTwoTone,
  AssignmentTurnedInTwoTone,
  ListAltTwoTone,
} from "@mui/icons-material/";
import { styles } from "./styles";
export function PropertiesTab({
  doctype,
  error,
  kind,
  fields,
  onChangeName,
  onChangeDesc,
  currentFolder,
  name,
  created_by_username,
  created,
  modified_by_username,
  modified,
  version,
  subscribe,
  setSubscribe,
  favorite,
  setFavorite,
  validationSubscription,
  setValidationSubscription,
  emailSubscription,
  setEmailSubscription,
  permList,
}) {
  return (
    <div>
      <DialogContent style={{ paddingTop: "0px", overflowY: "hidden" }}>
        {error && <Alert severity="error">{error}</Alert>}
        <Grid container wrap="nowrap">
          <Grid item xs={2} style={{ paddingLeft: 0 }}>
            {kind === "folder" ? (
              <FolderOpenTwoTone style={styles.folder} />
            ) : doctype === "presentation" ? (
              <InsertDriveFileTwoTone style={styles.document} />
            ) : doctype === "form" ? (
              <AssignmentTurnedInTwoTone style={styles.form} />
            ) : (
              <ListAltTwoTone style={styles.sheet} />
            )}
          </Grid>
          <Grid item container xs={10} style={{ display: "block" }}>
            <Grid item xs={true}>
              <TextField
                disabled={!permList.includes("Write")}
                autoFocus
                margin="dense"
                id="name"
                label={`${kind === "folder" ? "Folder" : "Document"} name`}
                type="text"
                value={fields.name || ""}
                onChange={onChangeName}
                fullWidth
              />
            </Grid>
            <Grid item xs={true}>
              <TextField
                disabled={!permList.includes("Write")}
                margin="dense"
                id="description"
                label="Description"
                type="text"
                value={fields.description || ""}
                onChange={onChangeDesc}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <FieldContainer>
          <DialogContentText style={{ marginBottom: "0px" }}>
            Path: {pathToDocument(currentFolder)} {currentFolder.folder.name === "root" ? "" : "/"} {name}
          </DialogContentText>
          <DialogContentText style={{ marginBottom: "0px" }}>
            Created by {created_by_username}: {formatDateTime(created)}
          </DialogContentText>
          <DialogContentText style={{ marginBottom: "0px" }}>
            Modified by {modified_by_username}: {formatDateTime(modified)}
          </DialogContentText>
          <DialogContentText style={{ marginBottom: "0px" }}>Version: {version}</DialogContentText>
          {doctype !== "sheet" ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={subscribe}
                  onChange={() => setSubscribe((state) => !state)}
                  name="Subscribe to Notifications"
                />
              }
              label="Subscribe to Notifications"
            />
          ) : (
            ""
          )}
          {doctype === "form" && subscribe ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={validationSubscription}
                  onChange={() => setValidationSubscription((state) => !state)}
                  name="Validation Errors Only"
                />
              }
              label="Validation Errors Only"
            />
          ) : (
            ""
          )}
          {doctype === "form" && subscribe ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailSubscription}
                  onChange={() => setEmailSubscription((state) => !state)}
                  name="Send Notifictions By Email"
                />
              }
              label="Send Notifictions By Email"
            />
          ) : (
            ""
          )}
          <FormControlLabel
            control={
              <Checkbox checked={favorite} onChange={() => setFavorite((state) => !state)} name="Mark as Favorite" />
            }
            label="Mark as Favorite"
          />
        </FieldContainer>
      </DialogContent>
    </div>
  );
}
