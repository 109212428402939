export const colors = ["red", "blue", "green", "purple", "orange", "black"];
export const randomColor = (colors) => colors[Math.floor(Math.random() * colors.length)];
export const viewState = (state) => JSON.stringify(state, undefined, 2);
export const isNotSelected = (val) => !val.selected;

export function mapDescription(id, description) {
  return (shape) => {
    if (shape.id === id) {
      shape.description = description;
      shape.selected = false;
    }
    return shape;
  };
}

export function rotateAroundPoint({ origin, point, angleDeg }) {
  let angle = (angleDeg * Math.PI) / 180;
  let x = Math.cos(angle) * (point.x - origin.x) - Math.sin(angle) * (point.y - origin.y) + origin.x;
  let y = Math.sin(angle) * (point.x - origin.x) + Math.cos(angle) * (point.y - origin.y) + origin.y;
  return { x, y };
}

export function angleBetweenPoints({ pt1, pt2 }) {
  return (Math.atan2((pt2.y - pt1.y) * 1000, (pt2.x - pt1.x) * 1000) * 180) / Math.PI;
}

export function getSlope(pt1, pt2) {
  return (pt2.y - pt1.y) / (pt2.x - pt1.x);
}

export const handleMove = (state, direction, index) => {
  let arr = [...state];
  let removed = arr.splice(index, 1)[0];
  arr.splice(direction === "up" ? index - 1 : index + 1, 0, removed);
  return arr;
};

export function handleNotifications(state, questionID, key, value) {
  if (!state.questions[questionID]?.validation[key]) {
    state.questions[questionID].validation[key] = [value];
  } else if (state.questions[questionID].validation[key].includes(value)) {
    state.questions[questionID].validation[key] = [
      ...state.questions[questionID].validation[key].filter((x) => x !== value),
    ];
  } else {
    state.questions[questionID].validation[key] = [...state.questions[questionID].validation[key], value];
  }
}

export function handleMultipleChoice(state, id, value) {
  if (state.questions[id].values.includes(value)) {
    state.questions[id].values = [...state.questions[id].values.filter((x) => x !== value)];
  } else {
    state.questions[id].values = [...state.questions[id].values, value];
  }
}
