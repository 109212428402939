import Axios from "axios";
import { received } from "../DocumentationReducer";
import { baseURL } from "../thunks";

export const claimAssignment =
  ({ kind, id }) =>
  async (dispatch) => {
    try {
      return await Axios({
        method: "post",
        url: `${baseURL}workflow/assignments/${kind}/${id}/claim`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (error) {
      console.log(error);
      dispatch(received({ error: true }));
    }
  };
