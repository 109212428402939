import { Star } from "@mui/icons-material";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { DataGridPro, GridOverlay, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import React, { useImperativeHandle, useMemo } from "react";
import { useSelector } from "react-redux";
import { avatar } from "../../Admin/Users/helpers";
import { LoadingSpinner } from "../../Viewer/Components/LoadingSpinner";

export function UsersWithRolesTable() {
  const rows = useSelector((state) => state.admin.rows);
  const loading = useSelector((state) => state.admin.loading);
  const total = useSelector((state) => state.admin.total);
  const roles = useSelector((state) => state.roles.roles);
  const role = useSelector((state) => state.roles.currentRole.role);
  const columns = useMemo(
    () => [
      {
        field: "Avatar",
        flex: 1,
        renderCell: (params) => params.value,
      },
      {
        field: "Username",
        flex: 2,
        renderCell: (params) => {
          const row = rows.filter((row) => row.user_account.username === params.value);

          return row[0].is_org_admin ? (
            <>
              <Star />
              {params.value}
            </>
          ) : (
            params.value
          );
        },
      },
      { field: "Full Name", flex: 2 },
      { field: "Email", flex: 2 },
      {
        field: "Roles",
        flex: 2,
        renderCell: (params) => {
          return params.value;
        },
        valueGetter: (params) => {
          return params.value.map((x) => x.name).join(", ");
        },

        filterOperators: [
          {
            label: "Includes",
            value: "includes",
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.columnField ||
                !filterItem.value ||
                !filterItem.value.length ||
                !filterItem.operatorValue
              ) {
                return null;
              }

              return (params) => {
                const rolesInColumn = params.value.split(",").map((x) => x.trim());
                const rolesToFilter = filterItem.value;

                const isInRoles = rolesToFilter.reduce((a, c) => {
                  if (rolesInColumn.includes(c)) {
                    a = true;
                  }
                  return a;
                }, false);
                return isInRoles;
              };
            },
            InputComponent: (props) => {
              const { item, applyValue, focusElementRef } = props;
              const rolesRef = React.useRef(null);
              useImperativeHandle(focusElementRef, () => ({
                focus: () => {
                  rolesRef.current.focus();
                },
              }));

              const handleFilterChange = (event, newValue) => {
                applyValue({ ...item, value: newValue });
              };
              //https://github.com/mui/mui-x/issues/3075, Multiselect within DataGrid cell
              return (
                <Autocomplete
                  ref={rolesRef}
                  multiple
                  value={item?.value ? [...item.value] : []}
                  id="tags-outlined"
                  options={roles.map((role) => role.name)}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  onChange={handleFilterChange}
                  renderInput={(params) => <TextField {...params} label="Roles to Filter" placeholder="Roles" />}
                />
              );
            },
            InputComponentProps: { type: "singleSelect", valueOptions: roles.map((role) => role.name) },
          },
        ],
      },
    ],
    [rows, roles]
  );
  const tableRows = rows.map((row) => {
    return {
      id: row.id,
      Avatar: avatar(row.user_account),
      Username: row.user_account.username,
      "Full Name": row.user_account.full_name,
      Email: row.user_account.email,
      Roles: row.roles,
    };
  });
  return (
    <div style={{ padding: 0, height: "100%", maxHeight: 1000 }}>
      <DataGridPro
        style={{ height: "100%", overflowY: "auto" }}
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <div>
                <Typography variant="h5" component="div">
                  Users With Role: {role}
                </Typography>
                <GridToolbarFilterButton />
              </div>
            </GridToolbarContainer>
          ),
          NoRowsOverlay: () => (
            <GridOverlay style={{ flexDirection: "column" }}>
              {loading ? <LoadingSpinner /> : "No Results Found"}
            </GridOverlay>
          ),
          Footer: () => (
            <div style={{ margin: "0px 16px", display: "flex", alignItems: "center" }}>Total: {total ? total : 0}</div>
          ),
        }}
        columns={columns}
        rows={tableRows}
      />
    </div>
  );
}
