import { useState } from "react";
import { useSelector } from "react-redux";
import { Breadcrumb } from "../Dashboard/Components/Breadcrumbs";
import { Dashboard } from "../Dashboard/Dashboard";
import { AssignmentModal } from "../Documentation/Components/Dialogs/AssignmentModal";
import { SnackbarAlertRedux } from "../Documentation/Components/SnackbarAlert";
import { ChecklistViewer } from "./Components/ChecklistViewer/ChecklistViewer";
import { EmptyDocument } from "./Components/EmptyDocument";
import { LoadingSpinner } from "./Components/LoadingSpinner";
import { PresentationViewer } from "./Components/PresentationViewer";
import { QRLinkModal } from "./Components/QRLinkModal";
import { ReportViewer } from "./Components/ReportViewer/ReportViewer";
import { SpreadsheetViewer } from "./Components/SpreadsheetViewer/SpreadsheetViewer";
import { SubscriptionModal } from "./Components/SubscriptionModal";
import UploadProgressBar from "./Components/UploadProgressBar";
import { isEmpty, setLinkVals } from "./helpers";
import { useDraftWarning } from "./Hooks/useDraftWarning";
import { useInitializeDocument } from "./Hooks/useInitializeDocument";
export const View = ({ draft }) => {
  const currentDocument = useSelector((state) => state.documentation.currentDocument);
  const loading = useSelector((state) => state.documentation.loading);
  const alert = useSelector((state) => state.documentation.alert);
  const severity = useSelector((state) => state.documentation.severity);
  const doctype = useSelector((state) => state.documentation.currentDocument?.doctype);
  const [openQR, setOpenQR] = useState(false);
  const [openSubs, setOpenSubs] = useState(false);
  const [openAssignments, setOpenAssignments] = useState(false);

  useInitializeDocument({ isEditor: false });
  useDraftWarning(draft);
  const linkVals = setLinkVals(currentDocument);
  return (
    <Dashboard
      pageName={`View ${currentDocument?.name ?? ""} ${draft ? `(Version: ${currentDocument?.version ?? ""})` : ""}`}
      breadcrumbs={
        <Breadcrumb
          currentValue={
            draft
              ? `${currentDocument?.name ?? ""} (Version: ${currentDocument?.version ?? ""})`
              : currentDocument?.name ?? ""
          }
          linkValues={linkVals}
          isViewer
          setOpenQR={setOpenQR}
          setOpenSubs={setOpenSubs}
          setOpenAssignments={setOpenAssignments}
        />
      }
    >
      {loading || !currentDocument ? (
        <LoadingSpinner />
      ) : (
        <>
          {doctype === "presentation" ? (
            isEmpty(currentDocument.contents) || !currentDocument?.contents?.page_index?.length ? (
              <EmptyDocument />
            ) : (
              <PresentationViewer />
            )
          ) : doctype === "sheet" ? (
            <SpreadsheetViewer />
          ) : doctype === "report" ? (
            <ReportViewer />
          ) : (
            <ChecklistViewer />
          )}
        </>
      )}
      <QRLinkModal openQR={openQR} setOpenQR={setOpenQR} value={window.location.href} name={currentDocument?.name} />
      <SubscriptionModal openSubs={openSubs} setOpenSubs={setOpenSubs} />
      <SnackbarAlertRedux alert={alert} severity={severity} />
      {openAssignments && (
        <AssignmentModal docToEdit={currentDocument} open={openAssignments} onClose={() => setOpenAssignments(false)} />
      )}
      <UploadProgressBar />
    </Dashboard>
  );
};
