import { useState, useRef, useEffect } from "react";

export default function useResponsiveRect(elRef) {
  const [rect, setRect] = useState(null);
  const observer = useRef(
    new ResizeObserver((entries) => {
      // Only care about the first element, we expect one element ot be watched
      setRect(entries[0].contentRect);
    })
  );

  useEffect(() => {
    if (elRef.current) {
      observer.current.observe(elRef.current);
    }
    const currentObserver = observer.current;
    const currentEl = elRef.current;
    return () => {
      currentObserver.unobserve(currentEl);
    };
  }, [elRef, observer]);

  return rect;
}

export function isEmpty(obj) {
  for (var i in obj) return false;
  return true;
}

export function setLinkVals(currentDocument) {
  const crumbs = [
    { name: "Home", to: "/" },
    { name: "Documentation", to: "/documentation/browse" },
  ];
  const linkVals =
    currentDocument && currentDocument.path.length > 1
      ? [
          ...crumbs,
          ...currentDocument.path.slice(1).map((x) => ({ name: x.name, to: "/documentation/browse/" + x.id })),
        ]
      : crumbs;
  return linkVals;
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export function determineSource({ fileObj, currentDocument, thumbID, draft_contents, isEditor }) {
  const isFileObj = fileObj && fileObj.pageID === thumbID;
  const videoPic = "https://image.shutterstock.com/image-vector/play-video-vector-icon-260nw-525553219.jpg";
  const pdfPic =
    "https://w7.pngwing.com/pngs/466/409/png-transparent-pdf-computer-icons-adobe-acrobat-adobe-reader-others-miscellaneous-text-logo-thumbnail.png";
  return isFileObj
    ? /video/.test(fileObj?.content_type)
      ? videoPic
      : /pdf/.test(fileObj?.content_type)
      ? pdfPic
      : fileObj?.media
    : isEditor && currentDocument.draft_user_id
    ? /video/.test(draft_contents.pages[thumbID]?.content_type)
      ? videoPic
      : /pdf/.test(draft_contents.pages[thumbID]?.content_type)
      ? pdfPic
      : draft_contents.pages[thumbID]?.media
    : /video/.test(currentDocument.contents.pages[thumbID]?.content_type)
    ? videoPic
    : /pdf/.test(currentDocument.contents.pages[thumbID]?.content_type)
    ? pdfPic
    : currentDocument.contents.pages[thumbID]?.media;
}
