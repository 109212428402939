import { useTheme } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useInitResults } from "../../Hooks/useInitResults";
import { LoadingSpinner } from "../LoadingSpinner";
import { ReportContainer } from "./ReportContainer";

export const ReportViewer = () => {
  const currentDocument = useSelector((state) => state.documentation.currentDocument);
  const results = useSelector((state) => state.documentation.spreadsheet?.results);
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  useInitResults({
    setLoading,
  });
  return loading || !results ? (
    <LoadingSpinner />
  ) : !currentDocument?.contents?.question_index || (results && !results.length) ? (
    <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div> - - Empty - - </div>
    </div>
  ) : (
    <div style={{ overflow: "auto", padding: "1em", justifyContent: "center", flexDirection: "column" }}>
      {currentDocument.contents.question_index.map((page, qIndex) =>
        results.map((result, index) =>
          index === 0 ? (
            <ReportContainer
              key={qIndex + index}
              currentDocument={currentDocument}
              qIndex={qIndex}
              singleReport={result}
              page={page}
              theme={theme}
            />
          ) : (
            ""
          )
        )
      )}
    </div>
  );
};
