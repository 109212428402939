import axios from "axios";
import { handle403 } from "../../Dashboard/Hooks/Helpers/handle403";
import { getAssignments } from "../../Documentation/Reducers/Thunks/getAssignments";
import { setLoggedInUser } from "./LoginReducer";
import { baseURL } from "./thunks";

export const refreshUserData = () => async (dispatch) => {
  try {
    const userReq = await axios.get(baseURL + "auth/me", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.token,
      },
    });
    const userPendingInvites = await axios({
      method: "get",
      url: baseURL + "auth/invites",

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const userAssignments = await dispatch(getAssignments());
    sessionStorage.setItem("is_org_admin", userReq.data.is_org_admin);
    dispatch(
      setLoggedInUser({
        username: userReq.data.username,
        email: userReq.data.email,
        full_name: userReq.data.full_name,
        is_org_admin: userReq.data.is_org_admin,
        available_orgs: userReq.data.available_orgs,
        org: userReq.data.org,
        roles: userReq.data.roles,
        assignments: userAssignments.data,
        authenticated: true,
        avatar: userReq.data.avatar,
        otp_enabled: userReq.data.otp_enabled,
        invitations: userPendingInvites.data,
      })
    );
    return userReq;
  } catch (err) {
    handle403(err, dispatch);
  }
};
