import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { reducers } from "./reducers";

const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers,
});

export const editorSelector = (state) => state.editor;

export const {
  addShape,
  removeShape,
  selectShape,
  setTool,
  setShapeDescription,
  setScale,
  setPosition,
  setRotation,
  setShapes,
  setScaleFactor,
  setPageDescription,
  setColor,
  setStrokeWidth,
  setZoom,
  setZoomOffset,
  setContainedSize,
  setQuestion,
  setMultipleChoice,
  addQuestion,
  addQuestionSpreadsheet,
  removeQuestion,
  setQuestions,
  setQuestionMedia,
  setSaving,
  deselectShapes,
  setTree,
  setFormIds,
} = editorSlice.actions;

export const editor = editorSlice.reducer;
