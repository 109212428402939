import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { createNewRole } from "../Reducers/createNewRole";
import { rolesSelector } from "../Reducers/RolesReducer";
import { Description, SupervisorAccount } from "@mui/icons-material/";
import { Form } from "../../Form/Form";
import { Dashboard } from "../../Dashboard/Dashboard";
import { Breadcrumb } from "../../Dashboard/Components/Breadcrumbs";
export const NewRoleForm = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(rolesSelector);
  const [role, setRole] = useState(() => "");
  const [description, setDescription] = useState(() => "");
  return (
    <Dashboard
      pageName="New Role"
      breadcrumbs={
        <Breadcrumb
          currentValue={"New"}
          linkValues={[
            { name: "Home", to: "/" },
            { name: "Admin", to: "/admin" },
            { name: "Roles", to: "/admin/roles/" },
          ]}
        />
      }
    >
      <Form>
        <Typography variant="h4" style={{ margin: "0.5em 0 0.5em 0.5em" }}>
          Create A New Role
        </Typography>
        <TextField
          id="role"
          label="Role"
          type="text"
          value={role}
          required
          onChange={(e) => setRole(e.target.value)}
          InputProps={{
            startAdornment: <SupervisorAccount />,
          }}
          fullWidth
          margin="normal"
        />
        <TextField
          id="description"
          label="Description"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          InputProps={{
            startAdornment: <Description />,
          }}
          fullWidth
          margin="normal"
        />

        <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
          <Button
            disabled={!role || loading}
            variant="outlined"
            onClick={() => {
              dispatch(
                createNewRole({
                  role,
                  description,
                })
              );
            }}
          >
            {loading ? "Submitting..." : "Create Role"}
          </Button>
        </Grid>
      </Form>{" "}
    </Dashboard>
  );
};
