import React from "react";
import { styles } from "../styles";
import { Grid, Typography } from "@mui/material";

export function EmptyDocument() {
  return (
    <Grid style={styles.viewContainer} container>
      <Grid style={styles.emptyThumbnailContainer} item container xs={3}>
        <Typography>--Empty--</Typography>
      </Grid>
    </Grid>
  );
}
