import axios from "axios";
import { setSaving } from "../../../Editor/Reducers/EditorReducer";
import { appendFormData } from "./appendFormData";
import { handleError } from "../../../Documentation/Reducers/Thunks/Helpers/handleError";

export async function uploadQuestionMedia(res, newFiles, dispatch, newFileIDs, setShowPasswordModal, onCancel) {
  let results = [];
  for (let index = 0; index < res.data.length; index++) {
    const file = res.data[index];
    const { upload_id, url, fields } = file;
    const formData = new FormData();
    appendFormData(formData, fields, newFiles, index);
    try {
      await axios({
        method: "post",
        url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(setSaving({ saving: false }));
      results.push({ questionID: newFileIDs[index], upload_id });
    } catch (e) {
      handleError(e, onCancel, dispatch, setShowPasswordModal);
    }
  }
  return results;
}
