import { useEffect } from "react";
import { useSelector } from "react-redux";

export function usePageName(pageName) {
  const name = useSelector((state) => state.login.org?.name);

  useEffect(() => {
    document.title = `${pageName}${name ? " :: " + name : ""}`;
  }, [pageName, name]);
}
