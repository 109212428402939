import axios from "axios";
import history from "../../../Utils/history";
import { requestLoading, requestReceived } from "../Reducers/AdminReducer";
import { baseURL } from "../Reducers/thunks";

export const createNewUser =
  ({ username, email, is_org_admin, full_name, password, roles, avatar }) =>
  async (dispatch) => {
    dispatch(requestLoading());
    try {
      const newUser = await axios.post(
        baseURL + `users/`,
        { username, email, is_org_admin, full_name, password, is_active: true, roles, avatar },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(newUser);
      dispatch(requestReceived({ detail: "success" }));
      history.push("/admin/users");
    } catch (error) {
      dispatch(requestReceived({ detail: error }));
      console.error(error);
    }
  };
