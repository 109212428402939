import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { reducers } from "./reducers";
const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers,
});
export const rolesSelector = (state) => state.roles;

export const {
  requestLoading,
  requestReceived,
  updateRoleRows,
  resetRoleRows,
  setValue,
  setCurrentRole,
  setSelectedRoles,
} = rolesSlice.actions;

export const roles = rolesSlice.reducer;
