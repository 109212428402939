import { Cancel, CheckCircle, Download } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepContent,
  StepLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { VerificationPhotoCarousel } from "../ChecklistViewer/VerificationPhotoCarousel";

export function SingleRowContainer({ currentDocument, page, singleRow, theme }) {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = singleRow.record[page] && singleRow.record[page].media ? singleRow.record[page].media.length : 0;
  const { title, description, choices, valueType, widget, required, media, content_type } =
    currentDocument.contents.questions[page];
  const values = singleRow.record[page]?.value || "";
  const TrueFalseRadio = (
    <FormControl component="fieldset">
      <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
      <RadioGroup
        aria-label="checklist"
        name="checklist"
        value={values || "false"}
        style={{
          flexDirection: "column",
        }}
      >
        <FormControlLabel value="true" control={<Radio />} label="True" />
        <FormControlLabel value="false" control={<Radio />} label="False" />
      </RadioGroup>
    </FormControl>
  );
  const PassFailRadio = (
    <FormControl component="fieldset">
      <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
      <RadioGroup aria-label="checklist" name="checklist" value={values || "false"} style={{ flexDirection: "column" }}>
        <FormControlLabel value="true" control={<Radio />} label="Pass" />
        <FormControlLabel value="false" control={<Radio />} label="Fail" />
      </RadioGroup>
    </FormControl>
  );
  const NumberInput = (
    <FormControl component="fieldset">
      <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
      <TextField type="number" value={values} label={title} />
    </FormControl>
  );
  const SingleChoiceRadio =
    widget === "dropdown" ? (
      <FormControl style={{ minWidth: 120 }}>
        <InputLabel id="demo-simple-select-label">Select One</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          displayEmpty
          value={values === "not checked" ? "" : values}
        >
          {choices.map((choice, j) => (
            <MenuItem key={j} value={choice}>
              {choice}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : (
      <FormControl component="fieldset">
        <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
        <RadioGroup aria-label="checklist" name="checklist" value={values} style={{ flexDirection: "row" }}>
          <FormControlLabel style={{ display: "none" }} value="not checked" control={<Radio />} label="Not Checked" />
          {choices.map((choice, j) => {
            return <FormControlLabel key={j} value={choice} control={<Radio />} label={choice} />;
          })}
        </RadioGroup>
      </FormControl>
    );
  const MultipleChoiceRadio =
    widget === "checkboxes" ? (
      <FormControl component="fieldset">
        <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
        {choices.map((choice, j) => {
          return (
            <FormControlLabel
              key={j}
              control={<Checkbox checked={values ? values.includes(choice) : false} name={choice} color="primary" />}
              label={choice}
            />
          );
        })}
      </FormControl>
    ) : (
      <FormControl component="fieldset">
        <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
        {choices.map((choice, j) => {
          return (
            <FormControlLabel
              key={j}
              checked={values ? values.includes(choice) : false}
              value={choice}
              control={<Radio />}
              label={choice}
            />
          );
        })}
      </FormControl>
    );
  const DateTimePicker = (
    <form noValidate>
      <TextField
        id="datetime-local"
        label={title}
        type="datetime-local"
        value={values}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
  );
  const EmailTextField = <TextField id="email-field" label={title} value={values || ""} />;
  const URLTextField = <TextField id="url-field" label={title} value={values || ""} />;
  const TextValueField = <TextField id="text-field" label={title} value={values || ""} />;
  const DurationField = <TextField id="duration-field" label={title} value={values || ""} />;
  const parsedFilename = media?.split("?")[0].substring(media.lastIndexOf("/") + 1) || "download";
  return singleRow.record[page] ? (
    <Step active={true}>
      <StepLabel
        style={{ justifyContent: "flex-start" }}
        icon={singleRow.record[page].valid ? <CheckCircle style={{ color: "green" }} /> : <Cancel color="error" />}
      >
        <Typography
          style={{
            background: singleRow.record[page].valid ? "inherit" : "red",
            color: singleRow.record[page].valid ? "inherit" : "white",
            width: "fit-content",
          }}
        >
          {title} {required && "*"}
        </Typography>
      </StepLabel>
      <StepContent>
        <div style={{ marginBottom: "1em", width: "100%" }}>
          {media ? (
            /video/.test(content_type) ? (
              <video
                style={{
                  maxHeight: "500px",
                  maxWidth: "500px",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  paddingRight: "10px",
                }}
                controls
              >
                <source src={media} type={content_type} />
              </video>
            ) : /image/.test(content_type) ? (
              <img
                src={media}
                style={{
                  maxHeight: "500px",
                  maxWidth: "500px",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  paddingRight: "10px",
                }}
                alt="imgAlt"
              />
            ) : (
              <Button
                startIcon={<Download />}
                variant="contained"
                style={{ width: "fit-content" }}
                onClick={() => {
                  const link = document.createElement("a");
                  link.download = parsedFilename || "download";
                  link.href = media;
                  link.click();
                }}
              >
                {parsedFilename}
              </Button>
            )
          ) : (
            ""
          )}
        </div>

        <div>
          {valueType === "true/false"
            ? TrueFalseRadio
            : valueType === "pass/fail"
            ? PassFailRadio
            : valueType === "number" || valueType === "decimal"
            ? NumberInput
            : valueType === "single choice"
            ? SingleChoiceRadio
            : valueType === "multiple choice"
            ? MultipleChoiceRadio
            : valueType === "datetime"
            ? DateTimePicker
            : valueType === "email"
            ? EmailTextField
            : valueType === "url"
            ? URLTextField
            : valueType === "text"
            ? TextValueField
            : valueType === "duration"
            ? DurationField
            : ""}
        </div>
        <div>
          {singleRow.record[page].comments ? (
            <TextField
              id="comments"
              label="Comments"
              multiline
              value={singleRow.record[page].comments || ""}
              margin="normal"
            />
          ) : (
            ""
          )}
        </div>

        {singleRow.record[page].media ? (
          <div>
            <VerificationPhotoCarousel
              media={singleRow.record[page].media}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              maxSteps={maxSteps}
              theme={theme}
            />
          </div>
        ) : (
          ""
        )}
      </StepContent>
    </Step>
  ) : (
    <Step active={true}>
      <StepLabel style={{ justifyContent: "flex-start" }}>
        <Typography
          style={{
            width: "fit-content",
          }}
        >
          {title} {required && "*"}
        </Typography>
      </StepLabel>
      <StepContent>
        <div style={{ marginBottom: "1em", width: "100%" }}>
          {media ? (
            /video/.test(content_type) ? (
              <video
                style={{
                  maxHeight: "500px",
                  maxWidth: "500px",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  paddingRight: "10px",
                }}
                controls
              >
                <source src={media} type={content_type} />
              </video>
            ) : /image/.test(content_type) ? (
              <img
                src={media}
                style={{
                  maxHeight: "500px",
                  maxWidth: "500px",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  paddingRight: "10px",
                }}
                alt="imgAlt"
              />
            ) : (
              <Button
                startIcon={<Download />}
                variant="contained"
                style={{ width: "fit-content" }}
                onClick={() => {
                  const link = document.createElement("a");
                  link.download = parsedFilename || "download";
                  link.href = media;
                  link.click();
                }}
              >
                {parsedFilename}
              </Button>
            )
          ) : (
            ""
          )}
        </div>

        <div>
          {valueType === "true/false" ? (
            <FormControl component="fieldset">
              <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
              <RadioGroup
                aria-label="checklist"
                name="checklist"
                value={""}
                style={{
                  flexDirection: "column",
                }}
              >
                <FormControlLabel value="true" control={<Radio />} label="True" />
                <FormControlLabel value="false" control={<Radio />} label="False" />
              </RadioGroup>
            </FormControl>
          ) : valueType === "pass/fail" ? (
            <FormControl component="fieldset">
              <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
              <RadioGroup aria-label="checklist" name="checklist" value={""} style={{ flexDirection: "column" }}>
                <FormControlLabel value="true" control={<Radio />} label="Pass" />
                <FormControlLabel value="false" control={<Radio />} label="Fail" />
              </RadioGroup>
            </FormControl>
          ) : valueType === "number" || valueType === "decimal" ? (
            <FormControl component="fieldset">
              <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>{description}</FormLabel>
              <TextField type="number" value={""} label={title} />
            </FormControl>
          ) : valueType === "single choice" ? (
            widget === "dropdown" ? (
              <FormControl style={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">Select One</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" displayEmpty value={""}>
                  {choices.map((choice, j) => (
                    <MenuItem key={j} value={choice}>
                      {choice}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <FormControl component="fieldset">
                <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>
                  {description}
                </FormLabel>
                <RadioGroup aria-label="checklist" name="checklist" value={values} style={{ flexDirection: "row" }}>
                  <FormControlLabel style={{ display: "none" }} value="" control={<Radio />} label="Not Checked" />
                  {choices.map((choice, j) => {
                    return <FormControlLabel key={j} value={choice} control={<Radio />} label={choice} />;
                  })}
                </RadioGroup>
              </FormControl>
            )
          ) : valueType === "multiple choice" ? (
            widget === "checkboxes" ? (
              <FormControl component="fieldset">
                <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>
                  {description}
                </FormLabel>
                {choices.map((choice, j) => {
                  return (
                    <FormControlLabel
                      key={j}
                      control={<Checkbox checked={null} name={choice} color="primary" />}
                      label={choice}
                    />
                  );
                })}
              </FormControl>
            ) : (
              <FormControl component="fieldset">
                <FormLabel style={{ fontSize: "0.95rem", fontStyle: "italic", color: "black" }}>
                  {description}
                </FormLabel>
                {choices.map((choice, j) => {
                  return <FormControlLabel key={j} checked={""} value={choice} control={<Radio />} label={choice} />;
                })}
              </FormControl>
            )
          ) : valueType === "datetime" ? (
            <form noValidate>
              <TextField
                id="datetime-local"
                label={title}
                type="datetime-local"
                value={""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          ) : valueType === "email" ? (
            <TextField id="email-field" label={title} value={""} />
          ) : valueType === "url" ? (
            <TextField id="url-field" label={title} value={""} />
          ) : valueType === "text" ? (
            <TextField id="text-field" label={title} value={""} />
          ) : valueType === "duration" ? (
            <TextField id="duration-field" label={title} value={""} />
          ) : (
            ""
          )}
        </div>
      </StepContent>
    </Step>
  );
}
