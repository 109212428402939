import { useDispatch, useSelector } from "react-redux";
import { setPageDescription } from "../../Editor/Reducers/EditorReducer";
import { useEffect } from "react";

export function usePageDescription() {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.documentation.currentPage);
  useEffect(() => {
    if (currentPage) dispatch(setPageDescription({ description: currentPage.description }));
  }, [dispatch, currentPage]);
}
