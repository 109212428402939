import axios from "axios";
import history from "../../../Utils/history";
import { requestReceived } from "./RolesReducer";
import { baseURL } from "./thunks";

export const createNewRole =
  ({ role, description }) =>
  async (dispatch) => {
    // dispatch(requestLoading());
    try {
      await axios.post(
        baseURL + `roles`,
        { name: role, description },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // dispatch(requestReceived({ detail: "success" }));
      history.push("/admin/roles");
    } catch (error) {
      dispatch(requestReceived({ detail: error }));
      console.error(error);
    }
  };
