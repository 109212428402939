export const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

export const validURL = (str) => {
  let pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return pattern.test(str);
};

export const isAnswered = (question) => {
  return (
    (question?.values || question?.values === 0) &&
    question?.values !== "not checked" &&
    question?.values !== "" &&
    question?.values.length !== 0
  );
};
export const isRequired = (question) => {
  return question?.required;
};
export const isVerified = (question, id, fileObj) => {
  return question.photoVerification ? (fileObj && fileObj.hasOwnProperty(id) ? true : false) : true;
};
export function areQuestionsComplete(questions, fileObj) {
  return Object.keys(questions).reduce((a, question) => {
    return !isRequired(questions[question])
      ? a
      : isAnswered(questions[question]) && isVerified(questions[question], question, fileObj)
      ? a
      : (a = false);
  }, true);
}
