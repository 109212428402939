import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./useStyles";
import { initalizeValidation } from "./Helpers/initalizeValidation";
import { setDidQuestionsChange } from "../../../Documentation/Reducers/DocumentationReducer";
import { setQuestion } from "../../../Editor/Reducers/EditorReducer";
import { widgetList } from "./widgetList";

export function ValueDropdown({ id, showValidation, setWidget }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const didQuestionsChange = useSelector((state) => state.documentation.didQuestionsChange);
  const valueType = useSelector((state) => state.editor?.questions[id].valueType);

  return (
    <FormControl fullWidth className={classes.formControl}>
      <InputLabel id="select-label">Value Type</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        label="Value Type"
        value={valueType}
        onChange={(e) => {
          if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

          if (showValidation || e.target.value === "pass/fail") {
            dispatch(
              setQuestion({
                valueType: e.target.value,
                ...initalizeValidation(id, e.target.value),
              })
            );
            setWidget(widgetList[e.target.value][0]);
          } else {
            dispatch(setQuestion({ id, valueType: e.target.value, validation: {} }));
            setWidget(widgetList[e.target.value][0]);
          }
        }}
      >
        <MenuItem value={"true/false"}>True/False</MenuItem>
        <MenuItem value={"pass/fail"}>Pass/Fail</MenuItem>
        <MenuItem value={"number"}>Number</MenuItem>
        <MenuItem value={"single choice"}>Single Choice</MenuItem>
        <MenuItem value={"multiple choice"}>Multiple Choice</MenuItem>
        <MenuItem value={"decimal"}>Decimal</MenuItem>
        <MenuItem value={"datetime"}>Datetime</MenuItem>
        <MenuItem value={"text"}>Text</MenuItem>
        <MenuItem value={"url"}>Url</MenuItem>
        <MenuItem value={"email"}>Email</MenuItem>
        <MenuItem value={"duration"}>Duration</MenuItem>
      </Select>
    </FormControl>
  );
}
