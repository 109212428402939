import React, { useState } from "react";
import { Dashboard } from "../../../Dashboard/Dashboard";
import { useSelector } from "react-redux";
import { documentationSelector } from "../../Reducers/DocumentationReducer";
import { Breadcrumb } from "../../../Dashboard/Components/Breadcrumbs";
import { Paper, CircularProgress, Typography, Grid } from "@mui/material";
import { TrashCards } from "./TrashCards";
import { useStyles } from "./styles";
import { SnackbarAlert } from "../SnackbarAlert";
import { TrashBreadcrumbs } from "./TrashBreadcrumbs";
import { useTrash } from "../../Hooks/useTrash";

export function TrashBin() {
  const classes = useStyles();
  const { currentFolder, loading, trash } = useSelector(documentationSelector);
  const [severity, setSeverity] = useState("success");
  const [alert, setAlert] = useState();

  useTrash();
  return (
    <Dashboard
      pageName="Trash"
      breadcrumbs={loading ? "" : <Breadcrumb currentValue={`Trash`} linkValues={TrashBreadcrumbs(currentFolder)} />}
    >
      <Paper className={classes.folderPaper}>
        {loading ? (
          <div className={classes.loadingEmpty}>
            <div>
              <CircularProgress />
            </div>
          </div>
        ) : trash.results && trash.results.length ? (
          <div className={classes.folderContainer}>
            <Grid container>
              <TrashCards setSeverity={setSeverity} setAlert={setAlert} />
            </Grid>
          </div>
        ) : (
          <div className={classes.loadingEmpty}>
            <div>
              <Typography>- - Empty - -</Typography>
            </div>
          </div>
        )}
        <SnackbarAlert alert={alert} setAlert={setAlert} severity={severity} />
      </Paper>
    </Dashboard>
  );
}
