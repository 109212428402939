import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getInviteID } from "../Thunks/getInviteID";

export function useInvitationID(params, setInvitation, setError) {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `Invitation to DropDoc`;

    dispatch(getInviteID({ id: params.id }))
      .then((res) => setInvitation(res.data))
      .catch((err) => {
        console.error("error:", err);
        setError(err?.response?.data?.detail);
      });
  }, [dispatch, params.id, setInvitation, setError]);
}
