import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useStyles } from "./useStyles";

export function StepNavContainer({ activeStep, setActiveStep }) {
  const classes = useStyles();
  const question_index = useSelector((state) => state.documentation?.currentDocument?.contents?.question_index);
  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep - 1;
    });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep + 1;
    });
  };

  return (
    <div className={classes.actionsContainer}>
      <div>
        {activeStep === 0 ? (
          ""
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: "1em" }}
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
          >
            Back
          </Button>
        )}
        {activeStep === question_index.length - 1 ? (
          ""
        ) : (
          <Button
            disabled={activeStep === question_index.length}
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.button}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
}
