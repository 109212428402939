import { Button, Checkbox, Fade, FormControlLabel, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert, setSubscriptions } from "../../Documentation/Reducers/DocumentationReducer";
import { createDocumentSubscription } from "../../Documentation/Reducers/Thunks/createDocumentSubscription";
import { createFolderSubscription } from "../../Documentation/Reducers/Thunks/createFolderSubscription";
import { getSubscriptions } from "../../Documentation/Reducers/Thunks/getSubscriptions";
import { useStyles } from "../styles";

export function SubscriptionModal({ openSubs, setOpenSubs, onClose = () => setOpenSubs(false) }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentDocument = useSelector((state) => state.documentation?.currentDocument);
  const currentFolderName = useSelector((state) => state.documentation.currentFolder?.folder?.name);
  const currentFolderID = useSelector((state) => state.documentation.currentFolder?.folder?.id);

  const id = useSelector((state) => state.documentation?.currentDocument?.id);
  const name = useSelector((state) => state.documentation?.currentDocument?.name);

  const [checked, setChecked] = useState({
    invalid: false,
    email: false,
  });
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openSubs}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={openSubs}>
        <div className={classes.modalPaper}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ margin: "0 auto" }}>
              <Typography variant="h5">Subscription Preferences</Typography>
              <hr />
              {!currentDocument ? (
                <Typography style={{ background: "#ffc400" }}>
                  Subscribing to "{currentFolderName}" will notify you of ALL changes to sub-folders and files.
                </Typography>
              ) : (
                ""
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.invalid}
                    onChange={() => setChecked((state) => ({ ...state, invalid: !state.invalid }))}
                    name="Validation Errors Only"
                  />
                }
                label="Validation Errors Only"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.email}
                    onChange={() => setChecked((state) => ({ ...state, email: !state.email }))}
                    name="Send Notification By Email"
                  />
                }
                label="Send Notification By Email"
              />
            </div>

            <div style={{ margin: "0 auto", display: "flex", justifyContent: "center" }}>
              <Button style={{ margin: "1em" }} color="secondary" variant="contained" onClick={onClose}>
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                style={{ margin: "1em" }}
                onClick={() => {
                  currentDocument
                    ? dispatch(
                        createDocumentSubscription({
                          id,
                          validationSubscription: String(checked.invalid),
                          emailSubscription: String(checked.email),
                        })
                      )
                        .then((_) =>
                          dispatch(getSubscriptions()).then((res) => {
                            dispatch(setAlert({ alert: `You have subscribed to ${name}`, severity: "success" }));
                            dispatch(setSubscriptions({ subscriptions: res.data }));
                          })
                        )
                        .catch((e) =>
                          dispatch(
                            setAlert({
                              alert: `Something went wrong with your subscription to ${name}... please try again`,
                              severity: "error",
                            })
                          )
                        )
                    : dispatch(
                        createFolderSubscription({
                          id: currentFolderID,
                          validationSubscription: String(checked.invalid),
                          emailSubscription: String(checked.email),
                        })
                      )
                        .then((_) =>
                          dispatch(getSubscriptions()).then((res) => {
                            dispatch(
                              setAlert({ alert: `You have subscribed to ${currentFolderName}`, severity: "success" })
                            );
                            dispatch(setSubscriptions({ subscriptions: res.data }));
                          })
                        )
                        .catch((e) =>
                          dispatch(
                            setAlert({
                              alert: `Something went wrong with your subscription to ${currentFolderName}... please try again`,
                              severity: "error",
                            })
                          )
                        );

                  onClose();
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
