import { useEffect } from "react";
import { getRoles } from "../../Roles/Reducers/getRoles";
import { listPendingOrgInvites } from "../../Dashboard/Thunks/listPendingOrgInvites";

export function useInvitations(dispatch, setCheckboxes, setPendingOrgInvites) {
  useEffect(() => {
    dispatch(getRoles({ skip: 0, limit: 1000, order: "asc", orderBy: "name", search: "" })).then((res) => {
      if (res.data.results.length) {
        setCheckboxes(
          res.data.results.reduce((acc, cur) => {
            acc[cur.name] = false;
            return acc;
          }, {})
        );
      }
    });
    dispatch(listPendingOrgInvites()).then((res) => {
      setPendingOrgInvites(res.data.results);
    });
  }, [dispatch, setCheckboxes, setPendingOrgInvites]);
}
