import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  title: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(1),
  },
  paper: {
    background: "#f5f5f5",
  },
  toolbar: {
    flexWrap: "wrap",
  },
  link: {
    margin: theme.spacing(1, 1.5),
    color: "white",
  },
}));
