import { AccordionSummary } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { selectColor } from "../../Admin/Users/helpers";

export const useStyles = makeStyles({
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
});
export const AccordionButton = styled(AccordionSummary)`
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;
export const styles = {
  sideDrawer: { height: "100%", display: "flex", flexDirection: "column" },
  staff: { bottom: "0", width: "100%" },
  linkContainer: {
    textDecoration: "none",
    color: "black",
    width: "100%",
  },
  AccordionButton: {
    textDecoration: "none",
    color: "black",
    width: "100%",
  },
  AccordionDetails: {
    flexWrap: "wrap",
    maxHeight: "20em",
    overflow: "hidden auto",
  },
  folder: {
    fontSize: "2em",
    verticalAlign: "middle",
    fill: "#f0dc82",
  },
  document: {
    fontSize: "2em",
    verticalAlign: "middle",
    fill: "cornflowerblue",
  },
  form: {
    fontSize: "2em",
    verticalAlign: "middle",
    fill: "darkcyan",
  },
  report: {
    fontSize: "2em",
    verticalAlign: "middle",
    fill: "#9c27b0",
  },

  sheet: {
    fontSize: "2em",
    verticalAlign: "middle",
    fill: "mediumslateblue",
  },
};
export const AvatarStyle = (initials) => ({
  background: selectColor(initials),
  color: "white",
});
