import React from "react";
import { Badge, ClickAwayListener, Fade, MenuItem, Paper, Popper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  Notifications,
  FolderOpenTwoTone,
  InsertDriveFileTwoTone,
  AssignmentTurnedInTwoTone,
  ListAltTwoTone,
} from "@mui/icons-material";
import history from "../../../Utils/history";
import { markNotificationsRead } from "../../Documentation/Reducers/Thunks/markNotificationsRead";
import { getNotifications } from "../../Documentation/Reducers/Thunks/getNotifications";
import { setNotifications } from "../../Documentation/Reducers/DocumentationReducer";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Link } from "react-router-dom";
import { styles } from "./useStyles";

export const NotificationPopover = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const divRef = React.useRef();
  const notifications = useSelector((state) => state.documentation.notifications);
  const handleClick = (event) => {
    anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;
  const handleNotification = (id) => {
    dispatch(markNotificationsRead({ arr: [id] }));
    dispatch(getNotifications())
      .then((res) => {
        dispatch(setNotifications({ notifications: res.data.results }));
      })
      .catch((err) => console.error(err));
    handleClose();
  };
  const handleClearNotifications = (arr) => {
    dispatch(markNotificationsRead({ arr })).then((_) => {
      dispatch(getNotifications())
        .then((res) => {
          dispatch(setNotifications({ notifications: res.data.results }));
        })
        .catch((err) => console.error(err));
      handleClose();
    });
  };
  const unreadNotifications = notifications.filter((notification) => !notification.read_at);
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div ref={divRef}>
        <Badge
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          color="primary"
          badgeContent={unreadNotifications.length}
        >
          <Notifications onClick={handleClick} style={{ fill: "#fafafa" }} />
        </Badge>
        <Popper
          anchorEl={anchorEl}
          open={open}
          placement={"bottom"}
          onClose={handleClose}
          transition
          modifiers={[
            {
              name: "offset",
              enabled: true,
              options: {
                offset: [0, 5],
              },
            },
          ]}
          style={{ zIndex: 10000, maxHeight: 500, overflow: "auto" }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper id={id}>
                {unreadNotifications.map((notification, index) => {
                  return (
                    <MenuItem
                      style={{ fontSize: "0.75em" }}
                      onClick={() => handleNotification(notification.id)}
                      key={index}
                    >
                      {activityResponse(notification)}
                    </MenuItem>
                  );
                })}
                {unreadNotifications.length ? (
                  <MenuItem
                    style={{ fontSize: "0.75em", justifyContent: "center" }}
                    onClick={() => handleClearNotifications(notifications.map((notification) => notification.id))}
                  >
                    Clear All
                  </MenuItem>
                ) : (
                  <MenuItem style={{ fontSize: "0.75em", justifyContent: "center" }}>Empty</MenuItem>
                )}
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

const isDoctypeIcon = (item) =>
  item.doctype === "presentation" ? (
    <InsertDriveFileTwoTone style={styles.document} />
  ) : item.doctype === "form" ? (
    <AssignmentTurnedInTwoTone style={styles.form} />
  ) : (
    <ListAltTwoTone style={styles.sheet} />
  );

export const activityResponse = (res) => {
  const { activity_type, username, context_data } = res;
  if (activity_type === "DataSubmitted") {
    return (
      <span>
        <ListAltTwoTone style={styles.sheet} /> {username} submitted{" "}
        <span style={{ color: "red" }}>{context_data.validation_errors ? "incorrect" : ""}</span> data to
        <Link to={`/documentation/results/${context_data.form_id}`}> {context_data.form_name}</Link>
      </span>
    );
  }
  if (activity_type === "FolderCreated") {
    return (
      <span onClick={() => history.push(`/documentation/browse/${context_data.id}`)}>
        <CreateNewFolderIcon style={styles.folder} /> {username} created {context_data.name}
      </span>
    );
  }
  if (activity_type === "FolderUpdated") {
    if (context_data.name !== context_data.previous_name) {
      return (
        <span onClick={() => history.push(`/documentation/browse/${context_data.id}`)}>
          <FolderOpenTwoTone style={styles.folder} /> {username} renamed {context_data.previous_name} to{" "}
          {context_data.name}
        </span>
      );
    } else if (context_data.description !== context_data.previous_description) {
      return (
        <span onClick={() => history.push(`/documentaion/browse/${context_data.id}`)}>
          <FolderOpenTwoTone style={styles.folder} /> {username} changed description of {context_data.name}
        </span>
      );
    } else {
      return (
        <span onClick={() => history.push(`/documentation/browse/${context_data.id}`)}>
          <FolderOpenTwoTone style={styles.folder} /> {username} updated name and description of {context_data.name}
        </span>
      );
    }
  }
  if (activity_type === "FolderDeleted") {
    return (
      <span onClick={() => history.push("/documentation/trash/1")}>
        <FolderOpenTwoTone style={styles.folder} /> {username} deleted {context_data.name}
      </span>
    );
  }
  if (activity_type === "FolderMoved") {
    return (
      <span onClick={() => history.push(`/documentation/browse/${context_data.id}`)}>
        <FolderOpenTwoTone style={styles.folder} /> {username} moved {context_data.name}
      </span>
    );
  }
  if (activity_type === "FolderCopied") {
    return (
      <span onClick={() => history.push(`/documentation/browse/${context_data.id}`)}>
        <FolderOpenTwoTone style={styles.folder} /> {username} copied {context_data.name}
      </span>
    );
  }
  if (activity_type === "FolderACLUpdated") {
    return (
      <span onClick={() => history.push(`/documentation/browse/${context_data.id}`)}>
        <FolderOpenTwoTone style={styles.folder} /> {username} updated permissions for {context_data.name}
      </span>
    );
  }
  if (activity_type === "DocumentCreated") {
    return (
      <span onClick={() => history.push(`/documentation/view/${context_data.id}`)}>
        {isDoctypeIcon(context_data)} {username}
        {" created "}
        {context_data.name}
      </span>
    );
  }
  if (activity_type === "DocumentMoved") {
    return (
      <span onClick={() => history.push(`/documentation/view/${context_data.id}`)}>
        {isDoctypeIcon(context_data)} {username} moved {context_data.name}
      </span>
    );
  }
  if (activity_type === "DocumentCopied") {
    return (
      <span onClick={() => history.push(`/documentation/view/${context_data.id}`)}>
        <FileCopyIcon style={styles.document} /> {username} copied {context_data.name}
      </span>
    );
  }
  if (activity_type === "DocumentDeleted") {
    return (
      <span onClick={() => history.push("/documentation/trash/1")}>
        {isDoctypeIcon(context_data)} {username} deleted {context_data.name}
      </span>
    );
  }
  if (activity_type === "DocumentACLUpdated") {
    return (
      <span onClick={() => history.push(`/documentation/view/${context_data.id}`)}>
        {isDoctypeIcon(context_data)} {username} updated permissions for {context_data.name}
      </span>
    );
  }
  if (activity_type === "DocumentUpdated") {
    return (
      <span onClick={() => history.push(`/documentation/${context_data.id}/history`)}>
        {isDoctypeIcon(context_data)} {username} updated {context_data.name}
      </span>
    );
  }
};
