import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUsers } from "../Thunks/getUsers";

export function useGetUsers({ roleFilters = [] }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers({ skip: 0, limit: 1000, order: "", orderBy: "", search: "", role_filters: roleFilters }));
  }, [dispatch, roleFilters]);
}
