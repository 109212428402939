import axios from "axios";
import { baseURL } from "../thunks";

export const createFolder =
  ({ parent_id, name, description }) =>
  async (dispatch) => {
    const { data } = await axios.post(
      baseURL + "documentation/folders",
      {
        parent_id,
        name,
        description,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return data;
  };
