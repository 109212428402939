import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocument } from "../Reducers/thunks";
import { getDocumentHistory } from "../Reducers/Thunks/getDocumentHistory";

export function useDocumentHistory() {
  const currentDocument = useSelector((state) => state.documentation.currentDocument);
  const results = useSelector((state) => state.documentation?.currentDocumentHistory?.results);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (currentDocument === null || !results) {
      dispatch(
        getDocument({
          id: document.location.pathname.split("/")[document.location.pathname.split("/").length - 2],
          isEditor: false,
        })
      );
      dispatch(
        getDocumentHistory({
          id: document.location.pathname.split("/")[document.location.pathname.split("/").length - 2],
        })
      );
    } else {
      return;
    }
  }, [dispatch, currentDocument, results]);
}
