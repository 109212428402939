import { Paper, useTheme } from "@mui/material";
import { styled } from "@mui/styles";
import { DataGridPro, getGridDateOperators, GridOverlay, LicenseInfo } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSpreadsheet } from "../../../Documentation/Reducers/DocumentationReducer";
import { useInitResults } from "../../Hooks/useInitResults";
import { getSpreadsheetData } from "../../Thunks/getSpreadsheetData";
import { LoadingSpinner } from "../LoadingSpinner";
import { SpreadsheetDialog } from "./Components/SpreadsheetDialog/SpreadsheetDialog";
import { CustomToolbar } from "./CustomToolbar";
import { foldQuestionFields } from "./foldQuestionFields";
import { last30days } from "./helpers";
import { useStyles } from "./useStyles";
LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_KEY}`);
const StripedDataGrid = styled(DataGridPro)`
  .empty {
    background-color: yellow;
  }
`;

export const SpreadsheetViewer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.documentation.currentDocument.contents?.questions);
  const question_index = useSelector((state) => state.documentation.currentDocument.contents?.question_index);
  const results = useSelector((state) => state.documentation.spreadsheet.results);
  const id = useSelector((state) => state.documentation.currentDocument.id);
  const [filterModel, setFilterModel] = React.useState({
    items: [{ columnField: "Datetime", operatorValue: "onOrAfter", value: last30days(), id: 1000 }],
  });
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [selectionModel, setSelectionModel] = React.useState([0]);
  useInitResults({
    setLoading,
  });
  const QuestionFields = question_index ? question_index.reduce(foldQuestionFields(questions, dispatch), []) : [];
  const columns = [
    {
      field: "Datetime",
      flex: 1,
      sortComparator: (a, b) => {
        return Date.parse(a) - Date.parse(b);
      },
      filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === "onOrAfter" || operator.value === "onOrBefore"
      ),
      type: "date",
    },
    {
      field: "Username",
      flex: 1,
      sortComparator: (a, b) => {
        return String(a) > String(b) ? 1 : -1;
      },
      type: "string",
    },
    ...QuestionFields,
  ];
  const rows = results
    ? results?.slice().map((res, index) => {
        const { created_at, created_by_username, record } = res;
        let [month, date, year] = new Date(created_at).toLocaleDateString("en-US").split("/");
        let [hour, minute, , pm] = new Date(created_at).toLocaleTimeString("en-US").split(/:| /);
        let altPM = new Date(created_at).toLocaleTimeString("en-US");

        return {
          id: index,
          Datetime: `${year}/${month}/${date} ${hour}:${minute} ${pm || (altPM.includes("AM") ? "AM" : "PM") || ""}`,
          Username: created_by_username,
          ...Object.keys(record).reduce((accumulator, currentRecord) => {
            accumulator[currentRecord] = record[currentRecord];
            return accumulator;
          }, {}),
        };
      })
    : [];
  return (
    <Paper className={classes.root}>
      {loading || !results ? (
        <LoadingSpinner />
      ) : (
        <StripedDataGrid
          columns={columns}
          rows={rows}
          initialState={{
            sorting: {
              sortModel: [{ field: "Datetime", sort: "desc" }],
            },
          }}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) => {
            const isDatetimeOld = filterModel?.items?.filter((item) => item.columnField === "Datetime")?.length;
            const isDatetimeNew = newFilterModel?.items?.filter((item) => item.columnField === "Datetime")?.length;
            if (isDatetimeOld === 1 && isDatetimeNew === 0) {
              dispatch(
                getSpreadsheetData({
                  id,
                })
              )
                .then((res) => {
                  const { results, total } = res.data;
                  dispatch(setSpreadsheet({ results, total }));

                  setLoading(false);
                })
                .catch((e) => console.error(e));
            } else {
              newFilterModel?.items?.forEach((item) => {
                let { columnField, operatorValue, value } = item;
                if (columnField === "Datetime" && value) {
                  let time = new Date(value).getTime() / 1000;
                  dispatch(
                    getSpreadsheetData(
                      operatorValue === "onOrBefore"
                        ? {
                            id,
                            start_time: 0,
                            end_time: time,
                          }
                        : {
                            id,
                            start_time: time,
                            end_time: Math.floor(new Date().getTime() / 1000),
                          }
                    )
                  )
                    .then((res) => {
                      const { results, total } = res.data;
                      dispatch(setSpreadsheet({ results, total }));

                      setLoading(false);
                    })
                    .catch((e) => console.error(e));
                }
              });
            }

            setFilterModel(newFilterModel);
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          getCellClassName={(params) => (params.value === "" ? "empty" : "")}
          sx={{
            ".empty": {
              backgroundColor: "#ffff0033",
            },
          }}
          components={{
            Toolbar: () =>
              CustomToolbar({
                selectionModel,
                selectedRow: results ? results[selectionModel[0]]?.id : "",
                filterModel,
              }),
            NoRowsOverlay: () => <GridOverlay style={{ flexDirection: "column" }}>No Results Found</GridOverlay>,
            Footer: () => (
              <div style={{ margin: "0px 16px", display: "flex", alignItems: "center" }}>
                Total Results: {results ? results.length : 0}
              </div>
            ),
          }}
        />
      )}
      <SpreadsheetDialog theme={theme} />
    </Paper>
  );
};
