import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { received } from "../Documentation/Reducers/DocumentationReducer";
import { LoadingSpinner } from "../Viewer/Components/LoadingSpinner";
import { createCheckoutSession } from "./Thunks/createCheckoutSession";
import { getProductList } from "./Thunks/getProductList";

const formatUSD = (num) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(num);

export const Checkout = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.documentation.loading);
  const handleCheckout = (price_id) =>
    dispatch(createCheckoutSession({ price_id })).then((res) => {
      if (res?.data?.redirect) {
        window.open(res?.data?.redirect, "_top");
      }
    });
  const [products, setProducts] = useState(null);
  useEffect(() => {
    dispatch(getProductList()).then((res) => {
      setProducts(res.data);
      dispatch(received({ error: false }));
    });
  }, [dispatch]);

  return loading ? (
    <LoadingSpinner />
  ) : products ? (
    <div style={{ height: "80%", padding: "24px", overflowY: "auto", display: "block" }}>
      {products.map((product, index) => {
        return (
          <Card key={index} style={{ padding: "1em", display: "block" }} variant="outlined">
            <CardContent>
              <Typography gutterBottom variant="h5">
                {product.product.name}
              </Typography>
              <Typography gutterBottom variant="subtitle1">
                {product.product.description}
              </Typography>
              <Typography variant="subtitle2">
                {formatUSD(product.unit_amount / 100)}
                {" " + product.currency.toUpperCase()}/{product.recurring.interval}
              </Typography>
              <CardActions>
                <Button onClick={() => handleCheckout(product.id)} size="small" color="primary" variant="contained">
                  Subscribe
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        );
      })}
    </div>
  ) : (
    ""
  );
};
