export const initialState = {
  results: null,
  q: "",
  include_folders: true,
  in_folder: 0,
  created_before: null,
  created_after: null,
  modified_before: null,
  modified_after: null,
};
