import axios from "axios";
import { baseURL } from "../thunks";

export const discardDocumentChanges =
  ({ currentDocument }) =>
  async (dispatch) => {
    console.log("discardChanges called");

    try {
      const putReq = await axios({
        method: "put",
        url: baseURL + "documentation/documents/" + currentDocument?.id,
        data: {
          ...currentDocument,
          draft_contents: null,
          draft_user_id: null,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      return putReq;
    } catch (e) {
      console.error(e);
    }
  };
