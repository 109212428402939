import React from "react";
import {
  FolderOpenTwoTone,
  InsertDriveFileTwoTone,
  AssignmentTurnedInTwoTone,
  ListAltTwoTone,
  SummarizeTwoTone,
} from "@mui/icons-material/";
import { styles } from "./styles";

export function isFolderIcon(x) {
  return x.kind === "folder" ? (
    <FolderOpenTwoTone style={styles.folder} />
  ) : x.doctype === "presentation" ? (
    <InsertDriveFileTwoTone style={styles.document} />
  ) : x.doctype === "form" ? (
    <AssignmentTurnedInTwoTone style={styles.form} />
  ) : x.doctype === "report" ? (
    <SummarizeTwoTone style={styles.report} />
  ) : (
    <ListAltTwoTone style={styles.sheet} />
  );
}
