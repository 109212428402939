import React from "react";
import { Accordion, ListItem, ListItemIcon, ListItemText, AccordionDetails } from "@mui/material";
import { FolderOpen, ExpandMore } from "@mui/icons-material/";
import { Link } from "react-router-dom";
import { LinkContainer } from "./LinkContainer";
import { AccordionButton, styles } from "./useStyles";

export function QuickLinks(classes, onClick, rootResults) {
  return (
    <Accordion
      style={{
        boxShadow: "none",
      }}
      classes={{
        root: classes.MuiAccordionroot,
      }}
      defaultExpanded
    >
      <AccordionButton expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
        <Link style={styles.AccordionButton} onClick={onClick} to={`/documentation/browse/`}>
          <ListItem style={{ padding: 0 }} key={"Browse Documents"} onClick={onClick}>
            <ListItemIcon>{<FolderOpen />}</ListItemIcon>
            <ListItemText primary={"Browse Documents"} />
          </ListItem>
        </Link>
      </AccordionButton>
      <AccordionDetails style={styles.AccordionDetails}>
        {rootResults &&
          rootResults.map((folder, i) => (
            <LinkContainer
              text={folder.name}
              icon={<FolderOpen />}
              key={i}
              onClick={onClick}
              to={`/documentation/browse/${folder.id}`}
            />
          ))}
      </AccordionDetails>
    </Accordion>
  );
}
