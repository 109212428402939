import React, { useEffect } from "react";
import { useStyles } from "./styles";
import { RegistrationForm } from "./Components/RegistrationForm";
import { Container, Typography, Grid, CssBaseline, AppBar, Toolbar } from "@mui/material";
export const Registration = () => {
  const classes = useStyles();
  useEffect(() => {
    document.title = `Registration`;
  }, []);
  return (
    <>
      <CssBaseline />
      <AppBar position="sticky" color="primary" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            DropDoc
          </Typography>
        </Toolbar>
      </AppBar>{" "}
      <Container style={{ marginTop: "2em" }} maxWidth="md">
        <Grid container>
          <RegistrationForm />
        </Grid>
      </Container>
    </>
  );
};
