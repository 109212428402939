import axios from "axios";
import { updateCurrentFolderContents } from "../../DocumentationReducer";
import { baseURL } from "../../thunks";

export async function getThrottledResults({ ID, dispatch, skip, limit, total, params }) {
  skip += limit;
  if (total > limit) {
    while (skip <= total) {
      params.set("skip", skip);
      const { data } = await axios.get(baseURL + "documentation/folders/" + ID + "/contents", {
        params,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      let path = window.location.pathname.split("/");
      let isFolderChanged = ID === path[path.length - 1] || (ID === "root" && path[path.length - 1] === "browse");
      if (isFolderChanged) {
        dispatch(updateCurrentFolderContents({ results: data.results }));
      } else {
        //cancel!
        return;
      }
      skip += limit;
    }
  }
}
