import { Add, AssignmentTurnedInTwoTone, Link, Remove } from "@mui/icons-material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { IconButton, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";
import { getChecklist } from "../../../Documentation/Reducers/Thunks/getChecklist";
import styles from "./CustomNode.module.css";
import { handleRemove } from "./handleRemove";
export const CustomNode = (props) => {
  const {
    dispatch,
    setSource,
    setDestination,
    node,
    setOpenSelection,
    setOpen,
    setTreeID,
    tree,
    onToggle,
    depth,
    isOpen,
    hasChild,
  } = props;

  const indent = depth * 24;
  const form_ids = useSelector((state) => state.editor?.form_ids);

  const handleToggle = (e) => {
    console.log("called");
    e.stopPropagation();
    onToggle(node.id);
  };
  return (
    <div
      className={`tree-node ${styles.root}`}
      style={{
        paddingInlineStart: indent,
        marginBottom: "1em",
      }}
    >
      <div className={`${styles.expandIconWrapper} ${isOpen ? styles.isOpen : ""}`}>
        {node.droppable && hasChild && (
          <div onClick={handleToggle}>
            <ArrowRightIcon />
          </div>
        )}
      </div>
      <div>
        <AssignmentTurnedInTwoTone
          style={{
            fontSize: "1em",
            fill: "darkcyan",
          }}
        />
      </div>
      <div className={styles.labelGridItem}>
        <ListItemText
          primary={node.name}
          secondary={node?.path ? node.path.map((x) => x.name).join(" / ") : ""}
          secondaryTypographyProps={{
            sx: {
              fontSize: "0.9em",
            },
          }}
        />
      </div>
      <div>
        {node.parent_question_id || node.question_id ? (
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const fetchChecklists = async () => {
                return await Promise.all(
                  form_ids ? form_ids.map((id) => dispatch(getChecklist({ id, currentFolderId: "root" }))) : []
                );
              };
              fetchChecklists()
                .then((res) => {
                  let formID = res.reduce((acc, checklist) => {
                    if (checklist?.contents?.question_index.includes(node.question_id)) {
                      acc["source"] = checklist.id;
                    }
                    if (checklist?.contents?.question_index.includes(node.parent_question_id)) {
                      acc["destination"] = checklist.id;
                    }

                    return acc;
                  }, {});
                  if (!formID.source || !formID.destination) {
                    return;
                  } else {
                    setSource(parseInt(formID.source));
                    setDestination(parseInt(formID.destination));
                    setOpenSelection(true);
                  }
                })
                .catch((e) => console.log(e));
            }}
          >
            <Link />
          </IconButton>
        ) : (
          ""
        )}
        <IconButton onClick={handleRemove(form_ids, node, dispatch, tree)} size={"small"}>
          <Remove />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setTreeID(node.id);
            setOpen(true);
          }}
          size={"small"}
        >
          <Add />
        </IconButton>
      </div>
    </div>
  );
};
