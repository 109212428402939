import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectShape } from "../../Reducers/EditorReducer";
import { IsSquareOrCircle } from "./IsSquareOrCircle";
import { Arrow } from "./Arrow";
export const Shape = ({ x, y, width, height, color, rotation, id, selected, shape, holding, strokeWidth }) => {
  const dispatch = useDispatch();
  const selectedState = useSelector((state) => state.editor.selected);
  const scaleX = useSelector((state) => state.editor.scaleX);
  const scaleY = useSelector((state) => state.editor.scaleY);
  const handleClick = (e) => {
    if ((!selectedState || !selected) && !holding) dispatch(selectShape({ id: e.target.id }));
  };
  const shapeStyles = IsSquareOrCircle(y, scaleY, x, scaleX, width, height, color, strokeWidth, shape, rotation);

  const arrow = [y, scaleY, x, scaleX, width, height, rotation, color, id, handleClick];
  const isArrow = shape === "arrow" ? Arrow(...arrow) : <div id={id} style={shapeStyles} onClick={handleClick}></div>;
  return isArrow;
};
