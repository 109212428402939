import React from "react";
import { ListItem, ListItemIcon, ListItemText, Paper, ListSubheader, List } from "@mui/material";
import { PeopleAlt, Work, Settings, Timeline, AddBusiness } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Dashboard } from "../Dashboard/Dashboard";
import { Breadcrumb } from "../Dashboard/Components/Breadcrumbs";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export function ListItemLink(props) {
  return <ListItem button component={RouterLink} {...props} />;
}

export const Admin = () => {
  const classes = useStyles();
  return (
    <Dashboard
      pageName="Admin"
      breadcrumbs={<Breadcrumb currentValue={"Admin"} linkValues={[{ name: "Home", to: "/" }]} />}
    >
      <div style={{ padding: "1em" }}>
        <Paper elevation={3}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Users
              </ListSubheader>
            }
            className={classes.root}
          >
            <ListItemLink to={`/admin/users`}>
              <ListItemIcon>
                <PeopleAlt style={{ fill: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItemLink>
            <ListItemLink to={`/admin/roles`}>
              <ListItemIcon>
                <Work style={{ fill: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Roles" />
            </ListItemLink>
          </List>
        </Paper>

        <Paper elevation={3} style={{ marginTop: "1em" }}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Activity Log
              </ListSubheader>
            }
            className={classes.root}
          >
            <ListItemLink to={`/admin/activity-log`}>
              <ListItemIcon>
                <Timeline style={{ fill: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Activity Log" />
            </ListItemLink>
          </List>
        </Paper>
        <Paper elevation={3} style={{ marginTop: "1em" }}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Settings
              </ListSubheader>
            }
            className={classes.root}
          >
            <ListItemLink to={`/admin/settings`}>
              <ListItemIcon>
                <Settings style={{ fill: "black" }} />
              </ListItemIcon>
              <ListItemText primary="General Settings" />
            </ListItemLink>
            <ListItemLink to={`/admin/create-org`}>
              <ListItemIcon>
                <AddBusiness style={{ fill: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Create New Organization" />
            </ListItemLink>
          </List>
        </Paper>
      </div>
    </Dashboard>
  );
};
