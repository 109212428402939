import axios from "axios";
import { baseURL } from "../thunks";

export const getACLSubjects = () => async (dispatch) => {
  const { data } = await axios.get(baseURL + "auth/acl-subjects", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return data;
};
