import axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";
import { handleLogout } from "./handleLogout";

export const listActiveLoginSessions = () => async (dispatch) => {
  try {
    const data = await axios({
      method: "get",
      url: baseURL + "auth/me/sessions",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return data;
  } catch (e) {
    console.error(e);
    if (e.response.data.detail === "Could not validate credentials") {
      handleLogout(dispatch)();
    }
    return e;
  }
};
