import retry from 'retry';
import Axios from 'axios';

export const retryRequest = (request) => {
    const operation = retry.operation({
      retries: 3,
      minTimeout: 500,
      maxTimeout: 3000,
      randomize: true,
    });
    return new Promise((resolve, reject) => {
      operation.attempt(async currentAttempt => {
           let response;
           if (currentAttempt > 1) {
               console.log(`Attempt #${currentAttempt}`);
           }
           try {
            response = await Axios(request);
            resolve(response);
           } catch (e) {
               if (operation.retry(e)) {
                   return;
               }
               reject(operation.mainError());
           }
    });
  })
}