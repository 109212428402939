import { AppBar, Toolbar, Typography } from "@mui/material";
import { Dashboard } from "../Dashboard";
import ErrorIcon from "@mui/icons-material/Error";
import { Link } from "react-router-dom";
export const NotFound404 = ({ loggedIn }) => {
  return loggedIn ? (
    <>
      <Dashboard pageName="404">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <div style={{ textAlign: "center" }}>
            <ErrorIcon style={{ color: "#af3c3c", fontSize: "8em" }} />
            <Typography component={"div"} variant="h4">
              404! Page Not Found.
            </Typography>
          </div>
          <div>
            <Link to="/">Return to Dashboard</Link>
          </div>
        </div>
      </Dashboard>
    </>
  ) : (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography edge="start" variant="h6" style={{ flexGrow: 1 }}>
            404
          </Typography>
          <div style={{ textAlign: "center" }}>
            <ErrorIcon style={{ color: "#af3c3c", fontSize: "8em" }} />
            <Typography component={"div"} variant="h4">
              404! Page Not Found.
            </Typography>
          </div>
          <div>
            <Link to="/login">Return to Login</Link>
          </div>
        </Toolbar>
      </AppBar>{" "}
    </>
  );
};
