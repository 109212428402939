import history from "../../../Utils/history";
import { received, setAlert, setIsBlocking } from "../Reducers/DocumentationReducer";
export function handleError(dispatch, currentDocument) {
  return (e) => {
    dispatch(received({ error: true }));
    console.log(e);
    currentDocument?.id
      ? history.push(`/documentation/edit/${currentDocument.id}`)
      : history.push(`/documentation/browse`);
    dispatch(setIsBlocking({ isBlocking: false }));
    dispatch(
      setAlert({
        alert: "Your document could not be saved at this time, please try again, or contact support.",
        severity: "warning",
      })
    );
  };
}
