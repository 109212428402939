export const reducers = {
  loading: (state, action) => {
    const { isLoading } = action.payload;
    state.loading = isLoading;
  },
  tokenLoading: (state) => {
    if (state.loading === false) {
      state.loading = true;
    }
  },
  tokenReceived: (state, action) => {
    const { error } = action.payload;
    if (state.loading) {
      state.loading = false;
    }
    if (error) {
      state.error = true;
    } else {
      state.error = false;
    }
  },
  logoutUser: (state) => {
    state.username = "";
    state.email = "";
    state.full_name = "";
    state.password = "";
    state.is_org_admin = "";
    state.roles = [];
    state.authenticated = false;
    state.otp_enabled = false;
    state.loading = false;
  },
  setLoggedInUser: (state, action) => {
    const {
      username,
      email,
      full_name,
      is_org_admin,
      org,
      roles,
      authenticated,
      avatar,
      otp_enabled,
      available_orgs,
      invitations,
      assignments,
    } = action.payload;
    state.username = username;
    state.email = email;
    state.full_name = full_name;
    state.is_org_admin = is_org_admin;
    state.org = org;
    state.assignments = assignments;
    state.roles = roles;
    state.authenticated = authenticated;
    state.avatar = avatar;
    state.otp_enabled = otp_enabled;
    state.available_orgs = available_orgs;
    state.invitations = JSON.stringify(invitations) === "[]" ? null : invitations;
  },
  setAssignments: (state, action) => {
    const { assignments } = action.payload;
    state.assignments = assignments;
  },
  resetPasswordSent: (state) => {
    if (state.loading === false && state.sent !== "sending") {
      state.loading = true;
      state.sent = "sending";
    }
  },
  resetPasswordReceived: (state, action) => {
    const { error } = action.payload;
    if (state.loading) {
      state.loading = false;
    }
    if (error) {
      state.sent = "error";
    } else {
      if (state.sent === "sent") {
        state.sent = "";
      } else {
        state.sent = "sent";
      }
    }
  },
  isTokenExpired: (state, action) => {
    const { expired } = action.payload;
    if (expired) {
      state.expired = true;
    } else {
      state.expired = false;
    }
  },
  successfulReset: (state, action) => {
    const { success } = action.payload;
    if (success) {
      state.successfulReset = true;
    } else {
      state.successfulReset = false;
    }
  },
};
