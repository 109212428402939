import React, { useState } from "react";
import { IconButton, Popover, ListItem, ListItemIcon, ListItemText, Grid } from "@mui/material";
import { MoreVert, RestoreFromTrash } from "@mui/icons-material/";
import { restoreFromTrash } from "../../Reducers/thunks";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { handleError } from "../../Helpers/handleError";
import { handleGetTrash } from "./handleGetTrash";

export const TrashPopover = ({ x, setSeverity, setAlert }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setOpen] = useState(Boolean(anchorEl));
  const dispatch = useDispatch();
  const { id } = useParams();
  return (
    <Grid key={x.name + x.id} item style={{ display: "flex", alignSelf: "center", height: "100%" }} xs={1}>
      <IconButton
        aria-label="options"
        size="small"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setOpen(true);
        }}
        id={x.name}
        disableFocusRipple
      >
        <MoreVert />
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClick={() => setOpen(false)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <ListItem
          button
          aria-label="restore from trash"
          id={x.name}
          onClick={(e) =>
            dispatch(restoreFromTrash({ id: x.id, destination_id: id, kind: x.kind }))
              .then(handleGetTrash(dispatch, id, x.name, setSeverity, setAlert))
              .catch(handleError(dispatch))
          }
        >
          <ListItemIcon>
            <RestoreFromTrash />
          </ListItemIcon>
          <ListItemText>Restore</ListItemText>
        </ListItem>
      </Popover>
    </Grid>
  );
};
