import axios from "axios";
import { handle403 } from "../../../Dashboard/Hooks/Helpers/handle403";
import { received } from "../DocumentationReducer";
import { baseURL } from "../thunks";

export const getSubscriptions = () => async (dispatch) => {
  try {
    const getReq = await axios({
      method: "get",
      url: baseURL + "documentation/subscriptions",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return getReq;
  } catch (err) {
    handle403(err, dispatch);
    dispatch(received({ error: true }));
  }
};
