export const reducers = {
  setSearch: (state, action) => {
    const { q, results, include_folders } = action.payload;
    if (q || q === "") {
      state.q = q;
    }
    if (results) {
      state.results = results;
    }
    if (include_folders) {
      state.include_folders = !state.include_folders;
    }
  },
};
