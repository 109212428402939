import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectShape, setShapes, setZoom, setZoomOffset } from "../Editor/Reducers/EditorReducer";
import { FiberManualRecord } from "@mui/icons-material/";
import Markdown from "react-markdown";
import { Divider } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "./helpers";
import { AnnotationGrid } from "./styles";

export function AnnotationList({ isEditor, shapeRect }) {
  const selected = useSelector((state) => state.editor.selected);
  const shapes = useSelector((state) => state.editor.shapes);
  const dispatch = useDispatch();

  const canvasRect = document.getElementById("canvas")?.getBoundingClientRect();
  const canvasMiddle = {
    y: canvasRect?.top + canvasRect?.height / 2,
    x: canvasRect?.left + canvasRect?.width / 2,
  };

  function onDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    const newOrder = reorder(shapes, result.source.index, result.destination.index);
    dispatch(setShapes({ shapes: newOrder, dragging: true }));
  }

  const MapShapes = (shape) => {
    const isSelected = shape.id === selected?.id;
    return (
      <div key={shape.id}>
        <AnnotationGrid
          onClick={() => {
            dispatch(selectShape({ id: shape.id }));
            if (!isEditor && !shapeRect?.current) {
              shapeRect.current = shapes.reduce((allShapes, shape) => {
                const rect = document.getElementById(shape.id)?.getBoundingClientRect();
                const shapeMiddle = {
                  y: rect?.top + rect?.height / 2,
                  x: rect?.left + rect?.width / 2,
                };
                allShapes[shape.id] = shapeMiddle;
                return allShapes;
              }, {});
            }
            if (!isEditor && selected && selected.id === shape.id) {
              const newZoom = 2;
              dispatch(setZoom({ zoom: newZoom }));
              dispatch(
                setZoomOffset({
                  top: canvasMiddle?.y * newZoom - shapeRect.current[selected.id]?.y * newZoom,
                  left: canvasMiddle?.x * newZoom - shapeRect.current[selected.id]?.x * newZoom,
                })
              );
            }
          }}
          // item
          // container
          style={{ cursor: "pointer", backgroundColor: `${isSelected ? "rgba(0, 0, 0, 0.08)" : "initial"}` }}
        >
          {isSelected ? (
            <div
              style={{
                display: "flex",
              }}
            >
              <FiberManualRecord
                fontSize="small"
                style={{
                  color: shape.color,
                  alignSelf: shape.description.split(/\n\n/g).length === 1 && "center",
                }}
              />
              <div
                style={{
                  maxHeight: 200,
                  overflowY: "auto",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <Markdown source={shape.description || "-- empty --"} />
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <FiberManualRecord fontSize="small" style={{ color: shape.color }} />
              <Markdown source={shape.description.split(/\n\n/g)[0] || "-- empty --"} />
            </div>
          )}
        </AnnotationGrid>
        <Divider style={{ marginTop: 1 }} />
      </div>
    );
  };
  return shapes && shapes.length ? (
    isEditor ? (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {shapes.map((shape, i) => {
                  return (
                    <Draggable key={`draggable${shape.id}`} draggableId={`draggable${shape.id}`} index={i}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          {MapShapes(shape)}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    ) : (
      shapes.map(MapShapes)
    )
  ) : (
    ""
  );
}
