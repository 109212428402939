import { widgetList } from "../../Viewer/Components/EditChecklistCard/widgetList";
import {
  isNotSelected,
  mapDescription,
  angleBetweenPoints,
  handleMove,
  handleNotifications,
  handleMultipleChoice,
} from "./helpers";
import {
  rightFn,
  leftFn,
  bottomFn,
  topFn,
  bottomRightFn,
  bottomLeftFn,
  topRightFn,
  topLeftFn,
  shapeMeasurements,
} from "./setScaleHelpers";

export const reducers = {
  setScaleFactor: (state, action) => {
    const { scaleX, scaleY } = action.payload;
    state.scaleX = scaleX;
    state.scaleY = scaleY;
  },
  setShapes: (state, action) => {
    const { shapes } = action.payload;
    state.shapes = shapes;
  },
  addShape: (state, action) => {
    const { id, x, y, shape } = action.payload;
    if (state.selected !== null) return;
    const newShape = {
      id,
      x,
      y,
      shape,
      width: 60 / state.scaleX,
      height: 60 / state.scaleY,
      color: "red",
      strokeWidth: 1,
      selected: true,
      description: "",
      rotation: 0,
    };
    state.shapes.push(newShape);
    state.shapes = state.shapes.map((shape) => {
      if (shape.id !== id) {
        shape.selected = false;
      } else {
        state.selected = shape;
        state.selected.selected = true;
      }
      return shape;
    });
    state.selected = newShape;
  },
  removeShape: (state) => {
    state.shapes = state.shapes.filter((shape) => {
      return !shape.selected;
    });
    state.selected = null;
  },
  setPageDescription: (state, action) => {
    const { description } = action.payload;
    state.description = description;
  },
  selectShape: (state, action) => {
    const { id } = action.payload;
    state.shapes = state.shapes.map((shape) => {
      if (shape.id === id) {
        shape.selected = true;
        state.selected = shape;
      } else {
        shape.selected = false;
      }
      return shape;
    });
    if (state.shapes.every(isNotSelected)) {
      state.selected = null;
      return;
    }
  },
  setShapeDescription: (state, action) => {
    const { id, description, submitting } = action.payload;
    if (description || description === "") state.selected.description = description;
    if (id && description) {
      state.shapes = state.shapes.map(mapDescription(id, description));
    }
    if (submitting) {
      state.selected = null;
    }
  },
  deselectShapes: (state) => {
    state.selected = null;
  },
  setTool: (state, action) => {
    const { tool } = action.payload;
    state.tool = tool;
  },
  setScale: (state, action) => {
    const { x, y, handle } = action.payload;
    const shape = state.shapes.filter((s) => s.id === state.selected.id)[0];
    const { center, topLeft, top, topRight, left, right, bottomLeft, bottom, bottomRight } = shapeMeasurements({
      shape,
    });
    const props = { center, x, y, shape, state };
    if (handle === "topLeft") topLeftFn({ right, bottom, bottomRight, ...props });
    if (handle === "topRight") topRightFn({ left, bottom, bottomLeft, ...props });
    if (handle === "bottomLeft") bottomLeftFn({ right, top, topRight, ...props });
    if (handle === "bottomRight") bottomRightFn({ topLeft, ...props });
    if (handle === "top") topFn({ bottom, top, ...props });
    if (handle === "bottom") bottomFn({ top, bottom, ...props });
    if (handle === "left") leftFn({ right, left, ...props });
    if (handle === "right") rightFn({ left, right, ...props });
  },
  setPosition: (state, action) => {
    const { x, y } = action.payload;
    const shape = state.shapes.filter((s) => s.id === state.selected.id)[0];
    const centerX = x - state.selected.width / 2;
    const centerY = y - state.selected.height / 2;
    state.selected.x = centerX;
    shape.x = centerX;
    state.selected.y = centerY;
    shape.y = centerY;
  },
  setRotation: (state, action) => {
    const { x, y } = action.payload;
    const shape = state.shapes.filter((s) => s.id === state.selected.id)[0];
    const pt2 = { x: state.selected.x + state.selected.width / 2, y: state.selected.y + state.selected.height / 2 };
    const pt1 = { x, y };
    const angle = angleBetweenPoints({ pt1, pt2 });
    state.selected.rotation = angle - 180;
    shape.rotation = angle - 180;
  },
  setColor: (state, action) => {
    const { color } = action.payload;
    const shape = state.shapes.filter((s) => s.id === state.selected.id)[0];
    shape.color = color;
  },
  setStrokeWidth: (state, action) => {
    const { strokeWidth } = action.payload;
    const shape = state.shapes.filter((s) => s.id === state.selected.id)[0];
    shape.strokeWidth = strokeWidth;
  },
  setZoom: (state, action) => {
    const { zoom } = action.payload;
    state.zoom = zoom;
  },
  setZoomOffset: (state, action) => {
    const { top, left } = action.payload;
    state.zoomOffset = {
      top,
      left,
    };
  },
  setContainedSize: (state, action) => {
    const { height, width } = action.payload;
    state.containedSize = { height, width };
  },

  setQuestion: (state, action) => {
    const {
      id,
      title,
      description,
      photoVerification,
      allowComments,
      valueType,
      widget,
      required,
      value,
      comments,
      multiple,
      textField,
      validation,
      actionType,
      roleID,
      userID,
    } = action.payload;
    if (title || title === "") {
      state.questions[id].title = title;
    }
    if (description || description === "") {
      state.questions[id].description = description;
    }
    if (photoVerification === false || photoVerification) {
      state.questions[id].photoVerification = photoVerification;
    }
    if (allowComments === false || allowComments) {
      state.questions[id].allowComments = allowComments;
    }
    if (valueType) {
      state.questions[id].valueType = valueType;
      state.questions[id].choices = [];
      state.questions[id].widget = Array.isArray(widgetList[valueType])
        ? widgetList[valueType][0]
        : widgetList[valueType];
    }
    if (widget) {
      state.questions[id].widget = widget;
    }
    if (required === false || required) {
      state.questions[id].required = required;
    }
    if (value || value === "" || value === 0) {
      if (multiple) {
        handleMultipleChoice(state, id, value);
      } else {
        state.questions[id].values = value;
      }
    }
    if (textField || textField === "") {
      state.questions[id].values = textField;
    }
    if (comments || comments === "") {
      state.questions[id].comments = comments;
    }
    if (validation) {
      state.questions[id].validation = validation;
    }
    if (actionType) {
      state.questions[id].validation.action.type = actionType;
    }
    if (roleID) {
      handleNotifications(state, id, "roles", roleID);
    }
    if (userID) {
      handleNotifications(state, id, "users", userID);
    }
  },
  setMultipleChoice: (state, action) => {
    const { id, direction, remove, add, index } = action.payload;
    if (add) {
      state.questions[id].choices = [...state.questions[id].choices, add];
    }
    if (remove) {
      state.questions[id].choices = state.questions[id].choices.filter((x) => x !== remove);
    }
    if (direction) {
      state.questions[id].choices = handleMove(state.questions[id].choices, direction, index);
    }
  },
  addQuestion: (state, action) => {
    const { id } = action.payload;
    state.questions[id] = {
      media: "",
      title: "",
      description: "",
      media_thumb: "",
      photoVerification: false,
      allowComments: false,
      required: true,
      valueType: "true/false",
      choices: [],
      widget: "radio buttons",
      validation: {},
    };
  },
  addQuestionSpreadsheet: (state, action) => {
    const { id, question, form_id } = action.payload;
    state.questions[id] = { ...question, form_id };
  },
  removeQuestion: (state, action) => {
    const { id } = action.payload;
    if (Object.keys(state.questions).length === 1) {
      state.questions = {};
      // state.form_ids = null;
    } else {
      state.questions = Object.keys(state.questions).reduce((a, c) => {
        if (c !== id) {
          a[c] = state.questions[c];
        }
        return a;
      }, {});
      // state.form_ids = state.form_ids.filter((form_id) => form_id === id);
    }
  },
  setQuestionMedia: (state, action) => {
    const { media, filename, content_type, lastModified, verification, id } = action.payload;
    if (media || media === "") {
      state.questions[id].media = media;
    }
    if (filename || filename === "") {
      state.questions[id].filename = filename;
    }
    if (content_type || content_type === "") {
      state.questions[id].content_type = content_type;
    }
    if (lastModified || lastModified === "") {
      state.questions[id].lastModified = lastModified;
    }
    if (verification) {
      state.questions[id].verification = verification;
    }
  },
  setQuestions: (state, action) => {
    const { questions, isEditor, reset, isLocalStorage, add, init } = action.payload;
    if (init) {
      initQuestions(state, questions);
    }
    if (reset) {
      state.questions = null;
      state.form_ids = null;
    } else {
      if (isEditor) {
        if (add) {
          if (!state.questions) {
            state.questions = { ...add };
          } else {
            state.questions = { ...state.questions, ...add };
          }
        } else {
          state.questions = questions;
        }
      } else {
        if (questions) {
          if (isLocalStorage) {
            state.questions = questions;
          } else {
            initQuestions(state, questions);
          }
        }
      }
    }
  },
  setSaving: (state, action) => {
    const { saving } = action.payload;

    state.saving = saving;
  },
  setTree: (state, action) => {
    const { tree, reset } = action.payload;
    if (reset) {
      state.tree = null;
    } else {
      state.tree = tree;
    }
  },
  setFormIds: (state, action) => {
    const { form_id, remove, reset } = action.payload;
    if (reset) {
      state.form_ids = null;
    }
    if (remove) {
      state.form_ids = state.form_ids.filter((id) => remove.indexOf(id) === -1);
    }
    if (form_id) {
      if (state.form_ids) {
        state.form_ids = [...state.form_ids.filter((id) => id !== form_id), form_id];
      } else {
        state.form_ids = [form_id];
      }
    }
  },
};
function initQuestions(state, questions) {
  state.questions = Object.keys(questions).reduce((a, c) => {
    a[c] = {
      ...questions[c],
      verification: "",
      comments: "",
      values:
        questions[c].values && questions[c].values !== "not checked"
          ? questions[c].values
          : questions[c].valueType === "number" ||
            questions[c].valueType === "datetime" ||
            questions[c].valueType === "email" ||
            questions[c].valueType === "url" ||
            questions[c].valueType === "text"
          ? ""
          : questions[c].valueType === "multiple choice"
          ? []
          : "not checked",
    };
    return a;
  }, {});
}
