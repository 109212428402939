import Axios from "axios";
import { received } from "../DocumentationReducer";
import { baseURL } from "../thunks";

export const getAssignments = () => async (dispatch) => {
  try {
    return await Axios({
      method: "get",
      url: `${baseURL}workflow/assignments`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    console.log(error);
    dispatch(received({ error: true }));
    return { data: null };
  }
};
