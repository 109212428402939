import React from "react";
import { Grid, MenuItem, Select, Switch, Typography, Checkbox, ListItemText, Input } from "@mui/material";
import { Person, Group, Business } from "@mui/icons-material/";
import { Perm } from "./helpers";

export function SubjectColumn(MenuProps, ACLList, ACLSubjects, setACLList) {
  return {
    field: "Subject",
    flex: 1,
    sortable: false,
    renderCell: (param) => {
      return param.row.Inherited ? (
        param.row.Subject
      ) : (
        <Select
          labelId="User-Role-select-label"
          id="User-Role-select-label"
          MenuProps={MenuProps}
          input={<Input />}
          value={
            ACLList[param.row.id].subject === 0
              ? ""
              : ACLSubjects.filter((x) => ACLList[param.row.id].subject === x.id)[0].id
          }
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();
            const newSubject = ACLSubjects.filter((x) => e.target.value === x.id)[0].id;
            setACLList((state) => [
              ...state.map((entry, i) => {
                if (i === param.row.id) {
                  return {
                    is_grant: entry.is_grant,
                    permissions: entry.permissions,
                    subject: newSubject,
                  };
                } else {
                  return entry;
                }
              }),
            ]);
          }}
        >
          {ACLSubjects &&
            ACLSubjects.sort((a, b) => (a.type > b.type ? 1 : -1)).map((item) => (
              <MenuItem key={item.id} value={item.id}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {item.type === "org" ? (
                    <Business style={{ marginRight: "5px" }} />
                  ) : item.type === "user" ? (
                    <Person style={{ marginRight: "5px" }} />
                  ) : (
                    <Group style={{ marginRight: "5px" }} />
                  )}
                  {item.name}
                </div>
              </MenuItem>
            ))}
        </Select>
      );
    },
  };
}
export function RevokeGrantColumn(setACLList) {
  return {
    field: "Revoke/Grant",
    flex: 0.5,
    sortable: false,
    renderCell: (param) => {
      return param.row.Inherited ? (
        <Switch color="primary" checked={param.row["Revoke/Grant"]} disabled></Switch>
      ) : (
        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>
              <Switch
                color="primary"
                checked={param.row["Revoke/Grant"]}
                onChange={() =>
                  setACLList((state) => [
                    ...state.map((x, i) => {
                      if (i === param.row.id) {
                        return { is_grant: !x.is_grant, permissions: x.permissions, subject: x.subject };
                      } else {
                        return x;
                      }
                    }),
                  ])
                }
                name="checkedC"
              />
            </Grid>
          </Grid>
        </Typography>
      );
    },
  };
}
export function PermissionsColumn(MenuProps, permissionList, setPermissionList, kind) {
  return {
    field: "Permissions",
    flex: 2,
    sortable: false,
    renderCell: (param) => {
      return param.row.Inherited ? (
        Perm.getLabelList(param.row.Permissions).join(", ")
      ) : (
        <Select
          multiple
          MenuProps={MenuProps}
          labelId="permissions-select-label"
          id="permissions-select"
          value={permissionList[param.row.id]}
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setPermissionList((state) => [
              ...state.map((permission, index) => {
                if (index === param.row.id) {
                  return e.target.value.includes("Select All")
                    ? [
                        "Read",
                        "Write",
                        "Delete",
                        "Edit Permissions",
                        ...(kind === "folder" ? ["Create Documents", "Create Folders"] : []),
                      ]
                    : [...e.target.value];
                } else {
                  return permission;
                }
              }),
            ]);
          }}
          input={<Input />}
          renderValue={(selected) => selected.join(", ")}
        >
          <MenuItem value={"Select All"}>Select All</MenuItem>
          {[
            "Read",
            "Write",
            "Delete",
            "Edit Permissions",
            ...(kind === "folder" ? ["Create Documents", "Create Folders"] : []),
          ].map((name, index) => (
            <MenuItem key={index} value={name}>
              <Checkbox checked={permissionList[param.row.id].indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      );
    },
  };
}
