import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { reducers } from "./reducers";
const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers,
});
export const registrationSelector = (state) => state.registration;

export const { registrationLoading, registrationComplete } = registrationSlice.actions;

export const registration = registrationSlice.reducer;
