import history from "../../../../Utils/history";
import { setAlert } from "../../../Documentation/Reducers/DocumentationReducer";
import { handleLogout } from "../../Thunks/handleLogout";

export const handle403 = (err, dispatch) => {
  if (err?.response?.data?.detail === "Invalid Organization Subscription") {
    dispatch(
      setAlert({
        alert: "There was a problem, please contact your administrator: " + err?.response?.data?.detail,
        severity: "warning",
      })
    );
  } else if (err?.response?.data?.detail === "Organization subscription is expired") {
    history.push("/billing");
    dispatch(setAlert({ alert: err?.response?.data?.detail, severity: "warning" }));
  } else if (err?.response?.data?.detail === "Could not validate credentials") {
    console.log("logging out...");
    handleLogout(dispatch)();
    dispatch(setAlert({ alert: err?.response?.data?.detail, severity: "warning" }));
  }
  console.error(err);
};
