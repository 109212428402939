import axios from "axios";
import { received } from "../DocumentationReducer";
import { baseURL } from "../thunks";

export const createDocumentSubscription =
  ({ id, validationSubscription, emailSubscription }) =>
  async (dispatch) => {
    try {
      const postReq = await axios({
        method: "post",
        url: baseURL + "documentation/documents/" + id + "/subscription",
        data: { validation_errors_only: validationSubscription, email_notifications: emailSubscription },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      return postReq;
    } catch (error) {
      console.log(error);
      dispatch(received({ error: true }));
    }
  };
