import { mergeWith } from "lodash";
import { concatIfArray } from "../concatIfArray";
import { foldPath } from "./foldPath";

// https://stackoverflow.com/a/52081991
export const foldFolderStructure = (acc, result) => {
  let pathStructure = result.path.reduceRight(foldPath(result), {});
  acc = mergeWith(acc, pathStructure, concatIfArray); //merge pathStructure object and concat the arrays
  return acc;
};
