export function getImageDimensions(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let img = new Image();
      img.src = data;
      img.onload = function () {
        resolve({
          width: img.width,
          height: img.height,
          naturalWidth: img.naturalWidth,
          naturalHeight: img.naturalHeight,
        });
      };
    };
    reader.readAsDataURL(file);
  });
}
