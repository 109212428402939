import Axios from "axios";
import { loading, received, setDocumentHistory } from "../DocumentationReducer";
import { baseURL } from "../thunks";

export const getDocumentHistory =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(loading());
      let skip = 0;
      let limit = 100;
      const params = new URLSearchParams();
      params.append("skip", skip);
      params.append("limit", limit);
      const {
        data: { results },
      } = await Axios.get(baseURL + "documentation/documents/" + id + "/history", {
        params,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(setDocumentHistory({ results }));

      dispatch(received({ error: false }));
    } catch (error) {
      dispatch(received({ error: true }));
      console.log(error);
    }
  };
