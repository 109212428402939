import axios from "axios";
import history from "../../../../Utils/history";
import { setQuestions } from "../../../Editor/Reducers/EditorReducer";
import { loading, received, setAlert, setCurrentDocument } from "../DocumentationReducer";
import { baseURL } from "../thunks";

export const getDocument =
  ({ id, isEditor, currentFolderId }) =>
  async (dispatch) => {
    try {
      dispatch(loading());
      const { data } = await axios.get(baseURL + "documentation/documents/" + id, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(setCurrentDocument({ doc: data, isEditor }));
      if (isEditor && data.doctype === "form") {
        dispatch(setQuestions({ questions: data.contents.questions || {}, isEditor: true }));
      }
      dispatch(received({ error: false }));
      return data;
    } catch (error) {
      console.log(error);
      dispatch(setAlert({ alert: error?.response?.data?.detail || "Something went wrong...", severity: "warning" }));
      if (currentFolderId) {
        history.push("/documentation/browse/" + currentFolderId);
      } else {
        history.push("/documentation/browse/");
      }
      dispatch(received({ error: true }));
    }
  };
