import React from "react";
import { FlexContainer } from "../styles";
import { Typography, Avatar, Popover, Badge, Button, Divider } from "@mui/material";
import { useStyles } from "../styles";
import { useSelector, useDispatch } from "react-redux";
import { loginSelector } from "../../Login/Reducers/LoginReducer";
import history from "../../../Utils/history";
import { AvailableOrgButtons } from "./AvailableOrgButtons";
import { handleLogout } from "../Thunks/handleLogout";
import { AvatarStyle } from "./useStyles";

export const PopoverAvatar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { email, full_name, avatar, available_orgs } = useSelector(loginSelector);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const initials = full_name
    .split(/(?:\s+)/g)
    .map((w) => w.charAt(0))
    .join("")
    .toUpperCase();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <Avatar alt={full_name} src={avatar ? avatar : ""} style={AvatarStyle(initials)} onClick={handleClick}>
        {initials}
      </Avatar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ overflowY: "auto" }}
      >
        <FlexContainer>
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            color="primary"
          >
            <Avatar
              alt={full_name}
              src={avatar && avatar}
              className={`${classes.largeAvatar}`}
              style={AvatarStyle(initials)}
              onClick={handleClick}
            >
              {!avatar && initials}
            </Avatar>
          </Badge>
        </FlexContainer>
        <Typography className={classes.typography} variant="h6">
          {full_name}
        </Typography>
        <Typography className={classes.typography} variant="body1">
          {email}
        </Typography>
        <Divider />
        {available_orgs ? <AvailableOrgButtons /> : ""}
        <FlexContainer>
          <Button onClick={() => history.push("/account-settings")}>Account Settings</Button>
        </FlexContainer>
        <Divider />
        <FlexContainer>
          <Button onClick={handleLogout(dispatch)}>Logout</Button>
        </FlexContainer>
      </Popover>
    </div>
  );
};
