import { Business, Group, Person } from "@mui/icons-material/";
import { Autocomplete, Box, Button, Fade, Modal, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingSpinner } from "../../../Viewer/Components/LoadingSpinner";
import { setAlert } from "../../Reducers/DocumentationReducer";
import { createAssignment } from "../../Reducers/Thunks/createAssignment";
import { getACLSubjects } from "../../Reducers/Thunks/getACLSubjects";

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    justifyContent: "center",
  },
}));

export const AssignmentModal = ({ open, onClose, docToEdit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentFolderID = useSelector((state) => state.documentation.currentFolder?.folder?.id);
  const currentFolderName = useSelector((state) => state.documentation.currentFolder?.folder?.name);
  const [assignment, setAssignment] = useState();
  const [ACLSubjects, setACLSubjects] = useState();
  const [notes, setNotes] = useState("");
  useEffect(() => {
    dispatch(getACLSubjects()).then((res) => setACLSubjects(res.results.sort((a, b) => (a.type > b.type ? -1 : 1))));
  }, [dispatch]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <div className={classes.modalPaper}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <div style={{ margin: "0 auto" }}>
              <Typography>Create Assignment</Typography>
            </div>
            {!ACLSubjects ? (
              <LoadingSpinner />
            ) : (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={ACLSubjects}
                sx={{ width: 300 }}
                onChange={(_, newValue) => {
                  setAssignment(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Choose Assignee..." />}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.type === "org" ? <Business /> : option.type === "role" ? <Group /> : <Person />}
                    {option.name}
                  </Box>
                )}
              />
            )}
            <form style={{ padding: "8px" }} noValidate autoComplete="off">
              <TextField
                fullWidth
                label="Notes"
                multiline
                rows={4}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                variant="outlined"
              />
            </form>
            <div style={{ margin: "0 auto" }}>
              <Button
                onClick={() => {
                  if (assignment) {
                    dispatch(
                      createAssignment({
                        kind: docToEdit?.kind ? docToEdit?.kind : docToEdit ? "document" : "folder",
                        object_id: docToEdit?.id ? docToEdit?.id : currentFolderID,
                        assigned_to: assignment.id,
                        notes,
                      })
                    )
                      .then((res) => {
                        dispatch(
                          setAlert({
                            alert: `${assignment.name} has been assigned to ${
                              docToEdit?.name ? docToEdit?.name : currentFolderName
                            }`,
                            severity: "success",
                          })
                        );
                        onClose();
                      })
                      .catch((err) =>
                        dispatch(
                          setAlert({ alert: `Something went wrong with your assignment creation`, severity: "error" })
                        )
                      );
                  }
                }}
                variant="outlined"
                style={{ color: !assignment ? "#9e9e9e" : "#097c09" }}
                disabled={!assignment}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
