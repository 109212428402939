export function comparePasswordLength(confirmPassword, password) {
  return confirmPassword.length >= 8 && password === confirmPassword;
}

export function checkPasswordLength(password) {
  return password.length >= 8;
}
export function disableButton(password, confirmPassword) {
  return (!confirmPassword.length && !password.length) || password !== confirmPassword;
}
