export const initialState = {
  scaleX: null,
  scaleY: null,
  shapes: [],
  tool: "select",
  selected: null,
  description: "",
  zoom: 1,
  zoomOffset: { top: 0, left: 0 },
  containedSize: null,
  questions: null,
  saving: false,
  tree: null,
  form_ids: null,
};
