export const styles = {
  folder: {
    fontSize: "2em",
    verticalAlign: "middle",
    fill: "#f0dc82",
  },
  document: {
    fontSize: "2em",
    verticalAlign: "middle",
    fill: "cornflowerblue",
  },
  form: {
    fontSize: "2em",
    verticalAlign: "middle",
    fill: "darkcyan",
  },
  sheet: {
    fontSize: "2em",
    verticalAlign: "middle",
    fill: "mediumslateblue",
  },
  report: {
    fontSize: "2em",
    verticalAlign: "middle",
    fill: "#9c27b0",
  },
};
