import React from "react";
import { Accordion, ListItem, ListItemIcon, ListItemText, MenuItem, AccordionDetails } from "@mui/material";
import {
  ExpandMore,
  FolderOpenTwoTone,
  InsertDriveFileTwoTone,
  AssignmentTurnedInTwoTone,
  ListAltTwoTone,
  FavoriteBorder,
  SummarizeTwoTone,
} from "@mui/icons-material/";
import history from "../../../Utils/history";
import { AccordionButton, styles } from "./useStyles";

export function SideDrawerFavorites(classes, onClick, favorites) {
  return (
    <Accordion
      style={{
        boxShadow: "none",
      }}
      classes={{
        root: classes.MuiAccordionroot,
      }}
    >
      <AccordionButton expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
        <ListItem style={{ padding: 0, width: "100%" }} key={"Favorites"}>
          <ListItemIcon>{<FavoriteBorder />}</ListItemIcon>
          <ListItemText primary={"Favorites"} />
        </ListItem>
      </AccordionButton>
      <AccordionDetails style={styles.AccordionDetails}>
        {favorites.map((favorite, index) => {
          return (
            <MenuItem
              onClick={() =>
                favorite.kind === "folder"
                  ? history.push(`/documentation/browse/${favorite.item_id}`)
                  : history.push(`/documentation/view/${favorite.item_id}`)
              }
              key={index}
              style={{ width: "100%" }}
            >
              <span>
                {favorite.kind === "folder" ? (
                  <FolderOpenTwoTone style={styles.folder} />
                ) : favorite.doctype === "presentation" ? (
                  <InsertDriveFileTwoTone style={styles.document} />
                ) : favorite.doctype === "form" ? (
                  <AssignmentTurnedInTwoTone style={styles.form} />
                ) : favorite.doctype === "report" ? (
                  <SummarizeTwoTone style={styles.report} />
                ) : (
                  <ListAltTwoTone style={styles.sheet} />
                )}
                {favorite.name}
              </span>
            </MenuItem>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}
