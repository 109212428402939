import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSingleDocumentData } from "../Thunks/getSingleDocumentData";
import { getDocument } from "../../Documentation/Reducers/thunks";
import { setSingleRow } from "../../Documentation/Reducers/DocumentationReducer";

export function useSingleRow(singleRow, params) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!singleRow) {
      dispatch(getDocument({ id: params.document_id, currentFolderId: params.document_id, isEditor: false })).then(
        (doc) => {
          Promise.all(
            params.id.split("-").map((id) => dispatch(getSingleDocumentData({ id })).then((res) => res.data))
          ).then((res) => {
            const singleRow = res.reduce((acc, row) => {
              acc.record = { ...acc.record, ...row.record };
              return acc;
            }, res[0]);
            singleRow.record = Object.keys(singleRow.record)
              .filter(key => key in doc.contents.questions)
              .reduce((result, key) => {
                result[key] = singleRow.record[key];
                return result;
              }, {});
            dispatch(setSingleRow({ singleRow }));
          });
        }
      );
    }
  }, [dispatch, singleRow, params]);
}
