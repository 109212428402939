import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setZoomOffset } from "../Reducers/EditorReducer";

export function useResetZoomOffset() {
  const dispatch = useDispatch();

  const zoom = useSelector((state) => state.editor.zoom);

  useEffect(() => {
    if (zoom === 1) dispatch(setZoomOffset({ top: 0, left: 0 }));
  }, [zoom, dispatch]);
}
