import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import App from "./App.js";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { theme } from "./theme";
import history from "./Utils/history";

const styles = { app: { height: "100%", touchAction: "none" } };
render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <App styles={styles.app} />
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
