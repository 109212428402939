import React from "react";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiContext,
  gridVisibleRowCountSelector,
} from "@mui/x-data-grid-pro";
import { Button, Typography } from "@mui/material";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import history from "../../../../Utils/history";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
export function CustomToolbar({ selectedRow, selectionModel, filterModel }) {
  const id = useSelector((state) => state.documentation.currentDocument.id);
  const [visibleRows, setVisibleRows] = React.useState();
  const apiRef = useGridApiContext();
  const location = useLocation();
  const dateFilter = filterModel.items.filter((item) => item.columnField === "Datetime")
    ? filterModel.items.filter((item) => item.columnField === "Datetime")[0]
    : "";
  const operatorValue = dateFilter
    ? dateFilter.operatorValue === "is"
      ? "on"
      : dateFilter.operatorValue === "not"
      ? "not on"
      : dateFilter.operatorValue === "after"
      ? "after"
      : dateFilter.operatorValue === "onOrAfter"
      ? "on or after"
      : dateFilter.operatorValue === "before"
      ? "before"
      : dateFilter.operatorValue === "onOrBefore"
      ? "on or before"
      : dateFilter.operatorValue === "isEmpty"
      ? "if date is empty"
      : "if date is not empty"
    : "";

  React.useEffect(() => {
    const handleStateChange = () => {
      const count = gridVisibleRowCountSelector(apiRef.current.state, apiRef.current.instanceId);
      setVisibleRows(count);
    };
    const unsubscribe = apiRef.current.subscribeEvent("stateChange", handleStateChange);
    return () => {
      unsubscribe();
    };
  }, [apiRef]);

  return (
    <GridToolbarContainer className="CustomToolbar">
      <GridToolbarExport
        printOptions={{
          // allColumns: true,
          // copyStyles: true,
          // hideFooter: true,
          // hideToolbar: true,
          // exportAllData: true,
          pageStyle: `
          @media print {
            .tableButton {
              display: none !important;
            }
            .Footer {
              display: none !important;
            }
            .CustomToolbar {
              display: none !important;
            }
          }
        `,
        }}
      />
      <GridToolbarFilterButton />
      <Button
        onClick={() =>
          location.pathname.includes("documentation/results")
            ? history.push(`/documentation/results/${id}/${selectedRow}`)
            : history.push(`/documentation/view/${id}/${selectedRow}`)
        }
        startIcon={<PlaylistAddCheckIcon />}
      >
        View Row {selectionModel[0] + 1}
      </Button>
      <div style={{ marginLeft: "auto" }}>
        {dateFilter ? (
          <Typography style={{ fontSize: "1em" }}>{`Displaying ${visibleRows || ""} results ${operatorValue} ${
            dateFilter.value || ""
          }`}</Typography>
        ) : (
          ""
        )}
      </div>
    </GridToolbarContainer>
  );
}
