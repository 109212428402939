import { createTheme } from "@mui/material/styles";

export let theme = createTheme({
  breakpoints: {
    values: {
      sm: 685,
      md: 960,
    },
  },
  palette: {
    primary: {
      main: "#202c45",
      light: "#4c566a",
      dark: "#161e30",
    },
    secondary: {
      main: "#ffc400",
      dark: "#b28900",
      light: "#ffcf33",
    },
  },
  overrides: {
    MuiButton: {
      outlinedSecondary: {
        color: "#f44336",
        border: "1px solid #f44336",
      },
    },
  },
});
theme.typography.body1 = {
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "1rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
  },
};
theme.typography.h5 = {
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontWeight: 400,
  fontSize: "1.15rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.95rem",
  },
};
theme.typography.h6 = {
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontWeight: 500,
  fontSize: "1.25rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
  },
};
// theme.overrides = {
//   MuiButtonBase: {
//     root: {
//       fontSize: "0.875rem",
//       [theme.breakpoints.down("sm")]: {
//         fontSize: "0.7rem",
//       },
//     },
//   },
//   MuiButton: {
//     root: {
//       fontSize: "0.875rem",
//       [theme.breakpoints.down("sm")]: {
//         fontSize: "0.7rem",
//       },
//     },
//   },
// };
