import { Paper } from "@mui/material";
import { useTheme } from "@mui/styles";
import { DataGridPro, GridOverlay, LicenseInfo } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../../Dashboard/Components/Breadcrumbs";
import { Dashboard } from "../../../Dashboard/Dashboard";
import { AssignmentModal } from "../../../Documentation/Components/Dialogs/AssignmentModal";
import { SnackbarAlertRedux } from "../../../Documentation/Components/SnackbarAlert";
import { setSingleRow, setSpreadsheet } from "../../../Documentation/Reducers/DocumentationReducer";
import { getDocument } from "../../../Documentation/Reducers/thunks";
import { getDocumentData } from "../../Thunks/getDocumentData";
import { setLinkVals } from "../../helpers";
import { LoadingSpinner } from "../LoadingSpinner";
import { QRLinkModal } from "../QRLinkModal";
import { SpreadsheetDialog } from "../SpreadsheetViewer/Components/SpreadsheetDialog/SpreadsheetDialog";
import { CustomToolbar } from "../SpreadsheetViewer/CustomToolbar";
import { foldQuestionFields } from "../SpreadsheetViewer/foldQuestionFields";
import { last30days } from "../SpreadsheetViewer/helpers";
import { useStyles } from "../SpreadsheetViewer/useStyles";
import { SubscriptionModal } from "../SubscriptionModal";
import UploadProgressBar from "../UploadProgressBar";

LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_KEY}`);

export const ChecklistResultsViewer = () => { 
  const classes = useStyles();
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.documentation.currentDocument?.contents?.questions);
  const question_index = useSelector((state) => state.documentation.currentDocument?.contents?.question_index);
  const results = useSelector((state) => state.documentation.spreadsheet.results);
  const [filterModel, setFilterModel] = useState({
    items: [{ columnField: "Datetime", operatorValue: "onOrAfter", value: last30days(), id: 1000 }],
  });
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [selectionModel, setSelectionModel] = useState([0]);
  const currentDocument = useSelector((state) => state.documentation.currentDocument);
  const loadingDoc = useSelector((state) => state.documentation.loading);
  const alert = useSelector((state) => state.documentation.alert);
  const severity = useSelector((state) => state.documentation.severity);
  const [openQR, setOpenQR] = useState(false);
  const [openSubs, setOpenSubs] = useState(false);
  const [openAssignments, setOpenAssignments] = useState(false);
  const resultsTotal = useSelector((state) => state.documentation.spreadsheet?.total);
  let { id } = useParams();
  useEffect(() => {
    dispatch(getDocument({ id, isEditor: false, currentFolderId: id })).then(() => {
      dispatch(setSingleRow({ reset: true }));
      if (id && !results) {
        setLoading(true);
        dispatch(
          getDocumentData({
            id,
          })
        )
          .then((res2) => {
            const { results, total } = res2.data;

            dispatch(setSpreadsheet({ results, total }));

            setLoading(false);
          })
          .catch((e) => console.error(e));
      }
    });
  }, [dispatch, id, results, resultsTotal]);

  const QuestionFields = question_index ? question_index.reduce(foldQuestionFields(questions, dispatch), []) : [];
  const columns = [
    {
      field: "Datetime",
      flex: 1,
      sortComparator: (a, b) => {
        return Date.parse(a) - Date.parse(b);
      },
      type: "date",
    },
    {
      field: "Username",
      flex: 1,
      sortComparator: (a, b) => {
        return String(a) > String(b) ? 1 : -1;
      },
      type: "string",
    },
    ...QuestionFields,
  ];

  const rows = results
    ? results?.slice().map((res, index) => {
        const { created_at, created_by_username, record } = res;
        let [month, date, year] = new Date(created_at).toLocaleDateString("en-US").split("/");
        let [hour, minute, , pm] = new Date(created_at).toLocaleTimeString("en-US").split(/:| /);

        return {
          id: index,
          Datetime: `${year}/${month}/${date} ${hour}:${minute} ${pm}`,
          Username: created_by_username,
          ...Object.keys(record).reduce((accumulator, currentRecord) => {
            accumulator[currentRecord] = record[currentRecord];
            return accumulator;
          }, {}),
        };
      })
    : [];
  const linkVals = setLinkVals(currentDocument);
  return (
    <Dashboard
      pageName={`View ${currentDocument?.name ?? ""} `}
      breadcrumbs={
        <Breadcrumb
          currentValue={currentDocument?.name ?? ""}
          linkValues={linkVals}
          isViewer
          setOpenQR={setOpenQR}
          setOpenSubs={setOpenSubs}
          setOpenAssignments={setOpenAssignments}
        />
      }
    >
      {loadingDoc || !currentDocument ? (
        <LoadingSpinner />
      ) : (
        <Paper className={classes.root}>
          {loading || !results ? (
            <LoadingSpinner />
          ) : (
            <DataGridPro
              columns={columns}
              rows={rows}
              initialState={{
                sorting: {
                  sortModel: [{ field: "Datetime", sort: "desc" }],
                },
              }}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => {
                setFilterModel(newFilterModel);
              }}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              components={{
                Toolbar: () =>
                  CustomToolbar({
                    selectionModel,
                    selectedRow: results ? results[selectionModel[0]]?.id : "",
                    filterModel,
                  }),
                NoRowsOverlay: () => <GridOverlay style={{ flexDirection: "column" }}>No Results Found</GridOverlay>,
                Footer: () => (
                  <div className="Footer" style={{ margin: "0px 16px", display: "flex", alignItems: "center" }}>
                    Total Results: {results ? results.length : 0}
                  </div>
                ),
              }}
            />
          )}
          <SpreadsheetDialog theme={theme} />
        </Paper>
      )}
      <QRLinkModal openQR={openQR} setOpenQR={setOpenQR} value={window.location.href} name={currentDocument?.name} />
      <SubscriptionModal openSubs={openSubs} setOpenSubs={setOpenSubs} />
      <SnackbarAlertRedux alert={alert} severity={severity} />
      {openAssignments && (
        <AssignmentModal docToEdit={currentDocument} open={openAssignments} onClose={() => setOpenAssignments(false)} />
      )}
      <UploadProgressBar />
    </Dashboard>
  );
};
