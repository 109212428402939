export const Perm = {
  Read: 1,
  Write: 2,
  Delete: 4,
  "Edit Permissions": 8,
  "Create Documents": 16,
  "Create Folders": 32,

  labels: ["Read", "Write", "Delete", "Edit Permissions", "Create Documents", "Create Folders"],

  getLabelList: function (permInt) {
    const lbls = [];
    for (var i = 0; i < 6; i++) {
      let val = 2 ** i;
      if ((permInt & val) === val) {
        lbls.push(this.labels[i]);
      }
    }
    return lbls;
  },
  convertToBinary: function (permArr) {
    return permArr.reduce((a, c) => a | (2 ** this.labels.indexOf(c)), 0);
  },
};
export function isKindAndId(kind, id) {
  return (a, c) => {
    if (c.kind === kind && c.item_id === id) {
      a = true;
    }
    return a;
  };
}

export const reorderMultiple = ({ list, selectedIdxs, direction }) => {
  let newList = [...list];
  if (!selectedIdxs.length) return newList;
  if (direction === "left") {
    selectedIdxs.reduce((_, selectedIdx) => {
      if (selectedIdx !== 0) {
        swapLeft(newList, selectedIdx);
      }
      return _;
    }, null);
  } else {
    selectedIdxs.reduceRight((_, selectedIdx) => {
      if (selectedIdx !== newList.length - 1) {
        swapRight(newList, selectedIdx);
      }
      return _;
    }, null);
  }
  return newList;
};

export function swapLeft(newList, selectedIdx) {
  [newList[selectedIdx - 1], newList[selectedIdx]] = [newList[selectedIdx], newList[selectedIdx - 1]];
}

export function swapRight(newList, selectedIdx) {
  [newList[selectedIdx], newList[selectedIdx + 1]] = [newList[selectedIdx + 1], newList[selectedIdx]];
}
export function reorderSelection(direction) {
  return (state) => {
    return [
      ...new Set(
        state.map((selection) => {
          if (direction === "left") {
            return selection === 0 ? selection : selection - 1;
          } else {
            return selection + 1;
          }
        })
      ),
    ];
  };
}
export const pathToDocument = (currentFolder) =>
  currentFolder && currentFolder.ancestors.length === 0
    ? ""
    : currentFolder.ancestors.length === 1
    ? currentFolder.folder.name
    : currentFolder.ancestors
        .slice(1)
        .map((x) => x.name)
        .join(" / ") +
      " / " +
      currentFolder.folder.name;
