import { useState, useEffect, useRef } from "react";
export const isLengthChanged = (prev, next) => prev.length === next.length;

export const isOrderChanged = (prev, next) =>
  prev.name === next.name &&
  prev.kind === next.kind &&
  prev.created_at === next.created_at &&
  prev.modified_at === next.modified_at;
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export const formatDateTime = (date) =>
  date
    .toLocaleTimeString([], {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
    .replace(",", "");
export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export const useInfiniteScroll = (callback) => {
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight ||
        isFetching
      )
        return;
      setIsFetching(true);
    }
    //detects bottom of the page
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetching]);

  useEffect(() => {
    if (!isFetching) return;
    callback(() => {
      console.log("called back");
    });
  }, [isFetching, callback]);

  return [isFetching, setIsFetching];
};

export const isOrderAscending = (order) => (order.direction === "ascending" ? "" : "-") + order.order;
