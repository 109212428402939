import { FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDidQuestionsChange } from "../../../Documentation/Reducers/DocumentationReducer";
import { setQuestion } from "../../../Editor/Reducers/EditorReducer";

import { trueFalse } from "./Helpers/validationHandlers";

export const ValidationEditor = ({ id }) => {
  const didQuestionsChange = useSelector((state) => state.documentation.didQuestionsChange);
  const valueType = useSelector((state) => state.editor?.questions[id].valueType);
  const choices = useSelector((state) => state.editor?.questions[id].choices);
  const validation = useSelector((state) => state.editor?.questions[id].validation);
  const dispatch = useDispatch();

  const SingleChoice = (
    <div>
      <Typography>Select Correct Answers</Typography>
      <FormControl aria-label="checklist" style={{ flexDirection: "column" }}>
        {choices.map((choice, j) => {
          return (
            <FormControlLabel
              key={j}
              onClick={(e) => {
                if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));
                if (!e?.target?.value) return;
                dispatch(
                  setQuestion({
                    id,
                    validation: {
                      type: valueType,
                      value: validation?.value?.includes(e.target.value)
                        ? [...validation?.value?.filter((opt) => opt !== e.target.value)]
                        : [...(validation?.value ? validation?.value : []), e.target.value],
                    },
                  })
                );
              }}
              checked={validation?.value?.includes(choice)}
              value={choice || " "}
              control={<Radio />}
              label={choice}
            />
          );
        })}
      </FormControl>
    </div>
  );
  const MultipleChoice = (
    <div>
      <Typography>Select Correct Answers</Typography>
      <FormControl aria-label="checklist" style={{ flexDirection: "column" }}>
        {choices.map((choice, j) => {
          return (
            <FormControlLabel
              key={j}
              onClick={(e) => {
                if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));
                if (!e?.target?.value) return;
                dispatch(
                  setQuestion({
                    id,
                    validation: {
                      type: valueType,
                      values: validation?.values?.includes(e.target.value)
                        ? [...validation?.values?.filter((opt) => opt !== e.target.value)]
                        : [...(validation?.values ? validation?.values : []), e.target.value],
                    },
                  })
                );
              }}
              checked={validation?.values?.includes(choice)}
              value={choice || " "}
              control={<Radio />}
              label={choice}
            />
          );
        })}
      </FormControl>
    </div>
  );
  const TrueFalse = (
    <div>
      <Typography>Correct Answer</Typography>
      <RadioGroup
        aria-label="checklist"
        name="checklist"
        value={validation?.value || false}
        style={{ flexDirection: "column" }}
        onChange={(e) => {
          if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

          dispatch(setQuestion(trueFalse(id, valueType, e)));
        }}
      >
        <FormControlLabel value={"false"} control={<Radio />} label="False" />
        <FormControlLabel value={"true"} control={<Radio />} label="True" />
      </RadioGroup>
    </div>
  );
  const Numeric = (
    <div>
      <Typography>Valid Numeric Range</Typography>
      <TextField
        id="number-Min"
        label="Min"
        type="number"
        style={{ marginRight: "1em" }}
        value={validation?.min}
        onWheel={(e) => e.target.blur()}
        onChange={(e) => {
          if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

          dispatch(
            setQuestion({
              id,
              validation: {
                type: valueType,
                min: e.target.value === "" ? "" : Number(e.target.value),
                max: Number(validation?.max),
              },
            })
          );
        }}
      />
      <TextField
        id="number-max"
        label="Max"
        type="number"
        value={validation?.max}
        onWheel={(e) => e.target.blur()}
        onChange={(e) => {
          if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

          dispatch(
            setQuestion({
              id,
              validation: {
                type: valueType,
                min: Number(validation?.min),
                max: e.target.value === "" ? "" : Number(e.target.value),
              },
            })
          );
        }}
      />
    </div>
  );
  const Datetime = (
    <div>
      <Typography>Valid Datetime Range</Typography>
      <form noValidate>
        <TextField
          id="datetime-from"
          label={"From"}
          type="datetime-local"
          value={validation?.min}
          onChange={(e) => {
            if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

            dispatch(setQuestion({ id, validation: { type: valueType, min: validation?.min, max: e.target.value } }));
          }}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginRight: "1em" }}
        />
        <TextField
          id="datetime-to"
          label={"To"}
          type="datetime-local"
          value={validation?.max}
          onChange={(e) => {
            if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

            dispatch(setQuestion({ id, validation: { type: valueType, min: e.target.value, max: validation?.max } }));
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </form>
    </div>
  );

  return valueType === "multiple choice"
    ? MultipleChoice
    : valueType === "single choice"
    ? SingleChoice
    : valueType === "number" || valueType === "decimal"
    ? Numeric
    : valueType === "datetime"
    ? Datetime
    : valueType === "true/false"
    ? TrueFalse
    : "";
};
