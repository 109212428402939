import React from "react";
import { IconButton, Fab } from "@mui/material";
import { Edit, Add } from "@mui/icons-material/";
import { useDispatch, useSelector } from "react-redux";
import { rolesSelector } from "../../Reducers/RolesReducer";
import { Link } from "react-router-dom";
import { Dashboard } from "../../../Dashboard/Dashboard";
import { Breadcrumb } from "../../../Dashboard/Components/Breadcrumbs";
import { GridOverlay, DataGridPro } from "@mui/x-data-grid-pro";
import history from "../../../../Utils/history";
import { LoadingSpinner } from "../../../Viewer/Components/LoadingSpinner";
import { useGetRoles } from "../../Hooks/useGetRoles";
import "./dataGridStyles.css";

export function RolesTable() {
  const { roles, total, loading } = useSelector(rolesSelector);
  const dispatch = useDispatch();
  const url = "/admin/roles/new";

  const columns = [
    { field: "Role", flex: 1 },
    { field: "Description", flex: 3 },
    { field: "Edit", flex: 1, renderCell: (params) => params.value },
  ];
  const rows = roles.map((role) => {
    return {
      id: role.id,
      Role: role.name,
      Description: role.description,
      Edit: (
        <IconButton onClick={() => history.push(`/admin/roles/${role.id}`)}>
          <Edit />
        </IconButton>
      ),
    };
  });
  useGetRoles(dispatch);
  return (
    <Dashboard
      pageName="Roles"
      breadcrumbs={
        <Breadcrumb
          currentValue={"Roles"}
          linkValues={[
            { name: "Home", to: "/" },
            { name: "Admin", to: "/admin" },
          ]}
        />
      }
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <DataGridPro
            components={{
              NoRowsOverlay: () => <GridOverlay style={{ flexDirection: "column" }}>No Results Found</GridOverlay>,
              Footer: () => (
                <div style={{ margin: "0px 16px", display: "flex", alignItems: "center" }}>
                  Total Results: {total ? total : 0}
                </div>
              ),
            }}
            columns={columns}
            rows={rows}
          />

          <Fab
            style={{ position: "absolute", right: 0, bottom: 0 }}
            color="primary"
            aria-label="add"
            component={Link}
            to={url}
          >
            <Add />
          </Fab>
        </>
      )}
    </Dashboard>
  );
}
