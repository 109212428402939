import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setZoom } from "../Reducers/EditorReducer";

export function useResetZoom() {
  const dispatch = useDispatch();

  const selectedThumbnail = useSelector((state) => state.documentation.selectedThumbnail);

  useEffect(() => {
    dispatch(setZoom({ zoom: 1 }));
  }, [dispatch, selectedThumbnail]);
}
