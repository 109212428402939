import axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const acceptInvitation =
  ({ id, accept }) =>
  async () => {
    try {
      return await axios({
        method: "post",
        url: baseURL + "auth/invite/" + id,
        data: accept ? "true" : "false",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (e) {
      console.error(e);
      return e;
    }
  };
