import React, { useState } from "react";
import { Button, IconButton, Popover, List, ListItemText } from "@mui/material";
import { SortByAlpha, ArrowUpward, ArrowDownward } from "@mui/icons-material/";
import { buttonStyle } from "./DocumentationToolbar";
import { useDispatch, useSelector } from "react-redux";
import { setOrder } from "../../Reducers/DocumentationReducer";
import { isOrderChanged } from "../../Helpers/helpers";
const listStyle = { width: "fit-content", display: "flex", flexDirection: "column" };

export const DocumentationDropdowns = () => {
  const [anchorOrder, setAnchorOrder] = useState(null);
  const dispatch = useDispatch();
  const order = useSelector((state) => state.documentation.order, isOrderChanged);
  const handleSetOrder = (a) => {
    dispatch(
      setOrder({
        order: { ...order, ...a },
      })
    );
    setAnchorOrder(null);
  };
  return (
    <>
      <div>
        <IconButton style={buttonStyle} onClick={(e) => setAnchorOrder(e.currentTarget)}>
          <SortByAlpha />
        </IconButton>
        <Popover
          id={anchorOrder ? "simple-popover" : undefined}
          open={Boolean(anchorOrder)}
          anchorEl={anchorOrder}
          onClose={() => setAnchorOrder(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List component="nav" style={listStyle}>
            <Button onClick={() => handleSetOrder(order.name === "" ? { name: "-" } : { name: "" })}>
              {order.name === "" ? <ArrowUpward /> : <ArrowDownward />}
              <ListItemText primary="Name" />
            </Button>
            <Button onClick={() => handleSetOrder(order.kind === "" ? { kind: "-" } : { kind: "" })}>
              {order.kind === "" ? <ArrowUpward /> : <ArrowDownward />}
              <ListItemText primary="Kind" />
            </Button>
            <Button onClick={() => handleSetOrder(order.created_at === "" ? { created_at: "-" } : { created_at: "" })}>
              {order.created_at === "" ? <ArrowUpward /> : <ArrowDownward />}
              <ListItemText primary="Created" />
            </Button>
            <Button
              onClick={() => handleSetOrder(order.modified_at === "" ? { modified_at: "-" } : { modified_at: "" })}
            >
              {order.modified_at === "" ? <ArrowUpward /> : <ArrowDownward />}
              <ListItemText primary="Modified" />
            </Button>
          </List>
        </Popover>
      </div>
    </>
  );
};
