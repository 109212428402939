import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Breadcrumb } from "../../Dashboard/Components/Breadcrumbs";
import { Dashboard } from "../../Dashboard/Dashboard";
import { setLinkVals } from "../../Viewer/helpers";
import { formatDateTime } from "../Helpers/helpers";
import { makeStyles } from "@mui/styles";
import { Visibility, Star } from "@mui/icons-material/";
import history from "../../../Utils/history";
import { useDocumentHistory } from "../Hooks/useDocumentHistory";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
export const DocumentHistory = () => {
  const currentDocument = useSelector((state) => state.documentation.currentDocument);
  const results = useSelector((state) => state.documentation?.currentDocumentHistory?.results);
  const linkVals = setLinkVals(currentDocument);
  const createdAt = new Date(currentDocument?.created_at);
  const classes = useStyles();

  useDocumentHistory();
  return (
    <Dashboard
      pageName="View History"
      breadcrumbs={<Breadcrumb currentValue={currentDocument && currentDocument.name} linkValues={linkVals} />}
    >
      <div style={{ overflow: "scroll" }}>
        <br />
        <Typography variant="h4">History: {currentDocument?.name}</Typography>
        <br />
        <TableContainer component={Paper}>
          <Table className={classes.table} style={{ padding: "1em" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Version</TableCell>
                <TableCell align="left">Created At</TableCell>
                <TableCell align="left">Created By</TableCell>
                <TableCell align="left">Changelog</TableCell>
                <TableCell align="left">View</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {results &&
                results.map((res, i) => {
                  const createdAtHistory = new Date(res.created_at);
                  const isFirst = i === 0;
                  return (
                    <TableRow key={i}>
                      {isFirst ? (
                        <TableCell align="right">
                          <Star />
                        </TableCell>
                      ) : (
                        <TableCell align="left"></TableCell>
                      )}
                      <TableCell align="left">{res.version}</TableCell>
                      <TableCell align="left">
                        {isFirst ? formatDateTime(createdAt) : formatDateTime(createdAtHistory)}
                      </TableCell>
                      <TableCell align="left">{res.created_by_username ?? "N/A"}</TableCell>
                      <TableCell align="left">{res.changelog ? res.changelog : "N/A"}</TableCell>
                      <TableCell align="left">
                        <Visibility
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (isFirst) {
                              history.push("/documentation/view/" + res.document_id);
                            } else {
                              history.push(`/documentation/view/${res.document_id}/version/${res.version}`);
                            }
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Dashboard>
  );
};
