import axios from "axios";
import { setUploadProgress } from "../../Documentation/Reducers/DocumentationReducer";
import { handleError } from "../../Documentation/Reducers/Thunks/Helpers/handleError";
import { baseURL } from "../../Documentation/Reducers/thunks";
import { setSaving } from "../../Editor/Reducers/EditorReducer";
import { compress } from "../Components/Helpers/compress";
import { convertBlobsToFiles } from "./Helpers/convertBlobsToFiles";
import { formatFileInfo } from "./Helpers/formatFileInfo";
import { uploadQuestionMedia } from "./Helpers/uploadQuestionMedia";

export const uploadNewMediaQuestions =
  ({ currentDocument, questions, onCancel, setShowPasswordModal }) =>
  async (dispatch) => {
    dispatch(setSaving({ saving: true }));
    dispatch(setUploadProgress({ uploadProgress: 20 }));

    try {
      const questionArr = Object.keys(questions).map((x) => questions[x]);
      const newFiles = await Promise.all(questionArr.reduce(convertBlobsToFiles(), []));
      const compressedFiles = await Promise.all(
        newFiles.map((file) => (file.type.includes("image") ? compress(file, 0.8) : file))
      );
      const newFileIDs = Object.keys(questions).reduce((acc, curr) => {
        const isBlob =
          questions[curr] &&
          questions[curr].media &&
          !questions[curr].media.includes("amazonaws.com") &&
          questions[curr]?.media.includes("blob:http");
        if (isBlob) acc.push(curr);
        return acc;
      }, []);
      if (!newFiles.length) return null;
      dispatch(setUploadProgress({ uploadProgress: 30 }));

      const signContentUpload = async () =>
        await axios.post(
          `${baseURL}documentation/documents/${currentDocument.id}/sign-content-upload`,
          formatFileInfo(compressedFiles),
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      dispatch(setUploadProgress({ uploadProgress: 40 }));

      return signContentUpload()
        .then(async (res) => {
          dispatch(setUploadProgress({ uploadProgress: 50 }));
          return await uploadQuestionMedia(res, compressedFiles, dispatch, newFileIDs, setShowPasswordModal, onCancel);
        })
        .catch((e) => handleError(e, onCancel, dispatch, setShowPasswordModal));
    } catch (e) {
      handleError(e, onCancel, dispatch, setShowPasswordModal);
    }
  };
