import {
  loading,
  received,
  setAlert,
  setCurrentDocument,
  setDidQuestionsChange,
  setIsBlocking,
  setUploadProgress,
} from "../../../Documentation/Reducers/DocumentationReducer";
import { publishSheet } from "../../../Documentation/Reducers/Thunks/publishSheet";
import { setQuestions, setSaving } from "../../../Editor/Reducers/EditorReducer";

export function handleSheet(
  dispatch,
  questions,
  currentDocument,
  question_index,
  changelog,
  tree,
  form_ids,
  onConfirm,
  onCancel,
  setShowPasswordModal
) {
  dispatch(loading());

  dispatch(
    publishSheet({
      currentDocument,
      question_index,
      questions,
      changelog,
      tree,
      form_ids,
      onCancel,
      setShowPasswordModal,
    })
  )
    .then((res) => {
      if (!res) {
        dispatch(setIsBlocking({ isBlocking: false }));
        dispatch(setSaving({ saving: false }));
        dispatch(received({ error: false }));
        dispatch(setUploadProgress({ reset: true }));
        onCancel();
        return;
      } else {
        onConfirm();
        dispatch(setIsBlocking({ isBlocking: false }));
        dispatch(setDidQuestionsChange({ isChanged: false }));
        dispatch(setCurrentDocument({ doc: res.data, isEditor: true }));
        dispatch(setQuestions({ reset: true }));
        dispatch(setAlert({ alert: "Publish Successful!", severity: "success" }));

        dispatch(received({ error: false }));
      }
    })
    .catch((e) => console.error(e));
}
