import React, { useCallback } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setTrash, received, setCopy } from "../Reducers/DocumentationReducer";
import { NewFolderDialog } from "../Components/Dialogs/NewFolderDialog";
import { NewDocumentDialog } from "../Components/Dialogs/NewDocumentDialog";
import { DeleteFolderDialog } from "../Components/Dialogs/DeleteFolderDialog";
import { EditPropertiesDialog } from "../Components/Dialogs/EditPropertiesDialog";
import { PasteDialog } from "../Components/Dialogs/PasteDialog";
import { restoreFromTrash, getTrash, getFolderContents, copyToFolder } from "../Reducers/thunks";
import { Link } from "react-router-dom";
import { handleError } from "../Helpers/handleError";
import { LastLocation } from "../Helpers/LastLocation";
import { isLengthChanged, isOrderChanged } from "../Helpers/helpers";
import history from "../../../Utils/history";
export function useDialogs(
  setShowNewFolder,
  setSeverity,
  setAlert,
  setShowNewDoc,
  setShowDeleteDoc,
  setShowEdit,
  setShowPaste,
  showNewFolder,
  showNewDoc,
  showDeleteDoc,
  docToEdit,
  showEdit,
  showPaste
) {
  const dispatch = useDispatch();
  const folderId = useSelector((state) => state.documentation.currentFolder?.folder.id);
  const folderName = useSelector((state) => state.documentation.currentFolder?.folder.name);
  const folderDescription = useSelector((state) => state.documentation.currentFolder?.folder.description);
  const folderPermissions = useSelector((state) => state.documentation.currentFolder?.folder.user_permissions);
  const folderAncestors = useSelector((state) => state.documentation.currentFolder?.ancestors, isLengthChanged);
  const folderResults = useSelector((state) => state.documentation.currentFolder?.results, isLengthChanged);
  const search = useSelector((state) => state.documentation.search);
  const rootID = useSelector((state) => state.documentation.rootID);
  const order = useSelector((state) => state.documentation.order, isOrderChanged);
  const last = LastLocation();

  const isLastBrowse = last === "browse" ? rootID : last;
  const reloadContents = useCallback(
    () =>
      dispatch(
        getFolderContents({
          id: folderId,
          q: search,
          name: folderName,
          description: folderDescription,
          user_permissions: folderPermissions,
          ancestors: folderAncestors,
          targetId: isLastBrowse,
          previousResults: folderResults,
          order,
        })
      ),
    [
      dispatch,
      search,
      isLastBrowse,
      order,
      folderId,
      folderName,
      folderDescription,
      folderPermissions,
      folderAncestors,
      folderResults,
    ]
  );
  const onFolderCreated = ({ folder }) => {
    setSeverity("success");
    setAlert(
      <div>
        Folder <Link to={`/documentation/browse/${folder.id}`}>{folder.name}</Link> created.
      </div>
    );
    reloadContents();
  };
  const closeNewFolder = () => setShowNewFolder(false);

  const onDocumentCreated = (value) => {
    setSeverity("success");
    setAlert("Document Created.");
    history.push("/documentation/edit/" + value.id);
  };
  const closeNewDoc = (e) => {
    setShowNewDoc(false);
  };
  const closeDeleteFolder = () => setShowDeleteDoc(false);

  const onDocDeleted = ({ id, name, kind, err, msg }) => {
    if (err) {
      setSeverity("error");
      setAlert(`${kind === "folder" ? "Folder" : "Document"}: ${name} could not be deleted... ${msg}`);
    } else {
      setSeverity("success");
      const setTrashAndAlert = (res) => {
        dispatch(setTrash({ trash: res }));
        dispatch(received({ error: false }));
        setSeverity("success");
        setAlert(`${name} successfully restored`);
        reloadContents();
      };
      setAlert(
        <div>
          {kind === "folder" ? "Folder" : "Document"}: {name} Successfully Deleted
          <Button
            color="secondary"
            onClick={() =>
              dispatch(restoreFromTrash({ id, destination_id: folderId, kind }))
                .then(
                  dispatch(getTrash({ folder_id: "root" }))
                    .then(setTrashAndAlert)
                    .catch(handleError(dispatch))
                )
                .catch(handleError(dispatch))
            }
          >
            UNDO
          </Button>
        </div>
      );
    }
    reloadContents();
  };
  const closeEdit = () => setShowEdit(false);
  const onEdit = (name, kind) => {
    reloadContents();

    setAlert(`${kind === "folder" ? "Folder" : "Document"}: ${name} Successfully Edited`);
  };

  const onPaste = ({ name, kind, id, originFolderName, destFolderName, originFolderID, destFolderID, remove }) => {
    dispatch(setCopy({ copy: null }));
    setSeverity("success");
    setAlert(
      <div>
        {`${name} pasted from ${originFolderName} to ${destFolderName} ${
          remove ? "and removed from " + originFolderName : ""
        }`}
        {remove && (
          <Button
            color="secondary"
            onClick={() => {
              dispatch(
                copyToFolder({
                  id,
                  destination_folder_id: originFolderID,
                  remove: true,
                  kind,
                })
              ).then((x) => {
                reloadContents();
                setAlert(`${name} returned to ${originFolderName}`);
              });
            }}
          >
            UNDO
          </Button>
        )}
      </div>
    );
    reloadContents();
  };
  const closePaste = () => setShowPaste(false);

  const Dialogs = showNewFolder ? (
    <NewFolderDialog open={showNewFolder} onClose={closeNewFolder} onCreated={onFolderCreated} />
  ) : showNewDoc ? (
    <NewDocumentDialog open={showNewDoc} onClose={closeNewDoc} onCreated={onDocumentCreated} />
  ) : showDeleteDoc ? (
    <DeleteFolderDialog
      open={showDeleteDoc}
      onClose={closeDeleteFolder}
      onDelete={onDocDeleted}
      docToEdit={docToEdit}
    />
  ) : showEdit ? (
    <EditPropertiesDialog open={showEdit} onClose={closeEdit} onEdit={onEdit} docToEdit={docToEdit} />
  ) : showPaste ? (
    <PasteDialog open={showPaste} onClose={closePaste} onPaste={onPaste} />
  ) : null;

  return {
    Dialogs,
  };
}
