import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import { Button, Stepper, Typography, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import history from "../../../../Utils/history";
import { Breadcrumb } from "../../../Dashboard/Components/Breadcrumbs";
import { Dashboard } from "../../../Dashboard/Dashboard";
import { SnackbarAlertRedux } from "../../../Documentation/Components/SnackbarAlert";
import { setLinkVals } from "../../helpers";
import { useSingleRow } from "../../Hooks/useSingleRow";
import { LoadingSpinner } from "../LoadingSpinner";
import { QRLinkModal } from "../QRLinkModal";
import { SubscriptionModal } from "../SubscriptionModal";
import { SingleRowContainer } from "./SingleRowContainer";
import { Print } from "@mui/icons-material";
import ReactToPrint from "react-to-print";

import styled from "styled-components";
export const SingleRowView = () => {
  const currentDocument = useSelector((state) => state.documentation.currentDocument);
  const singleRow = useSelector((state) => state.documentation.singleRow);
  const loading = useSelector((state) => state.documentation.loading);
  const [openQR, setOpenQR] = useState(false);
  const [openSubs, setOpenSubs] = useState(false);
  const linkVals = setLinkVals(currentDocument);
  const params = useParams();
  const alert = useSelector((state) => state.documentation.alert);
  const severity = useSelector((state) => state.documentation.severity);
  let componentRef = useRef();

  const theme = useTheme();
  const location = useLocation();
  let [month, date, year] = singleRow
    ? new Date(singleRow.created_at).toLocaleDateString("en-US").split("/")
    : [0, 0, 0];
  let [hour, minute, , pm] = singleRow
    ? new Date(singleRow.created_at).toLocaleTimeString("en-US").split(/:| /)
    : [[0, 0, 0, 0]];

  const StyledStepper = styled(Stepper)`
    width: 99%;
    @media print {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: visible;
      body *:not(.styled-stepper) {
        display: none !important;
      }
      .MuiBreadcrumbs-root {
        display: none !important;
      }
      .MuiToolbar-root {
        display: none !important;
      }
      .MuiButton-root {
        display: none !important;
      }
    }
  `;

  useSingleRow(singleRow, params);
  return (
    <Dashboard
      pageName={`Row from ${currentDocument?.name ?? " "}`}
      breadcrumbs={
        <Breadcrumb
          currentValue={"View Row"}
          linkValues={[
            ...linkVals,
            { name: currentDocument?.name ?? "", to: `/documentation/view/${params.document_id}` },
          ]}
          isViewer
          setOpenQR={setOpenQR}
          setOpenSubs={setOpenSubs}
        />
      }
    >
      {loading || !currentDocument || !singleRow ? (
        <LoadingSpinner />
      ) : (
        <div
          style={{
            overflow: "scroll",
          }}
        >
          <Button
            variant="text"
            style={{ padding: "1em", fontSize: "12px" }}
            startIcon={<AssignmentReturnIcon />}
            onClick={() => {
              location.pathname.includes("documentation/results")
                ? history.push(`/documentation/results/${params.document_id}`)
                : history.push(`/documentation/view/${params.document_id}`);
            }}
          >
            Back to Results
          </Button>
          <ReactToPrint
            documentTitle={currentDocument.name}
            pageStyle={{ padding: "1em" }}
            trigger={() => (
              <Button variant="text" style={{ padding: "1em", fontSize: "12px" }} startIcon={<Print />}>
                Print
              </Button>
            )}
            content={() => componentRef}
          />
          <div ref={(el) => (componentRef = el)}>
            <Typography style={{ padding: "1em" }}>
              {singleRow
                ? `Submitted on ${year}/${month}/${date} ${hour}:${minute} ${pm} by ${singleRow.created_by_username}`
                : ``}
            </Typography>
            <StyledStepper className="styled-stepper" orientation="vertical">
              {currentDocument.contents.question_index.map((page) => (
                <SingleRowContainer
                  key={page}
                  currentDocument={currentDocument}
                  page={page}
                  singleRow={singleRow}
                  theme={theme}
                />
              ))}
            </StyledStepper>
          </div>
        </div>
      )}
      <QRLinkModal openQR={openQR} setOpenQR={setOpenQR} value={window.location.href} name={currentDocument?.name} />
      <SubscriptionModal openSubs={openSubs} setOpenSubs={setOpenSubs} />
      <SnackbarAlertRedux alert={alert} severity={severity} />
    </Dashboard>
  );
};
