import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getFolderACL } from "../Reducers/Thunks/getFolderACL";
import { getDocumentACL } from "../Reducers/Thunks/getDocumentACL";
import { getACLSubjects } from "../Reducers/Thunks/getACLSubjects";
import { handleAclLists } from "./Helpers/handleAclLists";

export function usePermissions(kind, id, setACLList, setACLInherited, setPermissionList, setACLError, setACLSubjects) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (kind === "folder") {
      dispatch(getFolderACL({ id }))
        .then((res) => handleAclLists(res, setACLList, setACLInherited, setPermissionList))
        .catch((e) => setACLError(e.response.data.detail));
    } else {
      dispatch(getDocumentACL({ id }))
        .then((res) => handleAclLists(res, setACLList, setACLInherited, setPermissionList))
        .catch((e) => setACLError(e.response.data.detail));
    }
    dispatch(getACLSubjects())
      .then((res) => setACLSubjects(res.results))
      .catch((e) => setACLError(e.response.data.detail));
    return () => console.log("unmounted");
  }, [dispatch, kind, id, setACLList, setACLInherited, setPermissionList, setACLError, setACLSubjects]);
}
