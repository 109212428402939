import axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const listPendingInvites = () => async () => {
  try {
    const data = await axios({
      method: "get",
      url: baseURL + "auth/invites",

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return data;
  } catch (e) {
    console.error(e);
    return e;
  }
};
