import React, { useRef, useState } from "react";
import {
  canvasStyle,
  Image,
  Video,
  Annotations,
  CanvasPaper,
  Pdf,
  imageContainerStyle,
  imageStyle,
  annotationStyle,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useAddShape } from "../../Hooks/useAddShape";
import { useScaleFactor } from "../../Hooks/useScaleFactor";
import { Selection } from "./Selection";
import { Shape } from "./Shapes";
import { LoadingSpinner } from "../../../Viewer/Components/LoadingSpinner";
import { setZoom, setZoomOffset } from "../../Reducers/EditorReducer";
import { useResetZoomOffset } from "../../Hooks/useResetZoomOffset";
import { useResetZoom } from "../../Hooks/useResetZoom";
import { useContainedSize } from "../../Hooks/useContainedSize";
import { useDeselectShape } from "../../Hooks/useDeselectShape";
// import { PDFViewer } from "./PDFViewer";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { forceDownload } from "./Helpers/forceDownload";
import { parseFilename } from "./Helpers/parseFilename";

export const Canvas = ({
  view,
  dragging,
  annotationWidth,
  boxHeight,
  getRootPropsReplace,
  getInputPropsReplace,
  isDragActiveReplace,
}) => {
  const dispatch = useDispatch();
  const [zooming, setZooming] = useState(false);
  const [evCache, setEvCache] = useState([]);
  const [prevDiff, setPrevDiff] = useState(-1);
  const [panDiff, setPanDiff] = useState({ x: 0, y: 0 });
  const [holding, setHolding] = useState(false);
  const [naturalHeight, setNaturalHeight] = useState(null);
  const [naturalWidth, setNaturalWidth] = useState(null);
  const svgRef = useRef(null);
  const imageRef = useRef(null);
  const coordsRef = useRef();
  const shapes = useSelector((state) => state.editor.shapes);
  const tool = useSelector((state) => state.editor.tool);
  const selected = useSelector((state) => state.editor.selected);
  const zoom = useSelector((state) => state.editor.zoom);
  const zoomOffset = useSelector((state) => state.editor.zoomOffset);
  const containedSize = useSelector((state) => state.editor.containedSize);
  const currentPage = useSelector((state) => state.documentation.currentPage);
  const loading = useSelector((state) => state.documentation.loading);
  const isVideo = /video/.test(currentPage?.content_type);
  const isPdf = /pdf/.test(currentPage?.content_type);
  const isImage = /image/.test(currentPage?.content_type);
  const svgBoundingRect = svgRef?.current?.getBoundingClientRect();
  const svgMiddleY = svgBoundingRect && svgBoundingRect.top + svgBoundingRect.height * 0.5;
  const svgMiddleX = svgBoundingRect && svgBoundingRect.left + svgBoundingRect.width * 0.5;
  let clickHoldTimer = null;
  const handleMouseMove = (e) => {
    if (zoom > 1 && holding) {
      dispatch(
        setZoomOffset({
          top: zoomOffset.top + e.movementY * zoom,
          left: zoomOffset.left + e.movementX * zoom,
        })
      );
    }
  };

  const handlePointerDown = (ev) => {
    if (zoom > 1) {
      clickHoldTimer = setTimeout(() => {
        setHolding(true);
      }, 200);
    }
    if (ev.pointerType !== "mouse") {
      setEvCache((state) => {
        if ([...state, ev].length === 2) {
          setZooming(true);
        }
        return [...state, ev];
      });

      setPanDiff({ x: ev.clientX, y: ev.clientY });
    }
  };
  const handlePointerMove = (ev) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Pointer_events/Pinch_zoom_gestures
    let newArr = [...evCache];
    for (var i = 0; i < evCache.length; i++) {
      if (ev.pointerId === evCache[i].pointerId) {
        newArr[i] = ev;
        setEvCache(newArr);
        break;
      }
    }
    if (evCache.length === 2) {
      const clientXDiff = evCache[0].clientX - evCache[1].clientX;
      const clientYDiff = evCache[0].clientY - evCache[1].clientY;
      const curDiff = Math.abs(clientXDiff + clientYDiff);
      if (prevDiff > 0) {
        const diff = curDiff - prevDiff;
        const newZoom = zoom + diff * 0.01;
        const xDiff = (svgMiddleX - ev.clientX) * newZoom - (svgMiddleX - ev.clientX) * zoom;
        const yDiff = (svgMiddleY - ev.clientY) * newZoom - (svgMiddleY - ev.clientY) * zoom;
        if (newZoom > 1) {
          dispatch(setZoom({ zoom: newZoom }));
          if (newZoom > zoom) {
            dispatch(setZoomOffset({ top: zoomOffset.top + yDiff, left: zoomOffset.left + xDiff }));
          }
        } else {
          dispatch(setZoom({ zoom: 1 }));
        }
      }
      setPrevDiff(curDiff);
    }
    if (evCache.length === 1 && !zooming) {
      if (zoom > 1) {
        setPanDiff({ x: ev.clientX, y: ev.clientY });
        dispatch(
          setZoomOffset({
            top: zoomOffset.top - (panDiff.y - ev.clientY),
            left: zoomOffset.left - (panDiff.x - ev.clientX),
          })
        );
      }
    }
  };
  const handlePointerUp = (ev) => {
    clearTimeout(clickHoldTimer);
    setTimeout(() => {
      setHolding(false);
    }, 100);
    // Remove this pointer from the cache
    let newArr = [...evCache];
    for (var i = 0; i < newArr.length; i++) {
      if (newArr[i].pointerId === ev.pointerId) {
        newArr.splice(i, 1);
        break;
      }
    }
    setEvCache(newArr);

    if (evCache.length < 1) {
      setZooming(false);
      setPrevDiff(-1);
      setPanDiff({ y: 0, x: 0 });
    }
  };
  const handleWheel = (e) => {
    if (view) {
      const newZoom = zoom + e.deltaY * 0.001;
      const clientX = svgMiddleX - e.clientX;
      const clientY = svgMiddleY - e.clientY;
      const xDiff = clientX * newZoom - clientX * zoom;
      const yDiff = clientY * newZoom - clientY * zoom;
      if (newZoom >= 1) {
        dispatch(setZoom({ zoom: newZoom }));
        dispatch(setZoomOffset({ top: zoomOffset.top + yDiff, left: zoomOffset.left + xDiff }));
      } else {
        dispatch(setZoom({ zoom: 1 }));
      }
    }
  };
  const handleOnLoadImage = (e) => {
    setNaturalHeight(e.target.naturalHeight);
    setNaturalWidth(e.target.naturalWidth);
  };

  useContainedSize({ annotationWidth, boxHeight, svgMiddleY, svgMiddleX, imageRef });
  useScaleFactor({
    naturalHeight,
    naturalWidth,
    annotationWidth,
    boxHeight,
    svgMiddleY,
    svgMiddleX,
  });
  useAddShape(svgRef, coordsRef);
  useDeselectShape(svgRef, holding);
  useResetZoomOffset();
  useResetZoom();
  const isMobile = /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  return (
    <div style={{ height: "100%", width: "100%" }} {...(!view ? { ...getRootPropsReplace() } : {})}>
      {!view ? <input {...getInputPropsReplace()} /> : ""}

      <CanvasPaper
        ref={svgRef}
        id="canvas"
        tool={tool}
        style={{ ...canvasStyle, backgroundColor: isDragActiveReplace && !view ? "beige" : "initial" }}
      >
        {loading ? (
          <LoadingSpinner />
        ) : isVideo ? (
          <Video controls src={currentPage?.media} type={currentPage?.content_type} />
        ) : isPdf ? (
          isMobile ? (
            // <PDFViewer boxHeight={boxHeight} file={currentPage?.media} />

            <Button
              startIcon={<DownloadIcon />}
              variant="contained"
              onClick={() => {
                const link = document.createElement("a");
                link.download = parseFilename(currentPage?.media);
                link.href = currentPage?.media;
                link.click();
              }}
            >
              {parseFilename(currentPage?.media)}
            </Button>
          ) : (
            <Pdf dragging={dragging} src={currentPage?.media} />
          )
        ) : isImage ? (
          <div style={imageContainerStyle(zoomOffset)} id="imageContainer">
            <Image
              style={imageStyle(svgRef, zoom)}
              id="image"
              alt={currentPage?.media}
              src={currentPage?.media}
              onLoad={handleOnLoadImage}
              ref={imageRef}
            />
            {
              <Annotations
                onMouseMove={handleMouseMove}
                onPointerDown={handlePointerDown}
                onPointerMove={handlePointerMove}
                onPointerUp={handlePointerUp}
                onPointerCancel={handlePointerUp}
                onPointerLeave={handlePointerUp}
                onPointerOut={handlePointerUp}
                onWheel={handleWheel}
                className="editorCanvas"
                id="editorCanvas"
                style={annotationStyle(containedSize?.height, containedSize?.width)}
              >
                {shapes &&
                  shapes.map((shape) => <Shape holding={holding} key={shape.id} id={`${shape.id}`} {...shape} />)}
                {selected && <Selection view={view} />}
              </Annotations>
            }
          </div>
        ) : (
          <Button
            startIcon={<DownloadIcon />}
            variant="contained"
            onClick={() => forceDownload(currentPage?.media, parseFilename(currentPage.media))}
          >
            Download{" "}
            {currentPage?.filename ? currentPage?.filename : currentPage?.media ? parseFilename(currentPage.media) : ""}
          </Button>
        )}
      </CanvasPaper>
    </div>
  );
};
