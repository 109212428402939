import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isOrderChanged, usePrevious } from "../Helpers/helpers";

import { getFolder } from "../Reducers/thunks";

export const useSortFolder = (isRootFolder) => {
  const dispatch = useDispatch();
  const searching = useSelector((state) => state.documentation.searching);
  const order = useSelector((state) => state.documentation.order, isOrderChanged);
  const prevOrder = usePrevious(order);
  useEffect(() => {
    if (!searching && JSON.stringify(prevOrder) !== JSON.stringify(order)) {
      dispatch(getFolder({ ID: isRootFolder, order }));
    }
  }, [dispatch, order, prevOrder, isRootFolder, searching]);
};
