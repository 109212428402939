import axios from "axios";
import store from "../../../../store";
import { setQuestions } from "../../../Editor/Reducers/EditorReducer";
import {
  loading,
  received,
  setCurrentFolder,
  setCurrentFolderContents,
  setRootID,
  setTrash,
} from "../DocumentationReducer";
import { baseURL } from "../thunks";
import { getTrash } from "./getTrash";
import { getThrottledResults } from "./Helpers/getThrottledResults";
export const getFolder =
  ({ ID, order, initial = true }) =>
  async (dispatch) => {
    try {
      dispatch(loading());

      const {
        data: {
          folder: { id, name, description, user_permissions },
          ancestors,
        },
      } = await axios.get(baseURL + "documentation/folders/" + ID, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const filterAncestors = ancestors.filter((x) => x.name === "root");
      filterAncestors[0] ? dispatch(setRootID({ id: filterAncestors[0].id })) : dispatch(setRootID({ id }));
      dispatch(getTrash({ folder_id: "root" })).then((x) => {
        dispatch(setTrash({ trash: x }));
      });
      dispatch(
        setCurrentFolder({
          id,
          name,
          description,
          user_permissions,
          ancestors,
          targetId: ID,
          initial,
        })
      );

      let skip = 0;
      let limit = 100;
      const params = new URLSearchParams();
      params.append("ordering", order?.kind + "kind");
      params.append("ordering", order?.name + "name");
      params.append("ordering", order?.created_at + "created_at");
      params.append("ordering", order?.modified_at + "modified_at");
      params.append("skip", skip);
      params.append("limit", limit);

      const {
        data: { results, total },
      } = await axios.get(baseURL + "documentation/folders/" + ID + "/contents", {
        params,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (
        parseInt(id) === store.getState().documentation?.currentFolder?.folder?.id ||
        store.getState().documentation?.currentFolder?.folder?.id === null
      ) {
        if (JSON.stringify(store.getState().editor.questions) !== "{}") dispatch(setQuestions({ reset: true }));
        dispatch(
          setCurrentFolderContents({
            results,
            total,
          })
        );
        dispatch(received({ error: false }));
        await getThrottledResults({ ID, dispatch, skip, limit, total, params });
      }
    } catch (error) {
      console.log(error);
      dispatch(received({ error: true }));
    }
  };
