import Axios from "axios";
import history from "../../../../Utils/history";
import { handleLogout } from "../../../Dashboard/Thunks/handleLogout";
import { setAlert } from "../DocumentationReducer";
import { baseURL } from "../thunks";

export const queryAllOfType =
  ({ types }) =>
  async (dispatch) => {
    const params = new URLSearchParams();
    params.append("q", "");
    params.append("types", ...types);
    params.append("include_folders", true);
    params.append("limit", 1000000000);
    try {
      return await Axios.get(baseURL + "documentation/search/", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params,
      });
    } catch (error) {
      if (error.response.data.detail === "Could not validate credentials") {
        handleLogout(dispatch)();
      } else {
        history.push("/documentation/browse/");
      }
      dispatch(setAlert({ alert: error.response.data.detail, severity: "warning" }));
      console.log(error);
    }
  };
