import { baseURL } from "./thunks";
import { listPendingInvites } from "../../Dashboard/Thunks/listPendingInvites";
import Axios from "axios";
import { setLoggedInUser } from "../../Login/Reducers/LoginReducer";

export const loginAfterRegistration =
  ({ access_token }) =>
  async (dispatch) => {
    try {
      localStorage.setItem("login", true);
      localStorage.setItem("token", access_token);
      const userReq = await Axios.get(baseURL + "auth/me", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
        },
      });

      dispatch(listPendingInvites()).then((res2) => {
        sessionStorage.setItem("is_org_admin", userReq.data.is_org_admin);
        dispatch(
          setLoggedInUser({
            username: userReq.data.username,
            email: userReq.data.email,
            full_name: userReq.data.full_name,
            is_org_admin: userReq.data.is_org_admin,
            available_orgs: userReq.data.available_orgs,
            org: userReq.data.org,
            roles: userReq.data.roles,
            authenticated: true,
            avatar: userReq.data.avatar,
            otp_enabled: userReq.data.otp_enabled,
            invitations: res2.data,
          })
        );
      });
    } catch (error) {
      console.log(error?.response?.data?.detail);
    }
  };
