export const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    overflowWrap: "anywhere",
  },
  folder: {
    fontSize: "6em",
    fill: "#f0dc82",
  },
  document: {
    fontSize: "6em",
    fill: "cornflowerblue",
  },
  form: {
    fontSize: "6em",
    fill: "darkcyan",
  },
  report: {
    fontSize: "6em",
    fill: "#9c27b0",
  },
  sheet: {
    fontSize: "6em",
    fill: "mediumslateblue",
  },
  filename: {
    wordBreak: "break-word",
    color: "black",
  },
  description: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "12px",
    whiteSpace: "nowrap",
  },
  modified: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "12px",
  },
  descriptionContainer: {
    padding: "0.5em",
    textAlign: "start",
    overflow: "hidden",
  },
  folderContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "baseline",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  formControl: {
    minWidth: 120,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  folderPaper: {
    height: "100%",
    borderRadius: "0",
  },
  loadingEmpty: {
    height: "75%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 0.5em 0 0.5em",
  },
  buttons: {
    display: "flex",
    position: "absolute",
  },
  search: { display: "flex", position: "absolute", right: "2em" },
};
