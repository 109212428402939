import axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const forceLogoutSession =
  ({ session_id }) =>
  async () => {
    try {
      const data = await axios({
        method: "delete",
        url: baseURL + "auth/me/sessions/" + session_id,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return data;
    } catch (e) {
      console.error(e);
      return e;
    }
  };
