export function initializeFolder(state, action) {
  const { id, name, description, user_permissions, ancestors } = action.payload;
  state.currentFolder.folder.id = id;
  state.currentFolder.folder.name = name;
  state.currentFolder.folder.description = description;
  state.currentFolder.folder.user_permissions = user_permissions;
  state.currentFolder.ancestors = ancestors;
}
export function folderIsRoot(state) {
  state.currentFolder.folder.id = state.rootID;
  state.currentFolder.folder.name = "root";
  state.currentFolder.folder.description = null;
  state.currentFolder.folder.user_permissions = 32767;
  state.currentFolder.ancestors = [];
}

export function stepIntoFolder(state, filteredResults) {
  state.currentFolder.ancestors.push({
    id: state.currentFolder.folder.id,
    name: state.currentFolder.folder.name,
    description: state.currentFolder.folder.description,
    user_permissions: state.currentFolder.folder.user_permissions,
  });
  state.currentFolder.folder.id = filteredResults[0].id;
  state.currentFolder.folder.name = filteredResults[0].name;
  state.currentFolder.folder.description = filteredResults[0].description;
  state.currentFolder.folder.user_permissions = filteredResults[0].user_permissions;
}

export function stepOutOfFolder(state, filteredAncestor, splicedAncestors) {
  state.currentFolder.folder.id = filteredAncestor[0].id;
  state.currentFolder.folder.name = filteredAncestor[0].name;
  state.currentFolder.folder.description = filteredAncestor[0].description;
  state.currentFolder.folder.user_permissions = filteredAncestor[0].user_permissions;
  state.currentFolder.ancestors = splicedAncestors;
}

export function filterId(arr, target) {
  return arr.filter((x) => parseInt(x.id) === parseInt(target));
}
export function filterFolderId(arr, target) {
  return arr.filter((x) => {
    return x.kind === "folder" && parseInt(x.id) === parseInt(target);
  });
}

export function indexOfFirstValue(arr, arr2) {
  return arr.slice(0).indexOf(arr2[0]);
}

export function filterBelowIndex(arr, index) {
  return arr.slice(0).filter((_, i) => i < index);
}
