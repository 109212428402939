import React from "react";
import Avatar from "react-avatar-edit";
import { Dialog, DialogTitle, Button, DialogActions } from "@mui/material";

const previewStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    height: "10em",
    width: "10em",
  },
};

export const AvatarEditor = (props) => {
  const { preview, setPreview, setFileName } = props;
  function onClose() {
    setPreview(null);
  }

  function onCrop(prev) {
    setPreview(prev);
  }

  function onFileLoad(file) {
    setFileName(file.name);
  }

  const img = <img style={previewStyle.image} crossOrigin="Anonymous" src={preview} alt="Preview" />;
  return (
    <div style={previewStyle.container}>
      <Avatar
        data-testid="avatar"
        width={390}
        height={295}
        onCrop={onCrop}
        onClose={onClose}
        onFileLoad={onFileLoad}
        src={""}
      />
      <div>{preview && img}</div>
    </div>
  );
};

export const ChangeAvatarDialog = ({ setAvatarOpen, avatarOpen, preview, setPreview, setFileName }) => {
  const handleClick = () => {
    setAvatarOpen(false);
  };

  return (
    <Dialog open={avatarOpen}>
      <DialogTitle id="form-dialog-title">New Avatar</DialogTitle>
      <AvatarEditor preview={preview} setPreview={setPreview} setFileName={setFileName} />
      <DialogActions>
        <Button onClick={() => setAvatarOpen(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClick} type={"submit"} style={{ color: "#097c09" }}>
          Set Avatar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
