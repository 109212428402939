export function areArraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function compareShapes(currentPage, shapes) {
  if (!shapes || !currentPage.shapes || currentPage.shapes.length !== shapes.length) return false;
  return currentPage.shapes.reduce((a, c, i) => {
    if (
      c.x !== shapes[i].x ||
      c.y !== shapes[i].y ||
      c.id !== shapes[i].id ||
      c.color !== shapes[i].color ||
      c.shape !== shapes[i].shape ||
      c.width !== shapes[i].width ||
      c.height !== shapes[i].height ||
      c.rotation !== shapes[i].rotation ||
      c.description !== shapes[i].description ||
      c.strokeWidth !== shapes[i].strokeWidth
    ) {
      a = false;
    }
    return a;
  }, true);
}

export const isEmptyObject = (objectName) => {
  for (var prop in objectName) {
    if (objectName.hasOwnProperty(prop)) {
      return false;
    }
  }
  return true;
};
