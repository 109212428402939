export function flattenTree(tree, setParent) {
  const flat = [];
  for (let node of tree) {
    let flatNode = { ...node };
    if (flatNode.children) {
      for (let child of flattenTree(flatNode.children, node.id)) {
        flat.push(child);
      }
    }
    delete flatNode.children;
    flatNode.parent = setParent;
    flatNode.droppable = true;
    flatNode.text = flatNode.name;
    flat.push(flatNode);
  }
  return flat;
}
