import React from "react";
import { Grid, ListItem } from "@mui/material";
import { TrashPopover } from "./TrashPopover";
import { useSelector } from "react-redux";
import { documentationSelector } from "../../Reducers/DocumentationReducer";
import { descriptionContainer } from "./descriptionContainer";
import { isFolderIcon } from "./isFolderIcon";

export function TrashCards({ setSeverity, setAlert }) {
  const { trash } = useSelector(documentationSelector);
  return trash.results.map((x) => (
    <Grid item container xs={12} sm={6} md={4} lg={3} key={x.name + x.kind + x.id}>
      <ListItem disableGutters>
        <Grid item xs={3}>
          {isFolderIcon(x)}
        </Grid>
        <Grid style={{ width: "inherit" }} item xs={8}>
          {descriptionContainer(x)}
        </Grid>
        <TrashPopover x={x} setSeverity={setSeverity} setAlert={setAlert} />
      </ListItem>
    </Grid>
  ));
}
