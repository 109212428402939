import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectShape, setZoom } from "../Reducers/EditorReducer";

export function useDeselectShape(svgRef, holding) {
  const dispatch = useDispatch();
  const tool = useSelector((state) => state.editor.tool);
  const selected = useSelector((state) => state.editor.selected);
  const zoom = useSelector((state) => state.editor.zoom);
  const content_type = useSelector((state) => state.documentation?.currentPage?.content_type);
  const isPDF = /pdf/.test(content_type);

  useEffect(() => {
    if (tool === "select" && selected && !holding && !isPDF) {
      const setFromEvent = (e) => {
        e.preventDefault();

        if (e.target.id === "editorCanvas") {
          dispatch(selectShape({ id: "none" }));
          if (zoom > 1) dispatch(setZoom({ zoom: 1 }));
          return null;
        }
      };

      if (svgRef.current !== null) {
        let current = svgRef.current;
        current.addEventListener("click", setFromEvent);
        return () => current.removeEventListener("click", setFromEvent);
      }
    }
  }, [dispatch, tool, selected, zoom, svgRef, holding, isPDF]);
}
