import axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const updateInvitation =
  ({ id, email, expiry, roles, resend_email }) =>
  async (dispatch) => {
    try {
      const data = await axios({
        method: "put",
        url: baseURL + "auth/org-invites/" + id,
        data: {
          id,
          email,
          expiry,
          roles,
          resend_email: resend_email ? true : false,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
