import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Paper, Grid, Typography, TextField } from "@mui/material";
import { Face, Fingerprint, PhonelinkLock } from "@mui/icons-material";
import { useStyles } from "../styles";
import { setLoggedInUser } from "../Reducers/LoginReducer";
import { postLoginDetails } from "../Reducers/postLoginDetails";
import { useLocation } from "react-router-dom";
import { ForgotPasswordModal } from "./ForgotPasswordModal";
import history from "../../../Utils/history";
import { listPendingInvites } from "../../Dashboard/Thunks/listPendingInvites";
import { useIsAuthenticated } from "../Hooks/useIsAuthenticated";
import { AvailableOrgs } from "./AvailableOrgs";
import { SnackbarAlertRedux } from "../../Documentation/Components/SnackbarAlert";
import { getAssignments } from "../../Documentation/Reducers/Thunks/getAssignments";
import { isReferrer } from "./Helpers/isReferrer";
export const LoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.login.loading);
  const error = useSelector((state) => state.login.error);
  const [username, setUsername] = useState(() => window.sessionStorage.getItem("username") || "");
  const [password, setPassword] = useState(() => window.sessionStorage.getItem("password") || "");
  const [twoFactor, setTwoFactor] = useState("");
  const [isTwoFactor, setIsTwoFactor] = useState(false);
  const [isAvailableOrgs, setIsAvailableOrgs] = useState(false);
  const [availableOrgs, setAvailableOrgs] = useState([]);
  const [open, setOpen] = useState(false);
  const [isCapslock, setIsCapslock] = useState(false);
  const alert = useSelector((state) => state.documentation.alert);
  const severity = useSelector((state) => state.documentation.severity);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let location = useLocation();
  useIsAuthenticated();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postLoginDetails({ username, password, setIsTwoFactor, twoFactor }))
      .then((login) => {
        if (login.data.available_orgs.length > 1) {
          setAvailableOrgs(login.data.available_orgs);
          setIsAvailableOrgs(true);
        } else {
          sessionStorage.setItem("is_org_admin", login.data.is_org_admin);
          dispatch(listPendingInvites()).then((invites) => {
            dispatch(getAssignments()).then((assignments) => {
              dispatch(
                setLoggedInUser({
                  username: login?.data.username,
                  email: login?.data.email,
                  full_name: login?.data.full_name,
                  is_org_admin: login?.data.is_org_admin,
                  available_orgs: login?.data.available_orgs,
                  org: login?.data.org,
                  roles: login?.data.roles,
                  authenticated: true,
                  assignments: assignments?.data,
                  avatar: login?.data.avatar,
                  otp_enabled: login?.data.otp_enabled,
                  invitations: invites?.data,
                })
              );
              isReferrer(location);
            });
          });
        }
      })
      .catch((e) => {
        console.error(e);
        history.push("/login");
      });
  };
  const handleCapsLock = (e) => {
    if (e.getModifierState("CapsLock")) {
      setIsCapslock(true);
    } else {
      setIsCapslock(false);
    }
  };
  return (
    <Grid item xs={12} sm={6}>
      <Paper className={classes.padding}>
        {isAvailableOrgs ? (
          <AvailableOrgs availableOrgs={availableOrgs} />
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <TextField
                id="username"
                label="Username"
                type="username"
                inputProps={{ "data-testid": "username" }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                InputProps={{
                  startAdornment: <Face />,
                }}
                fullWidth
                margin="normal"
                error={isCapslock}
                onKeyUp={handleCapsLock}
              />
              <TextField
                id="password"
                label="Password"
                inputProps={{ "data-testid": "password" }}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: <Fingerprint />,
                }}
                error={isCapslock}
                onKeyUp={handleCapsLock}
                fullWidth
                margin="normal"
              />
              {isCapslock ? (
                <Typography style={{ color: "#d32f2f" }} variant="caption">
                  Caps Lock is on
                </Typography>
              ) : (
                ""
              )}

              {isTwoFactor && (
                <TextField
                  id="twofactor"
                  label="Two Factor Authentication"
                  inputProps={{ "data-testid": "twofactor" }}
                  type="twofactor"
                  value={twoFactor}
                  onChange={(e) => setTwoFactor(e.target.value)}
                  InputProps={{
                    startAdornment: <PhonelinkLock />,
                  }}
                  fullWidth
                  margin="normal"
                  autoFocus
                />
              )}
              {error && !loading ? (
                isTwoFactor ? (
                  <Typography color="secondary">Two Factor Authentication Required</Typography>
                ) : (
                  <Typography color="secondary">Incorrect username or password</Typography>
                )
              ) : (
                ""
              )}

              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Button
                    onClick={handleOpen}
                    disableFocusRipple
                    disableRipple
                    style={{ textTransform: "none" }}
                    variant="text"
                    color="primary"
                  >
                    Forgot your password?
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disableFocusRipple
                    disableRipple
                    style={{ textTransform: "none" }}
                    variant="text"
                    color="primary"
                    onClick={() => history.push("/register")}
                  >
                    Sign up
                  </Button>
                </Grid>
              </Grid>

              <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
                <Button
                  type="submit"
                  data-testid="button"
                  variant="contained"
                  color="primary"
                  style={{ textTransform: "none" }}
                >
                  {loading ? "Logging In..." : "Login"}
                </Button>
              </Grid>
            </form>
            <ForgotPasswordModal open={open} handleClose={handleClose} />
          </>
        )}
      </Paper>
      <SnackbarAlertRedux alert={alert} severity={severity} />
    </Grid>
  );
};
