import React from "react";
import { Typography, Paper, Grid } from "@mui/material";
import { useStyles } from "../styles";
import chrome from "../Icons/chrome.png";
import edge from "../Icons/edge.png";
import firefox from "../Icons/firefox.png";
import safari from "../Icons/safari.png";
export const LoginInfoBox = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} style={{ padding: "2em" }}>
      <Paper className={classes.paper}>
        <Typography variant="body1">Invaware Suite currently supports the following web browsers.</Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={chrome} alt="chrome" aria-label="chrome" />
          <img src={edge} alt="edge" aria-label="edge" />
          <img src={firefox} alt="firefox" aria-label="firefox" />
          <img src={safari} alt="safari" aria-label="safari" />
        </div>
        <Typography variant="body1">
          If your browser isn't listed here, you can click any of the icons below to download a supported browser.
        </Typography>
        <Typography variant="body1">
          If you need any assistance don't hesitate to Contact Us or talk to your system administrator.
        </Typography>
      </Paper>
    </Grid>
  );
};
