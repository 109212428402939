import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { setLoggedInUser } from "../Reducers/LoginReducer";
import { useLocation } from "react-router-dom";
import history from "../../../Utils/history";
import { selectOrg } from "../../Dashboard/Thunks/selectOrg";

export function AvailableOrgs({ availableOrgs }) {
  const [selectedOrg, setSelectedOrg] = useState(0);
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.login.loading);

  let location = useLocation();
  return (
    <div>
      <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
        <FormControl fullWidth>
          <InputLabel id="select-label">Select Organization</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            label="Select Organization"
            value={selectedOrg}
            onChange={(e) => setSelectedOrg(e.target.value)}
          >
            {availableOrgs &&
              availableOrgs.map((org, index) => {
                return (
                  <MenuItem key={index} value={index}>
                    {org.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
      <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
        <Button
          data-testid="button"
          variant="outlined"
          color="primary"
          style={{ textTransform: "none" }}
          onClick={() => {
            dispatch(selectOrg({ id: availableOrgs[selectedOrg].id })).then((res) => {
              dispatch(
                setLoggedInUser({
                  username: res.data.username,
                  email: res.data.email,
                  full_name: res.data.full_name,
                  is_org_admin: res.data.is_org_admin,
                  available_orgs: res.data.available_orgs,
                  org: res.data.org,
                  roles: res.data.roles,
                  authenticated: true,
                  avatar: res.data.avatar,
                  otp_enabled: res.data.otp_enabled,
                })
              );
              if (location.pathname === "/login") {
                history.push("/");
              } else {
                history.push(location.pathname);
              }
            });
          }}
        >
          {loading ? "Logging In..." : "Login"}
        </Button>
      </Grid>
    </div>
  );
}
