import React from "react";
import { Button, Grid } from "@mui/material";
import { Clear, Publish } from "@mui/icons-material/";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import { styles } from "../../styles";
import { setDidQuestionsChange } from "../../../Documentation/Reducers/DocumentationReducer";
import { setQuestionMedia } from "../../../Editor/Reducers/EditorReducer";

export function MediaContainer({ id }) {
  const dispatch = useDispatch();
  const media = useSelector((state) => state.editor?.questions[id].media);
  const content_type = useSelector((state) => state.editor?.questions[id].content_type);
  const filename = useSelector((state) => state.editor?.questions[id].filename);
  const parsedFilename = media?.split("?")[0].substring(media.lastIndexOf("/") + 1) || "download";
  const didQuestionsChange = useSelector((state) => state.documentation.didQuestionsChange);
  function handleUploadedFile() {
    return (e) => {
      if (e.target.files) {
        if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

        const uploadedFile = e.target.files[0];
        dispatch(
          setQuestionMedia({
            media: URL.createObjectURL(uploadedFile),
            content_type: uploadedFile.type,
            filename: uploadedFile.name,
            lastModified: uploadedFile.lastModified,
            id,
          })
        );
      }
    };
  }
  return (
    <Grid container item direction="column" xs={3}>
      {media ? (
        /video/.test(content_type) ? (
          <div>
            <video style={styles.media} controls>
              <source src={media} type={content_type} />
            </video>
          </div>
        ) : /image/.test(content_type) ? (
          <div>
            <img style={styles.media} src={media} alt="imgAlt" />
          </div>
        ) : (
          <Button
            startIcon={<DownloadIcon />}
            variant="contained"
            style={{ width: "fit-content" }}
            onClick={() => {
              const link = document.createElement("a");
              link.download = filename || parsedFilename;
              link.href = media;
              link.click();
            }}
          >
            {filename || parsedFilename}
          </Button>
        )
      ) : (
        ""
      )}
      <div>
        {media && (
          <Button onClick={() => dispatch(setQuestionMedia({ id, media: "" }))} startIcon={<Clear />}>
            Clear
          </Button>
        )}
        <input
          onChange={handleUploadedFile()}
          style={{ display: "none" }}
          // accept="image/*, video/mp4, video/mpeg, video/webm"
          id={`contained-button-file-${id}`}
          type="file"
        />
        <label htmlFor={`contained-button-file-${id}`}>
          <Button startIcon={<Publish />} component="span">
            {media ? "Replace" : "Add"} Media
          </Button>
        </label>
      </div>
    </Grid>
  );
}
