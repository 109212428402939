export function constructTree(form_links, questions, form_ids, fetchedChecklists) {
  let seen = [];
  let nodes = {};
  function getQuestionForm(qId) {
    return fetchedChecklists.filter((cl) => cl.contents.questions[qId] !== undefined)[0];
  }
  if (questions) {
    for (let [key, value] of Object.entries(form_links || {})) {
      if (!questions[key] || !questions[value]) continue;
      let fkey = getQuestionForm(key).id;
      let fval = getQuestionForm(value).id;
      if (!fval || !fkey) continue;
      if (seen.indexOf(fval) === -1) {
        seen.push(fval);
      }
      if (seen.indexOf(fkey) === -1) {
        seen.push(fkey);
      }
      nodes[fkey.toString()] = {
        id: fkey,
        question_id: key,
        name: getQuestionForm(key).name,
        path: getQuestionForm(key).path,
        parent_question_id: value,
        children: [],
      };
      if (!nodes[fval.toString()]) {
        nodes[fval.toString()] = {
          id: fval,
          question_id: null,
          name: getQuestionForm(value).name,
          path: getQuestionForm(value).path,
          parent_question_id: null,
          children: [],
        };
      }
    }

    for (let [child, parent] of Object.entries(form_links || {})) {
      if (!questions[parent] || !questions[child]) continue;
      nodes[getQuestionForm(parent).id]?.children.push(nodes[getQuestionForm(child).id]);
      seen.splice(seen.indexOf(getQuestionForm(child).id, 1));
    }
  }

  const roots = [];
  for (let val of Object.values(nodes)) {
    if (val.question_id == null) {
      roots.push(val);
    }
  }

  if (questions) {
    for (const key of Object.keys(questions)) {
      const formId = getQuestionForm(key).id;
      if (!formId || (formId && form_ids?.length && !form_ids.includes(formId))) continue;
      if (!nodes[formId]) {
        nodes[formId] = {
          id: formId,
          question_id: null,
          name: getQuestionForm(key).name,
          path: getQuestionForm(key).path,
          parent_question_id: null,
          children: [],
        };
        roots.push(nodes[formId]);
      }
    }
  }

  const ids = Object.keys(questions).map((question) => getQuestionForm(question).id);

  let outstandingIds = form_ids ? form_ids.filter((id) => !ids.includes(id)) : [];
  if (fetchedChecklists) {
    const matchingChecklists = fetchedChecklists.filter((checklist) => outstandingIds.includes(checklist.id));

    matchingChecklists.forEach((checklist) => {
      if (!nodes[checklist.id]) {
        nodes[checklist.id] = {
          id: checklist.id,
          question_id: null,
          name: checklist.name,
          path: checklist.path,
          parent_question_id: null,
          children: [],
        };
        roots.push(nodes[checklist.id]);
      }
    });
  }
  return roots;
}
