import {
  AssignmentInd,
  Favorite,
  FavoriteBorderOutlined,
  NotificationsActive,
  NotificationsNoneOutlined,
  QrCode2,
  RestartAlt,
  Settings,
} from "@mui/icons-material/";
import { Button, IconButton, Popover } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isKindAndId } from "../../Documentation/Components/Dialogs/helpers";
import {
  setAlert,
  setDidQuestionsChange,
  setFavorites,
  setIsBlockingViewer,
  setSubscriptions,
} from "../../Documentation/Reducers/DocumentationReducer";
import { createDocumentFavorite } from "../../Documentation/Reducers/Thunks/createDocumentFavorite";
import { createDocumentSubscription } from "../../Documentation/Reducers/Thunks/createDocumentSubscription";
import { createFolderFavorite } from "../../Documentation/Reducers/Thunks/createFolderFavorite";
import { deleteDocumentFavorite } from "../../Documentation/Reducers/Thunks/deleteDocumentFavorite";
import { deleteDocumentSubscription } from "../../Documentation/Reducers/Thunks/deleteDocumentSubscription";
import { deleteFolderFavorite } from "../../Documentation/Reducers/Thunks/deleteFolderFavorite";
import { deleteFolderSubscription } from "../../Documentation/Reducers/Thunks/deleteFolderSubscription";
import { getFavorites } from "../../Documentation/Reducers/Thunks/getFavorites";
import { getSubscriptions } from "../../Documentation/Reducers/Thunks/getSubscriptions";
import { setQuestions } from "../../Editor/Reducers/EditorReducer";
// import { setQuestions } from "../../Editor/Reducers/EditorReducer";
import { removeFromLocalStorage } from "../../Viewer/Components/ChecklistViewer/removeFromLocalStorage";

export function OptionsButton({
  anchorEl,
  setAnchorEl,
  setOpenQR,
  id,
  setOpenSubs,
  setOpenAssignments,
  doctype,
  isViewer,
}) {
  const dispatch = useDispatch();
  const currentFolderName = useSelector((state) => state.documentation.currentFolder?.folder?.name);
  const currentFolderID = useSelector((state) => state.documentation.currentFolder?.folder?.id);
  const subscriptions = useSelector((state) => state.documentation.subscriptions);
  const favorites = useSelector((state) => state.documentation.favorites);
  const username = useSelector((state) => state.login?.username);
  const docName = useSelector((state) => state.documentation?.currentDocument?.name);
  const orgID = useSelector((state) => state.login.org?.id);
  const docID = useSelector((state) => state.documentation?.currentDocument?.id);
  const questionsContents = useSelector((state) => state.documentation.currentDocument?.contents?.questions);
  const location = useLocation();

  const uid = `${username}_${orgID}`;
  const checklistUID = `${docName}---${docID}`;

  const isSubscribed = docID
    ? subscriptions.reduce(isKindAndId("document", id), false)
    : subscriptions.reduce(isKindAndId("folder", currentFolderID), false);
  const isFav = docID
    ? favorites.reduce(isKindAndId("document", id), false)
    : favorites.reduce(isKindAndId("folder", currentFolderID), false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popoverID = open ? "simple-popover" : undefined;
  function QRLinkButton(setOpenQR, handleClose) {
    return (
      <Button
        startIcon={<QrCode2 />}
        onClick={() => {
          setOpenQR(true);
          handleClose();
        }}
        style={{ color: "black", width: "100%" }}
        aria-label="qrlink"
      >
        QR Link
      </Button>
    );
  }
  const isRoot = !docID && currentFolderName === "root";
  return (
    <>
      <IconButton style={{ color: "white" }} aria-describedby={popoverID} variant="contained" onClick={handleClick}>
        <Settings />
      </IconButton>
      <Popover
        id={popoverID}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ overflowY: "auto" }}
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          {QRLinkButton(setOpenQR, handleClose)}
          {!isRoot && !location.pathname.includes("documentation/results") ? (
            <Button
              startIcon={isFav ? <Favorite style={{ color: "red" }} /> : <FavoriteBorderOutlined />}
              onClick={() => {
                isFav
                  ? docID
                    ? dispatch(deleteDocumentFavorite({ id })).then((_) =>
                        dispatch(getFavorites()).then((res) => {
                          dispatch(
                            setAlert({
                              alert: `${docName || "this document"} has been removed from your favourites`,
                              severity: "warning",
                            })
                          );
                          dispatch(setFavorites({ favorites: res.data.results }));
                        })
                      )
                    : dispatch(deleteFolderFavorite({ id: currentFolderID })).then((_) =>
                        dispatch(getFavorites()).then((res) => {
                          dispatch(
                            setAlert({
                              alert: `${currentFolderName || "this folder"} has been removed from your favourites`,
                              severity: "warning",
                            })
                          );
                          dispatch(setFavorites({ favorites: res.data.results }));
                        })
                      )
                  : docID
                  ? dispatch(createDocumentFavorite({ id })).then((_) =>
                      dispatch(getFavorites()).then((res) => {
                        dispatch(
                          setAlert({
                            alert: `${docName || "this document"} has been added to your favourites`,
                            severity: "success",
                          })
                        );
                        dispatch(setFavorites({ favorites: res.data.results }));
                      })
                    )
                  : dispatch(createFolderFavorite({ id: currentFolderID })).then((_) =>
                      dispatch(getFavorites()).then((res) => {
                        dispatch(
                          setAlert({
                            alert: `${currentFolderName || "this folder"} has been added to your favourites`,
                            severity: "success",
                          })
                        );
                        dispatch(setFavorites({ favorites: res.data.results }));
                      })
                    );
              }}
              style={{ color: "black", width: "100%" }}
              aria-label="favourite page"
            >
              {isFav ? "Unfavourite" : "Favourite"}
            </Button>
          ) : (
            ""
          )}
          {!isRoot && !location.pathname.includes("documentation/results") ? (
            <Button
              startIcon={isSubscribed ? <NotificationsActive /> : <NotificationsNoneOutlined />}
              onClick={() => {
                isSubscribed
                  ? docID
                    ? dispatch(deleteDocumentSubscription({ id }))
                        .then((_) =>
                          dispatch(getSubscriptions()).then((res) => {
                            dispatch(
                              setAlert({
                                alert: `You have unsubscribed from ${docName || "this document"}`,
                                severity: "warning",
                              })
                            );

                            dispatch(setSubscriptions({ subscriptions: res.data }));
                          })
                        )
                        .catch((e) =>
                          dispatch(
                            setAlert({
                              alert: `Something went wrong, please try again`,
                              severity: "error",
                            })
                          )
                        )
                    : dispatch(deleteFolderSubscription({ id: currentFolderID }))
                        .then((_) =>
                          dispatch(getSubscriptions()).then((res) => {
                            dispatch(
                              setAlert({
                                alert: `You have unsubscribed from ${currentFolderName || "this folder"}`,
                                severity: "warning",
                              })
                            );

                            dispatch(setSubscriptions({ subscriptions: res.data }));
                          })
                        )
                        .catch((e) =>
                          dispatch(
                            setAlert({
                              alert: `Something went wrong, please try again`,
                              severity: "error",
                            })
                          )
                        )
                  : doctype === "form"
                  ? setOpenSubs(true)
                  : dispatch(
                      createDocumentSubscription({
                        id,
                      })
                    )
                      .then((_) =>
                        dispatch(getSubscriptions()).then((res) => {
                          dispatch(setAlert({ alert: `You have subscribed to ${docName}`, severity: "success" }));
                          dispatch(setSubscriptions({ subscriptions: res.data }));
                        })
                      )
                      .catch((e) =>
                        dispatch(
                          setAlert({
                            alert: `Something went wrong with your subscription to ${docName}... please try again`,
                            severity: "error",
                          })
                        )
                      );
                handleClose();
              }}
              style={{ color: "black", width: "100%" }}
              aria-label="favourite page"
            >
              {isSubscribed ? "Unsubscribe" : "Subscribe"}
            </Button>
          ) : (
            ""
          )}
          {!isRoot && !location.pathname.includes("documentation/results") ? (
            <Button
              aria-label="assign"
              style={{ color: "black", width: "100%" }}
              startIcon={<AssignmentInd />}
              onClick={() => {
                setOpenAssignments(true);
                handleClose();
              }}
            >
              Assign
            </Button>
          ) : (
            ""
          )}
          {doctype === "form" && isViewer && !location.pathname.includes("documentation/results") ? (
            <Button
              startIcon={<RestartAlt />}
              onClick={() => {
                let storedChecklists = JSON.parse(localStorage.getItem(uid));
                if (storedChecklists) {
                  removeFromLocalStorage(uid, checklistUID);
                }
                dispatch(
                  setDidQuestionsChange({
                    questions: questionsContents,
                    isEditor: false,
                    isLocalStorage: false,
                  })
                );
                dispatch(setQuestions({ questions: questionsContents, init: true }));
                dispatch(setIsBlockingViewer({ isBlockingViewer: false }));

                handleClose();
                dispatch(setAlert({ alert: "Questions have been reset", severity: "success" }));
              }}
            >
              Reset Questions
            </Button>
          ) : (
            ""
          )}
        </div>
      </Popover>
    </>
  );
}
