import Axios from "axios";
import store from "../../../../store";
import { received, setCurrentFolderContents } from "../DocumentationReducer";
import { baseURL } from "../thunks";
import { getThrottledResults } from "./Helpers/getThrottledResults";

export async function fetchFolderContents({ targetId, params, dispatch, skip, limit }) {
  const {
    data: { results, total },
  } = await Axios.get(baseURL + "documentation/folders/" + targetId + "/contents", {
    params,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (parseInt(targetId) === store.getState().documentation?.currentFolder?.folder?.id) {
    dispatch(
      setCurrentFolderContents({
        results,
        total,
      })
    );

    dispatch(received({ error: false }));
    getThrottledResults({ ID: targetId, dispatch, skip, limit, total, params });
  }
}
