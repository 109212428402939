import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDidQuestionsChange } from "../../../Documentation/Reducers/DocumentationReducer";
import { setQuestion } from "../../../Editor/Reducers/EditorReducer";

export function DescriptionContainer({ id }) {
  const dispatch = useDispatch();
  const didQuestionsChange = useSelector((state) => state.documentation.didQuestionsChange);
  const title = useSelector((state) => state.editor?.questions[id].title);
  const description = useSelector((state) => state.editor?.questions[id].description);
  const photoVerification = useSelector((state) => state.editor?.questions[id].photoVerification);
  const allowComments = useSelector((state) => state.editor?.questions[id].allowComments);
  const required = useSelector((state) => state.editor?.questions[id].required);

  const handleTitle = (e) => {
    if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));
    dispatch(setQuestion({ id, title: e.target.value }));
  };
  const handleDescription = (e) => {
    if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));
    dispatch(setQuestion({ id, description: e.target.value }));
  };
  const handlePhotoVerification = () => {
    if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));
    dispatch(setQuestion({ id, photoVerification: !photoVerification }));
  };
  const handleComments = () => {
    if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));
    dispatch(setQuestion({ id, allowComments: !allowComments }));
  };
  const handleRequired = () => {
    if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));
    dispatch(setQuestion({ id, required: !required }));
  };
  return (
    <>
      <TextField onChange={handleTitle} value={title} name="title" label="Question" fullWidth margin="normal" />
      <TextField
        onChange={handleDescription}
        value={description}
        name="description"
        label="Description"
        fullWidth
        margin="normal"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={photoVerification}
            onChange={handlePhotoVerification}
            name="verification"
            color="primary"
          />
        }
        label="Require Photo Verification"
      />
      <FormControlLabel
        control={<Checkbox checked={allowComments} onChange={handleComments} name="comments" color="primary" />}
        label="Allow Comments"
      />
      <FormControlLabel
        control={<Checkbox checked={required} onChange={handleRequired} name="required" color="primary" />}
        label="Required"
      />
    </>
  );
}
