export function deconstructTree(tree) {
  const edges = {};
  for (const node of tree) {
    if (node.question_id) {
      edges[node.question_id] = node.parent_question_id;
    }
    if (node.children && node.children.length > 0) {
      Object.assign(edges, deconstructTree(node.children));
    }
  }
  return edges;
}
