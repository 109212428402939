import Avatar from "@mui/material/Avatar";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    justifyContent: "center",
  },
  root: {
    flexGrow: 1,
    height: "100%",
  },
  contents: {
    flexGrow: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  padding: {
    padding: theme.spacing(1),
  },
  paper: {
    background: "#f5f5f5",
  },
  typography: {
    padding: theme.spacing(1),
    textAlign: "center",
  },

  largeAvatar: {
    marginTop: "1em",
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: "0 auto",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    padding: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  iconButton: { marginLeft: "auto" },
}));

export const FlexContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BigAvatar = styled(Avatar)`
  width: 10em;
  height: 10em;
`;

export const BigAvatarDiv = styled("div")`
  padding-right: 3em;
  text-align: center;
`;
