import Axios from "axios";
import { received, setAlert, setCurrentFolder } from "../DocumentationReducer";
import { baseURL } from "../thunks";
import { handleLogout } from "../../../Dashboard/Thunks/handleLogout";
import history from "../../../../Utils/history";

export const fetchCurrentFolder =
  ({ targetId }) =>
  async (dispatch) => {
    console.log("called");
    try {
      const {
        data: {
          folder: { id, name, description, user_permissions },
          ancestors,
        },
      } = await Axios.get(baseURL + "documentation/folders/" + targetId, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      dispatch(
        setCurrentFolder({
          id,
          name,
          description,
          user_permissions,
          ancestors,
          targetId,
          initial: true,
          previousResults: null,
        })
      );
    } catch (error) {
      dispatch(received({ error: true }));
      if (error?.response?.data?.detail === "Could not validate credentials") {
        handleLogout(dispatch)();
      }

      history.push("/documentation/browse/");
      dispatch(
        setAlert({
          alert: error?.response?.data?.detail || `Something went wrong trying to access this folder...`,
          severity: "error",
        })
      );

      console.log(error);
    }
  };
