import React from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material/";
import { Box, Button, MobileStepper, Paper, Typography } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import DownloadIcon from "@mui/icons-material/Download";
import { parseFilename } from "../../../Editor/Components/Annotator/Helpers/parseFilename";

export function VerificationPhotoCarousel({ fileObj, id, activeStep, setActiveStep, theme, maxSteps, media }) {
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <Typography>{media ? "" : fileObj[id][activeStep].filename}</Typography>
      </Paper>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {(media ? media : fileObj[id]).map((step, index) => {
          const parsedFilename = media ? step?.split("?")[0].substring(step.lastIndexOf("/") + 1) || "download" : "";
          const extension = media ? parsedFilename.split(".").pop() : "";
          const isImage = /bmp|gif|jpg|jpeg|png|svg|webp/.test(extension);
          const isVideo = /avi|m4v|mov|mp4|mpeg|ogv|qt|webm|wmv/.test(extension);
          return (
            <div key={index} style={{ height: "100%" }}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  sx={{
                    height: "255px",
                    display: "block",
                    maxWidth: 400,
                    overflow: "hidden",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  component={
                    fileObj && fileObj[id]
                      ? step.content_type.includes("image")
                        ? "img"
                        : step.content_type.includes("video")
                        ? () => <video style={{ width: "100%" }} controls src={step} type={step?.content_type} />
                        : () => (
                            <Button
                              startIcon={<DownloadIcon />}
                              variant="contained"
                              onClick={() => {
                                const link = document.createElement("a");
                                link.download = parseFilename(step?.media);
                                link.href = step?.media;
                                link.click();
                              }}
                            >
                              {step?.filename}
                            </Button>
                          )
                      : isImage
                      ? "img"
                      : isVideo
                      ? () => <video style={{ width: "100%" }} controls src={step} />
                      : () => (
                          <Button
                            startIcon={<DownloadIcon />}
                            variant="contained"
                            onClick={() => {
                              const link = document.createElement("a");
                              link.download = parsedFilename;
                              link.href = step;
                              link.click();
                            }}
                          >
                            {parsedFilename}
                          </Button>
                        )
                  }
                  src={media ? step : step.media}
                  alt={media ? step : step.filename}
                />
              ) : null}
            </div>
          );
        })}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </Box>
  );
}
