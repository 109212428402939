import axios from "axios";
import { loading } from "../DocumentationReducer";
import { baseURL } from "../thunks";

export const restoreFromTrash =
  ({ id, kind }) =>
  async (dispatch) => {
    dispatch(loading());

    const { data } =
      kind === "folder"
        ? await axios.post(baseURL + "documentation/folders/" + id + "/restore-from-trash", null, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
        : await axios.post(baseURL + "documentation/documents/" + id + "/restore-from-trash", null, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

    return data;
  };
