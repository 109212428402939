import React from "react";
import { IconButton, Badge } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Delete, FileCopy } from "@mui/icons-material/";
import { buttonStyle } from "./DocumentationToolbar";
import { PasteButton } from "./styles";
export const DocumentationButtons = ({ setShowPaste }) => {
  const id = useSelector((state) => state.documentation.currentFolder.folder.id);
  const total = useSelector((state) => state.documentation.trash.total);
  const copy = useSelector((state) => state.documentation.copy);
  const is_org_admin = useSelector((state) => state.login.is_org_admin);
  return (
    <>
      {copy && copy.originalFolderID && (
        <PasteButton value="Paste" onClick={() => setShowPaste(true)}>
          <FileCopy />
        </PasteButton>
      )}
      {is_org_admin && (
        <IconButton style={buttonStyle} value="Trash Bin" component={Link} to={"/documentation/trash/" + id}>
          <Badge badgeContent={total} color="primary">
            <Delete />
          </Badge>
        </IconButton>
      )}
    </>
  );
};
