export const trueFalse = (id, valueType, e) => ({ id, validation: { type: valueType, value: String(e.target.value) } });
export const datetimeFrom = (id, valueType, question, e) => ({
  id,
  validation: { type: valueType, min: question.validation?.min, max: e.target.value },
});
export const datetimeTo = (id, valueType, e, question) => ({
  id,
  validation: { type: valueType, min: e.target.value, max: question.validation?.max },
});
export const singleChoice = (id, valueType, e) => ({
  id,
  validation: { type: valueType, value: String(e.target.value) },
});
export const multipleChoice = (id, valueType, question, e) => ({
  id,
  validation: {
    type: valueType,
    values: question.validation?.values?.includes(e.target.value)
      ? [...question.validation?.values?.filter((opt) => opt !== e.target.value)]
      : [...(question.validation?.values ? question.validation?.values : []), e.target.value],
  },
});
export const numericMax = (id, valueType, question, e) => ({
  id,
  validation: { type: valueType, min: Number(question.validation?.min), max: Number(e.target.value) },
});
export const numericMin = (id, valueType, e, question) => ({
  id,
  validation: { type: valueType, min: Number(e.target.value), max: Number(question.validation?.max) },
});
