import React from "react";
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TextField } from "@mui/material";
import { AddCircleOutline, RemoveCircleOutline, ArrowUpward, ArrowDownward } from "@mui/icons-material/";
import { useDispatch, useSelector } from "react-redux";
import { setDidQuestionsChange } from "../../../Documentation/Reducers/DocumentationReducer";
import { setMultipleChoice } from "../../../Editor/Reducers/EditorReducer";

export function MultipleChoiceEditor({ id, choice, setChoice }) {
  const dispatch = useDispatch();
  const didQuestionsChange = useSelector((state) => state.documentation.didQuestionsChange);
  const valueType = useSelector((state) => state.editor.questions[id].valueType);
  const choices = useSelector((state) => state.editor.questions[id].choices);

  return (
    (valueType === "single choice" || valueType === "multiple choice") && (
      <>
        <List>
          {choices.length
            ? choices.map((option, i) => {
                return (
                  <ListItem key={i}>
                    <ListItemText primary={option} />
                    <ListItemSecondaryAction>
                      {i !== 0 && (
                        <IconButton
                          edge="end"
                          aria-label="moveup"
                          onClick={() => {
                            if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

                            dispatch(setMultipleChoice({ id, direction: "up", index: i }));
                          }}
                        >
                          <ArrowUpward />
                        </IconButton>
                      )}
                      {i !== choices.length - 1 && (
                        <IconButton
                          edge="end"
                          aria-label="movedown"
                          onClick={() => {
                            if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

                            dispatch(setMultipleChoice({ id, direction: "down", index: i }));
                          }}
                        >
                          <ArrowDownward />
                        </IconButton>
                      )}
                      <IconButton
                        edge="end"
                        aria-label="remove"
                        onClick={() => {
                          if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

                          dispatch(setMultipleChoice({ id, remove: option }));
                        }}
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })
            : ""}
        </List>

        <TextField
          onKeyDown={(e) => {
            if (e.keyCode === 13 && choice !== "") {
              if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

              dispatch(setMultipleChoice({ id, add: choice }));
              setChoice("");
            }
          }}
          onChange={(e) => {
            if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

            setChoice(e.target.value);
          }}
          value={choice}
          name="choice"
          fullWidth
          label="Add A Choice"
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="Add Choice"
                onClick={() => {
                  if (choice !== "") {
                    if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

                    dispatch(setMultipleChoice({ id, add: choice }));
                    setChoice("");
                  }
                }}
              >
                <AddCircleOutline />
              </IconButton>
            ),
          }}
        />
      </>
    )
  );
}
