import axios from "axios";
import { baseURL } from "../thunks";

export const createDocument =
  ({ folder_id, name, description, contents, doctype }) =>
  async (dispatch) => {
    const { data } = await axios.post(
      baseURL + "documentation/documents",
      {
        folder_id,
        name,
        doctype,
        description,
        contents,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return data;
  };
