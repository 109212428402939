import React, { useEffect } from "react";
import { useStyles } from "./styles";
import { Switch } from "react-router-dom";
import { Admin } from "../Admin/Admin";
import { Users } from "../Admin/Users/Users";
import { NewUserForm } from "../Admin/NewUserForm/NewUserForm";
import { NewOrganization } from "../Admin/NewOrganization";
import { EditUserForm } from "../Admin/EditUserForm/EditUserForm";
import { NewRoleForm } from "../Roles/Components/NewRoleForm";
import { RolesTable } from "../Roles/Components/RolesTable/RolesTable";
import { EditRoleForm } from "../Roles/Components/EditRoleForm";
import { PrivateRoute } from "../Login/Components/PrivateRoute";
import { AdminRoute } from "./Components/AdminRoute";
import { Home } from "./Components/Home";
import { Documentation } from "../Documentation/Documentation";
import { Settings } from "../Admin/Settings/Settings";
import { useSelector, useDispatch } from "react-redux";
import { loginSelector } from "../Login/Reducers/LoginReducer";
import { refreshUserData } from "../Login/Reducers/refreshUserData";
import { TrashBin } from "../Documentation/Components/TrashBin/TrashBin";
import { View } from "../Viewer/View";
import { Edit } from "../Viewer/Edit";
import { DocumentHistory } from "../Documentation/Components/DocumentHistory";
import { SearchResults } from "../Search/SearchResults";
import { ActivityLog } from "../Admin/ActivityLog/ActivityLog";
import { AccountSettings } from "./Components/AccountSettings";
import { Billing } from "../Billing/Billing";
import { NotFound404 } from "./Components/NotFound404";
import { SingleRowView } from "../Viewer/Components/SpreadsheetViewer/SingleRowView";
import { ChecklistResultsViewer } from "../Viewer/Components/ChecklistResultViewer/ChecklistResultsViewer";

export const Dashboard = () => {
  const { authenticated, email, avatar, full_name } = useSelector(loginSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    if (authenticated && !email && !avatar && !full_name) {
      dispatch(refreshUserData());
    }
  });
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Switch>
        <PrivateRoute exact path={"/"}>
          <Home />
        </PrivateRoute>
        <PrivateRoute exact path={["/billing", "/billing/success", "billing/cancel"]}>
          <Billing />
        </PrivateRoute>
        <PrivateRoute exact path={"/account-settings"}>
          <AccountSettings />
        </PrivateRoute>
        <AdminRoute exact path={`/admin`}>
          <Admin />
        </AdminRoute>
        <AdminRoute exact path={`/admin/activity-log`}>
          <ActivityLog />
        </AdminRoute>
        <AdminRoute exact path={`/admin/settings`}>
          <Settings />
        </AdminRoute>
        <AdminRoute exact path={"/admin/users"}>
          <Users />
        </AdminRoute>
        <AdminRoute exact path={"/admin/users/new"}>
          <NewUserForm />
        </AdminRoute>
        <AdminRoute exact path={"/admin/users/:id"}>
          <EditUserForm />
        </AdminRoute>
        <AdminRoute exact path={"/admin/roles/"}>
          <RolesTable />
        </AdminRoute>
        <AdminRoute exact path={"/admin/roles/new"}>
          <NewRoleForm />
        </AdminRoute>
        <AdminRoute exact path={"/admin/roles/:id"}>
          <EditRoleForm />
        </AdminRoute>
        <AdminRoute exact path={"/admin/create-org"}>
          <NewOrganization />
        </AdminRoute>
        <PrivateRoute exact path={"/documentation/search/"}>
          <SearchResults />
        </PrivateRoute>
        <PrivateRoute path={"/documentation/browse/"}>
          <Documentation />
        </PrivateRoute>
        <PrivateRoute path={"/documentation/trash/:id"}>
          <TrashBin />
        </PrivateRoute>
        <PrivateRoute exact path={"/documentation/view/:id"}>
          <View />
        </PrivateRoute>
        <PrivateRoute exact path={"/documentation/results/:id"}>
          <ChecklistResultsViewer />
        </PrivateRoute>
        <PrivateRoute exact path={"/documentation/view/:document_id/:id"}>
          <SingleRowView />
        </PrivateRoute>
        <PrivateRoute exact path={"/documentation/results/:document_id/:id"}>
          <SingleRowView />
        </PrivateRoute>
        <PrivateRoute exact path={"/documentation/view/:id/version/:version_number"}>
          <View draft />
        </PrivateRoute>
        <PrivateRoute path={"/documentation/edit/:id"}>
          <Edit />
        </PrivateRoute>
        <PrivateRoute path={"/documentation/:id/history"}>
          <DocumentHistory />
        </PrivateRoute>
        <PrivateRoute path="*">
          <NotFound404 loggedIn />
        </PrivateRoute>
      </Switch>
    </div>
  );
};
