import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Button, Modal, Fade, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export function RemoveModal({ id, value, handleClose, open, thunk, text }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography variant="h4" id="transition-modal-title">
              Confirm Delete
            </Typography>
            <Typography variant="body1" id="transition-modal-title">
              Are you sure you want to delete the {text}: {value}?
            </Typography>
            <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
              <Button
                onClick={handleClose}
                variant="outlined"
                style={{ textTransform: "none", margin: "0.5em", color: "red" }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => dispatch(thunk({ id }))}
                variant="outlined"
                style={{ textTransform: "none", margin: "0.5em", color: "green" }}
              >
                Delete {text}
              </Button>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
