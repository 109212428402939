import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import * as React from "react";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDidQuestionsChange, setQuestionIndex } from "../../../Documentation/Reducers/DocumentationReducer";
import { getChecklist } from "../../../Documentation/Reducers/Thunks/getChecklist";
import { setFormIds, setQuestions, setTree } from "../../../Editor/Reducers/EditorReducer";
import { LoadingSpinner } from "../LoadingSpinner";
import { findNode } from "./findNode";
import { flattenTree } from "./flattenTree";
import { unflattenTree } from "./unflattenTree";

export function QuestionSelectionDialogMultiple({
  setOpen,
  openSelection,
  setOpenSelection,
  sourceID,
  destinationID,
  setStashedIndexes,
  setStashedQuestions,
  stashedIndexes,
  stashedQuesitons,
}) {
  const [sourceQuestions, setSourceQuestions] = useState(null);
  const [destinationQuestions, setDestinationQuestions] = useState(null);
  const [sourceName, setSourceName] = useState(null);
  const [destinationName, setDestinationName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const currentFolderId = useSelector(
    (state) => state.documentation?.currentDocument?.path[state.documentation?.currentDocument?.path.length - 1].id
  );
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const tree = useSelector((state) => state.editor.tree);
  const form_ids = useSelector((state) => state.editor.form_ids);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpenSelection(false);
  };
  React.useEffect(() => {
    setLoading(true);
    async function fetchChecklists() {
      const sourceRes = dispatch(getChecklist({ id: sourceID, currentFolderId }));
      const destinationRes = dispatch(getChecklist({ id: destinationID, currentFolderId }));
      const sourceIdx = await sourceRes.then((sourceRes) => sourceRes?.contents?.question_index);
      const destinationIdx = await destinationRes.then((destinationRes) => destinationRes?.contents?.question_index);
      if (sourceIdx && sourceIdx.some((idx) => destinationIdx.indexOf(idx) !== -1)) {
        setError("Unable to link checklists with duplicate questions.");
      } else {
        await sourceRes
          .then((sourceRes) => {
            setSourceQuestions(sourceRes?.contents?.questions || []);
            setSourceName(sourceRes?.name);
            let sourceNode = findNode(tree, sourceID)[1];
            if (sourceNode?.question_id) {
              setSelectedSource(sourceNode.question_id);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            setError("Something went wrong when retrieving your checklist.");
          });

        await destinationRes
          .then((res) => {
            setDestinationQuestions(res?.contents?.questions || []);
            setDestinationName(res?.name);
            let destinationNode = findNode(tree, sourceID)[1];
            if (destinationNode?.parent_question_id) {
              setSelectedDestination(destinationNode.parent_question_id);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            setError("Something went wrong when retrieving your checklist.");
          });
      }
    }
    fetchChecklists();
    setLoading(false);
  }, [currentFolderId, destinationID, sourceID, dispatch, setLoading, setError, tree, form_ids]);
  return (
    <Fragment>
      <Dialog
        hideBackdrop
        open={openSelection}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="child-modal-title" style={{ fontSize: "1rem" }}>
          Select Source and Destination Questions
        </DialogTitle>
        <DialogContent dividers>
          {error ? (
            <Typography>{error}</Typography>
          ) : loading ? (
            <LoadingSpinner />
          ) : !sourceName ||
            !sourceQuestions ||
            (sourceQuestions && !Object.keys(sourceQuestions).length) ||
            !destinationName ||
            !destinationQuestions ||
            (destinationQuestions && !Object.keys(destinationQuestions).length) ? (
            <Typography>No Questions Found In Checklist</Typography>
          ) : (
            <div>
              <Typography>Source: {sourceName}</Typography>
              <List>
                {Object.keys(sourceQuestions).map((question, index) => {
                  return (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          selectedSource === question ? setSelectedSource(null) : setSelectedSource(question);
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={selectedSource === question}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": question }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${sourceQuestions[question].title}${
                            sourceQuestions[question].description ? `: ${sourceQuestions[question].description}` : ""
                          }`}
                          secondary={`${sourceQuestions[question].valueType}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
              <Typography>Destination: {destinationName}</Typography>
              <List>
                {Object.keys(destinationQuestions).map((question, index) => {
                  return (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          selectedDestination === question
                            ? setSelectedDestination(null)
                            : setSelectedDestination(question);
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={selectedDestination === question}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": question }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${destinationQuestions[question].title}${
                            destinationQuestions[question].description
                              ? `: ${destinationQuestions[question].description}`
                              : ""
                          }`}
                          secondary={destinationQuestions[question].valueType}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          )}
          <Button
            disabled={
              !selectedSource ||
              !selectedDestination ||
              sourceQuestions[selectedSource]?.valueType !== destinationQuestions[selectedDestination]?.valueType
            }
            onClick={() => {
              let isNodePresent = findNode(tree, sourceID);
              if (isNodePresent[0] || isNodePresent[1]) {
                console.log("node is present");
                let flattenedTree = tree && flattenTree(tree, 0);
                let addParentId = JSON.parse(JSON.stringify(flattenedTree)).reduce((a, c) => {
                  if (c.id === sourceID) {
                    c.parent = destinationID;
                    c.question_id = selectedSource;
                    c.parent_question_id = selectedDestination;
                  }
                  a.push(c);
                  return a;
                }, []);
                dispatch(setTree({ tree: unflattenTree(addParentId) }));
              } else {
                console.log("node is not present");
                let flattenedTree = tree && flattenTree(tree, 0);
                let withNewNode = [
                  ...flattenedTree,
                  {
                    droppable: true,
                    id: sourceID,
                    parent: destinationID,
                    question_id: selectedSource,
                    parent_question_id: selectedDestination,
                    name: sourceName,
                    text: sourceName,
                  },
                ];

                dispatch(setTree({ tree: unflattenTree(withNewNode) }));

                stashedIndexes.forEach((question) => {
                  dispatch(setQuestionIndex({ add: question }));
                });
                dispatch(
                  setQuestions({
                    add: stashedQuesitons,
                    isEditor: true,
                  })
                );
                setStashedIndexes(null);
                setStashedQuestions(null);
              }
              dispatch(setDidQuestionsChange({ isChanged: true }));
              if (!form_ids || !form_ids.includes(sourceID)) {
                dispatch(setFormIds({ form_id: sourceID }));
              }
              setOpenSelection(false);
              setOpen(false);
            }}
          >
            Submit
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
