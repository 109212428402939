import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Paper, Grid, TextField, Typography, Checkbox } from "@mui/material";
import { useStyles } from "../styles";
import { Email, Person, AccountCircle, Lock, CheckCircle, Cancel, VpnKey } from "@mui/icons-material/";
import { registerUser } from "../Reducers/registerUser";
import { registrationComplete, registrationSelector } from "../Reducers/RegistrationReducer";
import { TermsAndConditionsModal } from "./TermsAndConditions";
import { checkPasswordLength, comparePasswordLength, disableButton } from "./helpers";
import { loginAfterRegistration } from "../Reducers/loginAfterRegistration";
import history from "../../../Utils/history";
export const isValidEmail = /^\S+@\S+\.\S+$/;

export const RegistrationForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading } = useSelector(registrationSelector);
  const [username, setUsername] = useState(() => "");
  const [email, setEmail] = useState(() => "");
  const [fullName, setFullName] = useState(() => "");
  const [error, setError] = useState(null);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState(() => "");
  const [confirmPassword, setConfirmPassword] = useState(() => "");
  const handleRegistration = () => {
    setError(null);
    dispatch(registerUser({ username, email, fullName, password }))
      .then((res) => {
        dispatch(loginAfterRegistration({ access_token: res.data.access_token })).then((res2) => {
          if (sessionStorage.getItem("inviteID")) {
            history.push("/invitation/" + sessionStorage.getItem("inviteID"));
          } else {
            history.push("/");
          }
        });
      })
      .catch((error) => {
        dispatch(registrationComplete());
        setError(error?.response?.data?.detail || "Something went wrong...");
      });
  };
  const RegistrationButton = (
    <Button
      disabled={
        !fullName ||
        !username ||
        !acceptTerms ||
        !email ||
        !isValidEmail.test(email) ||
        disableButton(password, confirmPassword)
      }
      onClick={handleRegistration}
      variant="contained"
      color="primary"
      style={{ textTransform: "none" }}
    >
      Register
    </Button>
  );
  return (
    <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
      <Paper className={classes.padding}>
        <Typography variant="h4">Registration</Typography>
        <Typography variant="body1">Use the form below to register your account.</Typography>
        <form
          onKeyPress={(e) => {
            if (e.key === "Enter" && username && email && fullName && acceptTerms) handleRegistration();
          }}
        >
          <TextField
            id="fullName"
            label="Full Name"
            type="fullName"
            autoComplete="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            InputProps={{
              startAdornment: <Person />,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            id="username"
            label="Username"
            type="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            InputProps={{
              startAdornment: <AccountCircle />,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            id="email"
            label="Email"
            type="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: <Email />,
            }}
            fullWidth
            margin="normal"
          />

          <TextField
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            autoFocus
            required
            margin="normal"
            InputProps={{
              startAdornment: <Lock />,
              endAdornment: checkPasswordLength(password) ? <CheckCircle /> : <Cancel />,
            }}
          />
          <TextField
            id="confirmPassword"
            label="Re-Type Password"
            type="password"
            autoComplete="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            required
            margin="normal"
            InputProps={{
              startAdornment: <VpnKey />,
              endAdornment: comparePasswordLength(confirmPassword, password) ? <CheckCircle /> : <Cancel />,
            }}
          />
          <Typography variant="body1">Password must be at least 8 characters long.</Typography>

          <Checkbox
            checked={acceptTerms}
            onChange={() => setAcceptTerms(!acceptTerms)}
            inputProps={{ "aria-label": "controlled" }}
          />
          <>
            <span>I've read and accept the</span>{" "}
            <span
              style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
              onClick={() => setOpen(true)}
            >
              Terms and Conditions
            </span>
          </>

          <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
            {loading === null ? (
              RegistrationButton
            ) : error ? (
              <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <div>
                  <Typography style={{ color: "red" }}>{error}</Typography>
                </div>
                <div>{RegistrationButton}</div>
              </div>
            ) : loading === true ? (
              <Button>Submitting Registration...</Button>
            ) : loading === false ? (
              <Typography>Submission complete! Check your email: {email}</Typography>
            ) : (
              ""
            )}
          </Grid>
        </form>
      </Paper>
      <TermsAndConditionsModal open={open} setOpen={setOpen} />
    </Grid>
  );
};
