import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getChecklists } from "../../../Documentation/Reducers/Thunks/getChecklists";
import { setFormIds, setQuestions } from "../../../Editor/Reducers/EditorReducer";
import { constructTree } from "./constructTree";
import { setTree } from "../../../Editor/Reducers/EditorReducer";
import {
  loading,
  received,
  removeQuestion,
  removeQuestionIndexContents,
  setQuestionIndex,
  setSpreadsheet,
} from "../../../Documentation/Reducers/DocumentationReducer";

export function useSetQuestionTree(treeID) {
  const dispatch = useDispatch();
  const contentsQuestions = useSelector((state) => state.documentation.currentDocument?.contents?.questions);
  const contentsQuestionIndex = useSelector((state) => state.documentation.currentDocument?.contents?.question_index);
  const questions = useSelector((state) => state.editor?.questions);
  const question_index = useSelector((state) => state.editor?.question_index);
  const form_ids = useSelector((state) => state.documentation.currentDocument?.contents?.form_ids);
  const form_ids_editor = useSelector((state) => state.editor.form_ids);
  const form_links = useSelector((state) => state.documentation?.currentDocument?.contents?.form_links);
  const resultsArr = useSelector((state) => state.documentation.spreadsheet?.results);
  const isInitialRender = useRef(true);

  useEffect(() => {
    dispatch(loading());
    const fetching_form_ids = form_ids_editor ? form_ids_editor : form_ids ? form_ids : [];

    const fetchChecklists = async () => {
      console.log("called once!");
      return dispatch(getChecklists(fetching_form_ids));
    };
    if (!questions && !question_index && isInitialRender.current) {
      fetchChecklists()
        .then((fetchedChecklists) => {
          let combined = fetchedChecklists.reduce((acc, checklist) => {
            acc["questions"] = { ...(acc["questions"] ? acc["questions"] : {}), ...checklist?.contents?.questions };
            acc["question_index"] = [
              ...(acc["question_index"] ? acc["question_index"] : []),
              ...checklist?.contents?.question_index,
            ];
            return acc;
          }, {});
          let mismatchedQuestions =
            contentsQuestions &&
            Object.keys(contentsQuestions).filter((question) => !Object.keys(combined.questions).includes(question));
          let mismatchedIDXs =
            contentsQuestionIndex &&
            contentsQuestionIndex.filter((question) => !combined.question_index.includes(question));
          if (mismatchedQuestions?.length) {
            mismatchedQuestions.forEach((question) => dispatch(removeQuestion({ id: question })));
          }
          if (mismatchedIDXs?.length) {
            mismatchedIDXs.forEach((question) => dispatch(removeQuestionIndexContents({ remove: question })));
          }
          dispatch(setQuestions({ questions: combined.questions, isEditor: true }));
          dispatch(setQuestionIndex({ initialize: [...new Set(contentsQuestionIndex)] }));
          if ((form_links || (combined.questions && Object.keys(combined.questions)?.length)) && !treeID) {
            if (combined.questions) {
              dispatch(loading());
              async function combineWithNames() {
                const questionsWithNames = Object.keys(combined.questions).reduce((acc, question) => {
                  let copy = JSON.parse(JSON.stringify(combined.questions[question]));
                  fetchedChecklists.forEach((checklist) => {
                    if (checklist.id === combined.questions[question].form_id) {
                      copy = { ...copy, name: checklist.name, path: checklist.path };
                    }
                  });
                  acc[question] = copy;
                  return acc;
                }, {});
                return { questionsWithNames, fetchedChecklists };
              }
              combineWithNames().then((res) => {
                const { questionsWithNames, fetchedChecklists } = res;
                return dispatch(
                  setTree({
                    tree: constructTree(
                      form_links,
                      questionsWithNames,
                      form_ids_editor ? form_ids_editor : form_ids,
                      fetchedChecklists
                    ),
                  })
                );
              });
            }
            dispatch(received({ error: false }));
          } else if (!treeID) {
            dispatch(setTree({ tree: [] }));
            dispatch(received({ error: false }));
          }
        })
        .catch((e) => console.log(e));
    }

    if (form_ids && !form_ids_editor) {
      form_ids.map((id) => dispatch(setFormIds({ form_id: id })));
    }

    if (resultsArr) {
      dispatch(setSpreadsheet({ reset: true }));
    }
    isInitialRender.current = false;
    dispatch(received({ error: false }));
  }, [
    dispatch,
    treeID,
    form_ids,
    form_ids_editor,
    form_links,
    contentsQuestions,
    contentsQuestionIndex,
    resultsArr,
    questions,
    question_index,
  ]);
}
