import axios from "axios";
import { requestLoading, requestReceived, updateRoleRows } from "./RolesReducer";
import { baseURL } from "./thunks";

export const getRoles =
  ({ skip, limit, order, orderBy, search }) =>
  async (dispatch) => {
    dispatch(requestLoading());
    const params = new URLSearchParams();
    params.append("q", search);
    params.append("ordering", order + orderBy);
    params.append("skip", skip);
    params.append("limit", limit);

    try {
      const fetchRoles = await axios.get(`${baseURL}roles`, {
        params,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch(
        updateRoleRows({
          roles: fetchRoles.data.results,
          total: fetchRoles.data.total,
        })
      );
      dispatch(requestReceived({ detail: "requestReceived" }));

      return fetchRoles;
    } catch (error) {
      console.error(error);
    }
  };
