import React from "react";
import { makeStyles } from "@mui/styles";
import { LinearProgress, Typography, Box, Snackbar, CircularProgress, Alert } from "@mui/material";
import { useSelector } from "react-redux";
function LinearProgressWithLabel({ value, progress }) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="400px" mr={1}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles({
  root: {
    width: "500px",
  },
});

export default function UploadProgressBar() {
  const classes = useStyles();
  const uploadProgress = useSelector((state) => state.documentation.uploadProgress);
  return (
    <div className={classes.root}>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={Boolean(uploadProgress)}>
        <Alert severity={"success"} icon={<CircularProgress size={"1em"} />}>
          <LinearProgressWithLabel
            progress={Boolean(uploadProgress) ? uploadProgress : 100}
            value={(uploadProgress / 100) * 100}
          />
        </Alert>
      </Snackbar>
    </div>
  );
}
