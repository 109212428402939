import axios from "axios";
import history from "../../../Utils/history";
import { requestLoading, requestReceived, setCurrentRole } from "./RolesReducer";
import { baseURL } from "./thunks";

export const updateRoleInfo =
  ({ role, description, list_filterable }) =>
  async (dispatch) => {
    const roleID = document.location.pathname.split("/");
    dispatch(requestLoading());
    try {
      await axios.put(
        baseURL + `roles/${roleID[roleID.length - 1]}`,
        { name: role, description, list_filterable },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      dispatch(requestReceived({ detail: "requestReceived" }));
      dispatch(
        setCurrentRole({
          role: "",
          description: "",
          id: null,
        })
      );
      history.push("/admin/roles");
    } catch (error) {
      dispatch(requestReceived({ detail: error }));
      console.error(error);
    }
  };
