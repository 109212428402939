import React from "react";
import { Badge } from "@mui/material";
import {
  FolderOpenTwoTone,
  Notifications,
  InsertDriveFileTwoTone,
  AssignmentTurnedInTwoTone,
  ListAltTwoTone,
  Favorite,
  SummarizeTwoTone,
} from "@mui/icons-material/";
import { styles } from "../styles";

export function selectBadges(isSubbed, isFav, doctype) {
  const Icon = () =>
    doctype === "presentation" ? (
      <InsertDriveFileTwoTone style={styles.document} />
    ) : doctype === "form" ? (
      <AssignmentTurnedInTwoTone style={styles.form} />
    ) : doctype === "sheet" ? (
      <ListAltTwoTone style={styles.sheet} />
    ) : doctype === "report" ? (
      <SummarizeTwoTone style={styles.report} />
    ) : (
      <FolderOpenTwoTone style={styles.folder} />
    );
  return isSubbed && isFav ? (
    <Badge
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      badgeContent={<Notifications />}
      overlap="circular"
      style={{
        width: "100%",
        color: "#4c566a",
      }}
    >
      <Badge
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={<Favorite />}
        overlap="circular"
        style={{
          width: "100%",
          color: "tomato",
        }}
      >
        <Icon />
      </Badge>
    </Badge>
  ) : isFav ? (
    <Badge
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      badgeContent={<Favorite />}
      overlap="circular"
      style={{
        width: "100%",
        color: "tomato",
      }}
    >
      <Icon />
    </Badge>
  ) : isSubbed ? (
    <Badge
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      badgeContent={<Notifications />}
      overlap="circular"
      style={{
        width: "100%",
        color: "#4c566a",
      }}
    >
      <Icon />
    </Badge>
  ) : (
    <Icon />
  );
}
