export const widgetList = {
  "true/false": "radio buttons",
  "pass/fail": "radio buttons",
  "single choice": ["radio buttons", "dropdown"],
  "multiple choice": ["radio buttons", "checkboxes"],
  number: ["number", "qr code"],
  decimal: ["number", "qr code"],
  datetime: "datetime",
  text: ["text", "qr code"],
  url: ["text", "qr code"],
  email: ["text", "qr code"],
  duration: ["text", "qr code"],
};
