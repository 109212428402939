import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { setAlert } from "../Reducers/DocumentationReducer";
import { useDispatch } from "react-redux";
export const SnackbarAlert = ({ alert, setAlert, severity }) => {
  return alert ? (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={!!alert}
      onClose={() => {
        setAlert(null);
      }}
      ClickAwayListenerProps={{
        onClickAway: (e) => {
          e.preventDefault();
          e.stopPropagation();
          setAlert(null);
        },
      }}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
      autoHideDuration={5000}
    >
      <Alert onClose={() => setAlert(null)} severity={severity}>
        {alert}
      </Alert>
    </Snackbar>
  ) : (
    ""
  );
};

export const SnackbarAlertRedux = ({ alert, severity }) => {
  const dispatch = useDispatch();
  return alert ? (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={!!alert}
      onClose={() => {
        dispatch(setAlert({ alert: null, severity: null }));
      }}
      ClickAwayListenerProps={{ onClickAway: () => dispatch(setAlert({ alert: null, severity: null })) }}
      autoHideDuration={5000}
    >
      <Alert onClose={() => dispatch(setAlert({ alert: null, severity: null }))} severity={severity}>
        {alert}
      </Alert>
    </Snackbar>
  ) : (
    ""
  );
};
