import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSpreadsheetData } from "../Thunks/getSpreadsheetData";
import { setSingleRow, setSpreadsheet } from "../../Documentation/Reducers/DocumentationReducer";
import { setTree, setFormIds } from "../../Editor/Reducers/EditorReducer";

export function useInitResults({ setLoading, skip, limit }) {
  const id = useSelector((state) => state.documentation.currentDocument.id);
  const resultsArr = useSelector((state) => state.documentation.spreadsheet?.results);
  const resultsTotal = useSelector((state) => state.documentation.spreadsheet?.total);
  const tree = useSelector((state) => state.editor.tree);
  const form_ids = useSelector((state) => state.editor.form_ids);
  const dispatch = useDispatch();
  useEffect(() => {
    var today = new Date();
    var last30days = new Date(new Date().setDate(today.getDate() - 30));

    dispatch(setSingleRow({ reset: true }));
    if (id && !resultsArr) {
      setLoading(true);
      dispatch(
        getSpreadsheetData({
          id,
          start_time: Math.floor(last30days.getTime() / 1000),
          end_time: Math.floor(today.getTime() / 1000),
        })
      )
        .then((res) => {
          const { results, total } = res.data;
          dispatch(setSpreadsheet({ results, total }));

          setLoading(false);
        })
        .catch((e) => console.error(e));
    }
    if (resultsArr?.length < resultsTotal) {
      dispatch(
        getSpreadsheetData({
          id,
          skip: resultsArr.length,
          limit: 100,
        })
      )
        .then((res) => {
          const { results, total } = res.data;
          dispatch(setSpreadsheet({ additionalResults: true, results, total }));
        })
        .catch((e) => console.error(e));
    }
    if (tree) {
      dispatch(setTree({ reset: true }));
    }
    if (form_ids) {
      dispatch(setFormIds({ reset: true }));
    }
  }, [dispatch, id, resultsArr, resultsTotal, setLoading, skip, limit, tree, form_ids]);
}
