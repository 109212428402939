import axios from "axios";
import { received, setAlert } from "../../Documentation/Reducers/DocumentationReducer";
import { baseURL } from "../../Documentation/Reducers/thunks";
import { compress } from "../Components/Helpers/compress";
import { postFormData } from "./Helpers/postFormData";
export const convertBlobURLToFile = async (blob) => {
  const r = await fetch(blob.media);
  const blobFile = await r.blob();
  return new File([blobFile], blob.filename, {
    type: blob.content_type,
    lastModified: blob.lastModified,
  });
};
export const addPhotoVerification =
  ({ currentDocument, uploadedFiles }) =>
  async (dispatch) => {
    try {
      return Promise.all(
        uploadedFiles.map(async (uploadedFile) => {
          const converted = await convertBlobURLToFile(uploadedFile);
          const compressedFile = converted.type.includes("image") ? await compress(converted, 0.6) : converted;
          return await axios
            .post(
              `${baseURL}documentation/documents/${currentDocument}/sign-content-upload`,
              [
                {
                  filename: compressedFile.name,
                  file_size: compressedFile.size,
                  content_type: compressedFile.type,
                },
              ],
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then(async (res) => {
              const { upload_id, url, fields } = res.data[0];
              const formData = new FormData();
              formData.append("Content-Type", fields["Content-Type"]);
              formData.append("acl", fields.acl);
              formData.append("key", fields.key);
              formData.append("policy", fields.policy);
              formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
              formData.append("x-amz-credential", fields["x-amz-credential"]);
              formData.append("x-amz-date", fields["x-amz-date"]);
              formData.append("x-amz-signature", fields["x-amz-signature"]);
              formData.append("file", compressedFile);
              return await axios(postFormData(url, formData))
                .then(() => {
                  dispatch(received({ error: false }));
                  return { upload_id };
                })
                .catch((e) => {
                  console.error(e);
                  dispatch(setAlert({ alert: "Something went wrong with your submission", severity: "error" }));
                });
            })
            .catch((e) => {
              dispatch(received({ error: true }));
            });
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(setAlert({ alert: "Something went wrong with your submission", severity: "error" }));
    }
  };
