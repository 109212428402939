import axios from "axios";
import { received } from "../../Documentation/Reducers/DocumentationReducer";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const getActivityLog = () => async (dispatch) => {
  try {
    const putReq = await axios({
      method: "get",
      url: baseURL + "activity",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return putReq;
  } catch (error) {
    console.log(error);
    dispatch(received({ error: true }));
  }
};
