import axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const postSetOtp =
  ({ verify_otp, enabled, uri }) =>
  async () => {
    if (!verify_otp && !uri) {
      return await axios({
        method: "post",
        url: baseURL + "auth/set-otp",
        data: {
          enabled,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } else {
      return await axios({
        method: "post",
        url: baseURL + "auth/set-otp",
        data: {
          verify_otp,
          enabled,
          uri,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  };
