import { AssignmentTurnedInTwoTone, FolderOpenTwoTone } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TreeView } from "@mui/lab";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";
import { StyledTreeItem } from "../../../Documentation/Components/Dialogs/StyledTreeItem";
import { findNode } from "./findNode";

export function ChecklistTreeBrowser({ results, setSelectedChecklist, selectedChecklist }) {
  const renderTree = (nodes, name = "root") => {
    const keys = Object.keys(nodes).reverse().sort();
    const labelIdFolder = `checkbox-list-label-${name}`;
    if (name !== "folder_id" && name !== "folder_path") {
      return (
        <StyledTreeItem
          expandIcon={keys.length <= 2 ? <></> : <ChevronRightIcon />}
          collapseIcon={keys.length <= 2 ? <></> : <ExpandMoreIcon />}
          key={name}
          nodeId={name}
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              <FolderOpenTwoTone
                style={{
                  fontSize: "2em",
                  verticalAlign: "middle",
                  fill: "#f0dc82",
                }}
              />
              <ListItemText id={labelIdFolder} primary={`${name}`} />
            </div>
          }
        >
          {nodes && keys && keys.length
            ? keys.map((key) =>
                key === "arr"
                  ? nodes[key]
                      .filter((x) => x.doctype === "form")
                      .sort((a, b) => {
                        return a - b;
                      })
                      .map((form) => {
                        return DocumentNode(form, setSelectedChecklist, selectedChecklist);
                      })
                  : renderTree(nodes[key], key)
              )
            : null}
        </StyledTreeItem>
      );
    }
  };

  return (
    <TreeView
      aria-label="rich object"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={["root"]}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {renderTree(results?.root)}
    </TreeView>
  );
}
function DocumentNode(form, setSelectedChecklist, selectedChecklist) {
  const tree = useSelector((state) => state.editor.tree);
  const labelId = `checkbox-list-label-${form.name}`;
  let isNodePresent = tree ? findNode(tree, form.id) : null;

  return (
    <ListItemButton
      key={form.id}
      role={undefined}
      onClick={() => {
        setSelectedChecklist(form.id);
      }}
      selected={selectedChecklist === form.id}
      style={{ padding: 0, height: "2em" }}
      disabled={isNodePresent && (isNodePresent[0] || isNodePresent[1]) ? true : false}
    >
      <ListItemIcon style={{ minWidth: 0 }}>
        <AssignmentTurnedInTwoTone
          style={{
            fontSize: "2em",
            verticalAlign: "middle",
            fill: "darkcyan",
          }}
        />
      </ListItemIcon>
      <ListItemText
        id={labelId}
        primary={`${form.name}`}
        secondary={isNodePresent && (isNodePresent[0] || isNodePresent[1]) && <span>already selected</span>}
      />
    </ListItemButton>
  );
}
