import React from "react";
import { styles } from "../styles";
import { CircularProgress } from "@mui/material";

export function LoadingSpinner() {
  return (
    <div style={styles.progress}>
      <div>
        <CircularProgress />
      </div>
    </div>
  );
}
