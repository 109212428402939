import React, { useState } from "react";
import { Box } from "@mui/material";
import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
import { LoadingSpinner } from "../../../Viewer/Components/LoadingSpinner";
import { reorderMultiple, reorderSelection } from "./helpers";
import { PermissionsFooter } from "./PermissionsFooter";
import { PermissionsToolbar } from "./PermissionsToolbar";
import { SubjectColumn, RevokeGrantColumn, PermissionsColumn } from "./PermissionsColumns";
LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_KEY}`);

export function PermissionsTab({
  kind,
  ACLList,
  ACLSubjects,
  setACLList,
  permissionList,
  setPermissionList,
  selectedRow,
  setSelectedRow,
  ACLInherited,
}) {
  const [showInherited, setShowInherited] = useState(false);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const columns = [
    SubjectColumn(MenuProps, ACLList, ACLSubjects, setACLList),
    RevokeGrantColumn(setACLList),
    PermissionsColumn(MenuProps, permissionList, setPermissionList, kind),
  ];

  const rows = [
    ...(showInherited && ACLInherited && ACLSubjects
      ? ACLInherited.map((ACL, i) => {
          const sub = ACLSubjects.filter((subject) => subject.id === ACL.subject);
          return {
            id: i + "I",
            Subject: sub.length ? sub[0].name : ACLSubjects[0],
            "Revoke/Grant": ACL.is_grant,
            Permissions: ACL.permissions,
            Inherited: true,
          };
        })
      : []),
    ...(ACLList && ACLSubjects
      ? ACLList.map((ACL, i) => {
          const sub = ACLSubjects.filter((subject) => subject.id === ACL.subject);
          return {
            id: i,
            Subject: sub.length ? sub[0].name : ACLSubjects[0],
            "Revoke/Grant": ACL.is_grant,
            Permissions: ACL.permissions,
            Inherited: false,
          };
        })
      : []),
  ];
  const handleAddPerm = () => {
    setACLList((state) => [...state, { subject: 0, is_grant: true, permissions: 0 }]);
    setPermissionList((state) => [...state, []]);
  };
  const handleRemovePerm = (e) => {
    e.stopPropagation();
    setACLList((state) => state.filter((x, i) => !selectedRow.includes(i)));
    setPermissionList((state) => state.filter((x, i) => !selectedRow.includes(i)));
  };
  const handlePermOrder = (direction) => {
    setACLList((state) => reorderMultiple({ list: state, selectedIdxs: selectedRow, direction }));
    setPermissionList((state) => reorderMultiple({ list: state, selectedIdxs: selectedRow, direction }));
    setSelectedRow(reorderSelection(direction));
  };
  return (
    <Box p={3} style={{ display: "flex", flexDirection: "column", height: "50vh", padding: "0 1em" }}>
      <div style={{ flexGrow: 1 }}>
        {permissionList ? (
          <DataGridPro
            columns={columns}
            rows={rows}
            onSelectionModelChange={(ids) => {
              setSelectedRow(ids.sort((a, b) => a - b));
            }}
            selectionModel={selectedRow}
            isRowSelectable={(params) => !params.row.Inherited}
            checkboxSelection
            components={{
              ...(ACLInherited && ACLInherited.length
                ? { Toolbar: PermissionsToolbar(showInherited, setShowInherited) }
                : {}),
              Footer: PermissionsFooter(handleAddPerm, handleRemovePerm, handlePermOrder),
            }}
          />
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </Box>
  );
}
