import {
  InsertDriveFileTwoTone,
  AssignmentTurnedInTwoTone,
  ListAltTwoTone,
  SummarizeTwoTone,
} from "@mui/icons-material/";
import { styles } from "../styles";

export const isDoctypeIcon = (item) =>
  item.doctype === "presentation" ? (
    <InsertDriveFileTwoTone style={styles.document} />
  ) : item.doctype === "form" ? (
    <AssignmentTurnedInTwoTone style={styles.form} />
  ) : item.doctype === "report" ? (
    <SummarizeTwoTone style={styles.report} />
  ) : (
    <ListAltTwoTone style={styles.sheet} />
  );
