import axios from "axios";
import { baseURL } from "../thunks";

export const removeFolder =
  ({ id, kind }) =>
  async (dispatch) => {
    return kind === "folder"
      ? await axios.delete(baseURL + "documentation/folders/" + id, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
      : await axios.delete(baseURL + "documentation/documents/" + id, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
  };
