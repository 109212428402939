import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { reducers } from "./reducers";
const documentationSlice = createSlice({
  name: "documentation",
  initialState,
  reducers,
});
export const documentationSelector = (state) => state.documentation;

export const {
  loading,
  received,
  setCurrentFolder,
  setCurrentFolderContents,
  updateCurrentFolderContents,
  setRootID,
  setCurrentDocument,
  setSelectedThumbnail,
  setTrash,
  setCopy,
  setPhotoVerification,
  setFileObjQueue,
  setFileObjQueueMedia,
  resetDocumentChanges,
  resetFileObjQueue,
  resetFileObj,
  resetCurrentDocument,
  setCurrentPage,
  setFileObjDescription,
  setFileObjShapes,
  setSearch,
  setSearching,
  setPrevPage,
  setOrder,
  setPageIndex,
  setQuestionIndex,
  setRootResults,
  setDocumentHistory,
  setPrevLocation,
  setAlert,
  setSpreadsheet,
  setSingleRow,
  setSubscriptions,
  setNotifications,
  removeFromQueue,
  removeFromFileObj,
  setDocumentChanges,
  setDocumentChangesMedia,
  setDidQuestionsChange,
  setFavorites,
  removeQuestionIndexContents,
  resetDocumentation,
  setUploadProgress,
  setIsBlocking,
  removeQuestion,
  setQuestionMedia,
  setQuestion,
  setMultipleChoice,
  addQuestion,
  setIsBlockingViewer,
} = documentationSlice.actions;

export const documentation = documentationSlice.reducer;
