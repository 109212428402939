import { Accordion, AccordionDetails, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDidQuestionsChange } from "../../../Documentation/Reducers/DocumentationReducer";
import { setQuestion } from "../../../Editor/Reducers/EditorReducer";
import { DescriptionContainer } from "./DescriptionContainer";
import { MediaContainer } from "./MediaContainer";
import { MultipleChoiceEditor } from "./MultipleChoiceEditor";
import { SummaryContainer } from "./SummaryContainer";
import { ValidationEditor } from "./ValidationEditor";
import { ValueDropdown } from "./ValueDropdown";
import { WidgetDropdown } from "./WidgetDropdown";
import { widgetList } from "./widgetList";

export const EditChecklistCard = ({ id, index, isLast }) => {
  const dispatch = useDispatch();
  const title = useSelector((state) => state.editor?.questions[id].title);
  const valueType = useSelector((state) => state.editor?.questions[id].valueType);
  const validation = useSelector((state) => state.editor?.questions[id].validation);
  const questionWidget = useSelector((state) => state.editor?.questions[id].widget);
  const didQuestionsChange = useSelector((state) => state.documentation.didQuestionsChange);

  const [choice, setChoice] = useState("");
  const [widget, setWidget] = useState(
    questionWidget
      ? questionWidget
      : Array.isArray(widgetList[valueType])
      ? widgetList[valueType][0]
      : widgetList[valueType]
  );

  const [expanded] = useState(!title && isLast ? true : false);
  const [showValidation, setShowValidation] = useState(validation && Object.keys(validation).length ? true : false);

  return (
    <Accordion key={index} defaultExpanded={expanded} variant={"outlined"}>
      <SummaryContainer index={index} id={id} />
      <AccordionDetails>
        <Grid container>
          <MediaContainer id={id} />
          <Grid container item direction="column" xs={9}>
            <DescriptionContainer id={id} />
            <ValueDropdown id={id} showValidation={showValidation} setWidget={setWidget} />
            <WidgetDropdown widget={widget} setWidget={setWidget} id={id} />
            <MultipleChoiceEditor id={id} choice={choice} setChoice={setChoice} />
            {["true/false", "single choice", "multiple choice", "number", "decimal", "datetime"].includes(valueType) ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showValidation}
                    onChange={() => {
                      if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));

                      if (showValidation) {
                        dispatch(setQuestion({ id, validation: {} }));
                      } else {
                        dispatch(
                          setQuestion({
                            id,
                            validation: [
                              "true/false",
                              "pass/fail",
                              "single choice",
                              "multiple choice",
                              "number",
                              "decimal",
                              "datetime",
                            ].includes(valueType)
                              ? {
                                  type: valueType,
                                  ...(valueType === "multiple choice"
                                    ? { values: [] }
                                    : valueType === "single choice"
                                    ? { value: "" }
                                    : valueType === "number" || valueType === "decimal"
                                    ? { min: 0, max: 1 }
                                    : valueType === "datetime"
                                    ? {
                                        min: new Date().toISOString().split(".")[0],
                                        max: new Date().toISOString().split(".")[0],
                                      }
                                    : valueType === "true/false"
                                    ? { value: String(false) }
                                    : valueType === "pass/fail"
                                    ? { value: String(true) }
                                    : ""),
                                }
                              : {},
                          })
                        );
                      }
                      setShowValidation(!showValidation);
                    }}
                    name="Include Validation"
                    color="primary"
                  />
                }
                label="Require Validation"
              />
            ) : (
              ""
            )}
            {showValidation ? <ValidationEditor id={id} /> : ""}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
