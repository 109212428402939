export const reducers = {
  requestLoading: (state) => {
    if (state.loading === false) {
      state.loading = true;
    }
  },
  requestReceived: (state, action) => {
    const { detail } = action.payload;
    if (state.loading === true) {
      state.loading = false;
    } else {
      state.error = detail;
    }
  },
  updateRows: (state, action) => {
    const { rows, total } = action.payload;
    state.rows = rows;
    state.total = total;
  },
  resetUserRows: (state) => {
    state.rows = [];
    state.total = 0;
  },
  setCurrentUser: (state, action) => {
    const { username, email, is_active, is_org_admin, roles, full_name, avatar, id } = action.payload;
    state.currentUser.username = username;
    state.currentUser.email = email;
    state.currentUser.is_active = is_active;
    state.currentUser.is_org_admin = is_org_admin;
    state.currentUser.roles = roles;
    state.currentUser.full_name = full_name;
    state.currentUser.avatar = avatar;
    state.currentUser.id = id;
  },
  setValue: (state, action) => {
    const { field, value } = action.payload;
    if (field === "full_name") {
      state.currentUser.full_name = value || "";
    }
    if (field === "username") {
      state.currentUser.username = value || "";
    }
    if (field === "email") {
      state.currentUser.email = value || "";
    }
    if (field === "is_org_admin") {
      state.currentUser.is_org_admin = value;
    }
  },
  setFileInput: (state, action) => {
    const { upload_id, url, name, type } = action.payload;
    state.fileInput.upload_id = upload_id;
    state.fileInput.url = url;
    state.fileInput.name = name;
    state.fileInput.type = type;
  },
};
