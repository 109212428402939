import axios from "axios";
import { deconstructTree } from "../../../Viewer/Components/SpreadsheetEditor/deconstructTree";
import { baseURL } from "../thunks";
import { handleError } from "./Helpers/handleError";

export const publishSheet =
  ({ currentDocument, question_index, questions, changelog, tree, form_ids, onCancel, setShowPasswordModal }) =>
  async (dispatch) => {
    const toEdges = deconstructTree(tree);
    try {
      const putReq = await axios({
        method: "put",
        url: baseURL + "documentation/documents/" + currentDocument?.id,
        data: {
          ...currentDocument,
          contents: {
            questions,
            question_index,
            form_links: toEdges,
            form_ids,
          },
          changelog,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return putReq;
    } catch (e) {
      handleError(e, onCancel, dispatch, setShowPasswordModal);
    }
  };
