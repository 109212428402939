import { AppBar, Button, IconButton, TextField, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dashboard } from "../Dashboard";
import { Email, Person, AccountCircle, Home } from "@mui/icons-material/";
import { postSetOtp } from "../Thunks/postSetOtp";
import { ChangeAvatarDialog } from "../../Admin/AvatarEditor/AvatarEditor";
import { refreshUserData } from "../../Login/Reducers/refreshUserData";
import { updateCurrentUser } from "../Thunks/updateCurrentUser";
import history from "../../../Utils/history";
import { TwoFactorModal } from "./TwoFactorModal";
import { UserAvatar } from "./UserAvatar";
import { PopoverAvatar } from "./PopoverAvatar";
import { DeviceListModal } from "./DeviceListModal";

export const AccountSettings = () => {
  const dispatch = useDispatch();
  const full_name = useSelector((state) => state.login.full_name);
  const username = useSelector((state) => state.login.username);
  const email = useSelector((state) => state.login.email);
  const avatar = useSelector((state) => state.login.avatar);
  const otp_enabled = useSelector((state) => state.login.otp_enabled);
  const org = useSelector((state) => state.login.org);
  const [user_name, setUsername] = useState(username);
  const [emailAddress, setEmailAddress] = useState(email);
  const [fullName, setFullName] = useState(full_name);
  const [result, setResult] = useState(null);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [avatarOpen, setAvatarOpen] = useState(false);
  const [clearAvatar, setClearAvatar] = useState(false);
  const [preview, setPreview] = useState(null);
  const [fileName, setFileName] = useState();
  useEffect(() => {
    dispatch(refreshUserData());
  }, [dispatch, full_name, username, email, avatar]);
  const SettingsBody = (
    <div style={{ padding: "24px" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <UserAvatar
          full_name={full_name}
          small={false}
          avatar={preview ? preview : avatar}
          setAvatarOpen={setAvatarOpen}
          clearAvatar={clearAvatar}
          setClearAvatar={setClearAvatar}
        />
      </div>
      <TextField
        style={{ marginTop: "0.5em" }}
        label="Full Name"
        id="fullName"
        value={fullName || full_name}
        onChange={(e) => setFullName(e.target.value)}
        fullWidth
        autoFocus
        required
        InputProps={{
          startAdornment: <Person />,
        }}
      />
      <TextField
        style={{ marginTop: "0.5em" }}
        id="user_name"
        label="Username"
        value={user_name || username}
        onChange={(e) => setUsername(e.target.value)}
        fullWidth
        autoFocus
        required
        InputProps={{
          startAdornment: <AccountCircle />,
        }}
      />
      <TextField
        style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
        id="emailAddress"
        label="Email"
        value={emailAddress || email}
        onChange={(e) => setEmailAddress(e.target.value)}
        fullWidth
        autoFocus
        required
        InputProps={{
          startAdornment: <Email />,
        }}
      />

      <ChangeAvatarDialog
        preview={preview}
        setPreview={setPreview}
        fileName={fileName}
        setFileName={setFileName}
        avatarOpen={avatarOpen}
        setAvatarOpen={setAvatarOpen}
      />

      <div>
        <div style={{ marginBottom: "1em" }}>
          <Button onClick={() => setOpen2(true)} variant="contained" color="primary">
            Show Active Login Sessions
          </Button>
        </div>
        <Button
          onClick={() => {
            if (!otp_enabled) {
              dispatch(postSetOtp({ enabled: true }))
                .then((res) => {
                  setResult(res.data);
                  setOpen(true);
                })
                .catch((e) => console.error(e));
            } else {
              setOpen(true);
            }
          }}
          variant="contained"
          color="primary"
        >
          {otp_enabled ? "Disable Two-Factor Authentication" : "Enable Two-Factor Authentication"}
        </Button>
        <Typography variant="body1" gutterBottom>
          Two Factor Authentication provides an extra layer of security via a 6-digit code each time you log in.
        </Typography>

        <TwoFactorModal open={open} setOpen={setOpen} result={result} />
        <DeviceListModal open={open2} setOpen={setOpen2} />
        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            dispatch(
              updateCurrentUser({
                username: user_name,
                email: emailAddress,
                full_name: fullName,
                avatar,
                preview,
                fileName,
              })
            ).then((res) => {
              dispatch(refreshUserData());
              history.push("/");
            })
          }
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
  return (!org || !org.is_active) && full_name ? (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={() => history.push("/")} edge="start">
            <Home style={{ color: "white" }} />
          </IconButton>
          <Typography edge="start" variant="h6" style={{ flexGrow: 1 }}>
            Account Settings
          </Typography>
          <PopoverAvatar />
        </Toolbar>
      </AppBar>
      {SettingsBody}
    </>
  ) : (
    <Dashboard pageName="Account Settings">{SettingsBody}</Dashboard>
  );
};
