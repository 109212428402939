import { rotateAroundPoint, getSlope } from "./helpers";
function setNewHandle({ perpSlope, x, y, center }) {
  let slope = -1 / perpSlope;
  if (Math.abs(perpSlope) === Infinity) {
    return { newHandle: { x: center.x, y: y } };
  } else if (Math.abs(slope) === Infinity) {
    return { newHandle: { x: x, y: center.y } };
  } else {
    let newB = y - slope * x;
    let perpB = center.y - perpSlope * center.x;
    let desiredX = (perpB - newB) / (slope - perpSlope);
    return { newHandle: { x: desiredX, y: slope * desiredX + newB } };
  }
}

export function rightFn({ center, left, shape, right, y, x, state }) {
  let anchor = rotateAroundPoint({ origin: center, point: left, angleDeg: shape.rotation });
  let perpSlope = getSlope(center, rotateAroundPoint({ origin: center, point: right, angleDeg: shape.rotation }));
  const { newHandle } = setNewHandle({ perpSlope, x, y, center });
  let newMid = {
    x: (newHandle.x + anchor.x) / 2,
    y: (newHandle.y + anchor.y) / 2,
  };
  let newRight = rotateAroundPoint({ origin: newMid, point: newHandle, angleDeg: -shape.rotation });
  let w = (newMid.x - newRight.x) * 2;
  if (w < -0.001) {
    shape.width = Math.abs(w);
    shape.x = newRight.x - shape.width;
    shape.y = newRight.y - shape.height / 2;
    state.selected.width = shape.width;
    state.selected.x = shape.x;
    state.selected.y = shape.y;
  }
}

export function leftFn({ center, right, shape, left, y, x, state }) {
  let anchor = rotateAroundPoint({ origin: center, point: right, angleDeg: shape.rotation });
  let perpSlope = getSlope(center, rotateAroundPoint({ origin: center, point: left, angleDeg: shape.rotation }));
  const { newHandle } = setNewHandle({ perpSlope, x, y, center });
  let newMid = {
    x: (newHandle.x + anchor.x) / 2,
    y: (newHandle.y + anchor.y) / 2,
  };
  let newLeft = rotateAroundPoint({ origin: newMid, point: newHandle, angleDeg: -shape.rotation });
  let w = (newMid.x - newLeft.x) * 2;

  if (w > -0.001) {
    shape.x = newLeft.x;
    shape.y = newLeft.y - shape.height / 2;
    shape.width = Math.abs(w);
    state.selected.x = shape.x;
    state.selected.y = shape.y;
    state.selected.width = shape.width;
  }
}

export function bottomFn({ center, top, shape, bottom, y, x, state }) {
  let anchor = rotateAroundPoint({ origin: center, point: top, angleDeg: shape.rotation });
  let perpSlope = getSlope(center, rotateAroundPoint({ origin: center, point: bottom, angleDeg: shape.rotation }));
  const { newHandle } = setNewHandle({ perpSlope, x, y, center });
  let newMid = {
    x: (newHandle.x + anchor.x) / 2,
    y: (newHandle.y + anchor.y) / 2,
  };
  let newBottom = rotateAroundPoint({ origin: newMid, point: newHandle, angleDeg: -shape.rotation });
  let h = (newMid.y - newBottom.y) * 2;
  if (h < 0.001) {
    shape.x = newBottom.x - shape.width / 2;
    shape.height = Math.abs(h);
    shape.y = newBottom.y - shape.height;
    state.selected.x = shape.x;
    state.selected.height = shape.height;
    state.selected.y = shape.y;
  }
}

export function topFn({ center, bottom, shape, top, y, x, state }) {
  let anchor = rotateAroundPoint({ origin: center, point: bottom, angleDeg: shape.rotation });
  let perpSlope = getSlope(center, rotateAroundPoint({ origin: center, point: top, angleDeg: shape.rotation }));
  const { newHandle } = setNewHandle({ perpSlope, x, y, center });
  let newMid = {
    x: (newHandle.x + anchor.x) / 2,
    y: (newHandle.y + anchor.y) / 2,
  };
  let newTop = rotateAroundPoint({ origin: newMid, point: newHandle, angleDeg: -shape.rotation });
  let h = (newMid.y - newTop.y) * 2;
  if (h > 0.001) {
    shape.x = newTop.x - shape.width / 2;
    shape.y = newTop.y;
    shape.height = h;
    state.selected.x = shape.x;
    state.selected.y = shape.y;
    state.selected.height = shape.height;
  }
}

function setCornerDimensions({ shape, state, x, y, width, height }) {
  shape.x = x;
  shape.y = y;
  shape.height = height;
  shape.width = width;
  state.selected.x = x;
  state.selected.y = y;
  state.selected.width = width;
  state.selected.height = height;
}

export function bottomRightFn({ center, x, y, shape, topLeft, state }) {
  let adjPt = rotateAroundPoint({ origin: center, point: { x, y }, angleDeg: -shape.rotation });
  adjPt.x = Math.max(adjPt.x, topLeft.x + 0.001);
  adjPt.y = Math.max(adjPt.y, topLeft.y + 0.001);
  const pt = rotateAroundPoint({ origin: center, point: adjPt, angleDeg: shape.rotation });
  const anchor = rotateAroundPoint({
    origin: center,
    point: topLeft,
    angleDeg: shape.rotation,
  });
  const newMid = { x: (anchor.x + pt.x) / 2, y: (anchor.y + pt.y) / 2 };
  let xPt = rotateAroundPoint({ origin: newMid, point: pt, angleDeg: -shape.rotation });
  let d2 = { x: 2 * (newMid.x - xPt.x), y: 2 * (newMid.y - xPt.y) };
  setCornerDimensions({
    shape,
    state,
    x: xPt.x + d2.x,
    y: xPt.y + d2.y,
    width: Math.abs(d2.x),
    height: Math.abs(d2.y),
  });
}

export function bottomLeftFn({ center, x, y, shape, right, top, topRight, state }) {
  let adjPt = rotateAroundPoint({ origin: center, point: { x, y }, angleDeg: -shape.rotation });
  adjPt.x = Math.min(adjPt.x, right.x - 0.001);
  adjPt.y = Math.max(adjPt.y, top.y + 0.001);
  const pt = rotateAroundPoint({ origin: center, point: adjPt, angleDeg: shape.rotation });
  const anchor = rotateAroundPoint({
    origin: center,
    point: topRight,
    angleDeg: shape.rotation,
  });
  const newMid = { x: (anchor.x + pt.x) / 2, y: (anchor.y + pt.y) / 2 };
  let xPt = rotateAroundPoint({ origin: newMid, point: pt, angleDeg: -shape.rotation });
  let d2 = { x: 2 * (newMid.x - xPt.x), y: 2 * (newMid.y - xPt.y) };
  setCornerDimensions({ shape, state, x: xPt.x, y: xPt.y + d2.y, width: Math.abs(d2.x), height: Math.abs(d2.y) });
}

export function topRightFn({ center, x, y, shape, left, bottom, bottomLeft, state }) {
  let adjPt = rotateAroundPoint({ origin: center, point: { x, y }, angleDeg: -shape.rotation });
  adjPt.x = Math.max(adjPt.x, left.x + 0.001);
  adjPt.y = Math.min(adjPt.y, bottom.y - 0.001);
  const pt = rotateAroundPoint({ origin: center, point: adjPt, angleDeg: shape.rotation });
  const anchor = rotateAroundPoint({
    origin: center,
    point: bottomLeft,
    angleDeg: shape.rotation,
  });
  const newMid = { x: (anchor.x + pt.x) / 2, y: (anchor.y + pt.y) / 2 };
  let xPt = rotateAroundPoint({ origin: newMid, point: pt, angleDeg: -shape.rotation });
  let d2 = { x: 2 * (newMid.x - xPt.x), y: 2 * (newMid.y - xPt.y) };
  setCornerDimensions({ shape, state, x: xPt.x + d2.x, y: xPt.y, width: Math.abs(d2.x), height: d2.y });
}

export function topLeftFn({ center, x, y, shape, right, bottom, bottomRight, state }) {
  let adjPt = rotateAroundPoint({ origin: center, point: { x, y }, angleDeg: -shape.rotation });
  adjPt.x = Math.min(adjPt.x, right.x - 0.001);
  adjPt.y = Math.min(adjPt.y, bottom.y - 0.001);
  const pt = rotateAroundPoint({ origin: center, point: adjPt, angleDeg: shape.rotation });
  const anchor = rotateAroundPoint({
    origin: center,
    point: bottomRight,
    angleDeg: shape.rotation,
  });
  const newMid = { x: (anchor.x + pt.x) / 2, y: (anchor.y + pt.y) / 2 };
  let xPt = rotateAroundPoint({ origin: newMid, point: pt, angleDeg: -shape.rotation });
  let d2 = { x: 2 * (newMid.x - xPt.x), y: 2 * (newMid.y - xPt.y) };
  setCornerDimensions({ shape, state, x: xPt.x, y: xPt.y, width: d2.x, height: d2.y });
}

export const shapeMeasurements = ({ shape: { x, y, width, height } }) => ({
  center: { x: x + width / 2, y: y + height / 2 },
  topLeft: { x, y },
  top: { x: x + width / 2, y },
  topRight: { x: x + width, y },
  left: { x, y: y + height / 2 },
  right: { x: x + width, y: y + height / 2 },
  bottomLeft: { x, y: y + height },
  bottom: { x: x + width / 2, y: y + height },
  bottomRight: { x: x + width, y: y + height },
});
