import { Button } from "@mui/material";
import { makeInitials } from "../../Form/helpers";
import { BigAvatarDiv, BigAvatar } from "../../Form/styles";
import { useClearAvatar } from "../Hooks/useClearAvatar";

export const UserAvatar = ({ full_name, small, avatar, setAvatarOpen, clearAvatar, setClearAvatar }) => {
  const initials = makeInitials(full_name);
  useClearAvatar(avatar, setClearAvatar);

  return (
    <BigAvatarDiv small={small}>
      <BigAvatar name={initials} src={clearAvatar ? "" : avatar ? avatar : ""}>
        {initials}
      </BigAvatar>
      <Button
        onClick={() => setAvatarOpen(true)}
        style={{ marginTop: "1em", width: "fit-content", margin: "0 auto" }}
        variant="contained"
        color="primary"
        component="span"
      >
        Choose Avatar
      </Button>
      {avatar && !clearAvatar && (
        <Button
          onClick={() => setClearAvatar(true)}
          style={{ marginTop: "1em", width: "fit-content", margin: "0 auto" }}
          variant="contained"
          color="secondary"
          component="span"
        >
          Clear Avatar
        </Button>
      )}
    </BigAvatarDiv>
  );
};
