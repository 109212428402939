import { Divider, IconButton, Popover, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { setDidQuestionsChange, setQuestionIndex } from "../../../Documentation/Reducers/DocumentationReducer";
import { reorder } from "../../helpers";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function QuestionSelectionList() {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.editor?.questions);
  const question_index = useSelector((state) => state.documentation?.question_index);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "question-popover" : undefined;

  function onDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    const newOrder = reorder(question_index, result.source.index, result.destination.index);
    dispatch(setDidQuestionsChange({ isChanged: true }));
    dispatch(setQuestionIndex({ newOrder }));
  }
  const handleToggle = (question) => () => {
    if (question_index.includes(question)) {
      dispatch(setQuestionIndex({ remove: question }));
    } else {
      dispatch(setQuestionIndex({ add: question }));
    }
    dispatch(setDidQuestionsChange({ isChanged: true }));
  };
  const checked =
    questions &&
    question_index &&
    question_index.reduce((a, c) => {
      Object.keys(questions).includes(c) && a.push(c);
      return a;
    }, []);
  const unchecked =
    questions &&
    question_index &&
    Object.keys(questions).reduce((a, c) => {
      !question_index.includes(c) && a.push(c);
      return a;
    }, []);
  // console.log({ questions, questionLength: questions && Object.keys(questions).length, question_index });
  return (
    <div style={{ display: "flex" }}>
      <List
        sx={{ width: "100%", bgcolor: "background.paper" }}
        subheader={
          questions && question_index && (checked?.length || unchecked?.length) ? (
            <div style={{ display: "flex", alignItems: "center", paddingLeft: "1em" }}>
              <Typography style={{ textDecoration: "underline" }} variant="h5">
                Columns
              </Typography>
              <IconButton aria-describedby={id} variant="contained" onClick={handleClick}>
                <HelpOutlineIcon />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: { border: "1px solid", padding: "0.5em" },
                }}
              >
                <Typography style={{ fontSize: "0.8em" }} variant="subtitle2" id="nested-list-subheader">
                  - Use the checkboxes below to add/remove questions from the results sheet.
                </Typography>
                <Typography style={{ fontSize: "0.8em" }} variant="subtitle2" id="nested-list-subheader">
                  - You can also drag and drop to change the order of the questions
                </Typography>
              </Popover>
            </div>
          ) : (
            ""
          )
        }
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {questions &&
                  question_index &&
                  checked &&
                  checked.map((question, index) => {
                    const labelId = `checkbox-list-label-${question}`;
                    return (
                      <div key={index}>
                        <Divider style={{ margin: 0 }} />
                        <Draggable key={`draggable${index}`} draggableId={`draggable${index}`} index={index}>
                          {(provided2) => (
                            <div ref={provided2.innerRef} {...provided2.draggableProps} {...provided2.dragHandleProps}>
                              <ListItem key={question} disablePadding>
                                <ListItemButton
                                  role={undefined}
                                  style={{ paddingTop: 0, paddingBottom: 0 }}
                                  onClick={handleToggle(question)}
                                  dense
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge="start"
                                      checked={question_index.includes(question)}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{ "aria-labelledby": labelId }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    primary={`${questions[question].title}`}
                                    secondary={questions[question]?.description}
                                    style={{ margin: 0 }}
                                  />
                                </ListItemButton>
                              </ListItem>
                              {provided2.placeholder}
                            </div>
                          )}
                        </Draggable>
                      </div>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {questions &&
          question_index &&
          unchecked &&
          unchecked.map((question, index) => {
            if (!questions[question]) return "";
            const labelId = `checkbox-list-label-${question}`;

            return (
              <div key={question}>
                <Divider style={{ margin: 0 }} />

                <ListItem key={question} disablePadding>
                  <ListItemButton
                    role={undefined}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    onClick={handleToggle(question)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={question_index.indexOf(question) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${questions[question].title}`}
                      secondary={`${questions[question]?.description}`}
                      style={{ margin: 0 }}
                    />
                  </ListItemButton>
                </ListItem>
                {index === unchecked.length - 1 ? <Divider style={{ margin: 0 }} /> : ""}
              </div>
            );
          })}
      </List>
    </div>
  );
}
