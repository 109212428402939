import React from "react";
import { Typography, Button, Card, CardContent } from "@mui/material";
import {
  AssignmentTurnedInTwoTone,
  Business,
  FolderOpenTwoTone,
  Group,
  InsertDriveFileTwoTone,
  ListAltTwoTone,
  Person,
  SummarizeTwoTone,
} from "@mui/icons-material";
import { styles } from "./useStyles";
import { setAlert } from "../../Documentation/Reducers/DocumentationReducer";
import { claimAssignment } from "../../Documentation/Reducers/Thunks/claimAssignment";
import { setAssignments } from "../../Login/Reducers/LoginReducer";
import { unclaimAssignment } from "../../Documentation/Reducers/Thunks/unclaimAssignment";

export function Assignments(assignments, dispatch) {
  return assignments.map((assignment, index) => {
    return (
      <Card variant="outlined" key={index}>
        <CardContent style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <div>
            <Typography variant="body2">
              {new Date(assignment.assigned_at).toLocaleTimeString(
                {},
                {
                  hour12: true,
                  weekday: "short",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                }
              )}
            </Typography>
            <Typography style={{ display: "flex", alignItems: "center" }}>
              {assignment.kind === "folder" ? (
                <FolderOpenTwoTone style={styles.folder} />
              ) : assignment.doctype === "presentation" ? (
                <InsertDriveFileTwoTone style={styles.document} />
              ) : assignment.doctype === "form" ? (
                <AssignmentTurnedInTwoTone style={styles.form} />
              ) : assignment.doctype === "report" ? (
                <SummarizeTwoTone style={styles.report} />
              ) : (
                <ListAltTwoTone style={styles.sheet} />
              )}
              {assignment.object_name}
            </Typography>
            <Typography style={{ display: "flex", alignItems: "center" }}>
              {assignment.assigned_to.type === "org" ? (
                <Business />
              ) : assignment.assigned_to.type === "role" ? (
                <Group />
              ) : (
                <Person />
              )}
              {assignment.assigned_to.name}
            </Typography>
            <Typography variant="subtitle2" style={{ overflowWrap: "anywhere" }}>
              {assignment.notes ? `${assignment.notes}` : ""}
            </Typography>
          </div>
          <div style={{ justifySelf: "left", alignSelf: "center" }}>
            {assignment.claimed ? (
              <Button
                variant="outlined"
                color="error"
                onClick={() =>
                  dispatch(unclaimAssignment({ kind: assignment.kind, id: assignment.id }))
                    .then((res) => {
                      dispatch(
                        setAssignments({
                          assignments: assignments.map((x) => (x.id === res.data.id ? res.data : x)),
                        })
                      );
                      dispatch(
                        setAlert({
                          alert: `You've unclaimed the assignment to ${assignment.object_name}`,
                          severity: "warning",
                        })
                      );
                    })
                    .catch((e) => {
                      dispatch(
                        setAlert({
                          alert: `Something went wrong unclaiming this assignment...`,
                          severity: "warning",
                        })
                      );
                    })
                }
              >
                Unclaim
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="success"
                onClick={() =>
                  dispatch(claimAssignment({ kind: assignment.kind, id: assignment.id }))
                    .then((res) => {
                      dispatch(
                        setAssignments({
                          assignments: assignments.map((x) => (x.id === res.data.id ? res.data : x)),
                        })
                      );
                      dispatch(
                        setAlert({
                          alert: `You've claimed the assignment to ${assignment.object_name}`,
                          severity: "success",
                        })
                      );
                    })
                    .catch((e) => {
                      dispatch(
                        setAlert({
                          alert: `Something went wrong claiming this assignment...`,
                          severity: "warning",
                        })
                      );
                    })
                }
              >
                Claim
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    );
  });
}
