import React from "react";
import Typography from "@mui/material/Typography";
import { formatDateTime } from "../../Helpers/helpers";
import { styles } from "../styles";

export const CardDescription = ({ name, description, modifiedAt, createdAt, kind }) => {
  var date = new Date(modifiedAt);
  var created = new Date(createdAt);
  const isDocument =
    kind === "document" ? (
      <Typography variant="body2" color="textSecondary" component="p" style={styles.modified}>
        Modified: {formatDateTime(date)}
      </Typography>
    ) : (
      <Typography variant="body2" color="textSecondary" component="p" style={styles.modified}>
        Created: {formatDateTime(created)}
      </Typography>
    );
  return (
    <div style={styles.descriptionContainer}>
      <Typography variant="h5" component="h5" style={styles.filename}>
        {name}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p" style={styles.description}>
        {description}
      </Typography>
      {isDocument}
    </div>
  );
};
