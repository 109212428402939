import axios from "axios";
import { registrationLoading } from "./RegistrationReducer";
import { baseURL, headers } from "./thunks";

export const registerUser =
  ({ username, email, fullName, password }) =>
  async (dispatch) => {
    dispatch(registrationLoading());
    return await axios.post(
      baseURL + `auth/register`,
      {
        username,
        email,
        full_name: fullName,
        password,
      },
      headers
    );
  };
