import React, { useState } from "react";
import { Typography, Grid, Container, Snackbar, Alert as MuiAlert } from "@mui/material";
import { LoginAppBar } from "./Components/LoginAppBar";
import { LoginInfoBox } from "./Components/LoginInfoBox";
import { LoginForm } from "./Components/LoginForm";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { loginSelector } from "./Reducers/LoginReducer";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Login = () => {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const { successfulReset } = useSelector(loginSelector);
  useEffect(() => {
    document.title = "Login";
  }, []);
  return (
    <div style={{ height: "100%", overflow: "scroll" }}>
      <LoginAppBar />
      {successfulReset && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={`${"top"},${"center"}`}
          open={open}
          onClose={handleClose}
          autoHideDuration={6000}
        >
          <Alert onClose={handleClose} severity="success">
            Password reset successful!
          </Alert>
        </Snackbar>
      )}
      <Container maxWidth="md">
        <Typography variant="h4">Login</Typography>
        <Typography variant="body1">Use the form below to login to the site.</Typography>
        <Grid container style={{ justifyContent: "center" }}>
          <LoginInfoBox />
          <LoginForm />
        </Grid>
      </Container>
    </div>
  );
};
