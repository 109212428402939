import axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const getInviteID =
  ({ id }) =>
  async () => {
    const data = await axios({
      method: "get",
      url: baseURL + "auth/invite/" + id,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return data;
  };
