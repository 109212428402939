import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "../../Documentation/Helpers/helpers";
import { getContainedSize } from "../helpers";
import { setContainedSize } from "../Reducers/EditorReducer";

export function useContainedSize({ annotationWidth, boxHeight, svgMiddleY, svgMiddleX, imageRef }) {
  const dispatch = useDispatch();
  const prevImageRef = usePrevious(imageRef?.current);
  const containedSize =
    imageRef?.current &&
    prevImageRef?.width === imageRef.current?.width &&
    prevImageRef?.height === imageRef.current?.height &&
    getContainedSize(imageRef.current);
  const zoom = useSelector((state) => state.editor.zoom);
  const selected = useSelector((state) => state.editor.selected);
  const shapes = useSelector((state) => state.editor.shapes);
  const selectedThumbnail = useSelector((state) => state.documentation.selectedThumbnail);

  useEffect(() => {
    if (containedSize?.width && containedSize?.height) {
      dispatch(setContainedSize({ width: containedSize?.width, height: containedSize?.height }));
    }
  }, [
    dispatch,
    containedSize?.height,
    containedSize?.width,
    selected,
    zoom,
    annotationWidth,
    boxHeight,
    svgMiddleY,
    svgMiddleX,
    selectedThumbnail,
    shapes,
  ]);
}
