import axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const cancelInvitation =
  ({ id }) =>
  async (dispatch) => {
    try {
      const data = await axios({
        method: "delete",
        url: baseURL + "auth/org-invites/" + id,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
