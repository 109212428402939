import { v4 as uuidv4 } from "uuid";

export const formatNewFiles = (files) => {
  return [...files].map((file) => {
    return {
      pageID: uuidv4(),
      description: file.name.split(".")[0],
      content_type: file.type,
      shapes: [],
      media: URL.createObjectURL(file),
      filename: file.name,
      size: file.size,
      lastModified: file.lastModified,
    };
  });
};
