export const reducers = {
  registrationLoading: (state) => {
    if (state.loading === null) {
      state.loading = true;
    }
  },
  registrationComplete: (state) => {
    state.loading = false;
  },
};
