import history from "../../../../Utils/history";
import {
  loading,
  received,
  resetDocumentChanges,
  resetFileObjQueue,
  setAlert,
  setCurrentDocument,
  setIsBlocking,
  setUploadProgress,
} from "../../../Documentation/Reducers/DocumentationReducer";
import { setSaving } from "../../../Editor/Reducers/EditorReducer";
import { publishDocument } from "../publishDocument";

export function handlePresentation(
  dispatch,
  currentDocument,
  fileObjQueue,
  currentDocumentChanges,
  page_index,
  changelog,
  onCancel,
  onConfirm,
  setShowPasswordModal
) {
  dispatch(loading());
  dispatch(setUploadProgress({ uploadProgress: 0 }));
  dispatch(
    publishDocument({
      currentDocument,
      fileObjQueue,
      currentDocumentChanges,
      page_index,
      changelog,
      onCancel,
      setShowPasswordModal,
    })
  )
    .then((res) => {
      if (!res) {
        dispatch(setIsBlocking({ isBlocking: false }));
        dispatch(setSaving({ saving: false }));
        dispatch(received({ error: false }));
        dispatch(setUploadProgress({ reset: true }));
        onCancel();
        return;
      } else {
        onConfirm();
        dispatch(setIsBlocking({ isBlocking: false }));
        dispatch(setSaving({ saving: false }));
        dispatch(received({ error: false }));
        dispatch(setUploadProgress({ reset: true }));
        dispatch(setCurrentDocument({ doc: res.data, isEditor: false }));
        dispatch(setAlert({ alert: "Publish Successful!", severity: "success" }));
        dispatch(resetFileObjQueue());
        dispatch(resetDocumentChanges());
        history.push(`/documentation/view/${currentDocument.id}`);
      }
    })
    .catch((e) => console.error(e));
}
