import React from "react";
import { styles } from "../styles";
import { VideoLibrary, PictureAsPdfOutlined, FilePresent } from "@mui/icons-material";

export function determineThumbnail({ thumbnailObj, index }) {
  return /video/.test(thumbnailObj?.content_type) ? (
    <VideoLibrary
      id={index + "video"}
      style={{ width: "100%", height: "100%", maxHeight: "200px", color: "firebrick" }}
      alt={index}
      key={index}
    />
  ) : /pdf/.test(thumbnailObj?.content_type) ? (
    <PictureAsPdfOutlined
      id={index + "pdf"}
      style={{ width: "100%", height: "100%", maxHeight: "200px", color: "darkslategrey" }}
      alt={index}
      key={index}
    />
  ) : /image/.test(thumbnailObj?.content_type) ? (
    <img
      id={index + "image"}
      style={styles.image}
      loading={"lazy"}
      src={thumbnailObj?.media_thumb || thumbnailObj?.media}
      alt={index}
      key={index}
    />
  ) : (
    <FilePresent
      id={index + "attachment"}
      style={{ width: "100%", height: "100%", maxHeight: "200px", color: "cornflowerblue" }}
      alt={index}
      key={index}
    />
  );
}
