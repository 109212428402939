import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { handle403 } from "../../Dashboard/Hooks/Helpers/handle403";
import { foldFolderStructure } from "../../Documentation/Components/Dialogs/Helpers/foldFolderStructure";
import { queryAllOfType } from "../../Documentation/Reducers/Thunks/queryAllOfType";

export function useChecklistFolderStructure(results, setResults) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!results) {
      dispatch(queryAllOfType({ types: ["form"] }))
        .then((res) => {
          let folderStructure = res.data.results.reduce(foldFolderStructure, {});
          setResults(folderStructure);
        })
        .catch((err) => {
          handle403(err, dispatch);
        });
    }
    // return () => setResults(null);
  }, [dispatch, setResults, results]);
}
