import React from "react";
import { styles } from "./styles";
import { useSelector } from "react-redux";

export function Handles({ handleMouseDown }) {
  const selected = useSelector((state) => state.editor.selected);
  const scaleX = useSelector((state) => state.editor.scaleX);
  const scaleY = useSelector((state) => state.editor.scaleY);
  const {
    handle,
    top,
    topRight,
    topLeft,
    right,
    left,
    bottom,
    bottomRight,
    bottomLeft,
    rotationArm,
    rotationHandle,
    center,
  } = styles({ selected, scaleX, scaleY });

  return (
    <>
      <div data-handle="rotationArm" style={{ ...handle, ...rotationArm }} />
      <div
        data-handle="rotationHandle"
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
        style={{ ...handle, ...rotationHandle }}
      />
      <div
        data-handle="topLeft"
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
        style={{ ...handle, ...topLeft }}
      />
      <div
        data-handle="top"
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
        style={{ ...handle, ...top }}
      />
      <div
        data-handle="topRight"
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
        style={{ ...handle, ...topRight }}
      />
      <div
        data-handle="right"
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
        style={{ ...handle, ...right }}
      />
      <div
        data-handle="bottomRight"
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
        style={{ ...handle, ...bottomRight }}
      />
      <div
        data-handle="bottom"
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
        style={{ ...handle, ...bottom }}
      />
      <div
        data-handle="bottomLeft"
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
        style={{ ...handle, ...bottomLeft }}
      />
      <div
        data-handle="left"
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
        style={{ ...handle, ...left }}
      />
      <div
        data-handle="center"
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
        style={{ ...handle, ...center }}
      />
    </>
  );
}
