export const initialState = {
  loading: false,
  roles: [],
  total: 0,
  error: "",
  selectedRoles: [],
  currentRole: {
    role: "",
    description: "",
    id: null,
  },
};
