import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { activateUser } from "../Reducers/activateUser";
import { LoadingSpinner } from "../../Viewer/Components/LoadingSpinner";

export const ActivateAccount = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(activateUser());
  }, [dispatch]);
  return <LoadingSpinner />;
};
