import { getTrash } from "../../Reducers/thunks";
import { received, setTrash } from "../../Reducers/DocumentationReducer";
import { handleError } from "../../Helpers/handleError";

export function handleGetTrash(dispatch, id, name, setSeverity, setAlert) {
  return () => {
    dispatch(getTrash({ folder_id: id }))
      .then((res) => {
        dispatch(setTrash({ trash: res }));
        dispatch(received({ error: false }));
        setSeverity("success");
        setAlert(`${name} successfully restored`);
      })

      .catch(handleError(dispatch));
  };
}
