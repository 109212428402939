import { Cancel } from "@mui/icons-material/";
import { AppBar, Button, Dialog, DialogActions, DialogTitle, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions } from "../../Hooks/usePermissions";
import { setFavorites, setNotifications, setSubscriptions } from "../../Reducers/DocumentationReducer";
import { editFolder } from "../../Reducers/thunks";
import { createDocumentFavorite } from "../../Reducers/Thunks/createDocumentFavorite";
import { createDocumentSubscription } from "../../Reducers/Thunks/createDocumentSubscription";
import { createFolderFavorite } from "../../Reducers/Thunks/createFolderFavorite";
import { createFolderSubscription } from "../../Reducers/Thunks/createFolderSubscription";
import { deleteDocumentFavorite } from "../../Reducers/Thunks/deleteDocumentFavorite";
import { deleteDocumentSubscription } from "../../Reducers/Thunks/deleteDocumentSubscription";
import { deleteFolderFavorite } from "../../Reducers/Thunks/deleteFolderFavorite";
import { deleteFolderSubscription } from "../../Reducers/Thunks/deleteFolderSubscription";
import { getFavorites } from "../../Reducers/Thunks/getFavorites";
import { getNotifications } from "../../Reducers/Thunks/getNotifications";
import { getSubscriptions } from "../../Reducers/Thunks/getSubscriptions";
import { putDocumentACL } from "../../Reducers/Thunks/putDocumentACL";
import { putFolderACL } from "../../Reducers/Thunks/putFolderACL";
import { AssignmentsTab } from "./AssignmentsTab";
import { isKindAndId, Perm } from "./helpers";
import { PermissionsTab } from "./PermissionsTab";
import { PropertiesTab } from "./PropertiesTab";
import { a11yProps } from "./styles";
import { TabPanel } from "./TabPanel";

export const EditPropertiesDialog = (props) => {
  const dispatch = useDispatch();
  const currentFolder = useSelector((state) => state.documentation.currentFolder);
  const { onEdit, onClose, open, docToEdit } = props;
  const {
    id,
    name,
    doctype,
    description,
    kind,
    version,
    modifiedAt,
    createdAt,
    created_by_username,
    modified_by_username,
    subscription_options,
    user_permissions,
  } = docToEdit;
  const [error, setError] = useState();
  const [aclError, setACLError] = useState();
  const [fields, setFields] = useState({});
  const [value, setValue] = React.useState(0);
  const subscriptions = useSelector((state) => state.documentation.subscriptions);
  const favorites = useSelector((state) => state.documentation.favorites);
  const is_org_admin = useSelector((state) => state.login.is_org_admin);
  const assignments = useSelector((state) => state.login.assignments);
  const isSubscribed = subscriptions.reduce(isKindAndId(kind, id), false);
  const isFav = favorites.reduce(isKindAndId(kind, id), false);
  const [subscribe, setSubscribe] = React.useState(isSubscribed);
  const [favorite, setFavorite] = React.useState(isFav);
  const [ACLList, setACLList] = useState();
  const [ACLInherited, setACLInherited] = useState();
  const [ACLSubjects, setACLSubjects] = useState();
  const [permissionList, setPermissionList] = useState();
  const [selectedRow, setSelectedRow] = useState([]);
  const [validationSubscription, setValidationSubscription] = useState(
    subscription_options?.validation_errors_only || false
  );
  const [emailSubscription, setEmailSubscription] = useState(subscription_options?.email_notifications || false);

  usePermissions(kind, id, setACLList, setACLInherited, setPermissionList, setACLError, setACLSubjects);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const onChangeName = (e) => setFields({ ...fields, name: e.target.value });
  const onChangeDesc = (e) => setFields({ ...fields, description: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    const didCreateFolderSub = () =>
      kind === "folder"
        ? dispatch(createFolderSubscription({ id }))
        : dispatch(createDocumentSubscription({ id, validationSubscription, emailSubscription }));
    const didDeleteFolderSub = () =>
      kind === "folder" ? dispatch(deleteFolderSubscription({ id })) : dispatch(deleteDocumentSubscription({ id }));
    const didCreateFolderFav = () =>
      kind === "folder" ? dispatch(createFolderFavorite({ id })) : dispatch(createDocumentFavorite({ id }));
    const didDeleteFolderFav = () =>
      kind === "folder" ? dispatch(deleteFolderFavorite({ id })) : dispatch(deleteDocumentFavorite({ id }));

    const didCreateSub = () => {
      return subscribe && !isSubscribed
        ? didCreateFolderSub()
        : subscribe &&
          ((subscription_options?.validation_errors_only && !validationSubscription) ||
            (!subscription_options?.validation_errors_only && validationSubscription) ||
            (subscription_options?.email_notifications && !emailSubscription) ||
            (!subscription_options?.email_notifications && emailSubscription))
        ? didCreateFolderSub()
        : !subscribe && isSubscribed
        ? didDeleteFolderSub()
        : null;
    };

    const didCreateFav = () =>
      favorite && !isFav ? didCreateFolderFav() : !favorite && isFav ? didDeleteFolderFav() : null;
    const didEditFields = () =>
      description !== fields.description || name !== fields.name
        ? dispatch(
            editFolder({
              id,
              doctype,
              name: fields.name,
              description: fields.description,
              version,
              kind,
            })
          )
        : null;
    if (!aclError) {
      if (kind === "folder") {
        dispatch(
          putFolderACL({
            id,
            ACLList: ACLList.map((acl, index) => ({
              subject: acl.subject,
              is_grant: acl.is_grant,
              permissions: Perm.convertToBinary(permissionList[index]),
            })),
          })
        );
      } else {
        dispatch(
          putDocumentACL({
            id,
            ACLList: ACLList.map((acl, index) => ({
              subject: acl.subject,
              is_grant: acl.is_grant,
              permissions: Perm.convertToBinary(permissionList[index]),
            })),
          })
        );
      }
    }

    Promise.all([didCreateSub(), didCreateFav(), didEditFields()]).then((_) => {
      Promise.all([dispatch(getSubscriptions()), dispatch(getNotifications()), dispatch(getFavorites())])
        .then((res) => {
          dispatch(setSubscriptions({ subscriptions: res[0].data }));
          dispatch(setNotifications({ notifications: res[1].data.results }));
          dispatch(setFavorites({ favorites: res[2].data.results }));
        })
        .then((_) => {
          onClose();
          onEdit(name, kind);
        });
    });
  };
  const permList = Perm.getLabelList(user_permissions);
  const resetForm = () => {
    setError();
    setFields({
      name,
      description,
    });
  };
  let created = new Date(createdAt);
  let modified = new Date(modifiedAt);
  let noEditPerms = !permList.includes("Edit Permissions");
  return (
    <Dialog maxWidth={"md"} fullWidth onClose={onClose} TransitionProps={{ onEntering: resetForm }} open={open}>
      <AppBar position="static">
        <div style={{ display: "grid", gridTemplateColumns: "20fr 1fr", alignItems: "center" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons={doctype === "form" && is_org_admin}
            allowScrollButtonsMobile={doctype === "form" && is_org_admin}
          >
            <Tab style={{ color: "white" }} label="Properties" {...a11yProps(0)} />
            {aclError || noEditPerms ? "" : <Tab style={{ color: "white" }} label="Permissions" {...a11yProps(1)} />}
            <Tab style={{ color: "white" }} label="Assignments" {...a11yProps(noEditPerms ? 1 : 2)} />
          </Tabs>
          <Cancel onClick={onClose} style={{ color: "red", cursor: "pointer" }} />
        </div>
      </AppBar>
      <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0, paddingLeft: 24 }}>
        Edit{" "}
        {value === 0
          ? "Properties"
          : noEditPerms && value === 1
          ? "Assignments"
          : value === 1
          ? "Permissions"
          : "Assignments"}
        : {name}
      </DialogTitle>
      <TabPanel value={value} index={0}>
        {PropertiesTab({
          doctype,
          error,
          kind,
          fields,
          onChangeName,
          onChangeDesc,
          currentFolder,
          name,
          created_by_username,
          created,
          modified_by_username,
          modified,
          version,
          subscribe,
          setSubscribe,
          favorite,
          setFavorite,
          validationSubscription,
          setValidationSubscription,
          emailSubscription,
          setEmailSubscription,
          permList,
        })}
      </TabPanel>
      {aclError || noEditPerms ? (
        ""
      ) : (
        <TabPanel value={value} index={1}>
          {PermissionsTab({
            kind,
            ACLList,
            ACLSubjects,
            setACLList,
            permissionList,
            setPermissionList,
            selectedRow,
            setSelectedRow,
            ACLInherited,
          })}
        </TabPanel>
      )}

      <TabPanel value={value} index={noEditPerms ? 1 : 2} style={{ padding: "1em" }}>
        {AssignmentsTab(assignments, id, dispatch, name)}
      </TabPanel>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} style={{ color: "#097c09" }}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
