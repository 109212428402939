import { Card, CardContent, Typography } from "@mui/material";
import { useState } from "react";
import { VerificationPhotoCarousel } from "../ChecklistViewer/VerificationPhotoCarousel";

export function ReportContainer({ currentDocument, page, singleReport, theme }) {
  const { title, valueType } = currentDocument.contents.questions[page];
  const values = singleReport.record[page]?.value;
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps =
    singleReport.record[page] && singleReport.record[page].media ? singleReport.record[page].media.length : 0;
  return singleReport.record[page] ? (
    <Card raised style={{ maxWidth: 1000 }}>
      <CardContent style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ margin: "0 auto" }}>
          <Typography variant={"h5"} style={{ fontSize: "2em" }}>
            {title} {singleReport.record[page].valid ? "✅" : "❌"}
          </Typography>
        </div>
        <div>
          <Typography
            style={{
              width: "fit-content",
              paddingTop: "1em",
              fontWeight: "bold",
            }}
          >
            {valueType === "multiple choice" ? "Answers:" : "Answer:"}
          </Typography>
          <Typography
            style={{
              width: "fit-content",
              paddingTop: "1em",
            }}
          >
            {Array.isArray(values) ? (
              values.map((value, idx) => <li key={idx}>{value}</li>)
            ) : (
              <li>{valueType === "datetime" ? values.split("T").join(" ") : values.toString()}</li>
            )}
          </Typography>
        </div>
        <div>
          {singleReport.record[page].comments ? (
            <>
              <Typography style={{ paddingTop: "1em", fontWeight: "bold" }}>Comments:</Typography>
              <Typography style={{ paddingTop: "1em" }}>{singleReport.record[page].comments}</Typography>
            </>
          ) : (
            ""
          )}
        </div>

        {singleReport.record[page].media ? (
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <VerificationPhotoCarousel
              media={singleReport.record[page].media}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              maxSteps={maxSteps}
              theme={theme}
            />
          </div>
        ) : (
          ""
        )}
      </CardContent>
    </Card>
  ) : (
    ""
  );
}
