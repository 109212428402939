export function unflattenTree(flatTree) {
  const nodeMap = new Map(flatTree.map((n) => [n.id, { ...n }]));
  const roots = Array.from(nodeMap.values()).filter((n) => !n.parent);
  for (let node of nodeMap.values()) {
    if (node.parent) {
      let parent = nodeMap.get(node.parent);
      if (parent) {
        if (!parent.children) {
          parent.children = [];
        }
        parent.children.push(node);
      }
      delete node.parent;
      delete node.droppable;
      delete node.text;
    }
  }
  return roots;
}
