import axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const createOrg =
  ({ name }) =>
  async () => {
    try {
      return await axios({
        method: "post",
        url: baseURL + "auth/create-org",
        data: {
          name,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } catch (e) {
      console.error(e);
      return e;
    }
  };
