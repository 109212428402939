import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { selectColor } from "../Admin/Users/helpers";

export const BigAvatar = styled(Avatar)`
  width: 10em;
  height: 10em;
  background: ${(props) => (props.name ? selectColor(props.name) : "#bdbdbd")};
  /* color: ${(props) => (props.string ? "#" + invertHex(intToRGB(hashCode(props.string))) : "")}; */
  color: "white";
`;
export function invertHex(hex) {
  return (Number(`0x1${hex}`) ^ 0xffffff).toString(16).substr(1).toUpperCase();
}

export function hashCode(str) {
  /*
    It needs to pad the hex strings, such as: 
    ("00" + ((this >> 24) & 0xFF).toString(16)).slice(-2) 
    + ("00" + ((this >> 16) & 0xFF).toString(16)).slice(-2) 
    + ("00" + ((this >> 8) & 0xFF).toString(16)).slice(-2) 
    + ("00" + (this & 0xFF).toString(16)).slice(-2);
  */
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return hash;
}

export function intToRGB(i) {
  let c = (i & 0x00ffffff).toString(16).toUpperCase();
  return "00000".substring(0, 6 - c.length) + c;
}

export const BigAvatarDiv = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(1),
    marginBottom: "1em",
  },
  paper: {
    background: "#f5f5f5",
  },
  extraLargeAvatar: {
    width: "10em",
    height: "10em",
  },
  input: {
    display: "none",
  },
}));
