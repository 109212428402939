import React, { useState } from "react";
import { ButtonGroup, Button, Popover, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Crop169,
  RadioButtonUnchecked,
  Delete,
  Mouse,
  HighlightOff,
  Palette,
  LineWeight,
  ArrowRightAlt,
} from "@mui/icons-material/";
import { useSelector, useDispatch } from "react-redux";
import { setTool, removeShape, setColor, setStrokeWidth } from "../../Reducers/EditorReducer";
import { ToolbarContainer, ToolbarButton } from "./styles";
import { setDocumentChangesMedia, setFileObjQueueMedia } from "../../../Documentation/Reducers/DocumentationReducer";
import { ReactComponent as FileReplace } from "./FileReplace.svg";

const styles = {
  toolbarContainer: {
    borderBottom: "1px solid gainsboro",
    padding: "4px",
    display: "flex",
    width: "100%",
    minHeight: "50px",
    backgroundColor: "#f9f9f9",
  },
  flexSpace: { flexGrow: 1 },
};
const useStyles = makeStyles((theme) => ({
  colorGrid: {
    padding: theme.spacing(1),
    height: "fit-content",
    width: "fit-content",
  },
}));

const buttonStyles = (tool) => ({
  select: { background: tool === "select" ? "gainsboro" : "transparent" },
  rectangle: { background: tool === "rectangle" ? "gainsboro" : "transparent" },
  ellipse: { background: tool === "ellipse" ? "gainsboro" : "transparent" },
  arrow: { background: tool === "arrow" ? "gainsboro" : "transparent" },
});

export const EditorToolbar = ({ setShowRemovePage }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const currentPage = useSelector((state) => state.documentation.currentPage);
  const fileObjQueue = useSelector((state) => state.documentation.fileObjQueue);
  const isFileObjQueue = fileObjQueue.find((x) => x.pageID === currentPage?.pageID);
  const tool = useSelector((state) => state.editor.tool);
  const selected = useSelector((state) => state.editor.selected);
  const isImage = /image/.test(currentPage?.content_type);
  const classes = useStyles();
  const handleSetTool = (e) => dispatch(setTool({ tool: e.currentTarget.value }));

  const handleChangeMedia = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const file = e.target.files[0];
      if (isFileObjQueue) {
        dispatch(
          setFileObjQueueMedia({
            content_type: file.type,
            filename: file.name,
            size: file.size,
            lastModified: file.lastModified,
            media: URL.createObjectURL(file),
            pageID: currentPage.pageID,
          })
        );
      } else {
        dispatch(
          setDocumentChangesMedia({
            content_type: file.type,
            filename: file.name,
            size: file.size,
            lastModified: file.lastModified,
            media: URL.createObjectURL(file),
            pageID: currentPage.pageID,
          })
        );
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const popoverID = open ? "color-popover" : undefined;
  const popoverID2 = open2 ? "stokeWidth-popover" : undefined;
  const handleColorSelect = (e) => {
    dispatch(setColor({ color: e.target.title }));
    handleClose();
  };
  const handleStrokeWidthSelect = (strokeWidth) => {
    dispatch(setStrokeWidth({ strokeWidth }));
    setAnchorEl2(null);
  };
  const ColorPopper = () => (
    <Popover
      id={popoverID}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Grid className={classes.colorGrid} container direction="row" justifyContent="center" alignItems="center">
        <Grid
          item
          xs={2}
          title="red"
          onClick={handleColorSelect}
          style={{ backgroundColor: "red", width: "1em", height: "1em", margin: 10 }}
        />
        <Grid
          item
          xs={2}
          title="blue"
          onClick={handleColorSelect}
          style={{ backgroundColor: "blue", width: "1em", height: "1em", margin: 10 }}
        />
        <Grid
          item
          xs={2}
          title="yellow"
          onClick={handleColorSelect}
          style={{ backgroundColor: "yellow", width: "1em", height: "1em", margin: 10 }}
        />
        <Grid
          item
          xs={2}
          title="orange"
          onClick={handleColorSelect}
          style={{ backgroundColor: "orange", width: "1em", height: "1em", margin: 10 }}
        />
        <Grid
          item
          xs={2}
          title="green"
          onClick={handleColorSelect}
          style={{ backgroundColor: "green", width: "1em", height: "1em", margin: 10 }}
        />
        <Grid
          item
          xs={2}
          title="black"
          onClick={handleColorSelect}
          style={{ backgroundColor: "black", width: "1em", height: "1em", margin: 10 }}
        />
      </Grid>
    </Popover>
  );
  const StrokeWidthPopper = () =>
    selected && (
      <Popover
        id={popoverID2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={() => setAnchorEl2(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid component={Button} item xs={2} onClick={() => handleStrokeWidthSelect(1)}>
            <div style={{ backgroundColor: selected.color, width: "2em", height: "1px", margin: 10 }} />
          </Grid>
          <Grid component={Button} item xs={2} onClick={() => handleStrokeWidthSelect(2)}>
            <div style={{ backgroundColor: selected.color, width: "2em", height: "2px", margin: 10 }} />
          </Grid>
          <Grid component={Button} item xs={2} onClick={() => handleStrokeWidthSelect(3)}>
            <div style={{ backgroundColor: selected.color, width: "2em", height: "3px", margin: 10 }} />
          </Grid>
          <Grid component={Button} item xs={2} onClick={() => handleStrokeWidthSelect(4)}>
            <div style={{ backgroundColor: selected.color, width: "2em", height: "4px", margin: 10 }} />
          </Grid>
          <Grid component={Button} item xs={2} onClick={() => handleStrokeWidthSelect(5)}>
            <div style={{ backgroundColor: selected.color, width: "2em", height: "5px", margin: 10 }} />
          </Grid>
        </Grid>
      </Popover>
    );

  return (
    <ToolbarContainer style={styles.toolbarContainer}>
      <ColorPopper />
      <StrokeWidthPopper />
      {isImage ? (
        <ButtonGroup>
          <ToolbarButton
            aria-label="Selection Tool"
            disableElevation
            style={buttonStyles(tool).select}
            value={"select"}
            onClick={handleSetTool}
          >
            <Mouse />
          </ToolbarButton>
          {selected && (
            <ToolbarButton
              aria-label="Select Color"
              aria-describedby={popoverID}
              disableElevation
              variant="outlined"
              onClick={handleClick}
            >
              <Palette />
            </ToolbarButton>
          )}
          {selected && selected.shape !== "arrow" && (
            <ToolbarButton
              aria-label="Select Shape Thickness"
              aria-describedby={popoverID2}
              disableElevation
              variant="outlined"
              onClick={(event) => {
                setAnchorEl2(event.currentTarget);
              }}
            >
              <LineWeight />
            </ToolbarButton>
          )}
          {!selected && (
            <ToolbarButton
              disableElevation
              aria-label="Rectangle Tool"
              style={buttonStyles(tool).rectangle}
              value={"rectangle"}
              onClick={handleSetTool}
            >
              <Crop169 />
            </ToolbarButton>
          )}
          {!selected && (
            <ToolbarButton
              disableElevation
              aria-label="Ellipse Tool"
              style={buttonStyles(tool).ellipse}
              value={"ellipse"}
              onClick={handleSetTool}
            >
              <RadioButtonUnchecked />
            </ToolbarButton>
          )}
          {!selected && (
            <ToolbarButton
              disableElevation
              aria-label="Arrow Tool"
              style={buttonStyles(tool).arrow}
              value={"arrow"}
              onClick={handleSetTool}
            >
              <ArrowRightAlt />
            </ToolbarButton>
          )}
          {selected && (
            <ToolbarButton
              aria-label="Remove Shape"
              disableElevation
              variant="outlined"
              onClick={() => dispatch(removeShape())}
            >
              <HighlightOff />
            </ToolbarButton>
          )}
        </ButtonGroup>
      ) : (
        ""
      )}
      <div style={styles.flexSpace} />
      <Button aria-label="Upload File" disableElevation variant="outlined" component="label">
        <FileReplace />
        <input type="file" onChange={handleChangeMedia} style={{ display: "none" }} />
      </Button>
      <Button
        aria-label="Remove Page"
        disableElevation
        variant="outlined"
        component="label"
        onClick={() => {
          setShowRemovePage(true);
        }}
      >
        <Delete />
      </Button>
    </ToolbarContainer>
  );
};
