export function initalizeValidation(id, valueType) {
  return {
    id,
    validation: [
      "true/false",
      "pass/fail",
      "single choice",
      "multiple choice",
      "number",
      "decimal",
      "datetime",
    ].includes(valueType)
      ? {
          type: valueType,
          ...(valueType === "multiple choice"
            ? { values: [] }
            : valueType === "single choice"
            ? { value: [] }
            : valueType === "number" || valueType === "decimal"
            ? { min: 0, max: 1 }
            : valueType === "datetime"
            ? {
                min: new Date().toISOString().split(".")[0],
                max: new Date().toISOString().split(".")[0],
              }
            : valueType === "true/false"
            ? { value: String(false) }
            : valueType === "pass/fail"
            ? { value: String(true) }
            : ""),
        }
      : {},
  };
}
