import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Modal, Grid, Button, Fade, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { MailOutline } from "@mui/icons-material/";
import { loginSelector } from "../Reducers/LoginReducer";
import { sendPasswordReset } from "../Reducers/sendPasswordReset";
import { TextFieldContainer } from "../../Form/TextFieldContainer";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
}));

export function ForgotPasswordModal({ handleClose, open }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, sent } = useSelector(loginSelector);
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(sendPasswordReset({ email }));
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography variant="body1" id="transition-modal-title">
              Enter your email to receive a reset password link.
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextFieldContainer
                id="email"
                label="Email"
                type="email"
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
                icon={<MailOutline />}
              />
              {!loading && sent === "sent" ? (
                <Typography className={classes.success}>
                  Success! Please, check your email for the reset password link...
                </Typography>
              ) : !loading && sent === "error" ? (
                <Typography className={classes.warning}>Incorrect email, please try again.</Typography>
              ) : (
                ""
              )}
              <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={sent === "sent"}
                  style={{ textTransform: "none", margin: "0.5em" }}
                >
                  {loading ? "Sending..." : sent === "sent" ? "Sent!" : "Send"}
                </Button>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="secondary"
                  style={{ textTransform: "none", margin: "0.5em" }}
                >
                  Cancel
                </Button>
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
