import axios from "axios";
import { baseURL } from "../../Documentation/Reducers/thunks";

export const inviteUser =
  ({ email, roles }) =>
  async () => {
    return await axios({
      method: "post",
      url: baseURL + "users",
      data: {
        email,
        roles,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  };
