import {
  AssignmentTurnedInTwoTone,
  Business,
  FolderOpenTwoTone,
  Group,
  InsertDriveFileTwoTone,
  ListAltTwoTone,
  Person,
} from "@mui/icons-material/";
import { Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { setAssignments } from "../../../Login/Reducers/LoginReducer";
import { setAlert } from "../../Reducers/DocumentationReducer";
import { claimAssignment } from "../../Reducers/Thunks/claimAssignment";
import { unclaimAssignment } from "../../Reducers/Thunks/unclaimAssignment";

export function AssignmentsTab(assignments, id, dispatch, name) {
  return assignments && assignments.filter((assignment) => assignment.object_id === id).length ? (
    assignments
      .filter((assignment) => assignment.object_id === id)
      .map((assignment, index) => {
        return (
          <Card variant="outlined" key={index}>
            <CardContent style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              <div>
                <Typography variant="body2">
                  {new Date(assignment.assigned_at).toLocaleTimeString(
                    {},
                    {
                      hour12: true,
                      weekday: "short",
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    }
                  )}
                </Typography>
                <Typography style={{ display: "flex", alignItems: "center" }}>
                  {assignment.kind === "folder" ? (
                    <FolderOpenTwoTone
                      style={{
                        fontSize: "2em",
                        verticalAlign: "middle",
                        fill: "#f0dc82",
                      }}
                    />
                  ) : assignment.doctype === "presentation" ? (
                    <InsertDriveFileTwoTone
                      style={{
                        fontSize: "2em",
                        verticalAlign: "middle",
                        fill: "cornflowerblue",
                      }}
                    />
                  ) : assignment.doctype === "form" ? (
                    <AssignmentTurnedInTwoTone
                      style={{
                        fontSize: "2em",
                        verticalAlign: "middle",
                        fill: "darkcyan",
                      }}
                    />
                  ) : (
                    <ListAltTwoTone
                      style={{
                        fontSize: "2em",
                        verticalAlign: "middle",
                        fill: "mediumslateblue",
                      }}
                    />
                  )}
                  {assignment.object_name}
                </Typography>
                <Typography style={{ display: "flex", alignItems: "center" }}>
                  {assignment.assigned_to.type === "org" ? (
                    <Business />
                  ) : assignment.assigned_to.type === "role" ? (
                    <Group />
                  ) : (
                    <Person />
                  )}
                  {assignment.assigned_to.name}
                </Typography>
                <Typography variant="subtitle2" style={{ overflowWrap: "anywhere" }}>
                  {assignment.notes ? `${assignment.notes}` : ""}
                </Typography>
              </div>
              <div style={{ justifySelf: "left", alignSelf: "center" }}>
                {assignment.claimed ? (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() =>
                      dispatch(unclaimAssignment({ kind: assignment.kind, id: assignment.id }))
                        .then((res) => {
                          dispatch(
                            setAssignments({
                              assignments: assignments.map((x) => (x.id === res.data.id ? res.data : x)),
                            })
                          );
                          dispatch(
                            setAlert({
                              alert: `You've unclaimed the assignment to ${assignment.object_name}`,
                              severity: "warning",
                            })
                          );
                        })
                        .catch((e) => {
                          dispatch(
                            setAlert({
                              alert: `Something went wrong unclaiming this assignment...`,
                              severity: "warning",
                            })
                          );
                        })
                    }
                  >
                    Unclaim
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={() =>
                      dispatch(claimAssignment({ kind: assignment.kind, id: assignment.id }))
                        .then((res) => {
                          dispatch(
                            setAssignments({
                              assignments: assignments.map((x) => (x.id === res.data.id ? res.data : x)),
                            })
                          );
                          dispatch(
                            setAlert({
                              alert: `You've claimed the assignment to ${assignment.object_name}`,
                              severity: "success",
                            })
                          );
                        })
                        .catch((e) => {
                          dispatch(
                            setAlert({
                              alert: `Something went wrong claiming this assignment...`,
                              severity: "warning",
                            })
                          );
                        })
                    }
                  >
                    Claim
                  </Button>
                )}
              </div>
            </CardContent>
          </Card>
        );
      })
  ) : (
    <div>
      <Typography>{`No Assignments availble for ${name}`}</Typography>
    </div>
  );
}
