import { getBackendOptions, MultiBackend, Tree } from "@minoru/react-dnd-treeview";
import { AssignmentTurnedInTwoTone } from "@mui/icons-material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { CssBaseline, IconButton, Popover, Typography } from "@mui/material";
import { useState } from "react";
import { DndProvider } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { setDidQuestionsChange } from "../../../Documentation/Reducers/DocumentationReducer";
import { setTree } from "../../../Editor/Reducers/EditorReducer";
import { CustomNode } from "./CustomNode";
import { flattenTree } from "./flattenTree";
import { QuestionSelectionDialogMultiple } from "./QuestionSelectionDialogMultiple";
import styles from "./Tree.module.css";
import { unflattenTree } from "./unflattenTree";

export const QuestionLinkEditor = ({ treeID, openSelection, setOpenSelection, setOpen, setTreeID }) => {
  const dispatch = useDispatch();
  const tree = useSelector((state) => state.editor.tree);
  const questions = useSelector((state) => state.editor.questions);
  const [source, setSource] = useState(null);
  const [destination, setDestination] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "form-link-popover" : undefined;

  function handleDrop(flatTree, options) {
    let { dragSourceId, dropTargetId } = options;
    if (dropTargetId !== 0) {
      //target is not root
      console.log("target is not root");
      setSource(parseInt(dragSourceId));
      setDestination(parseInt(dropTargetId));
      setOpenSelection(true);
    } else {
      //target is root
      let removeParentId = JSON.parse(JSON.stringify(flatTree)).reduce((a, c) => {
        if (c.id === dragSourceId) {
          c.parent = 0;
          c.parent_question_id = null;
          c.question_id = null;
        }
        a.push(c);
        return a;
      }, []);

      const unflattedTree = unflattenTree(removeParentId);
      dispatch(setTree({ tree: unflattedTree }));
      dispatch(setDidQuestionsChange({ isChanged: true }));
    }
  }

  let flattenedTree = tree && flattenTree(tree, 0);
  return (
    <>
      <CssBaseline />

      {!tree || !tree?.length || !flattenedTree ? (
        <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div> - - Empty - - </div>
        </div>
      ) : (
        <div>
          <div style={{ display: "flex", alignItems: "center", paddingLeft: "1em" }}>
            <Typography style={{ textDecoration: "underline" }} variant="h5">
              Form Links
            </Typography>
            <IconButton aria-describedby={id} variant="contained" onClick={handleClick}>
              <HelpOutlineIcon />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              PaperProps={{
                style: { border: "1px solid", padding: "0.5em" },
              }}
            >
              <Typography style={{ fontSize: "0.8em" }} variant="subtitle2" id="nested-list-subheader">
                - Use the plus button in the bottom right to add a form to this result sheet.
              </Typography>
              <Typography style={{ fontSize: "0.8em" }} variant="subtitle2" id="nested-list-subheader">
                - Use the minus button to the right of each form to remove it and its associated questions.
              </Typography>
              <Typography style={{ fontSize: "0.8em" }} variant="subtitle2" id="nested-list-subheader">
                - Use the plus button to the right of the form to link two forms together, You can also drag and drop
                forms in this section to link forms together.
              </Typography>
            </Popover>
          </div>
          <DndProvider backend={MultiBackend} options={getBackendOptions()}>
            <Tree
              tree={flattenedTree}
              rootId={0}
              render={(node, options) => {
                return (
                  <CustomNode
                    hasChild={options.hasChild}
                    node={node}
                    depth={options.depth}
                    isOpen={options.isOpen}
                    onToggle={options.onToggle}
                    dispatch={dispatch}
                    questions={questions}
                    setSource={setSource}
                    setDestination={setDestination}
                    tree={tree}
                    setTreeID={setTreeID}
                    setOpen={setOpen}
                    setOpenSelection={setOpenSelection}
                  />
                );
              }}
              listComponent={"div"}
              listItemComponent={"div"}
              onDrop={handleDrop}
              classes={{
                root: styles.treeRoot,
                draggingSource: styles.draggingSource,
                dropTarget: styles.dropTarget,
                listItem: styles.listItem,
              }}
              initialOpen={true}
              dragPreviewRender={(monitorProps) => {
                return (
                  <div>
                    <AssignmentTurnedInTwoTone
                      style={{
                        fontSize: "1em",
                        fill: "darkcyan",
                      }}
                    />
                    <Typography variant="body2">{monitorProps.item.text}</Typography>
                  </div>
                );
              }}
            />
          </DndProvider>
        </div>
      )}

      {openSelection && (
        <QuestionSelectionDialogMultiple
          treeID={treeID}
          setOpen={setOpen}
          openSelection={openSelection}
          setOpenSelection={setOpenSelection}
          sourceID={source}
          destinationID={destination}
          setTreeID={setTreeID}
        />
      )}
    </>
  );
};
