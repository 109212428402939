import { AssignmentTurnedInTwoTone, InsertDriveFileTwoTone, ListAltTwoTone } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { documentationSelector } from "../../Reducers/DocumentationReducer";
import { createDocument } from "../../Reducers/thunks";

export const NewDocumentDialog = (props) => {
  const dispatch = useDispatch();
  const { currentFolder } = useSelector(documentationSelector);
  const { onCreated, onClose, ...passthroughProps } = props;
  const [name, setName] = useState(() => "");
  const [description, setDescription] = useState(() => "");
  const [doctype, setDoctype] = React.useState("presentation");
  const [error, setError] = useState();
  const handleChange = (event) => {
    setDoctype(event.target.value);
  };
  const onChangeName = (e) => setName(e.target.value);
  const onChangeDesc = (e) => setDescription(e.target.value);
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createDocument({
        folder_id: currentFolder.folder.id,
        name,
        description,
        contents: {},
        doctype,
      })
    )
      .then((value) => {
        onClose();
        onCreated(value);
      })
      .catch(function (err) {
        if (err.response) {
          setError(err.response.data.detail);
        }
        return Promise.reject(err);
      });
  };
  const resetForm = () => {
    setName("");
    setDescription("");
    setError();
  };

  return (
    <Dialog onClose={onClose} TransitionProps={{ onEntering: resetForm }} {...passthroughProps}>
      <div style={{ display: "flex" }}>
        {doctype === "presentation" ? (
          <InsertDriveFileTwoTone
            style={{
              fontSize: "4em",
              fill: "cornflowerblue",
            }}
          />
        ) : doctype === "form" ? (
          <AssignmentTurnedInTwoTone
            style={{
              fontSize: "4em",
              fill: "darkcyan",
            }}
          />
        ) : (
          //  : doctype === "report" ? (
          //   <SummarizeTwoTone
          //     style={{
          //       fontSize: "4em",
          //       fill: "#9c27b0",
          //     }}
          //   />
          // )
          <ListAltTwoTone
            style={{
              fontSize: "4em",
              fill: "mediumslateblue",
            }}
          />
        )}
        <DialogTitle
          style={{
            padding: "16px 0",
          }}
          id="form-dialog-title"
        >
          New{" "}
          {doctype === "presentation"
            ? "Presentation"
            : doctype === "form"
            ? "Checklist"
            : // : doctype === "report"
              // ? "Report"
              "Result Sheet"}
        </DialogTitle>
      </div>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <DialogContentText style={{ paddingBottom: "1em" }}>
            To create a new{" "}
            {doctype === "presentation"
              ? "presentation"
              : doctype === "form"
              ? "checklist"
              : // : doctype === "report"
                // ? "report"
                "spreadsheet"}
            , enter a name and optional description below
          </DialogContentText>
          {error && <Alert severity="error">{error}</Alert>}
          <FormControl fullWidth>
            <InputLabel id="select-required-label">Kind</InputLabel>
            <Select
              label="Kind"
              labelId="select-required-label"
              id="select-required"
              value={doctype}
              onChange={handleChange}
            >
              <MenuItem value={"presentation"}>Presentation</MenuItem>
              <MenuItem value={"form"}>Checklist</MenuItem>
              <MenuItem value={"sheet"}>Result Sheet</MenuItem>
              {/* <MenuItem value={"report"}>Report</MenuItem> */}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={name}
            onChange={onChangeName}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            value={description}
            onChange={onChangeDesc}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button disabled={!name || !doctype} type={"submit"} style={{ color: "#097c09" }}>
            Create{" "}
            {doctype === "presentation"
              ? "Presentation"
              : doctype === "form"
              ? "Checklist"
              : // : doctype === "report"
                // ? "Report"
                "Result Sheet"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
