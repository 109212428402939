import { Add } from "@mui/icons-material/";
import { Fab } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { setDidQuestionsChange, setQuestionIndex } from "../../Documentation/Reducers/DocumentationReducer";
import { addQuestion } from "../../Editor/Reducers/EditorReducer";
import { reorder } from "../helpers";
import { EditChecklistCard } from "./EditChecklistCard/EditChecklistCard";

export function ChecklistEditor() {
  const dispatch = useDispatch();
  const question_index = useSelector((state) => state.documentation.question_index);
  const didQuestionsChange = useSelector((state) => state.documentation.didQuestionsChange);

  function onDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const newOrder = reorder(question_index, result.source.index, result.destination.index);
    dispatch(setQuestionIndex({ newOrder }));
  }
  return (
    <div style={{ overflowY: "auto", height: "100%", paddingBottom: "5em" }}>
      {!question_index || !question_index?.length ? (
        <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div> - - Empty - - </div>
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {question_index
                  ? question_index.map((question, index, arr) => (
                      <Draggable key={`draggable${index}`} draggableId={`draggable${index}`} index={index}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <EditChecklistCard
                              key={index}
                              id={question}
                              index={index + 1}
                              isLast={Object.keys(arr).length - 1 === index}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))
                  : ""}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <div style={{ position: "absolute", bottom: "1em", right: "1em" }}>
        <Fab
          color="primary"
          onClick={() => {
            const id = uuidv4();
            if (!didQuestionsChange) dispatch(setDidQuestionsChange({ isChanged: true }));
            dispatch(addQuestion({ id }));
            dispatch(setQuestionIndex({ add: id }));
          }}
          aria-label="addQuestion"
        >
          <Add />
        </Fab>
      </div>
    </div>
  );
}
